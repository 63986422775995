import { PaletteColor, Stack, TypeBackground, TypeText } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ColorPartial } from "@mui/material/styles/createPalette";
import { isNumber, isObject } from "lodash-es";

import InlineCode from "@/common/components/Code/InlineCode";
import { THEMES } from "@/common/constants/common";
import { createCustomTheme } from "@/theme";

export default function DevPalette() {
  const isPaletteColor = (colorObj: any) => (colorObj as PaletteColor).main;
  const isBackgroundColor = (colorObj: any) =>
    (colorObj as Partial<TypeBackground>).default && (colorObj as Partial<TypeBackground>).paper;
  const isTextColor = (colorObj: any) =>
    (colorObj as Partial<TypeText>).primary && (colorObj as Partial<TypeText>).secondary;
  const isPartialColor = (colorObj: any) =>
    Object.keys(colorObj as ColorPartial).length !== 0 &&
    Object.keys(colorObj as ColorPartial).every((key) => isNumber(key) || /^A\d+$/.test(key));

  const getColorsByThemeMode = (mode: THEMES) => {
    const themeByMode = createCustomTheme({
      theme: mode,
      responsiveFontSizes: false,
    });
    return Object.entries(themeByMode.palette)
      .filter(
        ([colorName, colorObj]) =>
          isObject(colorObj) &&
          (isPaletteColor(colorObj) ||
            isBackgroundColor(colorObj) ||
            isTextColor(colorObj) ||
            isPartialColor(colorObj)),
      )
      .map(([colorName, colorObj]) => ({
        name: colorName,
        value: colorObj as PaletteColor,
        paletteColor: isPaletteColor(colorObj) ? (colorObj as PaletteColor) : undefined,
        backgroundColor: isBackgroundColor(colorObj)
          ? (colorObj as Partial<TypeBackground>)
          : undefined,
        textColor: isTextColor(colorObj) ? (colorObj as Partial<TypeText>) : undefined,
        partialColor: isPartialColor(colorObj) ? (colorObj as ColorPartial) : undefined,
      }))
      .map((color) => ({
        ...color,
        variants:
          (color.paletteColor && [
            { name: "main", value: color.paletteColor.main },
            { name: "dark", value: color.paletteColor.dark },
            { name: "light", value: color.paletteColor.light },
            { name: "contrastText", value: color.paletteColor.contrastText },
          ]) ||
          (color.backgroundColor && [
            { name: "default", value: color.backgroundColor.default },
            { name: "paper", value: color.backgroundColor.paper },
            { name: "gray", value: color.backgroundColor.gray },
          ]) ||
          (color.textColor && [
            { name: "primary", value: color.textColor.primary },
            { name: "secondary", value: color.textColor.secondary },
            { name: "disabled", value: color.textColor.disabled },
          ]) ||
          (color.partialColor &&
            Object.entries(color.partialColor).map(([key, value]) => ({
              name: key,
              value: value,
            }))) ||
          [],
      }));
  };
  return (
    <Stack spacing={2}>
      <Stack spacing={4}>
        {Object.values(THEMES).map((mode) => (
          <>
            <Typography component='div' variant='h4'>
              {`Palette ${mode}`}
            </Typography>
            {getColorsByThemeMode(mode).map((color, i) => {
              return (
                <Box key={i}>
                  <Typography component='div' variant='subtitle1'>
                    {color.name}
                  </Typography>

                  <Stack direction='row' spacing={2}>
                    {color.variants.map((variant, j) => (
                      <Stack key={j} direction='row' spacing={1} sx={{ width: 250 }}>
                        <Box
                          sx={{
                            width: 48,
                            height: 48,
                            borderRadius: 4,
                            border: "1px solid grey",
                            backgroundColor: variant.value,
                          }}
                        ></Box>
                        <Box>
                          <Typography component='div' variant='body1'>
                            {variant.name}
                          </Typography>
                          <Typography component='div' variant='body1'>
                            <InlineCode>{variant.value || "-"}</InlineCode>
                          </Typography>
                        </Box>
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              );
            })}
          </>
        ))}
      </Stack>
    </Stack>
  );
}
