import { Box, Fade, Stack } from "@mui/material";
import { isEmpty } from "lodash-es";
import { useState } from "react";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import { GeneralAttachedTagsDto, TagEntityType } from "@/core/api/generated";

import GeneralAttachedTagsOfEntityEditModal from "./GeneralAttachedTagsOfEntityEditModal";
import GeneralTagDisplay from "./GeneralTagDisplay";
import TagList, { TagListProps } from "./TagList";

export interface OwnProps {
  tags: GeneralAttachedTagsDto | null | undefined;
  defaultIsFolded?: boolean;
  /** Set to non-nullable value to enable edit. */
  edit?: {
    entityType: TagEntityType;
    entityId: string | null | undefined;
    onSaved: (newTags: GeneralAttachedTagsDto | null | undefined) => void | Promise<void>;
  };
  tagListSx?: TagListProps["sx"];
  isActionButtonAtStart?: boolean;
}

type Props = OwnProps;

export default function GeneralAttachedTagsDisplay({
  tags,
  defaultIsFolded = false,
  edit,
  tagListSx,
  isActionButtonAtStart,
}: Props) {
  const [isFolded] = useState(false);
  const [isEditTagsModalOpen, setIsEditTagsModalOpen] = useState(defaultIsFolded);

  return (
    <>
      <Stack direction='row' spacing={1} alignItems='center'>
        {!isActionButtonAtStart && isEmpty(tags?.tags) && (
          <NoDataAlert variant='inline' title='No tags' withIcon={false} />
        )}

        <Fade in={!isFolded} unmountOnExit>
          <Box>
            <TagList
              sx={tagListSx}
              tags={tags?.tags?.map((tag, i) => <GeneralTagDisplay key={i} tag={tag} />) || []}
              maxVisibleItems={undefined}
              startAction={
                isActionButtonAtStart &&
                edit?.entityId && (
                  <AppIconButton
                    variant='outlined'
                    size='extraSmall'
                    color='text'
                    tooltipProps={{
                      title: "Edit tags",
                    }}
                    onClick={(e) => {
                      setIsEditTagsModalOpen(true);
                    }}
                  >
                    <AppIcon of='edit' />
                  </AppIconButton>
                )
              }
              endAction={
                !isActionButtonAtStart &&
                edit?.entityId && (
                  <AppIconButton
                    variant='outlined'
                    size='extraSmall'
                    color='text'
                    tooltipProps={{
                      title: "Edit tags",
                    }}
                    onClick={(e) => {
                      setIsEditTagsModalOpen(true);
                    }}
                  >
                    <AppIcon of='edit' />
                  </AppIconButton>
                )
              }
            />
          </Box>
        </Fade>

        {isActionButtonAtStart && isEmpty(tags?.tags) && (
          <NoDataAlert variant='inline' title='No tags' withIcon={false} />
        )}
      </Stack>

      {edit && (
        <GeneralAttachedTagsOfEntityEditModal
          open={isEditTagsModalOpen}
          onClose={() => setIsEditTagsModalOpen(false)}
          entityType={edit.entityType}
          entityId={edit.entityId || ""}
          onSaved={(newValue) => {
            edit.onSaved && edit.onSaved(newValue);
          }}
        />
      )}
    </>
  );
}
