import { isNil } from "lodash-es";
import { createContext, useContext, useRef } from "react";

import {
  EventKey,
  EventReceiver,
  ITypedEventEmitter,
  TypedEventEmitter,
} from "../eventEmmiters/typedEventEmitter";
import { useEffectWithDeepCompare } from "../hooks/effect/useEffectWithDeepCompare";
import { useEventEmitterSubscription } from "../hooks/useEventEmitterSubscription";
import { AppBreadcrumbsReplacements, IAppBreadcrumbIdValueReplacement } from "../ts/breadcrumbs";

export type BreadcrumbsContextEventsMap = {
  // list of supported events
  idBreadcrumbReplacementReceived: IAppBreadcrumbIdValueReplacement;
  breadcrumbReplacementsReceived: AppBreadcrumbsReplacements;
};

class BreadcrumbsContextValue extends TypedEventEmitter<BreadcrumbsContextEventsMap> {
  setIdBreadcrumbReplacement(data: IAppBreadcrumbIdValueReplacement | null | undefined): void {
    if (data) {
      this.emit("idBreadcrumbReplacementReceived", data);
    }
  }

  setBreadcrumbReplacements(data: AppBreadcrumbsReplacements | null | undefined): void {
    if (data) {
      this.emit("breadcrumbReplacementsReceived", data);
    }
  }
}

export type BreadcrumbsContextType = BreadcrumbsContextValue;

const breadcrumbsContextValue = new BreadcrumbsContextValue();

const BreadcrumbsContext = createContext<BreadcrumbsContextType | null>(null);

export function BreadcrumbsContextProvider({ children }: { children: React.ReactNode }) {
  const valueRef = useRef(breadcrumbsContextValue);

  return (
    <BreadcrumbsContext.Provider value={valueRef.current}>{children}</BreadcrumbsContext.Provider>
  );
}

export const useBreadcrumbsContext = (): BreadcrumbsContextType => {
  const breadcrumbsContext = useContext(BreadcrumbsContext);

  if (!breadcrumbsContext) {
    throw new Error("useBreadcrumbsContext() has to be used within <BreadcrumbsContext.Provider>");
  }

  return breadcrumbsContext;
};

export const useBreadcrumbsContextOptional = (): BreadcrumbsContextType | null => {
  const breadcrumbsContext = useContext(BreadcrumbsContext);
  return breadcrumbsContext;
};

export const useIdBreadcrumbReplacement = (
  data: IAppBreadcrumbIdValueReplacement | null | undefined,
): void => {
  const breadcrumbsContext = useBreadcrumbsContext();

  useEffectWithDeepCompare(() => {
    breadcrumbsContext.setIdBreadcrumbReplacement(data);
  }, [data]);
};

export const useBreadcrumbReplacements = (replacements: AppBreadcrumbsReplacements): void => {
  const breadcrumbsContext = useBreadcrumbsContext();

  useEffectWithDeepCompare(() => {
    if (isNil(replacements.isEnabled) || replacements.isEnabled) {
      breadcrumbsContext.setBreadcrumbReplacements(replacements);
    }
  }, [replacements]);
};

export const useBreadcrumbsEventEmitterSubscription = <
  T extends BreadcrumbsContextEventsMap,
  K extends EventKey<T>,
>(
  eventEmitter: BreadcrumbsContextType,
  eventName: K,
  handler: EventReceiver<T[K]>,
): void => {
  return useEventEmitterSubscription<T, K>(
    eventEmitter as ITypedEventEmitter<T>,
    eventName,
    handler,
  );
};
