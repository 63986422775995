import { Alert, AlertTitle, Box, SxProps, Theme } from "@mui/material";

import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import { DamageCostEvaluationHelper } from "@/common/helpers/entity/damageCostEvaluation";
import {
  DamageCostEvaluationCreateDto,
  DamageCostEvaluationDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationItemUpdateDto,
  DamageCostEvaluationUpdateDto,
  RepairSpecDto,
} from "@/core/api/generated";

import AppPopover from "../../Popover/AppPopover";
import AppTypography from "../../Text/AppTypography";

interface OwnProps {
  entity:
    | DamageCostEvaluationDto
    | DamageCostEvaluationCreateDto
    | DamageCostEvaluationUpdateDto
    | null
    | undefined;
  item:
    | DamageCostEvaluationItemDto
    | (DamageCostEvaluationItemUpdateDto & { repairSpec?: RepairSpecDto });
  sx?: SxProps<Theme>;
}

export type CurrencyMismatchWithRepairSpecAlertProps = OwnProps;

export default function CurrencyMismatchWithRepairSpecAlert({
  entity,
  item,
  sx,
}: CurrencyMismatchWithRepairSpecAlertProps) {
  return DamageCostEvaluationHelper.isCurrencyMismatchWithRepairSpec(entity, item?.repairSpec) ? (
    <Alert
      severity='warning'
      sx={{
        width: "fit-content",
        ...sx,
      }}
    >
      <AlertTitle>
        <AppPopover
          hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
          trigger={
            <AppTypography isInheritFontStyle decoration={{ variant: "helpText" }}>
              Currency mismatch detected!
            </AppTypography>
          }
        >
          <Alert icon={false} severity='warning' sx={{ maxWidth: { xxs: "90vw", md: 400 } }}>
            <Box>
              Damage cost evaluation uses{" "}
              <GeneralCurrencyDisplay
                currency={entity?.currency}
                sx={{ color: "inherit", fontWeight: "bold" }}
              />
              , but repair spec uses{" "}
              <GeneralCurrencyDisplay
                currency={item.repairSpec?.currency}
                sx={{ color: "inherit" }}
              />
              . {`Prices won't be converted automatically between different currencies.`}{" "}
              {`You need to resolve the mismatch manually.`}{" "}
              <>
                If not,{" "}
                <GeneralCurrencyDisplay
                  currency={entity?.currency}
                  sx={{ color: "inherit", fontWeight: "bold" }}
                />{" "}
                will be used.
              </>
            </Box>
          </Alert>
        </AppPopover>
      </AlertTitle>
    </Alert>
  ) : null;
}
