import { debounce } from "lodash-es";
import { useCallback, useEffect } from "react";

import { UserPartiesMembershipDto } from "@/core/api/generated";
import { getUserPartiesMembership } from "@/store/parties/slice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";

/** Returns parties membership of currently authenticated user. */
export const useCurrentUserPartiesMembership = (): UserPartiesMembershipDto | undefined => {
  const dispatch = useAppThunkDispatch();
  const userPartiesMembership = useAppSelector((x) => x.parties.userPartiesMembership);
  const isLoading = useAppSelector((x) => x.parties.loading.getUserPartiesMembership);

  const dispatchDebounce = useCallback(
    debounce(dispatch, 1000, { leading: true, trailing: false }),
    [dispatch],
  );

  useEffect(() => {
    if (!userPartiesMembership && !isLoading) {
      dispatchDebounce(getUserPartiesMembership({ nexusOpsTenant: EMPTY_TENANT_IDENTIFIER }));
    }
  }, [userPartiesMembership, isLoading]);

  return userPartiesMembership;
};
