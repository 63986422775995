import { LocalStorageKey } from "@/common/constants/localStorage";
import { IBaseEntityDto } from "@/core/api/generated";

import { LocalStorageHelper } from "../localStorage";

/** Repair spec, material, work, etc/ */
export type RepairEntityDtoType = IBaseEntityDto & { tenantId?: Nil<string> };

/** Settings for repair spare part autocomplete filters */
export interface RepairSparePartAutocompleteSettings {
  filters: {
    partType?: boolean;
    make?: boolean;
    model?: boolean;
  };
}

/** Settings for repair spec autocomplete filters */
export interface RepairSpecAutocompleteSettings {
  filters: {
    partType?: boolean;
    damageType?: boolean;
    make?: boolean;
    model?: boolean;
  };
}

export interface RepairEntitySettingsInLocalStorage {
  /** Tenant to be selected by default when accessing cross-tenant repair entities. */
  defaultTenantIdForCrossTenant?: Nil<string>;

  /** Autocomplete filter settings for repair spare parts. */
  repairSparePartAutocompleteSettings?: RepairSparePartAutocompleteSettings;

  /** Autocomplete filter settings for repair spec. */
  repairSpecAutocompleteSettings?: RepairSpecAutocompleteSettings;
}

export class RepairHelper {
  public static isExternal(params: {
    tenantId: Nil<string>;
    repairEntity: Nil<RepairEntityDtoType>;
  }): boolean {
    return (
      !!params.tenantId && !!params.repairEntity && params.tenantId !== params.repairEntity.tenantId
    );
  }

  public static getLocalSettings(): Nil<RepairEntitySettingsInLocalStorage> {
    return (
      LocalStorageHelper.getTypedJsonItem<RepairEntitySettingsInLocalStorage>(
        LocalStorageKey.repairEntitySettings,
      ) ||
      LocalStorageHelper.getTypedJsonItem<RepairEntitySettingsInLocalStorage>(
        LocalStorageKey.repairSpecSettings,
      ) // migrate from old settings
    );
  }

  public static saveLocalSettings(settings: Nil<RepairEntitySettingsInLocalStorage>): void {
    LocalStorageHelper.setJsonItem(LocalStorageKey.repairEntitySettings, settings);
  }

  public static getRepairSparePartAutocompleteSettings(): RepairSparePartAutocompleteSettings {
    const localSettings = this.getLocalSettings() || {};
    return localSettings.repairSparePartAutocompleteSettings ?? { filters: {} };
  }

  public static saveRepairSparePartAutocompleteSettings(
    settings: RepairSparePartAutocompleteSettings,
  ): void {
    const localSettings = this.getLocalSettings() || {};
    localSettings.repairSparePartAutocompleteSettings = settings;
    this.saveLocalSettings(localSettings);
  }

  public static getRepairSpecAutocompleteSettings(): RepairSpecAutocompleteSettings {
    const localSettings = this.getLocalSettings() || {};
    return localSettings.repairSpecAutocompleteSettings ?? { filters: {} };
  }

  public static saveRepairSpecAutocompleteSettings(settings: RepairSpecAutocompleteSettings): void {
    const localSettings = this.getLocalSettings() || {};
    localSettings.repairSpecAutocompleteSettings = settings;
    this.saveLocalSettings(localSettings);
  }
}
