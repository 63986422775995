import { Box, SxProps, Theme } from "@mui/material";
import { isNil } from "lodash-es";

interface Props {
  value?: boolean | null;
  /** Of container. */
  sx?: SxProps<Theme>;
}

/** Displays boolean value. */
export default function BooleanValue({ value, sx }: Props): React.ReactNode {
  return (
    <Box component='span' sx={sx}>
      {isNil(value) && "-"}
      {!isNil(value) ? (value && "Yes") || "No" : ""}
    </Box>
  );
}
