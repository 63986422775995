import {
  Autocomplete,
  CircularProgress,
  DialogProps,
  FormControl,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
  outlinedInputClasses,
} from "@mui/material";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  chain,
  debounce,
  isArray,
  isEmpty,
  isFunction,
  isString,
  orderBy,
  throttle,
  uniqBy,
} from "lodash-es";
import React, {
  DependencyList,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { PaginationHelper } from "@/common/helpers/pagination";
import { TypeHelper } from "@/common/helpers/type";
import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import { useMemoWithDeepCompare } from "@/common/hooks/memo/useMemoWithDeepCompare";
import {
  AutocompleteOption,
  AutocompleteOptionType,
  EntitiesToAutocompleteOptionsFunc,
  EntityToAutocompleteOptionFunc,
  createOptionForNoOptions,
  createOptionFromInputValue,
} from "@/common/ts/autocomplete";
import { PaginationDtoOfTItemTyped } from "@/common/ts/pagination";
import { IBaseEntityDto } from "@/core/api/generated";

import AppIconButton, { AppIconButtonProps } from "../../Button/AppIconButton";
import AppIcon from "../../Icons/AppIcon";

export type AutocompleteSortByFunc<TEntity extends IBaseEntityDto> = (
  option: AutocompleteOption<TEntity>,
) => string;

export type AutocompleteSortOrder = "asc" | "desc";

export type AutocompleteGroupByFunc<TEntity extends IBaseEntityDto> = (
  option: AutocompleteOption<TEntity>,
) => string;

export type AutocompleteRenderGroupTitleParams<TEntity extends IBaseEntityDto> = {
  group: string;
  options: AutocompleteOption<TEntity>[];
  props: HTMLAttributes<HTMLDivElement> & { key?: string };
};

export function sortOptions<TEntity extends IBaseEntityDto>(params: {
  options: AutocompleteOption<TEntity>[];
  sortBy: AutocompleteSortByFunc<TEntity> | null | undefined;
  sortOrder: AutocompleteSortOrder | null | undefined;
}) {
  // NB: the options must be sorted with the same dimension that they are grouped by, otherwise, you will notice duplicate headers.
  return chain(params.options)
    .groupBy((x) => x.groupBy)
    .mapValues((v, k) =>
      params.sortBy
        ? orderBy(v, (x) => params.sortBy && params.sortBy(x), params.sortOrder || "asc")
        : v,
    )
    .values()
    .flatten()
    .value();
}

export interface BaseRequestParams {
  limit?: number;
  search?: string;
  includeIds?: string[];
}

// export const baseRequestParamsNamesMap: Record<keyof BaseRequestParams, boolean> = {
//   limit: true,
//   search: true,
//   includeIds: true,
// };
// export const baseRequestParamsNames: string[] = Object.keys(baseRequestParamsNamesMap);

export type EntitySearchResponseData<TEntity> = TEntity[] | PaginationDtoOfTItemTyped<TEntity>;

export type EntitySearchRequestFuncParameters<
  TEntity,
  TRequestFunc extends (
    requestParameters: any,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
> = Parameters<TRequestFunc>[0] & BaseRequestParams;

export interface BaseEntitySearchAutocompleteProps<
  TEntity extends IBaseEntityDto,
  TRequestFunc extends (...args: any) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
  TRequestParams extends EntitySearchRequestFuncParameters<
    TEntity,
    TRequestFunc
  > = EntitySearchRequestFuncParameters<TEntity, TRequestFunc>,
> {
  // generic props
  entityId?: string | null;
  entity?: TEntity | null;
  entityToOption: EntityToAutocompleteOptionFunc<TEntity>;
  /** Load data even if the autocomplete is closed.*/
  isPreload?: boolean;
  /** Auto-select single option if the input is required and not disabled. */
  isAutoSelectSingleOption?: boolean;
  /** Auto-select first option if the input is required and not disabled. */
  isAutoSelectFirstOption?: boolean;
  /** Request configuration. */
  request: {
    requestFunc: TRequestFunc;
    limit?: number;
    parameters: TRequestParams;
    combineParameters: (params: TRequestParams, newParams: BaseRequestParams) => TRequestParams;
    deps: DependencyList;
    skip?: boolean;
  };
  /** Additional request configuration. */
  requestConfig?: {
    limit?: number;
    skip?: boolean;
  };

  // component props
  renderOption: (
    props: HTMLAttributes<HTMLLIElement> & { key: string },
    option: AutocompleteOption<TEntity>,
  ) => ReactNode;
  renderGroupTitle?: (params: AutocompleteRenderGroupTitleParams<TEntity>) => ReactNode;
  entityViewModal?: {
    /** @default true */
    if?: boolean;
    renderModal: (params: {
      entity: TEntity;
      dialogProps: Pick<DialogProps, "open" | "onClose">;
    }) => ReactNode;
  };
  entityEditModal?: {
    /** @default true */
    if?: boolean;
    renderModal: (params: {
      entity: TEntity;
      dialogProps: Pick<DialogProps, "open" | "onClose">;
      handleEntityUpdated: (newEntity: TEntity) => void;
    }) => ReactNode;
  };
  endAdornmentActions?: {
    /** @default true */
    if?: boolean;
    actions: Array<{
      /** @default true */
      if?: boolean;
      renderAction: (params: {
        actionIndex: number;
        entity: Nil<TEntity>;
        iconButtonProps: Partial<AppIconButtonProps>;
        handleEntitySelected: (newEntity: TEntity) => void;
      }) => ReactNode;
    }>;
  };
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  label?: TextFieldProps["label"];
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions?: boolean;
  placeholder: TextFieldProps["placeholder"];
  sortBy?: AutocompleteSortByFunc<TEntity>;
  sortOrder?: AutocompleteSortOrder;
  groupBy?: AutocompleteGroupByFunc<TEntity>;
  withCreate?: boolean;
  createOptionTitle?: string | ((inputValue: string) => string) | null;
  textFieldProps?: TextFieldProps;
  debugKey?: string;
  sx?: SxProps<Theme>;
  /** If `true`, the entity option will be disabled. */
  shouldDisable?: (entity: TEntity) => boolean;
  /** If `true`, the entity option will be hidden. */
  shouldHide?: (entity: TEntity) => boolean;
  onChange?: (newEntity?: TEntity) => void;
  /** Works only if withCreate is true. */
  onCreate?: (newOption?: AutocompleteOption<TEntity>) => void;
  /** Fired when new entities were fetched. */
  onLoaded?: (entities: TEntity[]) => void;
  onPaginatedLoaded?: (entities: PaginationDtoOfTItemTyped<TEntity>) => void;
  ignoreCanLoadCondition?: boolean;
}

/** Interface to be used by wrapper components that utilize this component. */
export interface BaseEntitySearchAutocompleteInheritableProps<TEntity extends IBaseEntityDto> {
  entityId?: string | null;
  entity?: TEntity | null;
  /** Load data even if the autocomplete is closed.*/
  isPreload?: boolean;
  /** Auto-select single option if the input is required and not disabled. */
  isAutoSelectSingleOption?: boolean;
  /** Auto-select first option if the input is required and not disabled. */
  isAutoSelectFirstOption?: boolean;
  /** Additional request configuration. */
  requestConfig?: {
    limit?: number;
    skip?: boolean;
  };
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  label?: TextFieldProps["label"];
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions?: boolean;
  withCreate?: boolean;
  createOptionTitle?: string | ((inputValue: string | null | undefined) => string) | null;
  textFieldProps?: TextFieldProps;
  debugKey?: string;
  sx?: SxProps<Theme>;
  /** If `true`, the entity option will be disabled. */
  shouldDisable?: (entity: TEntity) => boolean;
  /** If `true`, the entity option will be hidden. */
  shouldHide?: (entity: TEntity) => boolean;
  onChange?: (newEntity?: TEntity) => void;
  /** Works only if withCreate is true. */
  onCreate?: (newOption?: AutocompleteOption<TEntity>) => void;
  /** Fired when new entities were fetched. */
  onLoaded?: (entities: TEntity[]) => void;
  onPaginatedLoaded?: (entities: PaginationDtoOfTItemTyped<TEntity>) => void;
  ignoreCanLoadCondition?: boolean;
}

export default function BaseEntitySearchAutocomplete<
  TEntity extends IBaseEntityDto,
  TRequestFunc extends (
    requestParameters: any,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
  TRequestParams extends EntitySearchRequestFuncParameters<
    TEntity,
    TRequestFunc
  > = EntitySearchRequestFuncParameters<TEntity, TRequestFunc>,
>({
  entityId,
  entity,
  entityToOption,
  isPreload = true,
  isAutoSelectSingleOption = true,
  isAutoSelectFirstOption = false,
  request,
  requestConfig,
  renderOption,
  renderGroupTitle,
  entityViewModal,
  entityEditModal,
  endAdornmentActions,
  disabled,
  required,
  size,
  fullWidth,
  filterSelectedOptions = false,
  label,
  placeholder,
  sortBy,
  sortOrder,
  groupBy,
  withCreate,
  createOptionTitle,
  textFieldProps,
  debugKey,
  shouldDisable,
  shouldHide,
  onChange,
  onCreate,
  onLoaded,
  onPaginatedLoaded,
  ignoreCanLoadCondition,
  sx,
  ...otherProps
}: BaseEntitySearchAutocompleteProps<TEntity, TRequestFunc>) {
  const [isOpen, setIsOpen] = useState(false);
  const [hasPermission, setHasPermissions] = useState(true);
  const [options, setOptions] = useState<readonly AutocompleteOption<TEntity>[]>([]);
  const [selectedOption, setSelectedOption] = useState<AutocompleteOption<TEntity> | null>(null);

  const [inputValue, setInputValue] = useState<string | null | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResponseData, setSearchResponseData] = useState<
    EntitySearchResponseData<TEntity> | undefined
  >(undefined);
  const [isEntityViewModalOpen, setIsEntityViewModalOpen] = useState(false);
  const [isEntityEditModalOpen, setIsEntityEditModalOpen] = useState(false);

  const isRequestStartedRef = useRef(false);
  const isAutoSelectedRef = useRef(false);

  const isSelectedOptionLoaded = !entityId || (entityId && options.some((x) => x.id === entityId));
  const canLoad =
    ((isPreload || (!isPreload && isOpen)) && !selectedOption) || !isSelectedOptionLoaded;
  const canSearch = !selectedOption || selectedOption.title !== inputValue;
  const disabledComputed = disabled || !hasPermission;

  const optionsGroupedMap = useMemo(
    () =>
      options &&
      chain(options)
        .groupBy((x) => x.groupBy)
        .value(),
    [options],
  );

  const entityList = useMemo(
    () => (searchResponseData && isArray(searchResponseData) ? searchResponseData : undefined),
    [searchResponseData],
  );
  const paginatedEntities = useMemo(
    () => (searchResponseData && !isArray(searchResponseData) ? searchResponseData : undefined),
    [searchResponseData],
  );
  const entityListComputed = useMemo(
    () => entityList || paginatedEntities?.items || undefined,
    [entityList, paginatedEntities],
  );

  const entitiesToOptions = useMemo<EntitiesToAutocompleteOptionsFunc<TEntity>>(
    () => (entities) => (entities && entities.map((x) => entityToOption(x))) || [],
    [entityToOption],
  );

  const requestComputed = useMemo<typeof request>(
    () => ({
      ...request,
      ...requestConfig,
      limit: requestConfig?.limit ?? request.limit ?? PaginationHelper.defaultLimit,
      skip: request.skip || requestConfig?.skip,
    }),
    [request, requestConfig],
  );

  const currentLimitRef = useRef<number | undefined>(requestComputed.limit);

  const computeParameters = useCallback(
    (limit?: number, searchValue?: string | null, id?: string | null) => {
      const baseSearchParams: BaseRequestParams = {
        limit: limit,
        search: canSearch || !options.length ? searchValue || undefined : undefined,
        includeIds: id && !isSelectedOptionLoaded ? [id] : undefined,
      };
      return requestComputed.combineParameters(requestComputed.parameters, baseSearchParams);
    },
    [requestComputed.parameters, canSearch],
  );

  // compute request params
  const parametersComputed = useMemoWithDeepCompare(() => {
    return computeParameters(currentLimitRef.current, inputValue, entityId);
  }, [entityId, inputValue, requestComputed.parameters, currentLimitRef.current]);

  const searchRef = useRef(requestComputed.requestFunc);
  const searchComputed = useCallback(async (requestParams: TRequestParams) => {
    if (isRequestStartedRef.current) {
      return;
    }
    isRequestStartedRef.current = true;
    setIsLoading(true);
    try {
      const response = await searchRef.current(requestParams);
      setSearchResponseData(response.data);
    } catch (e) {
      const statusCode = (e as AxiosError).response?.status;
      if (statusCode === 403 || statusCode === 401) {
        setHasPermissions(false);
      }
    } finally {
      isRequestStartedRef.current = false;
      setIsLoading(false);
    }
  }, []);
  const searchThrottle = useCallback(
    throttle(searchComputed, 500, { leading: true, trailing: false }),
    [searchComputed],
  );
  const searchDebounce = useCallback(
    debounce(searchComputed, 500, { leading: false, trailing: true }),
    [searchComputed],
  );

  useEffectWithDeepCompare(() => {
    if (!ignoreCanLoadCondition && !canLoad) {
      return;
    }
    if (requestComputed.skip) {
      return;
    }
    if (isRequestStartedRef.current) {
      return;
    }
    currentLimitRef.current = requestComputed.limit;
    const params = computeParameters(currentLimitRef.current, inputValue, entityId);
    searchThrottle(params);
  }, [requestComputed.skip, entityId, ignoreCanLoadCondition, canLoad, ...requestComputed.deps]);

  useEffect(() => {
    if (!canLoad && !canSearch) {
      return;
    }
    if (requestComputed.skip) {
      return;
    }
    if (isRequestStartedRef.current) {
      return;
    }
    currentLimitRef.current = requestComputed.limit;
    const params = computeParameters(currentLimitRef.current, inputValue, entityId);
    searchDebounce(params);
  }, [inputValue, canLoad, canSearch]);

  useEffect(() => {
    if (entityListComputed) {
      onLoaded && onLoaded(entityListComputed);
    }
  }, [entityListComputed]);

  useEffect(() => {
    if (paginatedEntities) {
      onPaginatedLoaded && onPaginatedLoaded(paginatedEntities);
    }
  }, [paginatedEntities]);

  // construct new options
  useEffect(() => {
    let newOptions = uniqBy(
      [
        ...(selectedOption && selectedOption.title === inputValue ? [selectedOption] : []),
        ...entitiesToOptions(entityListComputed || []),
      ],
      (x) => x.id,
    );
    newOptions = sortOptions({ options: newOptions, sortBy, sortOrder });
    setOptions(newOptions);

    // handle isAutoSelectSingleOption, isAutoSelectFirstOption
    const singleOption = newOptions.length === 1 ? newOptions[0] : undefined;
    const firstOption = newOptions.length >= 1 ? newOptions[0] : undefined;
    const canAutoSelectOption =
      required &&
      !disabledComputed &&
      !isRequestStartedRef.current &&
      !isLoading &&
      !entityId &&
      !entity &&
      !selectedOption &&
      !inputValue &&
      !isAutoSelectedRef.current;
    if (isAutoSelectSingleOption && canAutoSelectOption && singleOption) {
      setSelectedOption(singleOption);
      onChange && onChange(singleOption.data || undefined);
      isAutoSelectedRef.current = true;
    } else if (isAutoSelectFirstOption && canAutoSelectOption && firstOption) {
      setSelectedOption(firstOption);
      onChange && onChange(firstOption.data || undefined);
      isAutoSelectedRef.current = true;
    }
  }, [entityListComputed]);

  useEffect(() => {
    // console.log("entityId:", entityId);
    if (!entity && !entityId && selectedOption) {
      setSelectedOption(null);
      setInputValue(undefined);
    } else if (entity && selectedOption?.id !== entity.id) {
      setOptions(
        sortOptions({
          options: uniqBy([entityToOption(entity), ...options], (x) => x.id),
          sortBy,
          sortOrder,
        }),
      );
      setSelectedOption(entityToOption(entity));
    } else if (entityId && selectedOption?.id !== entityId) {
      setSelectedOption(options.find((x) => x.id === entityId) || null);
    }
  }, [entityId, entity, selectedOption, options]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage:
      (paginatedEntities?.pagination?.limit || 0) <
      (paginatedEntities?.pagination?.totalCount || 0),
    onLoadMore: () => {
      currentLimitRef.current = (currentLimitRef.current || 0) + (requestComputed.limit || 0);
      searchComputed(parametersComputed);
    },
    rootMargin: "0px 0px 300px 0px",
  });

  const sentryOption: AutocompleteOption<TEntity> = useMemo(
    () => ({ id: "sentry", title: "", optionType: AutocompleteOptionType.Sentry }),
    [],
  );

  return (
    <Autocomplete
      sx={{ minWidth: 200, flex: 1, ...sx }}
      fullWidth={fullWidth}
      size={size}
      disabled={disabledComputed}
      filterSelectedOptions={filterSelectedOptions}
      open={isOpen}
      options={options || []}
      loading={isLoading}
      autoComplete
      openOnFocus
      blurOnSelect
      includeInputInList
      value={selectedOption}
      inputValue={inputValue || ""}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo={withCreate}
      ListboxProps={{ ref: rootRef }}
      groupBy={groupBy}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        // value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // regular option
        return option.title;
      }}
      filterOptions={(options2, params) => {
        // suggest the creation of a new value (add dynamic option with input value)
        if (withCreate) {
          const isExisting = options.some((option) => params.inputValue === option.title);
          if (params.inputValue !== "" && !isExisting) {
            const title =
              (createOptionTitle &&
                ((isFunction(createOptionTitle) && createOptionTitle(params.inputValue)) ||
                  (isString(createOptionTitle) && createOptionTitle))) ||
              `Create new "${params.inputValue}"`;

            options2.unshift(
              createOptionFromInputValue({
                inputValue: params.inputValue,
                title: title,
              }),
            );
          }
        }

        if (
          !isEmpty(options2) &&
          (paginatedEntities?.pagination?.limit || 0) <
            (paginatedEntities?.pagination?.totalCount || 0)
        ) {
          // add sentry option for infinite scroll
          options2.push(sentryOption);
        }

        // add 'No data' option
        if (isEmpty(options2) && !isLoading) {
          options2.push(createOptionForNoOptions());
        }

        return options2;
      }}
      onChange={(e, newValue) => {
        // value selected with enter, right from the input
        if (typeof newValue === "string") {
          withCreate &&
            onCreate &&
            onCreate(
              createOptionFromInputValue({
                inputValue: newValue,
              }),
            );
          return;
        } else if (newValue && newValue.inputValue) {
          // create a new value from the user input
          withCreate && onCreate && onCreate(newValue);
          return;
        } else {
          setSelectedOption(newValue || null);
          onChange && onChange(newValue?.data || undefined);
        }
      }}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <FormControl fullWidth error={textFieldProps?.error ?? !hasPermission}>
          <TextField
            {...textFieldProps}
            {...params}
            sx={{
              ...textFieldProps?.sx,

              // show action on hover
              "& .BaseEntitySearchAutocomplete-endAdornment-action": {
                visibility: "hidden",
              },
              "&:hover .BaseEntitySearchAutocomplete-endAdornment-action": {
                visibility: "visible",
              },
              [`& .${outlinedInputClasses.focused} .BaseEntitySearchAutocomplete-endAdornment-action`]:
                {
                  visibility: "visible",
                },
            }}
            error={textFieldProps?.error ?? !hasPermission}
            required={required}
            label={label || textFieldProps?.label}
            placeholder={placeholder || textFieldProps?.placeholder}
            fullWidth
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: textFieldProps?.InputProps?.startAdornment,
              endAdornment: (
                <InputAdornment position='end'>
                  {textFieldProps?.InputProps?.endAdornment || (
                    <>
                      {selectedOption?.data &&
                        TypeHelper.isNilOrTrue(entityViewModal?.if) &&
                        entityViewModal?.renderModal && (
                          <>
                            <AppIconButton
                              className='BaseEntitySearchAutocomplete-endAdornment-action'
                              disabled={disabledComputed}
                              size='small'
                              shape='round'
                              title='View in a modal'
                              onClick={(e) => {
                                e.stopPropagation(); // prevent autocomplete thinking it's open
                                setIsEntityViewModalOpen(true);
                              }}
                            >
                              <AppIcon of='view' />
                            </AppIconButton>

                            {entityViewModal.renderModal({
                              entity: selectedOption.data!,
                              dialogProps: {
                                open: isEntityViewModalOpen,
                                onClose: () => {
                                  setIsEntityViewModalOpen(false);
                                },
                              },
                            })}
                          </>
                        )}

                      {selectedOption?.data &&
                        TypeHelper.isNilOrTrue(entityEditModal?.if) &&
                        entityEditModal?.renderModal && (
                          <>
                            <AppIconButton
                              className='BaseEntitySearchAutocomplete-endAdornment-action'
                              disabled={disabledComputed}
                              size='small'
                              shape='round'
                              title='Edit in a modal'
                              onClick={(e) => {
                                e.stopPropagation(); // prevent autocomplete thinking it's open
                                setIsEntityEditModalOpen(true);
                              }}
                            >
                              <AppIcon of='edit' />
                            </AppIconButton>

                            {entityEditModal.renderModal({
                              entity: selectedOption.data!,
                              dialogProps: {
                                open: isEntityEditModalOpen,
                                onClose: () => {
                                  setIsEntityEditModalOpen(false);
                                },
                              },
                              handleEntityUpdated: (newEntity) => {
                                const newOption = entityToOption(newEntity);
                                setSelectedOption(newOption);
                                onChange && onChange(newOption?.data || undefined);
                              },
                            })}
                          </>
                        )}

                      {TypeHelper.isNilOrTrue(endAdornmentActions?.if) &&
                        endAdornmentActions?.actions
                          .filter((action) => TypeHelper.isNilOrTrue(action.if))
                          .map((action, actionIndex) => (
                            <React.Fragment key={actionIndex}>
                              {action.renderAction({
                                actionIndex,
                                entity: selectedOption?.data,
                                iconButtonProps: {
                                  className: "BaseEntitySearchAutocomplete-endAdornment-action",
                                  disabled: disabledComputed,
                                  size: "small",
                                  shape: "round",
                                  onClick: (e) => {
                                    e.stopPropagation(); // prevent autocomplete thinking it's open
                                  },
                                },
                                handleEntitySelected: (newEntity) => {
                                  const newOption = entityToOption(newEntity);
                                  setSelectedOption(newOption);
                                  onChange && onChange(newOption?.data || undefined);
                                },
                              })}
                            </React.Fragment>
                          ))}

                      {isLoading ? (
                        <CircularProgress color='inherit' size={20} />
                      ) : (
                        <AppIconButton
                          // using visibility like Mui clear button
                          className='BaseEntitySearchAutocomplete-endAdornment-action'
                          disabled={disabledComputed}
                          size='small'
                          shape='round'
                          title='Refresh'
                          onClick={(e) => {
                            searchComputed(parametersComputed);
                          }}
                        >
                          <AppIcon of='refresh' />
                        </AppIconButton>
                      )}

                      {params.InputProps.endAdornment}
                    </>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {!hasPermission && (
            <FormHelperText error>{`You don't have enough permission`}</FormHelperText>
          )}
        </FormControl>
      )}
      renderGroup={
        groupBy
          ? (params) => {
              const optionsInGroup = optionsGroupedMap[params.group] || [];
              const titleRendered = renderGroupTitle
                ? renderGroupTitle({
                    group: params.group,
                    options: optionsInGroup,
                    props: {},
                  })
                : undefined;

              return (
                <ListItem key={params.key} disablePadding>
                  <Stack sx={{ width: "100%" }}>
                    <ListSubheader component='div' sx={{ position: "sticky", top: "-8px" }}>
                      {titleRendered || params.group}
                    </ListSubheader>
                    <List disablePadding>{params.children}</List>
                  </Stack>
                </ListItem>
              );
            }
          : undefined
      }
      renderOption={(props, option) => {
        if (option.optionType === AutocompleteOptionType.Sentry) {
          return (
            <ListItem ref={sentryRef}>
              <ListItemText>Loading...</ListItemText>
            </ListItem>
          );
        }

        const isHidden = option.data && shouldHide ? shouldHide(option.data) : false;
        if (isHidden) {
          return null;
        }
        const isDisabled = option.data && shouldDisable ? shouldDisable(option.data) : false;
        return renderOption(
          {
            ...props,
            key: option.id,
            style: {
              ...props?.style,
              cursor: "default",
              ...(option.optionType === AutocompleteOptionType.NoOptions
                ? {
                    cursor: "default",
                  }
                : undefined),
              ...(isDisabled
                ? {
                    pointerEvents: "none",
                    opacity: 0.5,
                  }
                : undefined),
            },
          },
          option,
        );
      }}
      {...otherProps}
    />
  );
}
