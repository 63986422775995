import { useEffect } from "react";

import { TenantDto } from "@/core/api/generated";
import { fetchCurrentTenant } from "@/store/tenants/slice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";
import { useCurrentTenantInfo } from "./useCurrentTenantInfo";

/** Returns currently resolved tenant. */
export const useCurrentTenant = (params?: { isRefetch?: boolean }): Nil<TenantDto> => {
  const dispatch = useAppThunkDispatch();
  const tenantInfo = useCurrentTenantInfo();
  const currentTenant = useAppSelector((x) => x.tenants.currentTenant);

  useEffect(() => {
    if (!currentTenant && tenantInfo?.identifier) {
      dispatch(fetchCurrentTenant({ ...params }));
    }
  }, [tenantInfo]);

  return currentTenant;
};
