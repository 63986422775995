import { HubConnection } from "@microsoft/signalr";
import { flatten, isEmpty, uniq, uniqBy } from "lodash-es";

import { featureManager } from "@/config/features";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  DataUpdatesHubServerMethodName,
  EntityChangeType,
  EntityChangedDtoOfTEntityDto,
  EntityType,
  SubscribeOnDataUpdatesDto,
  UnsubscribeFromDataUpdatesDto,
} from "@/core/api/generated";
import store from "@/store";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import { FeatureName } from "../constants/featureName";
import { BaseHubService } from "./baseHubService";
import { DataUpdatesSubscription } from "./dataUpdatesSubscription";
import { HubSubscription } from "./hubSubscription";

export { DataUpdatesHubClientMethodName, DataUpdatesHubServerMethodName };

/** Client methods that can be called by the server. */
const clientMethods = DataUpdatesHubClientMethodName;

/** Server methods that can be called by the client. */
const serverMethods = DataUpdatesHubServerMethodName;

export class DataUpdatesHubService extends BaseHubService {
  /** channelName:subs */
  private dataUpdatesSubscriptionsMap: Record<string, DataUpdatesSubscription[]> = {};

  constructor() {
    super({
      hubName: "DataUpdatesHub",
      showLogs: true,
      logIncomingMessages: true,
      logOutcomingMessages: true,
    });

    this.on("initialized", ({ connection }) => {
      this.registerMethodHandlers(connection);
    });

    this.on("connected", ({ connectionId }) => {
      apiClient.updateSignalConnectionIds({
        dataUpdatesHubConnectionId: connectionId,
      });

      // this.echo("Hello");
    });
  }

  public async connect(): Promise<void> {
    if (featureManager.isEnabled(FeatureName.RealtimeDataUpdates)) {
      await super.connectToHub("/signalr/dataupdateshub");
    }
  }

  public async disconnect(): Promise<void> {
    await super.disconnectFromHub();
  }

  private registerMethodHandlers(connection: HubConnection) {
    // by default handle all data updates method calls without a need to register them explicitly
    const placeholderHandler = () => {};
    Object.values(clientMethods).forEach((methodName) => {
      this.registerDataUpdatesMethodHandler(methodName, placeholderHandler);
    });

    this.registerDataUpdatesMethodHandler(clientMethods.Echo, this.onEcho.bind(this));
    this.registerMethodHandler(
      clientMethods.ServerErrorOccurred,
      this.onServerErrorOccurred.bind(this),
    );

    this.registerDataUpdatesMethodHandler(
      clientMethods.EntityChanged,
      this.onEntityChanged.bind(this),
    );
  }

  //#region Method handlers

  private onEcho(data: any) {}

  private onServerErrorOccurred(data: any) {
    // always log error response from the BE
    console.error(this.logPrefix, "BE notified about server error:", data);
  }

  private onEntityChanged(data: EntityChangedDtoOfTEntityDto) {
    if (data.entityType === EntityType.Negotiation) {
      if (data.changeType === EntityChangeType.Created) {
        store.dispatch(negotiationsSlice.negotiationCreated(data));
      }
      if (data.changeType === EntityChangeType.Updated) {
        store.dispatch(negotiationsSlice.negotiationUpdated(data));
      }
      if (data.changeType === EntityChangeType.Deleted) {
        store.dispatch(negotiationsSlice.negotiationDeleted(data));
      }
    }
  }

  //#endregion

  //#region Server methods

  public echo(message: string) {
    this.send(serverMethods.Echo, message);
  }

  public async subscribeOnDataUpdates(
    data: SubscribeOnDataUpdatesDto,
    dataUpdatesSubscription?: DataUpdatesSubscription,
  ): Promise<{
    dataUpdatesSubscription: DataUpdatesSubscription;
  } | null> {
    if (!featureManager.isEnabled(FeatureName.RealtimeDataUpdates)) {
      return null;
    }

    const channelNames = data.channelNames || (data.channelName && [data.channelName]) || [];
    if (channelNames.length === 0) {
      throw new Error("Channel name must be set.");
    }

    await this.ensureConnectedToHub();

    const dataUpdatesSubscriptionComputed =
      dataUpdatesSubscription || new DataUpdatesSubscription({});
    dataUpdatesSubscriptionComputed!.addChannelNames(channelNames);

    const subscription = new HubSubscription({
      identifier: `subscribeOnDataUpdates:Channels:${channelNames.join(";")}`,
      subscribe: (async () => {
        return await this.send(serverMethods.SubscribeOnDataUpdates, {
          channelNames,
        });
      }).bind(this),
      unsubscribe: (async () => {
        return await this.unsubscribeFromDataUpdates(dataUpdatesSubscriptionComputed);
      }).bind(this),
    });
    await this.addSubscription(subscription);

    channelNames.forEach((channelName) => {
      this.dataUpdatesSubscriptionsMap[channelName] =
        this.dataUpdatesSubscriptionsMap[channelName] || [];
      this.dataUpdatesSubscriptionsMap[channelName].push(dataUpdatesSubscriptionComputed!);
    });

    return {
      dataUpdatesSubscription: dataUpdatesSubscriptionComputed,
    };
  }

  public async unsubscribeFromDataUpdates(subscription: DataUpdatesSubscription): Promise<void> {
    if (!featureManager.isEnabled(FeatureName.RealtimeDataUpdates)) {
      return;
    }

    const channelNames = subscription.channelNames;
    if (channelNames.length === 0) {
      console.warn("Channel names must be set.");
    }

    let channelNamesToUnsubscribe: string[] = [];
    channelNames.forEach((channelName) => {
      const oldSubscriptions = this.dataUpdatesSubscriptionsMap[channelName] || [];
      const newSubscriptions = oldSubscriptions.filter(
        (x) => x.identifier !== subscription.identifier,
      );
      this.dataUpdatesSubscriptionsMap[channelName] = newSubscriptions;

      if (isEmpty(newSubscriptions)) {
        channelNamesToUnsubscribe.push(channelName);
      }
    });

    channelNamesToUnsubscribe = uniq(channelNamesToUnsubscribe);
    if (!isEmpty(channelNamesToUnsubscribe)) {
      await this.ensureConnectedToHub();
      const requestData: UnsubscribeFromDataUpdatesDto = {
        channelNames: channelNamesToUnsubscribe,
      };
      await this.send(serverMethods.UnsubscribeFromDataUpdates, requestData);
    }
  }

  //#endregion

  // #region Private

  protected registerDataUpdatesMethodHandler(
    methodName: DataUpdatesHubClientMethodName,
    handler: (...args: any[]) => void,
  ) {
    this.registerMethodHandler(methodName, (...args) => {
      // before calling method handler, track internally which method is being called.
      this.handleClientMethodCalled(methodName, ...args);
      handler(...args);
    });
  }

  /** Handles data updates subscriptions. */
  private handleClientMethodCalled(
    methodName: DataUpdatesHubClientMethodName,
    ...args: any[]
  ): void {
    const subscriptions = uniqBy(
      flatten(Object.values(this.dataUpdatesSubscriptionsMap)),
      (x) => x.identifier,
    );

    subscriptions.forEach((sub) => {
      sub.handleMethodCalled(methodName, ...args);
    });
  }

  //#endregion
}

export const dataUpdatesHubService = new DataUpdatesHubService();
