import moment from "moment";

import { tipsEventEmitter } from "../eventEmmiters/tipsEventEmitter";

const key = "lastTipShownAt";
export class TipsSchedulerService {
  private intervalId: NodeJS.Timeout | null = null;

  /**
   * Start the scheduler
   * @param idle - maximum idle time without trigger
   * @param interval - interval in milliseconds for the trigger to check
   */
  public start(idle: number, interval: number) {
    this.intervalId = setInterval(() => {
      // Get the last time triggered
      const lastTriggerAt = localStorage.getItem(key);
      // If trigger was never executed then call it immediately
      if (!lastTriggerAt) {
        tipsEventEmitter.emit("show", undefined);
        return;
      }
      // Here we need to estimate the probability (P1) (P = P1 + P2 = 1):
      // 1. P1 - call trigger. It depends on the idle time. The longer the idle time the higher the probability.
      // 2. P2 - don't call trigger. P2 = 1 - P1
      const p1 = moment().diff(lastTriggerAt) / idle;
      // |0.0...0.25...0.5...0.75...1.0|
      // |show    |         do not show|
      const result = Math.random() <= p1;
      if (result) {
        tipsEventEmitter.emit("show", undefined);
      }
    }, interval);
  }

  public stop() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public restart(idle: number, interval: number) {
    this.stop();
    this.start(idle, interval);
  }

  public saveLastTriggerAt() {
    localStorage.setItem(key, moment().toISOString());
  }
}

const tipsSchedulerService = new TipsSchedulerService();
export default tipsSchedulerService;
