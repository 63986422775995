import { Box, Stack } from "@mui/material";
import { isNil } from "lodash-es";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import { VehicleBatteryStateDto } from "@/core/api/generated";

import Datetime from "../../Datetime/Datetime";

interface Props {
  batteryState: VehicleBatteryStateDto | null | undefined;
  columnCount?: number;
}

export default function VehicleBatteryStateDisplay({ batteryState, columnCount = 4 }: Props) {
  if (!batteryState) {
    return null;
  }
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile label='Health status' iconOf='data' isEmpty={!batteryState?.healthStatus}>
          <InlineApiEnumValue
            type='VehicleBatteryHealthStatus'
            value={batteryState?.healthStatus}
          />
        </InfoTile>
        <InfoTile
          label='Last service at'
          iconOf='date'
          isEmpty={isNil(batteryState?.lastServiceAt)}
        >
          <Datetime datetime={batteryState?.lastServiceAt} />
        </InfoTile>
        <InfoTile
          label='Last replacement at'
          iconOf='date'
          isEmpty={isNil(batteryState?.lastReplacementAt)}
        >
          <Datetime datetime={batteryState?.lastReplacementAt} />
        </InfoTile>
        <InfoTile label='Health rating' iconOf='data' isEmpty={!batteryState?.healthRating}>
          <InlineApiEnumValue
            type='VehicleBatteryHealthRating'
            value={batteryState?.healthRating}
          />
        </InfoTile>
      </Box>
    </Stack>
  );
}
