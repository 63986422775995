import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik, getIn } from "formik";
import { chain, isEmpty, isNil, isString } from "lodash-es";
import moment, { Moment } from "moment";
import { useMemo, useState } from "react";
import * as Yup from "yup";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { TextHelper } from "@/common/helpers/text";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { enumService } from "@/common/services/enum";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractStage,
  EntityType,
  VehicleArea,
  VehicleDto,
  VisualInspectionCreateDto,
  VisualInspectionDto,
  VisualInspectionItemDto,
  VisualInspectionItemInputDto,
  VisualInspectionUpdateDto,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import DropdownButton from "../../Button/DropdownButton";
import FoldableBlock from "../../Display/FoldableBlock";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import MileageInput from "../../Form/Input/MileageInput";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import InferEntityAffiliationFromVehicleFormikComputedField from "../EntityAffiliation/InferEntityAffiliationFromVehicleFormikComputedField";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  vehicleId?: VisualInspectionCreateDto["vehicleId"];
  contractId?: VisualInspectionCreateDto["contractId"];
  departmentId?: VisualInspectionCreateDto["departmentId"];
  locationId?: VisualInspectionCreateDto["locationId"];
  tenantRequestsMeta?: VisualInspectionCreateDto["tenantRequestsMeta"];
};

export interface VisualInspectionCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<VisualInspectionDto, DefaultValues> {
  visualInspectionId?: string;
}

export type VisualInspectionCreateUpdateProps = VisualInspectionCreateUpdateOwnProps;

export default function VisualInspectionCreateUpdate({
  visualInspectionId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: VisualInspectionCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const userAffiliation = useUserAffiliation();

  const isCreate = !visualInspectionId;
  // const isEdit = !!visualInspectionId;

  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const visualInspectionRequest = useApiRequest(
    apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      visualInspectionId: visualInspectionId!,
    },
    {
      deps: [visualInspectionId],
      skip: !visualInspectionId,
    },
  );
  const visualInspection = visualInspectionRequest?.data;

  const createMetaRequest = useApiRequest(
    apiClient.visualInspectionsApi.apiV1VisualInspectionsCreateMetaGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const createMeta = createMetaRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: visualInspection && {
      idValue: visualInspection.id!,
      newTitle: visualInspection.localNumber || "",
    },
  });

  const vehicleIdComputed = defaultValues?.vehicleId || visualInspection?.vehicle?.id;
  const contractIdComputed = defaultValues?.contractId || visualInspection?.contract?.id;

  const settingsComputed = useMemo(
    () =>
      createMeta?.settings
        ? {
            ...createMeta.settings,
            perAreaMap: chain(createMeta.settings.perArea)
              .keyBy((x) => x.area!)
              .mapValues((x) => x)
              .valueOf(),
          }
        : undefined,
    [createMeta],
  );

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.VisualInspection}
      entityId={visualInspectionId}
      entity={visualInspection}
      entityRequest={visualInspectionRequest}
      isIniting={createMetaRequest.isLoading || !createMeta || userAffiliation.isIniting}
    >
      <Formik<
        BaseFormikValues &
          Omit<VisualInspectionCreateDto, "items"> &
          Omit<VisualInspectionUpdateDto, "items"> & {
            vehicle?: VehicleDto;
            initialAttachments: VisualInspectionDto["attachments"];
            isAttachmentFilesHaveErrors: boolean;
            items?: Array<
              VisualInspectionItemInputDto & {
                isAttachmentFilesHaveErrors?: boolean;
                initialAttachments?: VisualInspectionItemDto["attachments"];
              }
            >;
          }
      >
        initialValues={{
          // temporary
          departmentId:
            defaultValues?.departmentId ||
            visualInspection?.departmentIds?.at(0) ||
            userAffiliation.departments?.at(0)?.id ||
            undefined,
          // temporary
          locationId:
            defaultValues?.locationId ||
            visualInspection?.locationIds?.at(0) ||
            userAffiliation.locations?.at(0)?.id ||
            undefined,
          contractId: visualInspection?.contract?.id || defaultValues?.contractId || undefined,
          vehicleId: visualInspection?.vehicle?.id || defaultValues?.vehicleId || undefined,
          vehicle: undefined,
          inspectedAt:
            visualInspection?.inspectedAt || moment().format(DATETIME_FORMATS.DISPLAY_DATETIME),
          inspector: {
            isCurrentUser: true,
            ...(visualInspection?.inspector || {}),
            phoneNumber: visualInspection?.inspector?.phoneNumber,
          },
          isUseSupplierBranding: visualInspection ? visualInspection.isUseSupplierBranding : true,
          mileage: visualInspection?.mileage || undefined,
          fuelLevel: visualInspection?.fuelLevel ?? undefined,
          notes: visualInspection?.notes || "",
          initialAttachments: visualInspection?.attachments || undefined,
          isAttachmentFilesHaveErrors: false,
          attachments: !isEmpty(visualInspection?.attachments)
            ? visualInspection?.attachments
            : undefined,
          items:
            visualInspection?.items?.map((x) => ({
              ...x,
              isAttachmentFilesHaveErrors: false,
              initialAttachments: x?.attachments || undefined,
              attachments: !isEmpty(x?.attachments) ? x.attachments : undefined,
            })) ||
            createMeta?.settings?.perArea
              ?.filter((x) => x.isRequired)
              ?.map((x) => ({
                area: x.area!,
              })) ||
            [VehicleArea.Exterior, VehicleArea.Interior].map((x) => ({ area: x })),
          settings: visualInspection?.settings || createMeta?.settings || {}, // must be passed
          tenantRequestsMeta:
            visualInspection?.tenantRequestsMeta || defaultValues?.tenantRequestsMeta || undefined,
          tags: visualInspection?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // contractId: Yup.string().required("Contract is required"),
          // vehicleId: Yup.string().required("Vehicle is required"),
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          const values2: typeof values = {
            ...values,
            contractId: contractIdComputed,
            inspectedAt: values.inspectedAt ? moment(values.inspectedAt).utc().format() : undefined,
          };

          try {
            if (isCreate) {
              const response = await apiClient.visualInspectionsApi.apiV1VisualInspectionsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                visualInspectionCreateDto: { ...values2 },
              });
              enqueueSnackbar("Visual inspection created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response =
                await apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  visualInspectionId,
                  visualInspectionUpdateDto: {
                    ...values2,
                  },
                });
              enqueueSnackbar("Visual inspection updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          const areasToSelect = enumService.getEnumValues("VehicleArea", {
            exceptNone: true,
            except: values.items?.map((x) => x.area || VehicleArea.None),
          });

          return (
            <form noValidate onSubmit={handleSubmit}>
              <InferEntityAffiliationFromVehicleFormikComputedField
                values={{
                  vehicleId: values.vehicleId,
                  vehicle: values.vehicle,
                  departmentId: values.departmentId,
                  locationId: values.locationId,
                }}
                onComputed={(computed) => {
                  setFieldValue("vehicle", computed.vehicle);
                  setFieldValue("departmentId", computed.departmentId);
                  setFieldValue("locationId", computed.locationId);
                }}
              />

              <FormContentBlock gap={1}>
                {/* Tags */}
                <FormControl margin='dense' fullWidth>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                      isIncludeNested: false,
                    })}
                  </FormHelperText>
                </FormControl>

                <Stack>
                  {!isCreate && (
                    <TextField
                      fullWidth
                      disabled
                      label='Inspection number'
                      margin='dense'
                      type='text'
                      value={visualInspection?.localNumber}
                      variant='outlined'
                    />
                  )}

                  <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                    <VehicleAutocompleteOrCreate
                      autocompleteProps={{
                        required: true,
                        disabled: !isCreate || !isEmpty(vehicleIdComputed),
                        entityId: values.vehicleId,
                        isPreload: isCreate,
                        onChange: (newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          if (newValue?.id) {
                            if (!values.departmentId) {
                              setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                            }
                            if (!values.locationId) {
                              setFieldValue("locationId", newValue?.locationIds?.at(0));
                            }
                          }
                        },
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => {
                        setFieldValue(`vehicleId`, newValue?.id);
                        if (newValue?.id) {
                          if (!values.departmentId) {
                            setFieldValue("departmentId", newValue?.departmentIds?.at(0));
                          }
                          if (!values.locationId) {
                            setFieldValue("locationId", newValue?.locationIds?.at(0));
                          }
                        }
                      }}
                    />
                    <FormHelperText>{errors.vehicleId}</FormHelperText>
                  </FormControl>

                  {/* Entity affiliation */}
                  <EntityAffiliationInput
                    department={{
                      departmentId: values.departmentId,
                      onChange: (d) => {
                        setFieldValue("departmentId", d?.id);
                        setFieldValue("locationId", undefined);
                      },
                      error: errors.departmentId,
                    }}
                    location={{
                      locationId: values.locationId,
                      onChange: (l) => {
                        setFieldValue("locationId", l?.id);
                      },
                      searchFilters: { departmentId: values.departmentId },
                      createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                      error: errors.locationId,
                      disabled: !values.departmentId,
                    }}
                  />

                  {values.contractId && (
                    <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                      <ContractAutocompleteOrCreate
                        autocompleteProps={{
                          disabled: true,
                          entityId: values.contractId,
                          isPreload: isCreate,
                          searchFilters: {
                            vehicleId: values.vehicleId || undefined,
                            excludeStage: ContractStage.Draft,
                          },
                          onChange: (newValue) => {
                            setFieldValue(`contractId`, newValue?.id);
                            setFieldValue(`contract`, newValue || undefined);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue(`contractId`, newValue?.id);
                          setFieldValue(`contract`, newValue || undefined);
                        }}
                      />
                      <FormHelperText>{errors.contractId}</FormHelperText>
                    </FormControl>
                  )}

                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.inspectedAt && errors.inspectedAt)}
                  >
                    <MobileDateTimePicker
                      ampm={false}
                      label='Inspected at'
                      value={(values.inspectedAt && moment(values.inspectedAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue: Moment | null) => {
                        setFieldValue("inspectedAt", newValue?.format() || null);
                      }}
                      slots={{ textField: (params) => <TextField {...params} /> }}
                    />
                    <FormHelperText>{touched.inspectedAt && errors.inspectedAt}</FormHelperText>
                  </FormControl>

                  {/* Mileage */}
                  <MileageInput
                    error={Boolean(touched.mileage && errors.mileage)}
                    helperText={touched.mileage && errors.mileage}
                    vehicleId={values.vehicleId}
                    vehicle={values.vehicle}
                    disabled={!values.vehicle}
                    fullWidth
                    name='mileage'
                    onBlur={handleBlur}
                    onChange={(newValue) => {
                      setFieldValue("mileage", newValue);
                    }}
                    value={values.mileage}
                  />

                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                  >
                    <Typography component='div' gutterBottom>
                      Fuel level
                    </Typography>

                    <FuelLevelInput
                      onBlur={handleBlur}
                      onChange={(e, val) => setFieldValue("fuelLevel", val ?? undefined)}
                      value={values.fuelLevel ?? undefined}
                    />
                    <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                  </FormControl>
                  <FormControl margin='dense' fullWidth>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isUseSupplierBranding}
                          onChange={(e) => setFieldValue(`isUseSupplierBranding`, e.target.checked)}
                        />
                      }
                      label={
                        <Typography component='span' sx={{ display: "flex" }}>
                          Include supplier branding
                        </Typography>
                      }
                    />
                  </FormControl>
                </Stack>

                {/* Items */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Inspections *
                      </Typography>
                    ),
                  }}
                >
                  <Stack spacing={2}>
                    {(!values.items || values.items.length === 0) && (
                      <NoDataAlert title='No items yet' />
                    )}

                    <Stack direction='column' spacing={1}>
                      {values.items?.map((item, index) => {
                        return (
                          <FormContentBlock key={index}>
                            <Stack spacing={2}>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 0fr",
                                  columnGap: 2,
                                }}
                              >
                                <Typography component='span' variant='subtitle1'>
                                  <InlineApiEnumValue type='VehicleArea' value={item.area} />
                                </Typography>

                                <Stack direction='row'>
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        "items",
                                        ArrayHelper.removeByIndex(values.items, index),
                                      )
                                    }
                                  >
                                    <AppIcon of='delete' />
                                  </IconButton>
                                </Stack>
                              </Box>

                              <Box>
                                <FormControl fullWidth margin='dense'>
                                  <FileUploader
                                    multiple
                                    maxFiles={30}
                                    defaultFiles={
                                      values.items &&
                                      FileItem.createManyFrom(
                                        values.items[index].initialAttachments,
                                      )
                                    }
                                    onChange={(newFiles) =>
                                      setFieldValue(
                                        `items[${index}].attachments`,
                                        FileItem.toManyGeneralAttachmentInputDto(newFiles),
                                      )
                                    }
                                    onUploadStarted={() => {
                                      setIsAttachmentFilesUploading(true);
                                    }}
                                    onUploadFinished={() => {
                                      setIsAttachmentFilesUploading(false);
                                    }}
                                    onValidationStatusChange={(filesValidationStatus) => {
                                      if (filesValidationStatus)
                                        setFieldValue(
                                          `items[${index}].isAttachmentFilesHaveErrors`,
                                          Object.values(filesValidationStatus).some(
                                            (x) => x === false,
                                          ),
                                        );
                                    }}
                                  />

                                  {item.area &&
                                    settingsComputed?.perAreaMap &&
                                    settingsComputed.perAreaMap[item.area] && (
                                      <FormHelperText>
                                        {!isNil(
                                          settingsComputed.perAreaMap[item.area].minPhotoCount,
                                        ) && (
                                          <Box>
                                            Min{" "}
                                            {settingsComputed.perAreaMap[item.area].minPhotoCount}{" "}
                                            {TextHelper.pluralizeManual(
                                              "photo",
                                              settingsComputed.perAreaMap[item.area]
                                                .minPhotoCount || 0,
                                              "photos",
                                            )}{" "}
                                            *
                                          </Box>
                                        )}

                                        {!isNil(
                                          settingsComputed.perAreaMap[item.area].minAttachmentCount,
                                        ) && (
                                          <Box>
                                            Min{" "}
                                            {
                                              settingsComputed.perAreaMap[item.area]
                                                .minAttachmentCount
                                            }{" "}
                                            {TextHelper.pluralizeManual(
                                              "attachment",
                                              settingsComputed.perAreaMap[item.area]
                                                .minAttachmentCount || 0,
                                              "attachments",
                                            )}{" "}
                                            *
                                          </Box>
                                        )}
                                      </FormHelperText>
                                    )}

                                  <FormHelperText error>
                                    {getIn(errors, `items[${index}].attachments`)}
                                  </FormHelperText>
                                </FormControl>
                              </Box>
                            </Stack>
                          </FormContentBlock>
                        );
                      })}
                    </Stack>

                    {errors.items && isString(errors.items) && (
                      <FormHelperText error>{errors.items}</FormHelperText>
                    )}

                    {/* Add item */}
                    <Box>
                      <DropdownButton
                        disabled={isEmpty(areasToSelect)}
                        buttonProps={{
                          color: "secondary",
                          size: "small",
                          variant: "outlined",
                          startIcon: <AppIcon of='add' />,
                        }}
                        dropdownContent={
                          <MenuList>
                            {areasToSelect.map((area) => (
                              <MenuItem
                                key={area}
                                onClick={() => {
                                  setFieldValue("items", [
                                    ...(values.items || []),
                                    {
                                      area: area,
                                    },
                                  ]);
                                }}
                              >
                                <ListItemIcon>
                                  <AppIcon of='add' fontSize='small' />
                                </ListItemIcon>
                                <ListItemText>
                                  <InlineApiEnumValue type='VehicleArea' value={area} />
                                </ListItemText>
                              </MenuItem>
                            ))}
                          </MenuList>
                        }
                      >
                        Add item
                      </DropdownButton>
                    </Box>
                  </Stack>
                </FoldableBlock>

                {/* Other */}
                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Other
                      </Typography>
                    ),
                  }}
                >
                  <AppTextArea
                    error={Boolean(touched.notes && errors.notes)}
                    fullWidth
                    helperText={touched.notes && errors.notes}
                    mode='notes'
                    margin='dense'
                    name='notes'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.notes}
                    variant='outlined'
                  />

                  <FormControl fullWidth component='fieldset' margin='dense'>
                    <FileUploader
                      multiple
                      maxFiles={20}
                      defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                      onChange={(newFiles) => {
                        setFieldValue(
                          `attachments`,
                          FileItem.toManyGeneralAttachmentInputDto(newFiles),
                        );
                      }}
                      onUploadStarted={() => {
                        setIsAttachmentFilesUploading(true);
                      }}
                      onUploadFinished={() => {
                        setIsAttachmentFilesUploading(false);
                      }}
                      onValidationStatusChange={(filesValidationStatus) => {
                        if (filesValidationStatus)
                          setFieldValue(
                            "isAttachmentFilesHaveErrors",
                            Object.values(filesValidationStatus).some((x) => x === false),
                          );
                      }}
                    />
                  </FormControl>
                </FoldableBlock>

                <FormControl>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled
                        checked={values.inspector?.isCurrentUser}
                        onChange={handleChange}
                        name='inspector.isCurrentUser'
                      />
                    }
                    label={
                      <AppTypography
                        decoration={{ variant: "helpText" }}
                        tooltipProps={{
                          title: "Currently logged in user evaluated the damages",
                        }}
                      >
                        Inspected by{" "}
                        {values.inspector?.personName
                          ? `${values.inspector.personName?.firstName} ${values.inspector.personName?.lastName}`
                          : profile?.personName?.name}
                      </AppTypography>
                    }
                  />
                </FormControl>
              </FormContentBlock>

              {errors.settings && isString(errors.settings) && (
                <FormHelperText error>{errors.settings}</FormHelperText>
              )}

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  color='primary'
                  disabled={
                    isAttachmentFilesUploading ||
                    values.isAttachmentFilesHaveErrors ||
                    values.items?.some((x) => x.isAttachmentFilesHaveErrors === true)
                  }
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
