import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { isNil } from "lodash-es";
import { ChangeEvent, useCallback } from "react";

import { NumberHelper } from "@/common/helpers/number";
import { TypeHelper } from "@/common/helpers/type";

interface Props extends Omit<TextFieldProps, "onChange" | "type" | "value" | "startAdornment"> {
  value?: number | null;
  onChange?: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    newValue?: number | null,
  ) => void;
}

/**  Percents are entered and displayed as [0; 100], but stored as [0;1]. */
export default function PercentInput({ value, onChange, ...textFieldProps }: Props) {
  const inputValue = isNil(value) ? undefined : NumberHelper.normalizePercentToZeroHundred(+value);

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newInputValue =
        TypeHelper.isNil(e.target.value) || TypeHelper.isEmptyString(e.target.value)
          ? undefined
          : NumberHelper.normalizePercentToZeroOne(+e.target.value);
      e.target.value = newInputValue?.toString() || "";
      onChange && onChange(e, newInputValue);
    },
    [onChange],
  );

  return (
    <TextField
      inputMode='decimal'
      value={inputValue ?? ""}
      onChange={_onChange}
      InputProps={{
        startAdornment: <InputAdornment position='start'>%</InputAdornment>,
      }}
      {...textFieldProps}
    />
  );
}
