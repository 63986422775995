import { Box, Stack, SxProps, Theme, Typography } from "@mui/material";
import { Moment } from "moment";

import Datetime from "@/common/components/Datetime/Datetime";
import { DatetimeHelper } from "@/common/helpers/datetime";
import { renderIf } from "@/common/helpers/render/renderIf";

import AppTooltip from "../AppTooltip";

export type TDatetimeRange = {
  startsAt: string | Moment | null | undefined;
  endsAt: string | Moment | null | undefined;
};

interface DatetimeRangeProps {
  range: TDatetimeRange;
  variant?: "normal" | "compact";
  direction?: "row" | "column";
  withDuration?: boolean;
  withTooltip?: boolean;
  sx?: SxProps<Theme>;
}

export default function DatetimeRange({
  range,
  variant = "normal",
  direction = "row",
  withDuration = false,
  withTooltip = true,
  sx,
}: DatetimeRangeProps) {
  if (!range.startsAt && !range.endsAt) {
    return <Box component='span'>-</Box>;
  }

  return (
    <AppTooltip
      sx={{ display: "inline" }}
      enabled={withTooltip}
      variant='helpText'
      title={
        <Typography component='div' variant='body2' sx={{ whiteSpace: "nowrap" }}>
          <Stack>
            <Box>
              Start - <Datetime datetime={range.startsAt} />
            </Box>
            <Box>
              End - <Datetime datetime={range.endsAt} />
            </Box>
            <Box>
              Duration - {DatetimeHelper.differenceInDaysHumanized(range.startsAt, range.endsAt)}
            </Box>
          </Stack>
        </Typography>
      }
    >
      <Stack
        component='span'
        direction={direction}
        spacing={direction === "row" ? 0.5 : 0}
        sx={{
          display: "inline-flex",
          ...((direction === "row" && { alignItems: "center", justifyContent: "start" }) || {}),
          ...((direction === "column" && { alignItems: "start", justifyContent: "start" }) || {}),
          ...sx,
        }}
      >
        {variant === "normal" && (
          <>
            <Box component='span'>
              <Datetime datetime={range.startsAt} />
            </Box>
            {range.endsAt && direction === "row" && <Box component='span'>{` - `}</Box>}
            {range.endsAt && (
              <Box component='span'>
                <Datetime datetime={range.endsAt} />
              </Box>
            )}
            {withDuration && (
              <Typography component='span' color='text.secondary' fontSize='smaller'>
                {" "}
                ({DatetimeHelper.differenceInDaysHumanized(range.startsAt, range.endsAt)})
              </Typography>
            )}
          </>
        )}

        {variant === "compact" &&
          renderIf()
            .if(!!range.startsAt && !!range.endsAt)
            .then(
              <Box component='span'>
                {DatetimeHelper.differenceInDaysHumanized(range.startsAt, range.endsAt)}
              </Box>,
            )
            .else(
              <>
                <Box component='span'>
                  {range.startsAt ? <Datetime datetime={range.startsAt} /> : "-"}
                </Box>
                {range.endsAt && direction === "row" && <Box component='span'>{` - `}</Box>}
                <Box component='span'>
                  {range.endsAt ? <Datetime datetime={range.endsAt} /> : "-"}
                </Box>
              </>,
            )
            .render()}
      </Stack>
    </AppTooltip>
  );
}
