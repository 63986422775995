import { chain, first, uniqBy } from "lodash-es";

import { FilterOperator } from "@/core/api/generated";

import { ArrayHelper } from "../helpers/array";
import { TypeHelper } from "../helpers/type";
import { FilterDefinition } from "./filterDefinition";
import { FilterFieldOperatorSpec } from "./filterFieldOperatorSpec";
import { FilterFieldSpec } from "./filterFieldSpec";

/** Spec of fields and filters applicable to them. */
export class FilterSpec {
  public fields: FilterFieldSpec[];
  private fieldsMap: Record<string, FilterFieldSpec>;
  // public readonly fields: FilterField[];

  constructor(params: { fields: FilterFieldSpec[] }) {
    this.fields = params.fields;
    this.cleanup();
    this.fieldsMap = this.computeFieldsMap();
  }

  //#region Static

  public static combineSpecs(
    spec1: FilterSpec | undefined,
    spec2: FilterSpec | undefined,
  ): FilterSpec | undefined {
    if (!spec1 && !spec2) {
      return undefined;
    }

    const currentFields: FilterFieldSpec[] = [...(spec1?.fields || []), ...(spec2?.fields || [])];
    const newFields: FilterFieldSpec[] = [];
    currentFields.forEach((field) => {
      const newField = newFields.find((x) => x.field === field.field);
      if (!newField) {
        newFields.push(field);
      } else {
        if (field.fieldType === newField.fieldType) {
          const newOperators = uniqBy(
            [...newField.operators, ...field.operators],
            (x) => x.operator,
          );
          const newField2 = new FilterFieldSpec({
            ...newField,
            operators: !TypeHelper.isEmpty(newOperators) ? newOperators : undefined,
          });
          ArrayHelper.replaceByPredicate(newFields, (x) => x.field === newField2.field, newField2);
        }
      }
    });

    const newSpec = !TypeHelper.isEmpty(newFields)
      ? new FilterSpec({ fields: newFields })
      : undefined;
    return newSpec;
  }

  //#endregion

  public get isValid(): boolean {
    return !TypeHelper.isEmpty(this.fields) && this.fields.every((x) => x.isValid);
  }

  public hasField(field: string): boolean {
    return !!this.fieldsMap[field];
  }

  public getField(field: string): FilterFieldSpec | undefined {
    return this.fieldsMap[field];
  }

  public getFieldOrThrow(field: string): FilterFieldSpec {
    const result = this.getField(field);
    if (!result) {
      throw new Error(`Unable to get field spec for '${field}'.`);
    }
    return result;
  }

  public getFirstField(): FilterFieldSpec | undefined {
    return this.fields.at(0);
  }

  public getFirstFieldOrThrow(): FilterFieldSpec {
    const result = this.getFirstField();
    if (!result) {
      throw new Error(`Unable to get first field spec.`);
    }
    return result;
  }

  public getFieldOperators(field: string): FilterFieldOperatorSpec[] | undefined {
    return this.getField(field)?.operators;
  }

  public getFieldOperator(
    field: string,
    operator: FilterOperator,
  ): FilterFieldOperatorSpec | undefined {
    return this.getField(field)?.operators.find((x) => x.operator === operator);
  }

  /** Auto-selects first suitable field for new filter. */
  public autoSelectFieldForNewFilter(
    filterDefinition: FilterDefinition | undefined,
  ): FilterFieldSpec | undefined {
    const notSelectedFieldSpec = this.fields.find((x) =>
      filterDefinition ? !filterDefinition?.items?.some((y) => y.field === x.field) : true,
    );
    const arbitraryFieldSpec = first(this.fields);
    const fieldSpec = notSelectedFieldSpec || arbitraryFieldSpec;
    return fieldSpec;
  }

  /** Auto-selects first suitable field operator for new filter. */
  public autoSelectOperatorForNewFilter(
    filterDefinition: FilterDefinition | undefined,
    fieldSpec: FilterFieldSpec | undefined,
  ): FilterFieldOperatorSpec | undefined {
    const operatorSpec = fieldSpec?.operators.at(0);
    return operatorSpec;
  }

  /** Removes empty field specs to make sure the spec is relevant and valid. */
  public cleanup(): void {
    const invalidFields = this.fields.filter((x) => !x.isValid);
    if (!TypeHelper.isEmpty(invalidFields)) {
      console.warn(
        `Found ${invalidFields.length} invalid field spec during cleanup. Invalid fields:`,
        invalidFields,
      );
    }

    this.fields = this.fields.filter((x) => x.isValid);
    this.fields = uniqBy(this.fields, (x) => x.field);
  }

  private computeFieldsMap(): Record<string, FilterFieldSpec> {
    this.fieldsMap = chain(this.fields)
      .keyBy((x) => x.field)
      .mapValues((x) => x)
      .value();
    return this.fieldsMap;
  }
}
