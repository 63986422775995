import { Box, FormControl, FormHelperText, SxProps, Theme, Typography } from "@mui/material";
import { useEffect } from "react";

import UserAutocomplete from "@/App/MainAppView/components/User/UserAutocomplete";
import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import { renderIf } from "@/common/helpers/render/renderIf";
import {
  AssignmentPartyInputDto,
  AssignmentPartyRoleType,
  AssignmentPartyType,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import CustomerAutocompleteOrCreate from "../Customer/CustomerAutocompleteOrCreate";
import DepartmentAutocompleteOrCreate from "../Department/DepartmentAutocompleteOrCreate";
import LocationAutocompleteOrCreate from "../Location/LocationAutocompleteOrCreate";
import SupplierAutocompleteOrCreate from "../Supplier/SupplierAutocompleteOrCreate";
import TenantAutocomplete from "../Tenant/TenantAutocomplete";
import AssignmentPartyCustomInput from "./AssignmentPartyCustomInput";

interface Props {
  formikProps: CustomFormikSubProps<AssignmentPartyInputDto | null | undefined>;
  assignmentPartyTypes: AssignmentPartyType[];
  assignmentPartyRoleType?: AssignmentPartyRoleType | undefined | null;
  userId?: string | undefined | null;
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export default function AssignmentPartyInput({
  formikProps,
  assignmentPartyTypes,
  assignmentPartyRoleType,
  userId,
  required,
  disabled,
}: Props) {
  const { values, errors, setFieldValue, setValues } = formikProps;

  // Update roleType
  useEffect(() => {
    if (assignmentPartyRoleType && values?.roleType !== assignmentPartyRoleType && values?.type) {
      setFieldValue("roleType", assignmentPartyRoleType);
    }
  }, [assignmentPartyRoleType, values?.roleType, values?.type, setFieldValue]);

  // Update userId
  useEffect(() => {
    if (userId && !values?.userId) {
      setFieldValue("userId", userId);
    }
  }, []);

  return (
    <>
      {assignmentPartyRoleType && (
        <Typography component='span' variant='subtitle1'>
          {assignmentPartyRoleType}
        </Typography>
      )}
      <FormControl margin='dense' fullWidth>
        <ApiEnumAutocomplete
          type='AssignmentPartyType'
          required={required}
          disabled={disabled}
          onlyEnumValues={assignmentPartyTypes}
          value={values?.type || undefined}
          textFieldProps={{
            label: "Party type",
            error: Boolean(errors?.type),
            helperText: errors?.type,
          }}
          onChange={(newValue) => {
            if (
              !newValue &&
              assignmentPartyRoleType != AssignmentPartyRoleType.Assignee &&
              assignmentPartyRoleType != AssignmentPartyRoleType.Assigner
            ) {
              setValues(undefined);
            } else {
              setFieldValue("roleType", undefined);
              setFieldValue("userId", undefined);
              setFieldValue("tenantId", undefined);
              setFieldValue("departmentId", undefined);
              setFieldValue("locationId", undefined);
              setFieldValue("locationId", undefined);
              setFieldValue("supplierId", undefined);
              setFieldValue("customerId", undefined);
              setFieldValue("custom", undefined);
              setFieldValue("type", newValue);
            }
          }}
        />
      </FormControl>
      {/* Specific pert type inputs */}
      {values?.type && (
        <Box>
          {renderIf()
            .if(values.type === AssignmentPartyType.User)
            .then(
              <FormControl margin='dense' fullWidth>
                <UserAutocomplete
                  entity={undefined}
                  entityId={values.userId || undefined}
                  required={required || values.type != null}
                  disabled={disabled}
                  label='User'
                  onChange={(newValue) => {
                    setFieldValue("userId", newValue?.id);
                  }}
                  textFieldProps={{
                    error: Boolean(errors?.userId),
                    helperText: errors?.userId,
                  }}
                />
              </FormControl>,
            )
            .elseif(values.type === AssignmentPartyType.Company)
            .then(
              <FormControl margin='dense' fullWidth error={Boolean(errors?.tenantId)}>
                <TenantAutocomplete
                  entityId={values.tenantId}
                  searchFilters={{
                    isInMyConnections: true,
                  }}
                  required={required || values.type != null}
                  disabled={disabled}
                  label='Company'
                  onChange={(newValue) => setFieldValue("tenantId", newValue?.id)}
                  textFieldProps={{
                    error: Boolean(errors?.tenantId),
                    helperText: errors?.tenantId,
                  }}
                />
              </FormControl>,
            )
            .elseif(values.type === AssignmentPartyType.Department)
            .then(
              <FormControl margin='dense' fullWidth>
                <DepartmentAutocompleteOrCreate
                  autocompleteProps={{
                    required: required || values.type != null,
                    disabled: disabled,
                    entityId: values.departmentId,
                    onChange: (newValue) => {
                      setFieldValue("departmentId", newValue?.id);
                    },
                    textFieldProps: {
                      error: Boolean(errors?.departmentId),
                      helperText: errors?.departmentId,
                    },
                  }}
                  createFormPlacement='modal'
                  onCreate={(newValue) => {
                    setFieldValue("departmentId", newValue?.id);
                  }}
                />
              </FormControl>,
            )
            .elseif(values.type === AssignmentPartyType.Location)
            .then(
              <FormControl margin='dense' fullWidth>
                <LocationAutocompleteOrCreate
                  autocompleteProps={{
                    required: required || values.type != null,
                    disabled: disabled,
                    entityId: values.locationId,
                    entity: undefined,
                    textFieldProps: {
                      error: Boolean(errors?.locationId),
                      helperText: errors?.locationId,
                    },
                    onChange: (newValue) => {
                      setFieldValue("locationId", newValue?.id);
                    },
                  }}
                  createFormPlacement='modal'
                  onCreate={(newValue) => {
                    setFieldValue("locationId", newValue?.id);
                  }}
                />
              </FormControl>,
            )
            .elseif(values.type === AssignmentPartyType.Supplier)
            .then(
              <FormControl margin='dense' fullWidth>
                <SupplierAutocompleteOrCreate
                  autocompleteProps={{
                    required: required || values.type != null,
                    disabled: disabled,
                    entityId: values.supplierId,
                    onChange: (newValue) => {
                      setFieldValue(`supplierId`, newValue?.id);
                    },
                    textFieldProps: {
                      error: Boolean(errors?.supplierId),
                      helperText: errors?.supplierId,
                    },
                  }}
                  createFormPlacement='modal'
                  onCreate={(newValue) => {
                    setFieldValue(`supplierId`, newValue?.id);
                  }}
                />
              </FormControl>,
            )
            .elseif(values.type === AssignmentPartyType.Customer)
            .then(
              <FormControl margin='dense' fullWidth>
                <CustomerAutocompleteOrCreate
                  autocompleteProps={{
                    required: required || values.type != null,
                    disabled: disabled,
                    entityId: values.customerId,
                    onChange: (newValue) => setFieldValue("customerId", newValue?.id),
                    textFieldProps: {
                      error: Boolean(errors?.customerId),
                      helperText: errors?.customerId,
                    },
                  }}
                  onCreate={(newValue) => setFieldValue("customerId", newValue?.id)}
                />
              </FormControl>,
            )
            .else(
              <FormControl margin='dense' fullWidth error={Boolean(errors?.custom)}>
                <AssignmentPartyCustomInput
                  formikProps={FormikHelper.getSubProps(formikProps, "custom", (v) => v.custom)}
                  required={required}
                  disabled={disabled}
                />
                <FormHelperText>
                  {!!errors?.custom && typeof errors?.custom === "string" ? errors?.custom : null}
                </FormHelperText>
              </FormControl>,
            )
            .render()}
        </Box>
      )}
    </>
  );
}
