import { GridRowSelectionModel } from "@mui/x-data-grid";
import {
  GridInitialStateCommunity,
  GridStateCommunity,
} from "@mui/x-data-grid/models/gridStateCommunity";
import { fill, zipObject } from "lodash-es";

import { LocalStorageKey } from "../constants/localStorage";
import { FilterFieldSpec } from "../filters/filterFieldSpec";
import { FilterSpec } from "../filters/filterSpec";
import { SortFieldSpec } from "../sorting/sortFieldSpec";
import { SortSpec } from "../sorting/sortSpec";
import {
  TabularColumnModel,
  TabularRowSelectionMap,
  TabularState,
  TabularStateAggregatedLocalStorageModel,
  TabularStatePersistenceStrategy,
  TabularValidRowModel,
} from "../ts/dataTabular";
import { LocalStorageHelper } from "./localStorage";
import { TypeHelper } from "./type";

/** DataTabular helper. */
export class TabularHelper {
  public static buildSortSpecFromColumns<TItem extends TabularValidRowModel>(
    columns: TabularColumnModel<TItem>[],
  ): SortSpec | undefined {
    const sortableColumns = columns.filter((x) => x.isSortable);
    if (TypeHelper.isEmpty(sortableColumns)) {
      return undefined;
    }
    const sortSpec = new SortSpec({
      fields: sortableColumns.map(
        (x) =>
          new SortFieldSpec({
            field: x.field,
            title: x.title,
          }),
      ),
    });
    return sortSpec;
  }

  public static buildFilterSpecFromColumns<TItem extends TabularValidRowModel>(
    columns: TabularColumnModel<TItem>[],
  ): FilterSpec | undefined {
    const filterableColumns = columns.filter((x) => x.isFilterable);
    if (TypeHelper.isEmpty(filterableColumns)) {
      return undefined;
    }
    const filterSpec = new FilterSpec({
      fields: filterableColumns
        .map((x) =>
          x.filters
            ? new FilterFieldSpec({
                field: x.field,
                title: x.title,
                ...x.filters,
              })
            : undefined,
        )
        .filter(Boolean)
        .map((x) => x!),
    });

    return filterSpec;
  }

  // #region Mapping

  public static mapGridStateToTabularState(gridState: GridStateCommunity): TabularState {
    const tabularState: TabularState = {
      column: {
        visibilityMap: gridState.columns?.columnVisibilityModel,
      },
    };

    this.cleanupState(tabularState);

    return tabularState;
  }

  public static mapTabularStateToGridInitialState(
    tabularState: TabularState,
  ): GridInitialStateCommunity {
    const gridState: GridInitialStateCommunity = {
      columns: {
        columnVisibilityModel: tabularState.column?.visibilityMap || {},
        orderedFields: undefined,
        dimensions: undefined,
      },
    };

    return gridState;
  }

  public static mapGridRowSelectionModelToTabularRowSelectionMap(
    source: GridRowSelectionModel,
  ): TabularRowSelectionMap {
    return zipObject(source, fill(Array(source.length), true));
  }

  public static mapTabularRowSelectionMapToGridRowSelectionModel(
    source: TabularRowSelectionMap,
  ): GridRowSelectionModel {
    return Object.keys(source);
  }

  // #endregion

  // #region TabularState

  public static readonly defaultStatePersistStrategy = TabularStatePersistenceStrategy.LocalStorage;

  /** Removes empty values. */
  public static cleanupState(state: TabularState): void {
    // remove empty values
    state.column = TypeHelper.isEmpty(state.column) ? undefined : state.column;
  }

  public static persistState(
    persistenceKey: string,
    state: TabularState,
    strategy: TabularStatePersistenceStrategy = this.defaultStatePersistStrategy,
  ): void {
    this.cleanupState(state);

    switch (strategy) {
      case TabularStatePersistenceStrategy.LocalStorage:
        this.persistStateToLocalStorage(persistenceKey, state);
        break;
      default:
        throw new Error(`Strategy '${strategy}' is not supported!`);
    }
  }

  public static getPersistedState(
    persistenceKey: string,
    strategy: TabularStatePersistenceStrategy = this.defaultStatePersistStrategy,
  ): TabularState | undefined {
    switch (strategy) {
      case TabularStatePersistenceStrategy.LocalStorage:
        return this.getPersistedStateFromLocalStorage(persistenceKey);
      default:
        throw new Error(`Strategy '${strategy}' is not supported!`);
    }
  }

  public static persistStateToLocalStorage(persistenceKey: string, state: TabularState): void {
    const persisted =
      LocalStorageHelper.getTypedJsonItem<TabularStateAggregatedLocalStorageModel>(
        LocalStorageKey.tabularStateAggregated,
      ) || {};
    persisted.byKeyMap ??= {};
    persisted.byKeyMap[persistenceKey] = state;
    LocalStorageHelper.setJsonItem(LocalStorageKey.tabularStateAggregated, persisted);
  }

  public static persistStateTuUrl(persistenceKey: string, state: TabularState): void {
    throw new Error("Not implemented yet!");
  }

  public static getPersistedStateFromLocalStorage(
    persistenceKey: string,
  ): TabularState | undefined {
    const persisted = LocalStorageHelper.getTypedJsonItem<TabularStateAggregatedLocalStorageModel>(
      LocalStorageKey.tabularStateAggregated,
    );
    return persisted && persisted.byKeyMap ? persisted.byKeyMap[persistenceKey] : undefined;
  }

  public static getPersistedStateFromUrl(persistenceKey: string): TabularState | undefined {
    throw new Error("Not implemented yet!");
  }

  // #endregion
}
