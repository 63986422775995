import { TypedEventEmitter } from "./typedEventEmitter";

export type TipsEventsMap = {
  // list of supported events
  /** Show tip */
  show: undefined;
};

/** Common tenant-to-tenant events. */
class TipsEventEmitter extends TypedEventEmitter<TipsEventsMap> {}

export const tipsEventEmitter = new TipsEventEmitter();
