import { ProfileUserLocationDto, UserDepartmentDto } from "@/core/api/generated";

import { useUserProfile } from "./useUserProfile";

export const useUserAffiliation = (): {
  isIniting: boolean;
  departments: UserDepartmentDto[] | undefined;
  locations: ProfileUserLocationDto[] | undefined;
} => {
  const profile = useUserProfile();
  return { isIniting: !profile, departments: profile?.departments, locations: profile?.locations };
};
