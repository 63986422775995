import { Alert, Box, LinearProgress, Stack, SxProps, Theme, Typography } from "@mui/material";
import { isEmpty, isNil, round } from "lodash-es";

import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { FileUploadSpecDto } from "@/core/api/generated";

import FieldValue from "../Form/Display/FieldValue";

export interface FileUploadSpecDisplayProps {
  spec: FileUploadSpecDto | null | undefined;
  sx?: SxProps<Theme>;
}

/** Input for duration that works on Moment.js Duration. */
export default function FileUploadSpecDisplay({ spec, sx }: FileUploadSpecDisplayProps) {
  const specRequest = useApiRequest(
    apiClient.filesApi.apiV1FilesUploadSpecGet,
    {},
    { skip: !!spec },
  );
  spec = spec || specRequest.data;

  if (specRequest.isLoading) {
    return <LinearProgress />;
  }
  if (!spec) {
    return null;
  }

  return (
    <Stack spacing={1} sx={sx}>
      <Alert severity='info'>
        File upload spec describes requirements and limitations for files that are being uploaded.
        Please review it thoroughly if you have problems with uploading files.
      </Alert>

      <FieldValue
        label='Allowed extensions'
        direction='row'
        labelAlight={{
          vertical: "start",
        }}
        isEmpty={isEmpty(spec.uploadPermittedExtensions)}
      >
        <Typography sx={{ whiteSpace: "wrap" }}>
          {StringHelper.joinIntoString(spec.uploadPermittedExtensions || [], ", ")}
        </Typography>
      </FieldValue>

      <FieldValue
        label='Allowed content types'
        direction='row'
        labelAlight={{
          vertical: "start",
        }}
        isEmpty={isEmpty(spec.uploadPermittedContentTypes)}
      >
        <Stack>{spec.uploadPermittedContentTypes?.map((x, i) => <Box key={i}>{x}</Box>)}</Stack>
      </FieldValue>

      <FieldValue
        label='Max file size'
        direction='row'
        labelAlight={{
          vertical: "start",
        }}
        isEmpty={isNil(spec.uploadFileSizeLimitBytes)}
      >
        {spec.uploadFileSizeLimitBytes && (
          <>
            {spec.uploadFileSizeLimitBytes} bytes / {round(spec.uploadFileSizeLimitBytes / 1024, 2)}{" "}
            KiB / {round(spec.uploadFileSizeLimitBytes / 1024 / 1024, 2)} MiB
          </>
        )}
      </FieldValue>

      <FieldValue
        label='Max file size per extension'
        direction='row'
        labelAlight={{
          vertical: "start",
        }}
        isEmpty={isEmpty(spec.uploadFileSizeLimitBytesPerExtensionMap)}
      >
        <Stack>
          {Object.entries(spec.uploadFileSizeLimitBytesPerExtensionMap || {})?.map(
            ([extension, limitBytes], i) => (
              <Box key={i}>
                {extension} = {limitBytes} bytes / {round(limitBytes / 1024, 2)} KiB /{" "}
                {round(limitBytes / 1024 / 1024, 2)} MiB
              </Box>
            ),
          )}
        </Stack>
      </FieldValue>

      <FieldValue
        label='Max file size per content type'
        direction='row'
        labelAlight={{
          vertical: "start",
        }}
        isEmpty={isEmpty(spec.uploadFileSizeLimitBytesPerContentTypeMap)}
      >
        <Typography sx={{ whiteSpace: "wrap" }}>
          {Object.entries(spec.uploadFileSizeLimitBytesPerContentTypeMap || {})?.map(
            ([contextType, limitBytes], i) => (
              <Box sx={{ mt: 0.5 }} key={i}>
                {contextType} = {limitBytes} bytes / {round(limitBytes / 1024, 2)} KiB /{" "}
                {round(limitBytes / 1024 / 1024, 2)} MiB
              </Box>
            ),
          )}
        </Typography>
      </FieldValue>
    </Stack>
  );
}
