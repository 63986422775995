import { isEmpty } from "lodash-es";

export class MakeLogoHelper {
  /**
   *  Returns make logo source from https://raw.githubusercontent.com/nexus-ops/car-logos-dataset
   *  Example: https://raw.githubusercontent.com/nexus-ops/car-logos-dataset/master/logos/thumb/bmw.png
   */
  public static getMakeLogoByIdentifier(identifier: string | null | undefined): string | null {
    if (isEmpty(identifier)) {
      return null;
    }
    return `https://raw.githubusercontent.com/nexus-ops/car-logos-dataset/master/logos/thumb/${identifier}.png`;
  }
}
