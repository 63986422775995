import { RepairSpecDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelectorPopover, {
  BaseRepairEntityCrossTenantSelectorPopoverInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelectorPopover";
import RepairSpecCrossTenantSelector, {
  RepairSpecCrossTenantSelectorProps,
} from "./RepairSpecCrossTenantSelector";

interface Props extends BaseRepairEntityCrossTenantSelectorPopoverInheritableProps<RepairSpecDto> {
  selectorProps: RepairSpecCrossTenantSelectorProps;
}

export default function RepairSpecCrossTenantSelectorPopover({
  selectorProps,
  ...otherProps
}: Props) {
  return (
    <BaseRepairEntityCrossTenantSelectorPopover<RepairSpecDto>
      selectorProps={selectorProps}
      renderSelector={(params) => <RepairSpecCrossTenantSelector {...params.selectorProps} />}
      {...otherProps}
    />
  );
}
