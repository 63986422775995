import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography } from "@mui/material";
import { getIn, useFormik } from "formik";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import GeneralBrandingInputs from "@/common/components/Entity/General/GeneralBranding/GeneralBrandingInputs";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateDepartmentDto,
  DepartmentDto,
  GeneralBrandingInputDto,
  UpdateDepartmentDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import GeneralAddressInput from "../General/Input/GeneralAddressInput";
import { BaseEntityCreateUpdateInheritableProps } from "../components/BaseEntityCreateUpdate";

const defaultDisplayProps = {
  breadcrumbs: true,
};

export type DepartmentCreateUpdateDefaultValues = {
  name?: CreateDepartmentDto["name"];
};

export interface DepartmentCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<
    DepartmentDto,
    DepartmentCreateUpdateDefaultValues
  > {
  departmentId?: string;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export type DepartmentCreateUpdateProps = DepartmentCreateUpdateOwnProps;

export default function DepartmentCreateUpdate({
  departmentId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  displayProps = defaultDisplayProps,
}: DepartmentCreateUpdateProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !departmentId;

  const departmentRequest = useApiRequest(
    apiClient.departmentsApi.apiV1DepartmentsDepartmentIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      departmentId: departmentId!,
    },
    {
      skip: !departmentId,
    },
  );
  const department = departmentRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        department && {
          idValue: department.id!,
          newTitle: department.localNumber || "",
        }) ||
      undefined,
  });

  const formik = useFormik<CreateDepartmentDto & UpdateDepartmentDto & BaseFormikValues>({
    enableReinitialize: !isCreate,
    initialValues: {
      name: defaultValues?.name || department?.name || undefined,
      description: department?.description || undefined,
      address: department?.address || undefined,
      branding: department?.branding || undefined,
      submit: "",
    },
    onSubmit: async (values, { setFieldError, setStatus, setSubmitting }) => {
      try {
        if (isCreate) {
          const response = await apiClient.departmentsApi.apiV1DepartmentsPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            createDepartmentDto: {
              ...values,
            },
          });
          enqueueSnackbar("Department created.", { variant: "success" });
          onCreate && onCreate(response.data);
          onSave && onSave(response.data);
        } else {
          const response = await apiClient.departmentsApi.apiV1DepartmentsDepartmentIdPut({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            departmentId,
            updateDepartmentDto: {
              ...values,
            },
          });
          enqueueSnackbar("Department updated.", { variant: "success" });
          onUpdate && onUpdate(response.data);
          onSave && onSave(response.data);
        }

        if (mounted.current) {
          setStatus({ success: true });
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formik;

  const brandingFormikProps = FormikHelper.getSubProps<
    CreateDepartmentDto & UpdateDepartmentDto & BaseFormikValues,
    GeneralBrandingInputDto | undefined
  >(formik, "branding", (v) => v.branding);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormContentBlock spacing={2}>
        <TextField
          error={Boolean(touched.name && errors.name)}
          required
          fullWidth
          helperText={touched.name && errors.name}
          label='Name'
          margin='dense'
          name='name'
          onBlur={handleBlur}
          onChange={handleChange}
          type='text'
          value={values.name || ""}
          variant='outlined'
        />

        <AppTextArea
          error={Boolean(touched.description && errors.description)}
          fullWidth
          helperText={touched.description && errors.description}
          mode='description'
          margin='dense'
          name='description'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.description || ""}
          variant='outlined'
        />

        <Stack sx={{ mt: 1, p: 1 }}>
          <Typography component='div' variant='subtitle1'>
            Address
          </Typography>

          <GeneralAddressInput
            errors={getIn(errors, "address")}
            touched={getIn(touched, "address")}
            value={values.address}
            inputsProps={{ all: {} }}
            onChange={(newValue) => setFieldValue("address", newValue)}
          />
        </Stack>

        <Stack sx={{ mt: 1, p: 1 }}>
          <Typography component='div' variant='subtitle1' sx={{ mb: 1 }}>
            Branding
          </Typography>

          <FormContentBlock>
            <GeneralBrandingInputs formikProps={brandingFormikProps} />
          </FormContentBlock>
        </Stack>
      </FormContentBlock>

      <GeneralValidationError sx={{ my: 1 }} errors={errors} />

      <FormActions>
        <LoadingButton
          disabled={isSubmitting}
          color='primary'
          loading={isSubmitting}
          fullWidth
          type='submit'
          variant='contained'
        >
          Save
        </LoadingButton>
      </FormActions>
    </form>
  );
}
