import { Box, Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import PercentValue from "@/common/components/Form/Display/PercentValue";
import { PriceHelper } from "@/common/helpers/price";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { enumService } from "@/common/services/enum";
import {
  CurrencyCode,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralTaxDto,
  GeneralTaxInputDto,
} from "@/core/api/generated";

import CurrencyValue from "../../../Form/Display/CurrencyValue";

interface Props {
  forValue: {
    subTotal: number;
  };
  tax: GeneralTaxDto | GeneralTaxInputDto | null | undefined;
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  currencyCode?: CurrencyCode | null;
  sx?: SxProps<Theme>;
}

export default function GeneralTaxExplanation({
  forValue,
  tax,
  currency,
  currencyCode,
  sx,
}: Props) {
  const currentCurrency = useCurrentCurrency();
  const currencyComputed: GeneralCurrencyDto | undefined =
    currency || (currencyCode && { code: currencyCode }) || tax?.currency || currentCurrency;

  const explanation = useMemo(
    () =>
      forValue && tax
        ? PriceHelper.getTaxExplanation({ forValue, tax, currency: currencyComputed })
        : undefined,
    [forValue, tax],
  );

  return (
    <Stack sx={sx}>
      {tax && <Box component='span'>{enumService.getEnumValueName("TaxType", tax.type)} tax</Box>}

      {/* calced value */}
      {explanation && (
        <Box component='span'>
          <CurrencyValue value={explanation.subTotal} currency={explanation.currency} />
          {" + ("}
          <PercentValue value={explanation.taxPercent} />
          {" or "}
          <CurrencyValue value={explanation.taxValue} currency={explanation.currency} />
          {")"}
          <Box component='sub' sx={{ color: "text.secondary" }}>
            tax
          </Box>
          {" = "}
          <CurrencyValue value={explanation.subTotalIncTax} currency={explanation.currency} />
        </Box>
      )}

      <Box component='span'>Tax is applied after discounts.</Box>
    </Stack>
  );
}
