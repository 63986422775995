import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { isNil } from "lodash-es";
import { useMemo, useState } from "react";

import AuthorizedMenuItem from "@/common/components/Auth/AuthorizedMenuItem";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useChatContextOptional } from "@/common/contexts/chat";
import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import {
  AppPermission,
  ApprovalResponseType,
  GeneralScopeDto,
  NegotiationAllowedActionsDto,
  NegotiationDto,
  NegotiationProposalDto,
  NegotiationType,
  ProposalStatus,
  UserPartiesMembershipDto,
} from "@/core/api/generated";

import AppTooltip from "../../../AppTooltip";
import AuthorizedElement from "../../../Auth/AuthorizedElement";
import AppAvatar from "../../../Avatar/AppAvatar";
import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import ApiEnumIcon from "../../../Icons/ApiEnumIcon";
import MenuWithTrigger from "../../../Menu/MenuWithTrigger";
import GeneralPriceSummaryInline from "../../../PriceSummary/GeneralPriceSummaryInline";
import TotalPriceExplanationModal from "../../../PriceSummary/TotalPriceExplanationModal";
import StrikethroughText from "../../../Text/StrikethroughText";
import GeneralScopeDisplayModal from "../../General/Display/GeneralScopeDisplayModal";
import GeneralPriceDisplay from "../../General/GeneralPrice/GeneralPriceDisplay";
import GeneralTagDisplay from "../../General/GeneralTag/GeneralTagDisplay";
import TagListModal from "../../General/GeneralTag/TagListModal";
import GeneralEventLogsModal from "../../GeneralEventLog/GeneralEventLogsModal";
import NegotiationDeleteModal from "../DataGrantDeleteModal";
import NegotiationViewModal from "../NegotiationViewModal";
import CreateNegotiationProposalModal from "./CreateNegotiationProposalModal";
import EditNegotiationProposalModal from "./EditNegotiationProposalModal";
import NegotiationProposalComputedStatusIcon from "./NegotiationProposalComputedStatusIcon";
import NegotiationProposalDetailsModal from "./NegotiationProposalDetailsModal";
import RespondOnNegotiationProposalControl from "./RespondOnNegotiationProposalControl";
import RespondOnNegotiationProposalModal from "./RespondOnNegotiationProposalModal";

const defaultDisplayProps = {
  avatar: true,
  responses: true,
  result: true,
  scrollToChatButton: true,
  menu: true,
};

interface OwnProps {
  negotiation: NegotiationDto;
  proposal: NegotiationProposalDto;
  allowedActions?: NegotiationAllowedActionsDto;
  partiesMembership?: UserPartiesMembershipDto | null;
  displayProps?: Partial<typeof defaultDisplayProps>;
  onNegotiationDelete?: () => void;
  onProposalDelete?: () => void;
}

type Props = OwnProps;

export default function NegotiationProposal({
  negotiation,
  proposal,
  allowedActions,
  partiesMembership,
  displayProps,
  onNegotiationDelete,
  onProposalDelete,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const chatContext = useChatContextOptional();

  const myParty = useMemo(
    () => NegotiationHelper.getMyNegotiationParty(negotiation, partiesMembership),
    [negotiation],
  );
  const otherParties = useMemo(
    () => NegotiationHelper.getOtherNegotiationParties(negotiation, partiesMembership),
    [negotiation],
  );
  const partiesResponseMap = useMemo(
    () => NegotiationHelper.getProposalPartiesResponseMap(negotiation, proposal),
    [negotiation, proposal],
  );

  const isMyPartyIsInitiatorParty =
    negotiation.initiatorParty?.id === myParty?.id && !isNil(myParty);
  // const isMyPartyIsTargetParty = negotiation.targetParty?.id === myParty?.id && !isNil(myParty);

  const isMyPartyResponded = NegotiationHelper.isRespondedOnProposal(proposal, partiesMembership);
  // const myPartyResponse = NegotiationHelper.getMyPartyProposalResponse(proposal, partiesMembership);
  // const otherPartyResponses = NegotiationHelper.getOtherPartiesProposalResponse(
  //   proposal,
  //   partiesMembership,
  // );

  const canCreateProposal = NegotiationHelper.canCreateProposal(negotiation, allowedActions);
  const canRespond = NegotiationHelper.canRespondOnProposal({
    proposal,
    allowedActions,
    partiesMembership,
  });

  const [isProposalModalOpen, setIsProposalModalOpen] = useState(false);
  const [isNegotiationModalOpen, setIsNegotiationModalOpen] = useState(false);
  const [isNegotiationDeleteModalOpen, setIsNegotiationDeleteModalOpen] = useState(false);
  const [isCreateProposalModalOpen, setIsCreateProposalModalOpen] = useState(false);
  const [isEditProposalModalOpen, setIsEditProposalModalOpen] = useState(false);
  const [isRespondOnNegotiationProposalModalOpen, setIsRespondOnNegotiationProposalModalOpen] =
    useState(false);
  const [isEventLogModalOpen, setIsEventLogModalOpen] = useState(false);
  const [isNegotiationEventLogModalOpen, setIsNegotiationEventLogModalOpen] = useState(false);
  const [isViewTagsModalOpen, setIsViewTagsModalOpen] = useState(false);
  const [isViewScopeModalOpen, setIsViewScopeModalOpen] = useState(false);
  const [isPriceExplanationModalOpen, setIsPriceExplanationModalOpen] = useState(false);
  const [scopeToView, setScopeToView] = useState<GeneralScopeDto | undefined>(undefined);

  const computedStatus = useMemo(
    () => NegotiationHelper.getProposalComputedStatus(proposal, partiesMembership),
    [proposal, partiesMembership],
  );

  return (
    <Stack direction='row' spacing={2} sx={{ alignItems: "flex-start" }}>
      {displayProps?.avatar && <AppAvatar partyMember={proposal.partyMember} />}

      <Card
        variant='outlined'
        sx={{
          boxShadow: "none",
          flex: 1,
          bgcolor: "background.gray",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor:
            (computedStatus.isOtherPartyRespondedAndWaitsForMyPartyResponse &&
              ((th) => th.palette.warning.main)) ||
            (computedStatus.isMyPartyApprovedOrNotRespondedAndOtherPartyDeclined &&
              ((th) => th.palette.error.main)) ||
            (computedStatus.isConsensus && ((th) => th.palette.success.main)) ||
            (computedStatus.isForcedConsensus && ((th) => th.palette.success.main)) ||
            ((th) => th.palette.divider),
        }}
      >
        <CardContent sx={{ p: 1 }}>
          <Stack direction='column' spacing={0}>
            <Stack
              direction={{ xxs: "column", md: "row" }}
              spacing={1}
              sx={{ width: "100%", alignItems: { xxs: "flex-start", md: "center" } }}
            >
              <Stack
                direction='row'
                spacing={1}
                sx={{ flex: 1, alignItems: "center", height: "100%" }}
              >
                {/* Computed status icon */}
                {allowedActions && (
                  <NegotiationProposalComputedStatusIcon
                    negotiation={negotiation}
                    proposal={proposal}
                    allowedActions={allowedActions}
                    partiesMembership={partiesMembership}
                  />
                )}

                {/* Statement */}
                <ButtonBase
                  onClick={() => {
                    setIsViewScopeModalOpen(true);
                    setScopeToView(proposal.scope || negotiation.scope);
                  }}
                >
                  <Box component='span'>{proposal.statement}</Box>
                </ButtonBase>
              </Stack>

              {/* Proposal value */}
              {negotiation.type === NegotiationType.Value && proposal.value && (
                <Box>
                  {proposal.value.price && (
                    <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start", mx: 2 }}>
                      <Stack
                        spacing={0}
                        sx={{ cursor: "pointer", alignItems: "flex-start" }}
                        onClick={() => setIsPriceExplanationModalOpen(true)}
                      >
                        {/* Price */}
                        <Stack direction='row' spacing={2}>
                          {proposal.value.price.initial &&
                            proposal.value.price.initial.price?.price !== 0 && (
                              <StrikethroughText
                                variant='crossSingle'
                                lineProps={{
                                  style: "solid",
                                  color: (th) => th.palette.error.dark,
                                  thickness: "2px",
                                }}
                              >
                                {/* TODO */}
                                {/* <GeneralPriceDisplay
                                  price={proposal.value.price.initialPrice!}
                                  withTooltip
                                  tooltipText='Initial price'
                                /> */}
                              </StrikethroughText>
                            )}

                          <GeneralPriceDisplay
                            price={proposal.value.price.price!}
                            withTooltip
                            tooltipText='Current price'
                          />
                        </Stack>

                        {/* Price details */}
                        {proposal.value.price.tax && (
                          <Typography variant='caption'>
                            <GeneralPriceSummaryInline
                              summary={{
                                currency: proposal.value.price.price?.currency,
                                subTotal: proposal.value.price.subTotal,
                                discount: proposal.value.price.discount,
                                tax: proposal.value.price.tax,
                                total: proposal.value.price.total,
                              }}
                            />
                          </Typography>
                        )}
                      </Stack>

                      {/* Propose new price */}
                      <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                        {canCreateProposal.value && (
                          <Tooltip title='Propose new price'>
                            <IconButton
                              size='small'
                              onClick={() => setIsCreateProposalModalOpen(true)}
                            >
                              <AppIcon of='edit' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </AuthorizedElement>
                    </Stack>
                  )}
                </Box>
              )}

              {/* Who already responded */}
              {/* {proposal.responses!.length !== 0 && (
                <Tooltip title='See who already responded'>
                  <AvatarGroup
                    total={proposal.responses!.length}
                    max={5}
                    sx={{ cursor: "pointer" }}
                    componentsProps={{
                      additionalAvatar: {
                        sx: { width: 20, height: 20, fontSize: "small" },
                      },
                    }}
                    onClick={() => setIsProposalModalOpen(true)}
                  >
                    {proposal.responses!.map((response) => (
                      <AppAvatar
                        key={response.id!}
                        party={response.party}
                        partyMember={response.partyMember}
                        withOnlineStatus={false}
                        sx={{ width: 20, height: 20 }}
                      />
                    ))}
                  </AvatarGroup>
                </Tooltip>
              )} */}

              {displayProps?.responses && (
                <>
                  {/* My party response */}
                  <Box>
                    {/* Respond actions */}
                    {!isMyPartyResponded &&
                      proposal.status === ProposalStatus.Open &&
                      allowedActions && (
                        <Stack direction='row' spacing={2} alignItems='center'>
                          <RespondOnNegotiationProposalControl
                            variant='full'
                            negotiation={negotiation}
                            proposal={proposal}
                            allowedActions={allowedActions}
                            partiesMembership={partiesMembership}
                          />
                        </Stack>
                      )}

                    {/* My response */}
                    {isMyPartyResponded && (
                      <>
                        {isMyPartyIsInitiatorParty && canRespond && (
                          <RespondOnNegotiationProposalControl
                            variant='compact'
                            negotiation={negotiation}
                            proposal={proposal}
                            allowedActions={allowedActions}
                            partiesMembership={partiesMembership}
                            compactVariantProps={{
                              dropdownButtonContent: <>Action</>,
                              dropdownButtonProps: {
                                buttonProps: {
                                  variant: "outlined",
                                  color: "secondary",
                                  size: "small",
                                },
                                sx: {
                                  px: 1,
                                },
                              },
                            }}
                          />
                        )}

                        {!isMyPartyIsInitiatorParty && (
                          <RespondOnNegotiationProposalControl
                            variant='compact'
                            negotiation={negotiation}
                            proposal={proposal}
                            allowedActions={allowedActions}
                            partiesMembership={partiesMembership}
                            compactVariantProps={{
                              dropdownButtonProps: {
                                buttonProps: {
                                  variant: "text",
                                  color: "secondary",
                                  size: "small",
                                },
                                sx: {
                                  px: 1,
                                },
                              },
                            }}
                          />
                        )}
                      </>
                    )}
                  </Box>

                  {/* Other party response */}
                  {isMyPartyIsInitiatorParty && (
                    <Stack>
                      {otherParties?.map((otherParty, j) => {
                        const otherPartyResponse = partiesResponseMap[otherParty.id!];
                        const otherPartyResponseType =
                          otherPartyResponse?.responseType || ApprovalResponseType.None;

                        return (
                          <Stack key={j} direction='row' spacing={1}>
                            <Box>
                              {otherParty.name || (
                                <InlineApiEnumValue type='PartyType' value={otherParty.type} />
                              )}
                              :
                            </Box>
                            <Box>
                              <AppTooltip
                                enabled={!!otherPartyResponseType}
                                title={
                                  <>
                                    {otherParty?.type} response is{" "}
                                    <strong>
                                      <InlineApiEnumValue
                                        type='ApprovalResponseType'
                                        value={otherPartyResponseType}
                                      />
                                    </strong>
                                  </>
                                }
                              >
                                <ApiEnumIcon
                                  type='ApprovalResponseType'
                                  value={otherPartyResponseType}
                                  withTooltip={false}
                                  inText
                                  fontSize='small'
                                />
                              </AppTooltip>
                            </Box>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}
                </>
              )}

              {/* Result */}
              {displayProps?.result &&
                (proposal.status !== ProposalStatus.Open ||
                  (isMyPartyResponded && isMyPartyIsInitiatorParty)) && (
                  <>
                    <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />
                    <Stack direction='row' spacing={0.5}>
                      <Box>Conclusion:</Box>
                      {/* <Box>
                      <InlineApiEnumValue type='ApprovalStatus' value={proposal.approvalStatus} />
                    </Box> */}
                      <ApiEnumIcon
                        type='ApprovalStatus'
                        value={proposal.approvalStatus}
                        fontSize='small'
                        withTooltip
                        inText
                      />
                    </Stack>
                  </>
                )}

              {/* Chat icon */}
              {chatContext && displayProps?.scrollToChatButton && (
                <Stack direction='row'>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{ mx: 1, display: { xxs: "none", md: "block" } }}
                  />

                  <Button
                    variant='text'
                    color='text'
                    size='small'
                    startIcon={<AppIcon of='chat' />}
                    onClick={() => {
                      chatContext.requestCreateTagByScopeInMessageInput({
                        scope: proposal.scope || negotiation.scope,
                        isScrollToInput: true,
                        isFocusInput: true,
                      });
                    }}
                  >
                    Chat
                  </Button>
                </Stack>
              )}

              {displayProps.menu && <Divider orientation='vertical' flexItem sx={{ mx: 1 }} />}

              {/* Menu */}
              {displayProps.menu && (
                <Stack direction='row' spacing={1} sx={{ ml: "auto", alignItems: "center" }}>
                  <MenuWithTrigger
                    trigger={
                      <IconButton>
                        <AppIcon of='moreVert' />
                      </IconButton>
                    }
                    withAuthCloseOnClick
                  >
                    <ListItem sx={{ pt: 0 }}>
                      <ListItemText
                        sx={{ m: 0, p: 0 }}
                        primary={
                          <Typography
                            component='div'
                            variant='body2'
                            color='text.secondary'
                            sx={{ m: 0, p: 0 }}
                          >
                            Proposal
                          </Typography>
                        }
                      />
                    </ListItem>
                    <MenuItem
                      dense
                      onClick={() => {
                        setIsProposalModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='view' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>View proposal</ListItemText>
                    </MenuItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={() => {
                        setIsEditProposalModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='edit' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Edit proposal</ListItemText>
                    </AuthorizedMenuItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={() => {
                        setIsEventLogModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='history' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Proposal event log</ListItemText>
                    </AuthorizedMenuItem>

                    <ListItem sx={{ pt: 0 }}>
                      <ListItemText
                        sx={{ m: 0, p: 0 }}
                        primary={
                          <Typography
                            component='div'
                            variant='body2'
                            color='text.secondary'
                            sx={{ m: 0, p: 0 }}
                          >
                            Negotiation
                          </Typography>
                        }
                      />
                    </ListItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={() => {
                        setIsNegotiationModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='view' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>View negotiation</ListItemText>
                    </AuthorizedMenuItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={() => setIsViewTagsModalOpen(true)}
                    >
                      <ListItemIcon>
                        <AppIcon of='tag' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>View negotiation tags</ListItemText>
                    </AuthorizedMenuItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      dense
                      onClick={() => {
                        setIsNegotiationEventLogModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='history' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Negotiation event log</ListItemText>
                    </AuthorizedMenuItem>
                    <AuthorizedMenuItem
                      permissions={[AppPermission.FleetAppAccess]}
                      onClick={() => {
                        setIsNegotiationDeleteModalOpen(true);
                      }}
                    >
                      <ListItemIcon>
                        <AppIcon of='delete' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Delete negotiation</ListItemText>
                    </AuthorizedMenuItem>
                  </MenuWithTrigger>
                </Stack>
              )}
            </Stack>
          </Stack>
        </CardContent>

        {/* GeneralScope */}
        {scopeToView && (
          <GeneralScopeDisplayModal
            open={isViewScopeModalOpen}
            onClose={() => {
              setIsViewScopeModalOpen(false);
              setScopeToView(undefined);
            }}
            scope={scopeToView}
            title='Proposal scope'
            description='This is the scope of the negotiation'
          />
        )}

        {/* Proposal details */}
        {allowedActions && (
          <NegotiationProposalDetailsModal
            open={isProposalModalOpen}
            onClose={() => setIsProposalModalOpen(false)}
            negotiation={negotiation}
            allowedActions={allowedActions}
            proposal={proposal}
          />
        )}

        {/* View negotiation */}
        {negotiation && (
          <NegotiationViewModal
            open={isNegotiationModalOpen}
            onClose={() => setIsNegotiationModalOpen(false)}
            onDelete={() => {
              setIsNegotiationModalOpen(false);
              onNegotiationDelete && onNegotiationDelete();
            }}
            negotiation={negotiation}
          />
        )}

        {/* Delete negotiation */}
        {negotiation && (
          <NegotiationDeleteModal
            entity={negotiation}
            open={isNegotiationDeleteModalOpen}
            onClose={() => setIsNegotiationDeleteModalOpen(false)}
            onDelete={() => {
              setIsNegotiationDeleteModalOpen(false);
              onNegotiationDelete && onNegotiationDelete();
            }}
          />
        )}

        {/* Create new proposal */}
        <CreateNegotiationProposalModal
          open={isCreateProposalModalOpen}
          onClose={() => setIsCreateProposalModalOpen(false)}
          negotiation={negotiation}
          createFromProposal={proposal}
        />

        {/* Edit proposal */}
        {negotiation && (
          <EditNegotiationProposalModal
            open={isEditProposalModalOpen}
            onClose={() => setIsEditProposalModalOpen(false)}
            negotiationId={negotiation.id!}
            proposal={proposal}
            onSave={() => setIsEditProposalModalOpen(false)}
          />
        )}

        {/* Respond on proposal */}
        {proposal && (
          <RespondOnNegotiationProposalModal
            open={isRespondOnNegotiationProposalModalOpen}
            onClose={() => setIsRespondOnNegotiationProposalModalOpen(false)}
            proposal={proposal}
          />
        )}

        {/* Event log */}
        <GeneralEventLogsModal
          open={isEventLogModalOpen}
          onClose={() => setIsEventLogModalOpen(false)}
          entityId={proposal.id!}
        />
        <GeneralEventLogsModal
          open={isNegotiationEventLogModalOpen}
          onClose={() => setIsNegotiationEventLogModalOpen(false)}
          entityId={negotiation.id!}
        />

        {/* View tags */}
        {negotiation.tags && (
          <TagListModal
            tagListProps={{
              tags: negotiation.tags.tags!.map((tag, i) => <GeneralTagDisplay key={i} tag={tag} />),
            }}
            open={isViewTagsModalOpen}
            onClose={() => setIsViewTagsModalOpen(false)}
          />
        )}

        {/* Price explanation */}
        {proposal.value?.price && (
          <TotalPriceExplanationModal
            open={isPriceExplanationModalOpen}
            onClose={() => setIsPriceExplanationModalOpen(false)}
            totalPriceExplanationProps={{
              summary: {
                currency: proposal.value.price.price?.currency,
                subTotal: proposal.value.price.subTotal,
                discount: proposal.value.price.discount,
                tax: proposal.value.price.tax,
                total: proposal.value.price.total,
              },
            }}
          />
        )}
      </Card>
    </Stack>
  );
}
