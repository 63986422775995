import { ListItemIcon, ListItemText, MenuItem, Stack } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { AppPermission, MaintenanceDto } from "@/core/api/generated";

import AuthorizedElement from "../../Auth/AuthorizedElement";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import MaintenanceAddToAssignmentModal from "./MaintenanceAddToAssignmentModal";
import MaintenanceDeleteModal from "./MaintenanceDeleteModal";

enum ActionFlags {
  Edit = "Edit",
  Delete = "Delete",
  AddToAssignment = "AddToAssignment",
}
const defaultDisplayProps = {
  actions: {
    edit: true,
    delete: true,
    addToAssignment: true,
  },
};

interface TriggersProps {
  entity?: MaintenanceDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: MaintenanceDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: MaintenanceDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function MaintenanceMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = { ...defaultDisplayProps, ...displayProps };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {/* Add ot assignment */}
          {displayProps?.actions?.addToAssignment && (
            <AuthorizedElement permissions={[AppPermission.MaintenanceManage]}>
              <MenuItem
                disabled={!!entity?.assignment}
                onClick={() => startAction(ActionFlags.AddToAssignment)}
              >
                <ListItemIcon>
                  <AppIcon of='add' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add to assignment</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}
          {displayProps?.actions?.edit && (
            <MenuItem component={RouterLink} to={ROUTE_PATH.MAINTENANCE_EDIT(entity?.id)}>
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.delete && (
            <AuthorizedElement permissions={[AppPermission.MaintenanceManage]}>
              <MenuItem onClick={() => startAction(ActionFlags.Delete)}>
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <Stack>
          {/* Delete */}
          {entity && (
            <MaintenanceDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}

          {/* Add to assignment */}
          {entity && (
            <MaintenanceAddToAssignmentModal
              open={currentAction === ActionFlags.AddToAssignment}
              onClose={() => cancelAction()}
              maintenance={entity}
              onSaved={() => {
                onUpdate && onUpdate(null);
                completeAction();
              }}
            />
          )}
        </Stack>
      )}
    />
  );
}
