import { Badge, Stack, SxProps, Theme } from "@mui/material";
import { isNil } from "lodash-es";
import { useMemo, useState } from "react";

import { FileItem } from "@/common/fileItem";

import NoFilesAlert from "../AppAlerts/NoFilesAlert";
import FullScreenFileViewerV2 from "../Images/FullScreenFileViewerV2";
import FileItemView, { FileItemViewActions, FileItemViewProps } from "./FileItemView";

export interface FileListViewProps {
  files: FileItem[];
  /** Show '+N' if file count is greater than max value */
  maxVisibleFiles?: number;
  itemWidth?: number;
  itemHeight?: number;
  isShowNoFilesAlert?: boolean;
  fileItemViewProps?: Partial<FileItemViewProps>;
  itemActions?: (file: FileItem) => FileItemViewActions;
  sx?: SxProps<Theme>;
}

/** Displays list of files. */
export default function FileListView({
  files = [],
  maxVisibleFiles,
  itemWidth = 60,
  itemHeight = 25,
  isShowNoFilesAlert = true,
  fileItemViewProps,
  itemActions,
  sx,
}: FileListViewProps) {
  // FullScreen
  const [fsFiles, setFsFiles] = useState<FileItem[]>([]);
  const [fsFileId, setFsFileId] = useState<string | undefined>(undefined);

  const maxVisibleFilesValue = useMemo(
    () =>
      isNil(maxVisibleFiles) ? files.length : Math.max(Math.min(maxVisibleFiles, files.length), 0),
    [files, maxVisibleFiles],
  );
  const visibleFiles = useMemo(
    () => files.slice(0, maxVisibleFiles),
    [files, maxVisibleFilesValue],
  );
  const hiddenFiles = useMemo(
    () => files.slice(maxVisibleFilesValue, files.length),
    [files, maxVisibleFilesValue],
  );

  const lastVisibleFileIndex = visibleFiles.length - 1;

  if (files.length === 0) {
    return isShowNoFilesAlert ? <NoFilesAlert /> : null;
  }

  return (
    <>
      <Stack direction='row' gap={1} sx={{ alignItems: "center", flexWrap: "wrap", ...sx }}>
        {visibleFiles.map((f, i) => (
          <Badge
            key={i}
            color='primary'
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              zIndex: 0,
            }}
            badgeContent={`+${hiddenFiles.length}`}
            invisible={i !== lastVisibleFileIndex || hiddenFiles.length === 0}
          >
            <FileItemView
              {...fileItemViewProps}
              fileItem={f}
              size={{
                ...fileItemViewProps?.size,
                width: itemWidth,
                height: itemHeight,
              }}
              actions={{
                enabled: true,
                // enable click and fullscreen view for all types of files,
                // as, for instance, in table cells only 1-3 files are displayed out of N which makes impossible to view/download the rest.
                // click: MimeTypeHelper.isImage(f.mimeType),
                click: true,
                onClick: () => {
                  //if (MimeTypeHelper.isImage(f.mimeType)) {
                  setFsFiles(files);
                  setFsFileId(f.id);
                },
                download: true,
                openInNewTab: false,
                ...(itemActions?.(f) || {}),
              }}
              imageSx={{ ...fileItemViewProps?.imageSx, width: "100%", objectFit: "cover" }}
            />
          </Badge>
        ))}
      </Stack>

      {/* Fullscreen file viewer */}
      <FullScreenFileViewerV2
        files={fsFiles}
        selectedFileId={fsFileId}
        onSelectFile={(file) => {
          setFsFileId(file.id);
        }}
        onClose={() => {
          setFsFiles([]);
          setFsFileId(undefined);
        }}
      />
    </>
  );
}
