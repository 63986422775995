import { Box, FormControl, FormHelperText } from "@mui/material";
import { getIn } from "formik";

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AssignmentStepLocationInputDto, AssignmentStepLocationType } from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import GeneralAddressInput from "../General/Input/GeneralAddressInput";
import SpotAutocompleteOrCreate from "../Spot/SpotAutocompleteOrCreate";

interface Props {
  formikProps: CustomFormikSubProps<AssignmentStepLocationInputDto | null | undefined>;
  disabled?: boolean;
}
export default function AssignmentStepLocationInput({ formikProps, disabled }: Props) {
  const { values, touched, errors, setFieldValue } = formikProps;
  {
    return (
      <>
        <FormControl margin='dense' fullWidth error={Boolean(touched?.type && errors?.type)}>
          <ApiEnumAutocomplete
            type='AssignmentStepLocationType'
            required
            disabled={disabled}
            value={values?.type || undefined}
            textFieldProps={{
              label: "Location type",
              error: Boolean(errors?.type),
              helperText: errors?.type,
            }}
            onChange={(newValue) => {
              setFieldValue("type", newValue);
              setFieldValue("spotId", undefined);
              setFieldValue("address", undefined);
            }}
          />
        </FormControl>
        {/* Specific step location period type inputs */}
        {values?.type && (
          <Box>
            {renderIf()
              .if(values.type === AssignmentStepLocationType.Spot)
              .then(
                <FormControl margin='dense' fullWidth error={Boolean(errors?.spotId)}>
                  <SpotAutocompleteOrCreate
                    autocompleteProps={{
                      entityId: values?.spotId,
                      required: true,
                      disabled: disabled,
                      onChange: (newValue) => {
                        setFieldValue("spotId", newValue?.id);
                      },
                    }}
                    createFormPlacement='modal'
                    onCreate={(newValue) => {
                      setFieldValue("spotId", newValue?.id);
                    }}
                  />
                  <FormHelperText error>{errors?.spotId}</FormHelperText>
                </FormControl>,
              )
              .else(
                <FormControl margin='dense' fullWidth error={Boolean(errors?.address)}>
                  <GeneralAddressInput
                    errors={getIn(errors, "address")}
                    touched={getIn(touched, "address")}
                    value={values.address}
                    inputsProps={{ all: {} }}
                    onChange={(newValue) => setFieldValue("address", newValue)}
                  />
                </FormControl>,
              )
              .render()}
          </Box>
        )}
      </>
    );
  }
}
