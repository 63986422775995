import { Chip, Stack, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { uniq } from "lodash-es";
import { useMemo } from "react";

import FieldValue from "../../Form/Display/FieldValue";
import AppIcon from "../../Icons/AppIcon";
import AppPopover from "../../Popover/AppPopover";
import AppPopoverContent from "../../Popover/AppPopoverContent";
import AppTypography from "../../Text/AppTypography";
import DepartmentLink from "../Department/DepartmentLink";
import LocationLink from "../Location/LocationLink";
import TenantLink from "../Tenant/TenantLink";

export type AffiliationInfoDisplayVariant = "normal" | "compact";

export interface AffiliationInfoDisplayProps {
  variant?: AffiliationInfoDisplayVariant;
  tenantId?: string | null;
  departmentIds?: string[] | null;
  locationIds?: string[] | null;
  departmentId?: string | null;
  locationId?: string | null;
  withoutTenant?: boolean;
  sx?: SxProps<Theme>;
}

export default function AffiliationInfoDisplay({
  variant = "normal",
  tenantId,
  departmentId,
  departmentIds,
  locationId,
  locationIds,
  withoutTenant = false,
  sx,
}: AffiliationInfoDisplayProps) {
  const computedDepartmentIds = useMemo(
    () => uniq([departmentId, ...(departmentIds || [])].filter((id) => !!id)),
    [departmentId, departmentIds],
  );

  const computedLocationIds = useMemo(
    () => uniq([locationId, ...(locationIds || [])].filter((id) => !!id)),
    [locationId, locationIds],
  );

  return (
    <>
      {variant === "compact" && (
        <AppPopover
          hoverBehavior={{
            closeBehavior: "onTriggerOrContentMouseLeave",
          }}
          trigger={
            <Stack direction='row' spacing={1}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Chip
                  variant='filled'
                  color='default'
                  size='medium'
                  label={
                    <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                      <AppIcon of='department' />
                      <AppTypography>{computedDepartmentIds.length}</AppTypography>
                    </Stack>
                  }
                />
              </Stack>

              <Stack direction='row' spacing={1} alignItems='center'>
                <Chip
                  variant='filled'
                  color='default'
                  size='medium'
                  label={
                    <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                      <AppIcon of='location' />
                      <AppTypography>{computedLocationIds.length}</AppTypography>
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
          }
        >
          <AppPopoverContent sx={{ width: 400, maxWidth: 400 }}>
            <Stack spacing={1} sx={sx}>
              {!withoutTenant && (
                <FieldValue
                  variant='standart'
                  labelIcon={<AppIcon of='company' inText />}
                  direction='column'
                  label='Company'
                  isEmpty={!tenantId}
                  ellipsing={{
                    enabled: true,
                  }}
                >
                  <TenantLink entity={undefined} entityId={tenantId} />
                </FieldValue>
              )}
              <FieldValue
                variant='standart'
                labelIcon={<AppIcon of='departments' inText />}
                direction='column'
                label='Departments'
                isEmpty={computedDepartmentIds.length === 0}
                ellipsing={{
                  enabled: true,
                }}
              >
                {computedDepartmentIds.map((id) => (
                  <DepartmentLink
                    key={id}
                    entity={undefined}
                    entityId={id}
                    sx={{ mr: "10px !important" }}
                  />
                ))}
              </FieldValue>
              <FieldValue
                variant='standart'
                labelIcon={<AppIcon of='location' inText />}
                direction='column'
                label='Locations'
                isEmpty={computedLocationIds.length === 0}
              >
                {computedLocationIds.map((id) => (
                  <LocationLink
                    key={id}
                    entity={undefined}
                    entityId={id}
                    sx={{ mr: "10px !important" }}
                  />
                ))}
              </FieldValue>
            </Stack>
          </AppPopoverContent>
        </AppPopover>
      )}

      {variant === "normal" && (
        <Stack spacing={1} sx={sx}>
          {!withoutTenant && (
            <FieldValue
              variant='standart'
              labelIcon={<AppIcon of='company' inText />}
              direction='column'
              label='Company'
              isEmpty={!tenantId}
              ellipsing={{
                enabled: true,
              }}
            >
              <TenantLink entity={undefined} entityId={tenantId} />
            </FieldValue>
          )}
          <FieldValue
            variant='standart'
            labelIcon={<AppIcon of='departments' inText />}
            direction='column'
            label='Departments'
            isEmpty={computedDepartmentIds.length === 0}
            ellipsing={{
              enabled: true,
            }}
          >
            {computedDepartmentIds.map((id) => (
              <DepartmentLink
                key={id}
                entity={undefined}
                entityId={id}
                sx={{ mr: "10px !important" }}
              />
            ))}
          </FieldValue>
          <FieldValue
            variant='standart'
            labelIcon={<AppIcon of='location' inText />}
            direction='column'
            label='Locations'
            isEmpty={computedLocationIds.length === 0}
            ellipsing={{
              enabled: true,
            }}
          >
            {computedLocationIds.map((id) => (
              <LocationLink
                key={id}
                entity={undefined}
                entityId={id}
                sx={{ mr: "10px !important" }}
              />
            ))}
          </FieldValue>
        </Stack>
      )}
    </>
  );
}
