import { RepairWorkDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelectorPopover, {
  BaseRepairEntityCrossTenantSelectorPopoverInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelectorPopover";
import RepairWorkCrossTenantSelector, {
  RepairWorkCrossTenantSelectorProps,
} from "./RepairWorkCrossTenantSelector";

interface Props extends BaseRepairEntityCrossTenantSelectorPopoverInheritableProps<RepairWorkDto> {
  selectorProps: RepairWorkCrossTenantSelectorProps;
}

export default function RepairWorkCrossTenantSelectorPopover({
  selectorProps,
  ...otherProps
}: Props) {
  return (
    <BaseRepairEntityCrossTenantSelectorPopover<RepairWorkDto>
      selectorProps={selectorProps}
      renderSelector={(params) => <RepairWorkCrossTenantSelector {...params.selectorProps} />}
      {...otherProps}
    />
  );
}
