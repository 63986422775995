import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import ContactInput, { ContactData } from "@/common/components/Contact/ContactInput";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import GeneralAddressInput from "@/common/components/Entity/General/Input/GeneralAddressInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { IdHelper } from "@/common/helpers/id";
import { RegexHelper } from "@/common/helpers/regex";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { cast } from "@/common/ts/conversions";
import { BaseFormikValues, ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateSupplierContactDto,
  CreateSupplierDto,
  EntityType,
  SupplierDto,
  UpdateSupplierContactDto,
  UpdateSupplierDto,
} from "@/core/api/generated";

import ApiEnumMultiselect from "../../Enum/ApiEnumMultiselect";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  nameOrEmail?: string;
};

type ContactValue = CreateSupplierContactDto & UpdateSupplierContactDto & { tempId?: string };

interface SupplierCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<SupplierDto, DefaultValues> {
  supplierId?: string;
}

export type SupplierCreateUpdateProps = SupplierCreateUpdateOwnProps;

export default function SupplierCreateUpdate({
  supplierId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: SupplierCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !supplierId;
  const isEdit = !!supplierId;

  const supplierRequest = useApiRequest(
    apiClient.suppliersApi.apiV1SuppliersSupplierIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      supplierId: supplierId!,
    },
    {
      skip: !supplierId,
    },
  );
  const supplier = supplierRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: supplier && {
      idValue: supplier.id!,
      newTitle: supplier.name || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Supplier}
      entityId={supplierId}
      entity={supplier}
      entityRequest={supplierRequest}
    >
      <Stack spacing={2}>
        {supplierRequest.isLoading && <LinearProgress />}

        <Formik<
          Omit<CreateSupplierDto, "contacts"> &
            Omit<UpdateSupplierDto, "contacts"> &
            BaseFormikValues & {
              contacts?: ContactValue[];
            }
        >
          enableReinitialize={false}
          initialValues={{
            types: supplier?.types || undefined,
            name:
              supplier?.name ||
              (defaultValues?.nameOrEmail && !RegexHelper.isEmail(defaultValues.nameOrEmail)
                ? defaultValues.nameOrEmail
                : undefined) ||
              undefined,
            notes: supplier?.notes || undefined,
            departmentId: supplier?.departmentIds?.at(0) || undefined,
            locationId: supplier?.locationIds?.at(0) || undefined,
            contacts: supplier?.contacts
              ? supplier?.contacts.map((x) => ({
                  ...x,
                  id: x.id,
                  tempId: x.id || IdHelper.newUuid4(),
                  personName: {
                    firstName: x.personName?.firstName || undefined,
                    lastName: x.personName?.lastName || undefined,
                  },
                  email: x.email,
                  isPrimary: x.isPrimary,
                  phoneNumber: x.phoneNumber,
                  nationalIdentityNumber: x.nationalIdentityNumber,
                }))
              : [
                  {
                    id: undefined,
                    tempId: IdHelper.newUuid4(),
                    personName: undefined,
                    email:
                      (defaultValues?.nameOrEmail &&
                        RegexHelper.isEmail(defaultValues.nameOrEmail) &&
                        defaultValues?.nameOrEmail) ||
                      undefined,
                    phoneNumber: undefined,
                    nationalIdentityNumber: undefined,
                    isPrimary: true,
                  },
                ],
            submit: "",
            address: {
              country: supplier?.address?.country || undefined,
              state: supplier?.address?.state || undefined,
              city: supplier?.address?.city || undefined,
              line1: supplier?.address?.line1 || undefined,
              line2: supplier?.address?.line2 || undefined,
              postalCode: supplier?.address?.postalCode || undefined,
            },
            tags: supplier?.tags || undefined,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            const values2 = {
              ...values,
              contacts: values.contacts?.map((x) => ({
                ...x,
                nationalIdentityNumber:
                  x.nationalIdentityNumber?.number && x.nationalIdentityNumber?.countryCode
                    ? x.nationalIdentityNumber
                    : undefined,
              })),
            };
            try {
              if (isCreate) {
                const response = await apiClient.suppliersApi.apiV1SuppliersPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  createSupplierDto: {
                    ...values2,
                  },
                });
                enqueueSnackbar("Supplier created.", { variant: "success" });
                onCreate && onCreate(response.data);
                onSave && onSave(response.data);
              } else {
                const response = await apiClient.suppliersApi.apiV1SuppliersSupplierIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  supplierId,
                  updateSupplierDto: {
                    ...values2,
                  },
                });
                enqueueSnackbar("Supplier updated.", { variant: "success" });
                onUpdate && onUpdate(response.data);
                onSave && onSave(response.data);
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <FormContentBlock gap={1}>
                  {/* Tags */}
                  <FormControl margin='dense' fullWidth>
                    <GeneralAttachedTagsInput
                      value={values.tags}
                      onChange={(newValue) => {
                        setFieldValue("tags", newValue);
                      }}
                    />
                    <FormHelperText error>
                      {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                        isIncludeNested: false,
                      })}
                    </FormHelperText>
                  </FormControl>

                  <Stack>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name || ""}
                      variant='outlined'
                    />

                    <FormControl
                      margin='dense'
                      fullWidth
                      disabled={!isCreate}
                      error={Boolean(touched.types && errors.types)}
                    >
                      <ApiEnumMultiselect
                        type='SupplierType'
                        values={values.types}
                        required
                        onChange={(newValue) => setFieldValue("types", newValue)}
                        label='Supplier types'
                      />
                      <FormHelperText>{touched.types && errors.types}</FormHelperText>
                    </FormControl>

                    {/* Entity affiliation */}
                    <EntityAffiliationInput
                      department={{
                        departmentId: values.departmentId,
                        onChange: (d) => {
                          setFieldValue("departmentId", d?.id);
                          setFieldValue("locationId", undefined);
                          d?.address && setFieldValue("address", d.address);
                        },
                        error: errors.departmentId,
                      }}
                      location={{
                        locationId: values.locationId,
                        onChange: (l) => {
                          setFieldValue("locationId", l?.id);
                          l?.address && setFieldValue("address", l.address);
                        },
                        searchFilters: { departmentId: values.departmentId },
                        createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                        error: errors.locationId,
                        disabled: !values.departmentId,
                      }}
                    />
                  </Stack>

                  <Typography component='div' sx={{ fontWeight: "bold" }}>
                    Contacts *
                  </Typography>

                  {isEdit && (
                    <Alert severity='warning' sx={{ my: 2 }}>
                      <AlertTitle>Keep in mind!</AlertTitle>
                      <ul>
                        <li>
                          When contact email is updated, then supplier user with old email is
                          suspended.
                        </li>
                        <li>
                          When contact is deleted, then supplier user with that email is suspended.
                        </li>
                        <li>
                          When email of existing suspended supplier user is used for a contact, then
                          that user is resumed.
                        </li>
                      </ul>
                    </Alert>
                  )}

                  <Stack
                    direction={{ xxs: "column", md: "row" }}
                    spacing={1}
                    sx={{ mt: 1, flexWrap: "wrap" }}
                  >
                    {values.contacts?.map((contact, i) => (
                      <Box key={i}>
                        <ContactInput
                          sx={{ mb: 1, minWidth: 400 }}
                          value={{
                            id: contact.id || undefined,
                            tempId: contact.tempId || undefined,
                            firstName: contact.personName?.firstName,
                            lastName: contact.personName?.lastName,
                            email: contact.email,
                            phoneNumber: contact.phoneNumber,
                            nationalIdentityNumber: contact.nationalIdentityNumber,
                            isPrimary: contact.isPrimary,
                          }}
                          displayProps={{
                            firstName: true,
                            lastName: true,
                            email: true,
                            phoneNumber: true,
                            nationalIdentityNumber: true,
                            isPrimary: true,
                          }}
                          inputsProps={{
                            firstName: {
                              name: `contacts[${i}].personName.firstName`,
                              onBlur: handleBlur,
                            },
                            lastName: {
                              name: `contacts[${i}].personName.lastName`,
                              onBlur: handleBlur,
                            },
                            email: {
                              name: `contacts[${i}].email`,
                              onBlur: handleBlur,
                            },
                            phoneNumber: {
                              name: `contacts[${i}].phoneNumber`,
                              onBlur: handleBlur,
                            },
                            nationalIdentityNumber: {
                              name: `contacts[${i}].nationalIdentityNumber`,
                              onBlur: handleBlur,
                            },
                            isPrimary: {
                              name: `contacts[${i}].isPrimary`,
                              onBlur: handleBlur,
                            },
                          }}
                          errors={getIn(errors, `contacts[${i}]`) as ValidationErrors<ContactData>}
                          action={
                            <IconButton
                              onClick={() => {
                                setValues((currentValues) => ({
                                  ...currentValues,
                                  contacts: currentValues.contacts?.filter(
                                    (x, j) => x.tempId !== contact.tempId,
                                  ),
                                }));
                              }}
                            >
                              <AppIcon of='close' />
                            </IconButton>
                          }
                          onChange={(newValue) => {
                            setValues((currentValues) => ({
                              ...currentValues,
                              contacts: currentValues.contacts?.map((x) =>
                                x.tempId === newValue?.tempId
                                  ? {
                                      ...x,
                                      ...newValue,
                                      personName: {
                                        firstName: newValue?.firstName,
                                        lastName: newValue?.lastName,
                                      },
                                    }
                                  : { ...x, isPrimary: newValue.isPrimary ? false : x.isPrimary },
                              ),
                            }));
                          }}
                        />
                      </Box>
                    ))}
                  </Stack>

                  <Button
                    sx={{ mt: 1, width: "fit-content" }}
                    variant='outlined'
                    color='text'
                    size='small'
                    type='button'
                    startIcon={<AppIcon of='add' />}
                    onClick={() => {
                      setFieldValue("contacts", [
                        ...(values.contacts || []),
                        cast<ContactValue>({
                          tempId: IdHelper.newUuid4(),
                          isPrimary: values.contacts?.length === 0,
                        }),
                      ]);
                    }}
                  >
                    Add contact
                  </Button>

                  {/* Optional fields */}
                  <FoldableBlock
                    trigger={{
                      label: <Typography variant='subtitle1'>Show more details</Typography>,
                    }}
                  >
                    <Box sx={{ my: 1 }}>
                      <Typography component='div' sx={{ mb: 1, fontWeight: "bold" }}>
                        Address
                      </Typography>

                      <GeneralAddressInput
                        value={values.address}
                        inputsProps={{ all: {} }}
                        onChange={(newValue) => setFieldValue("address", newValue)}
                      />
                    </Box>

                    <AppTextArea
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      mode='notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.notes || ""}
                      variant='outlined'
                    />
                  </FoldableBlock>
                </FormContentBlock>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <Button
                    sx={{ mt: { xs: "auto", md: 2 }, mb: 2 }}
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </Button>
                </FormActions>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </BaseEntityCreateUpdate>
  );
}
