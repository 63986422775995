import { PopoverProps } from "@mui/material";
import { isBoolean, isNil, isObject } from "lodash-es";
import { useEffect, useRef, useState } from "react";

type anchorElType = PopoverProps["anchorEl"];

export interface IPopoverOpenStatus {
  isOpening: boolean;
  isOpen: boolean;
  isClosing: boolean;
  isClosed: boolean;
}

/** Simplifies tracking status of popover open/opening/close/closing. */
export const usePopoverOpenStatus = (
  openOrAnchorElOrPopoverProps: boolean | anchorElType | PopoverProps,
): IPopoverOpenStatus => {
  const popoverProps =
    (isObject(openOrAnchorElOrPopoverProps) &&
      "anchorEl" in (openOrAnchorElOrPopoverProps as PopoverProps) &&
      (openOrAnchorElOrPopoverProps as PopoverProps)) ||
    undefined;
  const anchorEl =
    popoverProps?.anchorEl ||
    (isObject(openOrAnchorElOrPopoverProps) && (openOrAnchorElOrPopoverProps as anchorElType)) ||
    undefined;

  const isOpen = isBoolean(openOrAnchorElOrPopoverProps)
    ? Boolean(openOrAnchorElOrPopoverProps)
    : Boolean(anchorEl) && (!isNil(popoverProps) ? popoverProps.open : true);

  const prevOpenRef = useRef(false);
  const [openStatus, setOpenStatus] = useState<IPopoverOpenStatus>({
    isOpening: false,
    isOpen: false,
    isClosing: false,
    isClosed: false,
  });

  useEffect(() => {
    const newValue: IPopoverOpenStatus = {
      isOpening: !prevOpenRef.current && isOpen,
      isOpen: isOpen,
      isClosing: prevOpenRef.current && !isOpen,
      isClosed: !isOpen,
    };
    setOpenStatus(newValue);

    // make sure opening/closing is set to false after short period of time
    if (newValue.isOpening || newValue.isClosing) {
      setTimeout(() => {
        setOpenStatus({ ...newValue, isOpening: false, isClosing: false });
      }, 100);
    }

    prevOpenRef.current = isOpen;
  }, [isOpen]);

  return openStatus;
};
