import Close from "@mui/icons-material/Close";
import { Box, DialogTitle, DialogTitleProps, IconButton } from "@mui/material";
import { isEmpty } from "lodash-es";
import { ReactNode } from "react";

import AppLink from "../Link/AppLink";

export interface AppModalTitleProps {
  withCloseIcon?: boolean;
  withLink?: boolean;
  to?: string;
  title?: ReactNode;
  variant?: DialogTitleProps["variant"];
  children?: DialogTitleProps["children"];
  onCloseClicked?: () => void;
}

export default function AppModalTitle({
  title,
  withCloseIcon = true,
  withLink = false,
  to,
  variant,
  children,
  onCloseClicked,
}: AppModalTitleProps) {
  return (
    <AppLink enabled={withLink || !isEmpty(to)} to={to || ""}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }} variant={variant}>
        <Box>{title || children}</Box>

        <Box sx={{ ml: "auto" }}>
          {withCloseIcon && (
            <IconButton
              edge='end'
              onClick={() => {
                onCloseClicked && onCloseClicked();
              }}
            >
              <Close />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
    </AppLink>
  );
}
