import { first, isNil, orderBy } from "lodash-es";
import uuid4 from "uuid4";

import {
  FileDto,
  FileReferenceDto,
  GeneralAttachmentDto,
  GeneralAttachmentInputDto,
  GeneralAvatarAddDto,
  GeneralAvatarDto,
  GeneralLogoInputDto,
  GeneralLogoResizedDto,
  ThumbnailSizeType,
  UploadedFileDto,
} from "@/core/api/generated";

import { FileHelper } from "./helpers/file";
import { ValidationInfo } from "./validation";

type FileType =
  | File
  | FileDto
  | GeneralAttachmentDto
  | GeneralAttachmentInputDto
  | GeneralAvatarDto
  | FileReferenceDto
  | GeneralLogoResizedDto;

export class FileItem {
  public id: string;
  public blob?: File;
  public file?: FileDto;
  public attachment?: GeneralAttachmentDto;
  public attachmentInput?: GeneralAttachmentInputDto;
  public avatar?: GeneralAvatarDto;
  public logo?: GeneralLogoResizedDto;
  public isUploading: boolean;
  public validation?: ValidationInfo;
  public abortUpload?: () => void;
  public abortController?: AbortController;
  public retryUpload?: () => void;
  public showErrorDetails?: (validationInfo: ValidationInfo) => void;
  constructor(file: FileType, isUploading: boolean = false) {
    if (file instanceof Blob) {
      this.blob = file;
    } else {
      const attachmentOrAvatarOrLogo = file as
        | GeneralAttachmentDto
        | GeneralAttachmentInputDto
        | GeneralAvatarDto
        | GeneralLogoResizedDto;
      const attachment = file as GeneralAttachmentDto;
      const attachmentInput = file as GeneralAttachmentInputDto;
      const avatar = file as GeneralAvatarDto;
      const logo = file as GeneralLogoResizedDto;
      const isAttachment = attachment.isAttachment === true || !!attachment.file?.url;
      const isAttachmentInput =
        attachmentInput.isAttachmentInput === true || attachmentInput.file?.isUploadedFile === true;
      const isAttachmentOrAvatar = !!attachmentOrAvatarOrLogo.file;
      const isAvatar = avatar.isAvatar === true;
      const isLogo = logo.isLogo === true;

      const newFile = isAttachmentOrAvatar ? attachmentOrAvatarOrLogo.file : file;
      this.file = newFile ? { ...newFile, id: newFile?.id || undefined } : undefined;
      this.attachment = isAttachment ? attachment : undefined;
      this.attachmentInput = isAttachmentInput ? attachmentInput : undefined;
      this.avatar = isAvatar ? avatar : undefined;
      this.logo = isLogo ? logo : undefined;
    }
    this.isUploading = isUploading;
    this.id = this.file?.id || this.attachment?.id || uuid4();
  }

  public get fileName(): string | undefined {
    return this.blob?.name || this.file?.originalFileName || undefined;
  }

  public get fileSize(): number {
    return this.blob?.size || this.file?.size?.bytes || this.file?.sizeBytes || 0;
  }

  public get fileExtension(): string | undefined {
    return FileHelper.getFileExtension(this.fileName);
  }

  public get mimeType(): string | undefined {
    return this.blob?.type || this.file?.contentType || undefined;
  }

  public get fileUrl(): string | undefined {
    return FileHelper.getBlobUrl(this.blob) || this.file?.url || undefined;
  }

  public get fileType() {
    return FileHelper.getFileType(this.mimeType);
  }

  public get hasThumbnails(): boolean {
    return (this.file?.thumbnails && this.file?.thumbnails.length !== 0) || false;
  }

  public get caption(): string | undefined {
    return this.attachment?.caption || this.attachmentInput?.caption || undefined;
  }

  public setUploadedFile(file: FileDto) {
    this.id = file.id || uuid4();
    this.file = file;
    this.isUploading = false;
  }

  public setValidation(validation: ValidationInfo) {
    this.validation = validation;
  }

  public setUploadAbortFn(callback: () => void) {
    this.abortUpload = callback;
  }

  public setAttachmentCaption(caption?: string) {
    if (this.attachment) {
      this.attachment.caption = caption;
    }
    if (this.attachmentInput) {
      this.attachmentInput.caption = caption;
    }
  }

  /** Return thumbnail URL or the file URL info thumbnails found. */
  public getThumbnailUrl({
    sizeType,
    maxSize,
    minSize,
  }: {
    sizeType?: ThumbnailSizeType;
    maxSize?: Partial<IImageSize>;
    minSize?: Partial<IImageSize>;
  }): string | undefined {
    if (!this.file) {
      return undefined;
    }
    if (!this.hasThumbnails) {
      return this.fileUrl!;
    }

    let thumbnails = orderBy(
      this.file!.thumbnails!.filter((x) => !!x.size),
      (x) => x.size!.height!,
      "desc",
    );

    if (sizeType) {
      thumbnails = thumbnails.filter((x) => x.sizeType === sizeType);
    } else if (maxSize) {
      thumbnails = thumbnails.filter(
        (x) =>
          (!isNil(maxSize.width) ? x.size!.width! <= maxSize.width : true) &&
          (!isNil(maxSize.height) ? x.size!.height! <= maxSize.height : true),
      );
    } else if (minSize) {
      thumbnails = thumbnails.filter(
        (x) =>
          (!isNil(minSize.width) ? x.size!.width! >= minSize.width : true) &&
          (!isNil(minSize.height) ? x.size!.height! >= minSize.height : true),
      );
    }

    const thumbnail = first(thumbnails);
    return thumbnail?.url || this.fileUrl!;
  }

  // #region Static

  public static createFrom(file: FileType, isUploading: boolean = false): FileItem {
    return new FileItem(file, isUploading);
  }

  public static createManyFrom(
    files?: FileType[] | null,
    isUploading: boolean = false,
  ): FileItem[] {
    if (!files) {
      return [];
    }
    return files.map((f) => new FileItem(f, isUploading));
  }

  public static toUploadedFileDto(file: FileDto): UploadedFileDto {
    return {
      id: file.id,
    };
  }

  public static toGeneralAttachmentDto(file: FileItem): GeneralAttachmentDto {
    return {
      ...file.attachment,
      file: {
        ...file.file,
      },
    };
  }

  public static toGeneralAttachmentInputDto(file: FileItem): GeneralAttachmentInputDto {
    return {
      ...file.attachment,
      ...file.attachmentInput,
      file: {
        id: file.id,
      },
    };
  }

  public static toGeneralAttachmentInputDtoOrDto(
    file: FileItem,
  ): GeneralAttachmentInputDto | GeneralAttachmentDto {
    return {
      ...file.attachment,
      ...file.attachmentInput,
      file: {
        ...file.file,
        id: file.id,
      },
    };
  }

  public static toManyGeneralAttachmentDto(files?: FileItem[] | null): GeneralAttachmentDto[] {
    if (!files) {
      return [];
    }
    return files.map((f) => this.toGeneralAttachmentDto(f));
  }

  public static toManyGeneralAttachmentInputDto(
    files?: FileItem[] | null,
  ): GeneralAttachmentInputDto[] {
    if (!files) {
      return [];
    }
    return files.map((f) => this.toGeneralAttachmentInputDto(f));
  }

  public static toManyGeneralAttachmentInputDtoOrDto(
    files?: FileItem[] | null,
  ): Array<GeneralAttachmentInputDto | GeneralAttachmentDto> {
    if (!files) {
      return [];
    }
    return files.map((f) => this.toGeneralAttachmentInputDtoOrDto(f));
  }

  public static toGeneralAvatarDto(file: FileItem): GeneralAvatarDto {
    return {
      ...file.avatar,
      file: {
        ...file.file,
      },
    };
  }

  public static toGeneralLogoResizedDto(file: FileItem): GeneralLogoResizedDto {
    return {
      ...file.logo,
      file: {
        ...file.file,
      },
    };
  }

  public static toGeneralAvatarAddDto(file: FileItem): GeneralAvatarAddDto {
    return {
      ...file.avatar,
      file: {
        id: file.id,
      },
    };
  }

  public static toGeneralLogoInputDto(file: FileItem): GeneralLogoInputDto {
    return {
      ...file.logo,
      file: {
        id: file.id,
      },
    };
  }

  // #endregion
}
