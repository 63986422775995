import { AppUpdateInfoViewQueryParams } from "@/App/AdminAppView/AppUpdateInfo/AppUpdateInfoView";
import { AdminUserRolesEditPageQueryParams } from "@/App/AdminAppView/Users/UpdateUserRolesPage";
import { AdminUserEditPageQueryParams } from "@/App/AdminAppView/Users/UserEditPage";
import { CompleteInfoQueryParams } from "@/App/BaseApp/Auth/CompleteInfo/CompleteInfo";
import { CheckUserStatusQueryParams } from "@/App/BaseApp/Auth/Invite/CheckUserStatus/CheckUserStatus";
import { CreatePasswordQueryParams } from "@/App/BaseApp/Auth/Invite/CreatePassword/CreatePassword";
import { EmailEnterQueryParams } from "@/App/BaseApp/Auth/Invite/EmailEnter/EmailEnter";
import { AcceptInviteQueryParams } from "@/App/BaseApp/Auth/Invite/InviteAcceptance/InviteAcceptance";
import { InviteAlreadyAnsweredPageQueryParams } from "@/App/BaseApp/Auth/Invite/InviteAlreadyAnsweredPage";
import { InviteExpiredPageQueryParams } from "@/App/BaseApp/Auth/Invite/InviteExpiredPage";
import { InviteNotFoundPageEnterQueryParams } from "@/App/BaseApp/Auth/Invite/InviteNotFoundPage";
import { InviteStartPageQueryParams } from "@/App/BaseApp/Auth/Invite/InviteStartPage";
import { LoginQueryParams } from "@/App/BaseApp/Auth/Login/Login";
import { AccessoryCheckCreateUpdateQueryParams } from "@/App/MainAppView/AccessoryChecks/AccessoryCheckCreateUpdatePage";
import { AssignmentCreateUpdateQueryParams } from "@/App/MainAppView/Assignments/AssignmentCreateUpdatePage";
import { ChatViewQueryParams } from "@/App/MainAppView/Chats/ChatViewPage";
import { ContractCreateUpdateQueryParams } from "@/App/MainAppView/Contracts/ContractCreateUpdatePage";
import { DamageCostEvaluationAggregateViewPageQueryParams } from "@/App/MainAppView/DamageCostEvaluationAggregates/DamageCostEvaluationAggregateViewPage";
import { DamageCostEvaluationCreateUpdateQueryParams } from "@/App/MainAppView/DamageCostEvaluations/DamageCostEvaluationCreateUpdatePage";
import { DamageDetectionAggregateViewPageQueryParams } from "@/App/MainAppView/DamageDetectionAggregates/DamageDetectionAggregateViewPage";
import { DamageDetectionCreateUpdateQueryParams } from "@/App/MainAppView/DamageDetections/DamageDetectionCreateUpdatePage";
import { DocumentsPageQueryParams } from "@/App/MainAppView/Documents/DocumentsPage";
import { AssessmentFlowChatPageQueryParams } from "@/App/MainAppView/Flows/Assessment/AssessmentFlowChatPage";
import { AssessmentFlowsPageQueryParams } from "@/App/MainAppView/Flows/Assessment/AssessmentFlowsPage";
import { GeneralHistoryListPageQueryParams } from "@/App/MainAppView/GeneralHistory/GeneralHistoryListPage";
import { GeneralStatusHistoryListPageQueryParams } from "@/App/MainAppView/GeneralStatusHistory/GeneralStatusHistoryListPage";
import { MaintenanceCreateUpdateQueryParams } from "@/App/MainAppView/Maintenances/MaintenanceCreateUpdatePage";
import { ImportCreateUpdateQueryParams } from "@/App/MainAppView/Management/DataImport/CreateUpdateImportPage";
import { CreateNewPersonalInvitePageQueryParams } from "@/App/MainAppView/Management/Invites/CreateNew/CreateNew";
import { CreateNewAnonymousInvitePageQueryParams } from "@/App/MainAppView/Management/Invites/CreateNewAnonymous/CreateNewAnonymous";
import { AssetSubscriptionUpdateQueryParams } from "@/App/MainAppView/Products/AssetSubscriptions/AssetSubscriptionCreateUpdatePage";
import {
  VehicleVisualModelViewPageQueryParams,
  VehicleVisualModelViewPageRouteParams,
} from "@/App/MainAppView/ReferenceData/VehicleVisualModels/VehicleVisualModelViewPage";
import { RepairOperationCreateUpdatePageQueryParams } from "@/App/MainAppView/RepairOperations/RepairOperationCreateUpdatePage";
import { RepairOperationViewPageQueryParams } from "@/App/MainAppView/RepairOperations/RepairOperationViewPage";
import { SpotCreateUpdatePageQueryParams } from "@/App/MainAppView/Spots/SpotCreateUpdatePage";
import { CustomTagCreateUpdatePageQueryParams } from "@/App/MainAppView/Tags/CustomTags/CustomTagCreateUpdatePage";
import { DataGrantCreateUpdatePageQueryParams } from "@/App/MainAppView/TenantToTenant/DataGrant/DataGrantCreateUpdatePage";
import { DataGrantsPageQueryParams } from "@/App/MainAppView/TenantToTenant/DataGrant/DataGrantsPage";
import {
  VehicleDamageViewPageAltRouteParams,
  VehicleDamageViewPageQueryParams,
  VehicleDamageViewPageRouteParams,
} from "@/App/MainAppView/VehicleDamages/VehicleDamageViewPage";
import {
  VehicleDamagesPageQueryParams,
  VehicleDamagesPageRouteParams,
} from "@/App/MainAppView/VehicleDamages/VehicleDamagesPage";
import { WashCreateUpdateQueryParams } from "@/App/MainAppView/Washes/WashCreateUpdatePage";
import { WheelOperationCreateUpdateQueryParams } from "@/App/MainAppView/WheelOperation/WheelOperationCreateUpdatePage";
import { EntitySourceType, GeneralScopeDto } from "@/core/api/generated";

import { RoutePathBuilder } from "../builders/routePath";
import { AssessmentFlowViewPageQueryParams } from "../components/Entity/AssessmentFlow/View/AssessmentFlowView";
import { VehicleViewPageQueryParams } from "../components/Entity/Vehicle/View/VehicleView";
import { GeneralScopeHelper } from "../helpers/generalScope";
import { UrlHelper } from "../helpers/url";

export const AppArea = {
  Development: "dev",
  Auth: "auth",
  Fleet: "app",
  FleetCustomer: "fleet-customer",
  Admin: "admin",
} as const;
export type AppArea = (typeof AppArea)[keyof typeof AppArea];

export const ROUTE_PARTS = {
  APP_AREA_FLEET_APP: AppArea.Fleet,
  APP_AREA_FLEET_CUSTOMER: AppArea.FleetCustomer,
  APP_AREA_ADMIN: AppArea.Admin,

  ERROR: "error",
  APP_INIT: "app-init",
  NOT_FOUND: "not-found",
  API_CONNECTION: "api-connection",
  NO_INTERNET: "no-internet",
  NETWORK: "network",
  FORBIDDEN: "forbidden",

  INDEX: "index",
  EDIT: "edit",
  CREATE_NEW: "create-new",
  CREATE: "create",
  CREATE_MANY: "create-many",
  CREATE_MANY_SIMILAR: "create-many-similar",
  VIEW: "view",

  AUTH: "auth",
  SIGN_IN: "signin",
  SIGN_UP: "signup",
  INVITE: "invite",
  PERSONAL: "personal",
  ANONYMOUS: "anonymous",
  EXPIRED: "expired",
  EMAIL: "email",
  ACCEPT: "accept",
  USER_PROFILE_CHECK: "user-profile-check",
  LOGOUT: "logout",
  LOGGED_OUT: "logged-out",

  AUTH0_CALLBACK: "auth0-callback",
  CALLBACK: "callback",

  TESLA: "tesla",

  DEVELOPMENT: "dev",
  DEV_STYLEGUIDE: "styleguide",

  DASHBOARD_TASKS: "dtasks",
  DASHBOARD: "dashboard",
  FLEET_CUSTOMER_DASHBOARD: "fleet-customer-dashboard",
  MANAGEMENT: "management",
  INTEGRATION: "integration",
  API_CLIENTS: "api-clients",
  WEBHOOKS: "webhooks",
  TESLA_CONNECTIONS: "tesla-connections",
  TENANT_TO_TENANT: "tenant-to-tenant",
  TENANT_CONNECTIONS: "tenant-connections",
  TENANT_REQUESTS: "tenant-requests",
  COMPANY_TO_COMPANY: "company-to-company",
  COMPANY_CONNECTIONS: "company-connections",
  COMPANY_REQUESTS: "company-requests",
  DATA_GRANTS: "data-grants",
  REQUESTS: "requests",
  CONNECTIONS: "connections",
  OPERATIONS: "operations",
  OTHER: "other",
  SELECT_COMPANY: "select-company",
  SELECT_TENANT: "select-tenant",
  ACCOUNT: "account",
  PROFILE: "profile",
  PERSONAL_INFO: "personal-info",
  COMPLETE_INFO: "complete-info",
  CREATE_COMPANY: "create-company",
  COMPANY: "company",
  INFO: "info",
  EMAIL_VERIFICATION: "email-verification",
  PASSWORD: "password",
  SUBSCRIPTIONS: "subscriptions",
  SUBSCRIPTION_PLANS: "subscription-plans",
  ASSET_SUBSCRIPTION_PLANS: "asset-subscription-plans",
  ASSET_SUBSCRIPTIONS: "asset-subscriptions",
  COMPANY_PROFILE: "company-profile",
  TENANT_PROFILE: "tenant-profile",
  PAYMENT_PROFILE: "payment-profile",
  INVOICES: "invoices",
  TENANTS: "tenants",
  COMPANIES: "companies",
  USERS: "users",
  INVITES: "invites",
  ROLES: "roles",
  VEHICLES: "vehicles",
  VEHICLE_DAMAGES: "vehicle-damages",
  ACCESSORIES: "accessories",
  CUSTOMERS: "customers",
  SUPPLIERS: "suppliers",
  CONTRACTS: "contracts",
  VISUAL_INSPECTIONS: "visual-inspections",
  DAMAGE_DETECTIONS: "damage-detections",
  DAMAGE_COST_EVALUATIONS: "damage-cost-evaluations",
  ACCESSORY_CHECKS: "accessory-checks",
  REPAIR_OPERATIONS: "repair-operations",
  WASHES: "washes",
  MAINTENANCES: "maintenances",
  WHEEL_OPERATIONS: "wheel-operations",
  DEPARTMENTS: "departments",
  LOCATIONS: "locations",
  SALES_LOCATIONS: "sales-locations",
  SPOTS: "spots",
  POOLS: "pools",
  ITEMS: "items",
  ASSETS: "assets",
  DOCUMENTS: "documents",
  FILES: "files",
  FLOWS: "flows",
  ASSESSMENT: "assessment",
  AGGREGATES: "aggregates",
  COMMUNICATION: "communication",
  CHATS: "chats",
  CHAT: "chat",
  NEGOTIATIONS: "negotiations",
  REPAIR: "repair",
  MATERIALS: "materials",
  WORK: "work",
  SPARE_PARTS: "spare-parts",
  SPECS: "specs",
  TENANT: "tenant",
  SETTINGS: "settings",
  COMPANY_SETTINGS: "company-settings",
  TENANT_SETTINGS: "tenant-settings",
  HISTORY: "history",
  ACTUAL_STATE: "actual-state",
  REFERENCE_DATA: "reference-data",
  VEHICLE_VISUAL_MODELS: "vehicle-visual-models",
  IMPORTS: "imports",
  PRODUCTS: "products",
  PARTIES: "parties",
  GENERAL_EVENT_LOG: "general-event-log",
  GENERAL_HISTORY: "general-history",
  GENERAL_STATUS_HISTORY: "general-status-history",
  ENTITY: "entity",
  TAGS: "tags",
  CUSTOM: "custom",
  CUSTOM_TAGS: "custom-tags",
  ASSIGNMENTS: "assignments",

  ADMIN_DASHBOARD: "dashboard",
  ADMIN_COMPANY_DASHBOARD: "company-dashboard",
  ADMIN_TENANTS: "tenants",
  ADMIN_USERS: "users",
  ADMIN_NOTIFICATIONS: "notifications",
  ADMIN_ROLES: "roles",
  ADMIN_SUBSCRIPTION_PLANS: "subscription-plans",
  ADMIN_INVOICES: "invoices",
  ADMIN_REFERENCE_DATA: "reference-data",
  ADMIN_COUNTRIES: "countries",
  ADMIN_CURRENCIES: "currencies",
  ADMIN_VEHICLE_TYPES: "vehicle-types",
  ADMIN_VEHICLE_FUEL_TYPES: "vehicle-fuel-types",
  ADMIN_VEHICLE_MAKES: "vehicle-makes",
  ADMIN_VEHICLE_MODELS: "vehicle-models",
  ADMIN_VEHICLE_GENERATIONS: "vehicle-generations",
  ADMIN_VEHICLE_MODIFICATIONS: "vehicle-modifications",
  ADMIN_VEHICLE_BODY_TYPES: "vehicle-body-types",
  ADMIN_DAMAGE_TYPES: "damage-types",
  ADMIN_VEHICLE_PART_TYPES: "vehicle-part-types",
  ADMIN_VEHICLE_VISUAL_MODELS: "vehicle-visual-models",
  ADMIN_DOMAIN_EVENTS: "domain-events",
  ADMIN_TENANT_REQUESTS: "tenant-requests",
  ADMIN_DAMAGE_DETECTIONS: "damage-detections",
};

// !!!!!!!!!!!!
// NB: mark routes that referenced externally with the next comment: REFERENCED_EXTERNALLY
// such routes can be referenced outside of the app, for instance in emails or by BE

export const ROUTE_PATH = {
  ROOT: `/`,
  DEVELOPMENT: `/${ROUTE_PARTS.DEVELOPMENT}`,

  APP_AREA_FLEET_APP: `/${ROUTE_PARTS.APP_AREA_FLEET_APP}`,
  APP_AREA_FLEET_CUSTOMER: `/${ROUTE_PARTS.APP_AREA_FLEET_CUSTOMER}`,
  APP_AREA_ADMIN: `/${ROUTE_PARTS.APP_AREA_ADMIN}`,

  DEV_STYLEGUIDE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.DEVELOPMENT}/${ROUTE_PARTS.DEV_STYLEGUIDE}`,
  ),

  // #region Error pages

  ERROR: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.ERROR}`),
  ERROR_APP_INIT: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.ERROR}/${ROUTE_PARTS.APP_INIT}`),
  ERROR_NO_API_CONNECTION: (returnPath?: string) =>
    RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.ERROR}/${ROUTE_PARTS.API_CONNECTION}`, {
      returnPath,
    }),
  ERROR_NO_INTERNET: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.ERROR}/${ROUTE_PARTS.NO_INTERNET}`,
  ),
  ERROR_NOT_FOUND: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.ERROR}/${ROUTE_PARTS.NOT_FOUND}`),

  // #endregion

  // #region Auth

  AUTH: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}`),
  // REFERENCED_EXTERNALLY - configured as allowed callback URL in Auth0 (URL where user is redirected after signin/signup)
  AUTH0_CALLBACK: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH0_CALLBACK}`),
  AUTH_SIGN_IN: (queryParams?: LoginQueryParams) =>
    RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_IN}`, queryParams),
  AUTH_SIGN_UP: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_UP}`),
  AUTH_SIGN_OUT: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.LOGOUT}`),
  AUTH_SIGNED_OUT: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.LOGGED_OUT}`),
  AUTH_FORBIDDEN: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.FORBIDDEN}`),
  AUTH_SIGN_UP_MUST_SIGN_OUT_WARNING: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_UP}/signout-warning`,
  ),
  AUTH_SIGN_UP_TENANT_CHECK_INCOMPLETE_REGISTRATION: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_UP}/check`,
  ),
  AUTH_SIGN_UP_TENANT_INFO: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_UP}/${ROUTE_PARTS.TENANT}/${ROUTE_PARTS.INFO}`,
  ),
  AUTH_SIGN_UP_TENANT_EMAIL_VERIFICATION: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_UP}/${ROUTE_PARTS.TENANT}/${ROUTE_PARTS.EMAIL_VERIFICATION}`,
  ),
  AUTH_SIGN_UP_TENANT_EMAIL_PASSWORD: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.SIGN_UP}/${ROUTE_PARTS.TENANT}/${ROUTE_PARTS.PASSWORD}`,
  ),

  AUTH_INVITE_PERSONAL: (inviteId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${ROUTE_PARTS.PERSONAL}/${
        inviteId || ":inviteId"
      }`,
    ),
  AUTH_INVITE_ANONYMOUS: (inviteId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${ROUTE_PARTS.ANONYMOUS}/${
        inviteId || ":inviteId"
      }`,
    ),
  AUTH_INVITE_START: (inviteId?: string | null, queryParams?: InviteStartPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/start`,
      queryParams,
    ),
  AUTH_INVITE_ENTER_EMAIL: (inviteId?: string | null, queryParams?: EmailEnterQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/${ROUTE_PARTS.EMAIL}`,
      queryParams,
    ),
  AUTH_INVITE_ENTER_PASSWORD: (inviteId?: string | null, queryParams?: CreatePasswordQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/${
        ROUTE_PARTS.PASSWORD
      }`,
      queryParams,
    ),
  AUTH_INVITE_ACCEPT: (inviteId?: string | null, queryParams?: AcceptInviteQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/${ROUTE_PARTS.ACCEPT}`,
      queryParams,
    ),
  AUTH_INVITE_USER_PROFILE_CHECK: (
    inviteId?: string | null,
    queryParams?: CheckUserStatusQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/${
        ROUTE_PARTS.USER_PROFILE_CHECK
      }`,
      queryParams,
    ),
  AUTH_INVITE_EXPIRED: (inviteId?: string | null, queryParams?: InviteExpiredPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/${
        ROUTE_PARTS.EXPIRED
      }`,
      queryParams,
    ),
  AUTH_INVITE_NOT_FOUND: (
    inviteId?: string | null,
    queryParams?: InviteNotFoundPageEnterQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/${
        ROUTE_PARTS.NOT_FOUND
      }`,
      queryParams,
    ),
  AUTH_INVITE_ALREADY_ANSWERED: (
    inviteId?: string | null,
    queryParams?: InviteAlreadyAnsweredPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}/${inviteId || ":inviteId"}/already-answered`,
      queryParams,
    ),

  AUTH_COMPLETE_INFO: (queryParams?: CompleteInfoQueryParams) =>
    RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.COMPLETE_INFO}`, queryParams),

  // #endregion

  //#region Tesla Auth

  // REFERENCED_EXTERNALLY - configured as allowed callback URL in Tesla Developer portal (URL where user is redirected after signin)
  TESLA_AUTH_CALLBACK: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.TESLA}/${ROUTE_PARTS.CALLBACK}`,
  ),

  //#endregion

  // #region Base app

  PROFILE: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.PROFILE}`),

  SELECT_TENANT: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.SELECT_COMPANY}`),

  INVITE: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.AUTH}/${ROUTE_PARTS.INVITE}`),

  // #endregion

  // #region Fleet app

  FLEET_PROFILE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PROFILE}`,
  ),

  /** v0.1 */
  DASHBOARD_TASKS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DASHBOARD_TASKS}`,
  ),
  /** v1 */
  DASHBOARD: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DASHBOARD}`,
  ),

  MANAGEMENT_SUBSCRIPTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.SUBSCRIPTIONS}`,
  ),
  MANAGEMENT_SUBSCRIPTION_PLANS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.SUBSCRIPTION_PLANS}`,
  ),

  MANAGEMENT: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}`,
  ),

  MANAGEMENT_TENANT_PROFILE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.COMPANY_PROFILE}`,
  ),
  MANAGEMENT_TENANT_PROFILE_EDIT: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.COMPANY_PROFILE}/${ROUTE_PARTS.EDIT}`,
  ),

  TENANT_SETTINGS_EDIT: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.COMPANY_SETTINGS}`,
  ),

  MANAGEMENT_PAYMENT_PROFILE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.PAYMENT_PROFILE}`,
  ),
  MANAGEMENT_PAYMENT_PROFILE_EDIT: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.PAYMENT_PROFILE}/${ROUTE_PARTS.EDIT}`,
  ),

  MANAGEMENT_IMPORTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.IMPORTS}`,
  ),
  MANAGEMENT_IMPORTS_CREATE: (queryParams?: ImportCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.IMPORTS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  MANAGEMENT_IMPORTS_EDIT: (importId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.IMPORTS}/${
        importId || ":importId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  MANAGEMENT_IMPORTS_VIEW: (importId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.IMPORTS}/${
        importId || ":importId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  TENANT_USERS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.USERS}`,
  ),
  TENANT_USER_EDIT: (userId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.USERS}/${
        userId || ":userId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  TENANT_USER_ROLES_EDIT: (userId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.USERS}/${
        userId || ":userId"
      }/roles/${ROUTE_PARTS.EDIT}`,
    ),
  TENANT_USER_VIEW: (userId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.USERS}/${
        userId || ":userId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  MANAGEMENT_INVITES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INVITES}`,
  ),
  MANAGEMENT_INVITES_CREATE_PERSONAL: (queryParams?: CreateNewPersonalInvitePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INVITES}/${ROUTE_PARTS.CREATE_NEW}/${ROUTE_PARTS.PERSONAL}`,
      queryParams,
    ),
  MANAGEMENT_INVITES_CREATE_ANONYMOUS: (queryParams?: CreateNewAnonymousInvitePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INVITES}/${ROUTE_PARTS.CREATE_NEW}/${ROUTE_PARTS.ANONYMOUS}`,
      queryParams,
    ),

  MANAGEMENT_INVITE_VIEW: (inviteId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INVITES}/${
        inviteId || ":inviteId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  MANAGEMENT_INTEGRATION: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}`,
  ),

  MANAGEMENT_INTEGRATION_API_CLIENTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.API_CLIENTS}`,
  ),
  MANAGEMENT_INTEGRATION_API_CLIENT_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.API_CLIENTS}/${ROUTE_PARTS.CREATE}`,
  ),
  MANAGEMENT_INTEGRATION_API_CLIENT_EDIT: (integrationApiClientId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${
        ROUTE_PARTS.API_CLIENTS
      }/${integrationApiClientId || ":integrationApiClientId"}/${ROUTE_PARTS.EDIT}`,
    ),
  MANAGEMENT_INTEGRATION_API_CLIENT_VIEW: (integrationApiClientId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${
        ROUTE_PARTS.API_CLIENTS
      }/${integrationApiClientId || ":integrationApiClientId"}/${ROUTE_PARTS.VIEW}`,
    ),

  MANAGEMENT_INTEGRATION_WEBHOOKS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.WEBHOOKS}`,
  ),
  MANAGEMENT_INTEGRATION_WEBHOOK_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.WEBHOOKS}/${ROUTE_PARTS.CREATE}`,
  ),
  MANAGEMENT_INTEGRATION_WEBHOOK_EDIT: (webhookId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${
        ROUTE_PARTS.WEBHOOKS
      }/${webhookId || ":webhookId"}/${ROUTE_PARTS.EDIT}`,
    ),
  MANAGEMENT_INTEGRATION_WEBHOOK_VIEW: (webhookId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${
        ROUTE_PARTS.WEBHOOKS
      }/${webhookId || ":webhookId"}/${ROUTE_PARTS.VIEW}`,
    ),

  MANAGEMENT_INTEGRATION_TESLA_CONNECTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.TESLA_CONNECTIONS}`,
  ),
  MANAGEMENT_INTEGRATION_TESLA_CONNECTION_VIEW: (teslaConnectionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INTEGRATION}/${
        ROUTE_PARTS.TESLA_CONNECTIONS
      }/${teslaConnectionId || ":teslaConnectionId"}/${ROUTE_PARTS.VIEW}`,
    ),

  TENANT_TO_TENANT_INDEX: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}`,
  ),

  TENANT_CONNECTIONS_INDEX: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_CONNECTIONS}`,
  ),

  TENANT_CONNECTION_REQUESTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_CONNECTIONS}/${ROUTE_PARTS.REQUESTS}`,
  ),
  TENANT_CONNECTION_REQUEST_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_CONNECTIONS}/${ROUTE_PARTS.REQUESTS}/${ROUTE_PARTS.CREATE}`,
  ),
  TENANT_CONNECTION_REQUEST_VIEW: (tenantConnectionRequestId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${
        ROUTE_PARTS.COMPANY_CONNECTIONS
      }/${ROUTE_PARTS.REQUESTS}/${tenantConnectionRequestId || ":tenantConnectionRequestId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  TENANT_CONNECTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_CONNECTIONS}/${ROUTE_PARTS.CONNECTIONS}`,
  ),
  TENANT_CONNECTION_VIEW: (tenantConnectionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${
        ROUTE_PARTS.COMPANY_CONNECTIONS
      }/${ROUTE_PARTS.CONNECTIONS}/${tenantConnectionId || ":tenantConnectionId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  TENANT_REQUESTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_REQUESTS}`,
  ),
  TENANT_REQUEST_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_REQUESTS}/${ROUTE_PARTS.CREATE}`,
  ),
  TENANT_REQUEST_EDIT: (tenantRequestId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${
        ROUTE_PARTS.COMPANY_REQUESTS
      }/${tenantRequestId || ":tenantRequestId"}/${ROUTE_PARTS.EDIT}`,
    ),
  TENANT_REQUEST_VIEW: (tenantRequestId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${
        ROUTE_PARTS.COMPANY_REQUESTS
      }/${tenantRequestId || ":tenantRequestId"}/${ROUTE_PARTS.VIEW}`,
    ),

  DATA_GRANTS: (queryParams?: DataGrantsPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.DATA_GRANTS}`,
      queryParams,
    ),
  DATA_GRANT_CREATE: (queryParams?: DataGrantCreateUpdatePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.DATA_GRANTS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  DATA_GRANT_EDIT: (dataGrantId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${
        ROUTE_PARTS.DATA_GRANTS
      }/${dataGrantId || ":dataGrantId"}/${ROUTE_PARTS.EDIT}`,
    ),
  DATA_GRANT_VIEW: (dataGrantId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${
        ROUTE_PARTS.DATA_GRANTS
      }/${dataGrantId || ":dataGrantId"}/${ROUTE_PARTS.VIEW}`,
    ),

  TENANT_ROLES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.ROLES}`,
  ),
  TENANT_ROLE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.ROLES}/${ROUTE_PARTS.CREATE}`,
  ),
  TENANT_ROLE_EDIT: (roleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.ROLES}/${
        roleId || ":roleId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  TENANT_ROLE_VIEW: (roleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.ROLES}/${
        roleId || ":roleId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  INVOICES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INVOICES}`,
  ),
  INVOICE_VIEW: (invoiceId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.MANAGEMENT}/${ROUTE_PARTS.INVOICES}/${
        invoiceId || ":invoiceId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  TENANT_VIEW: (tenantId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMPANIES}/${tenantId || ":tenantId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  USER_VIEW: (userId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.USERS}/${userId || ":userId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  VEHICLES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}`,
  ),
  VEHICLE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${ROUTE_PARTS.CREATE}`,
  ),
  VEHICLE_CREATE_MANY_SIMILAR: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${ROUTE_PARTS.CREATE_MANY_SIMILAR}`,
  ),
  VEHICLE_VIEW: (vehicleId?: string | null, queryParams?: VehicleViewPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${vehicleId || ":vehicleId"}/${
        ROUTE_PARTS.VIEW
      }`,
      queryParams,
    ),
  VEHICLE_EDIT: (vehicleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${vehicleId || ":vehicleId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),
  VEHICLE_HISTORY: (vehicleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${vehicleId || ":vehicleId"}/${
        ROUTE_PARTS.HISTORY
      }`,
    ),
  VEHICLE_ACTUAL_STATE: (vehicleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${vehicleId || ":vehicleId"}/${
        ROUTE_PARTS.ACTUAL_STATE
      }`,
    ),

  VEHICLES_DAMAGES: (queryParams?: VehicleDamagesPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLE_DAMAGES}`,
      queryParams,
    ),
  VEHICLE_DAMAGES: (
    routeParams?: VehicleDamagesPageRouteParams,
    queryParams?: VehicleDamagesPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${
        routeParams?.vehicleId || ":vehicleId"
      }/${ROUTE_PARTS.VEHICLE_DAMAGES}`,
      queryParams,
    ),
  VEHICLE_DAMAGE_VIEW: (
    routeParams?: VehicleDamageViewPageRouteParams,
    queryParams?: VehicleDamageViewPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLES}/${
        routeParams?.vehicleId || ":vehicleId"
      }/${ROUTE_PARTS.VEHICLE_DAMAGES}/${routeParams?.vehicleDamageId || ":vehicleDamageId"}/${
        ROUTE_PARTS.VIEW
      }`,
      queryParams,
    ),
  VEHICLE_DAMAGE_VIEW_ALT: (
    routeParams?: VehicleDamageViewPageAltRouteParams,
    queryParams?: VehicleDamageViewPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.VEHICLE_DAMAGES}/${
        routeParams?.vehicleDamageId || ":vehicleDamageId"
      }/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),

  REFERENCE_DATA_INDEX: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REFERENCE_DATA}`,
  ),

  VEHICLE_VISUAL_MODELS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REFERENCE_DATA}/${ROUTE_PARTS.VEHICLE_VISUAL_MODELS}`,
  ),
  VEHICLE_VISUAL_MODEL_VIEW: (
    routeParams?: VehicleVisualModelViewPageRouteParams,
    queryParams?: VehicleVisualModelViewPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REFERENCE_DATA}/${
        ROUTE_PARTS.VEHICLE_VISUAL_MODELS
      }/${routeParams?.vehicleVisualModelId || ":vehicleVisualModelId"}/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),

  CONTRACTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CONTRACTS}`,
  ),
  CONTRACT_CREATE: (queryParams?: ContractCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CONTRACTS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  CONTRACT_VIEW: (contractId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CONTRACTS}/${contractId || ":contractId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  CONTRACT_EDIT: (contractId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CONTRACTS}/${contractId || ":contractId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),

  OTHER: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}`),

  CUSTOMERS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CUSTOMERS}`,
  ),
  CUSTOMER_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CUSTOMERS}/${ROUTE_PARTS.CREATE}`,
  ),
  CUSTOMER_VIEW: (customerId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CUSTOMERS}/${customerId || ":customerId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  CUSTOMER_EDIT: (customerId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.CUSTOMERS}/${customerId || ":customerId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),

  SUPPLIERS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SUPPLIERS}`,
  ),
  SUPPLIER_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SUPPLIERS}/${ROUTE_PARTS.CREATE}`,
  ),
  SUPPLIER_VIEW: (supplierId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SUPPLIERS}/${supplierId || ":supplierId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  SUPPLIER_EDIT: (supplierId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SUPPLIERS}/${supplierId || ":supplierId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),

  ACCESSORIES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.ACCESSORIES}`,
  ),
  ACCESSORY_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.ACCESSORIES}/${ROUTE_PARTS.CREATE}`,
  ),
  ACCESSORY_VIEW: (accessoryId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.ACCESSORIES}/${
        accessoryId || ":accessoryId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  ACCESSORY_EDIT: (accessoryId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.ACCESSORIES}/${
        accessoryId || ":accessoryId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  OPERATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}`,
  ),

  VISUAL_INSPECTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.VISUAL_INSPECTIONS}`,
  ),
  VISUAL_INSPECTION_CREATE: (queryParams?: DamageDetectionCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.VISUAL_INSPECTIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  VISUAL_INSPECTION_VIEW: (visualInspectionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.VISUAL_INSPECTIONS
      }/${visualInspectionId || ":visualInspectionId"}/${ROUTE_PARTS.VIEW}`,
    ),
  VISUAL_INSPECTION_EDIT: (visualInspectionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.VISUAL_INSPECTIONS
      }/${visualInspectionId || ":visualInspectionId"}/${ROUTE_PARTS.EDIT}`,
    ),

  DAMAGE_DETECTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_DETECTIONS}`,
  ),
  DAMAGE_DETECTION_CREATE: (queryParams?: DamageDetectionCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_DETECTIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  DAMAGE_DETECTION_VIEW: (damageDetectionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.DAMAGE_DETECTIONS
      }/${damageDetectionId || ":damageDetectionId"}/${ROUTE_PARTS.VIEW}`,
    ),
  DAMAGE_DETECTION_EDIT: (damageDetectionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.DAMAGE_DETECTIONS
      }/${damageDetectionId || ":damageDetectionId"}/${ROUTE_PARTS.EDIT}`,
    ),

  DAMAGE_DETECTION_AGGREGATES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_DETECTIONS}/${ROUTE_PARTS.AGGREGATES}`,
  ),
  DAMAGE_DETECTION_AGGREGATE_VIEW: (
    aggregateId?: string | null,
    queryParams?: DamageDetectionAggregateViewPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.DAMAGE_DETECTIONS
      }/${ROUTE_PARTS.AGGREGATES}/${aggregateId || ":aggregateId"}/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),

  DAMAGE_COST_EVALUATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_COST_EVALUATIONS}`,
  ),
  DAMAGE_COST_EVALUATION_CREATE: (queryParams?: DamageCostEvaluationCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_COST_EVALUATIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  DAMAGE_COST_EVALUATION_VIEW: (damageCostEvaluationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.DAMAGE_COST_EVALUATIONS
      }/${damageCostEvaluationId || ":damageCostEvaluationId"}/${ROUTE_PARTS.VIEW}`,
    ),
  DAMAGE_COST_EVALUATION_EDIT: (damageCostEvaluationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.DAMAGE_COST_EVALUATIONS
      }/${damageCostEvaluationId || ":damageCostEvaluationId"}/${ROUTE_PARTS.EDIT}`,
    ),

  WHEEL_OPERATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.WHEEL_OPERATIONS}`,
  ),
  WHEEL_OPERATION_CREATE: (queryParams?: WheelOperationCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.WHEEL_OPERATIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  WHEEL_OPERATION_VIEW: (wheelOperationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.WHEEL_OPERATIONS
      }/${wheelOperationId || ":wheelOperationId"}/${ROUTE_PARTS.VIEW}`,
    ),
  WHEEL_OPERATION_EDIT: (wheelOperationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.WHEEL_OPERATIONS
      }/${wheelOperationId || ":wheelOperationId"}/${ROUTE_PARTS.EDIT}`,
    ),

  DAMAGE_COST_EVALUATION_AGGREGATES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_COST_EVALUATIONS}/${ROUTE_PARTS.AGGREGATES}`,
  ),
  DAMAGE_COST_EVALUATION_AGGREGATE_VIEW: (
    aggregateId?: string | null,
    queryParams?: DamageCostEvaluationAggregateViewPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.DAMAGE_COST_EVALUATIONS
      }/${ROUTE_PARTS.AGGREGATES}/${aggregateId || ":aggregateId"}/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),

  WASHES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.WASHES}`,
  ),
  WASH_CREATE: (queryParams?: WashCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.WASHES}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  WASH_VIEW: (washId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.WASHES
      }/${washId || ":washId"}/${ROUTE_PARTS.VIEW}`,
    ),
  WASH_EDIT: (washId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.WASHES
      }/${washId || ":washId"}/${ROUTE_PARTS.EDIT}`,
    ),

  MAINTENANCES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.MAINTENANCES}`,
  ),
  MAINTENANCE_CREATE: (queryParams?: MaintenanceCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.MAINTENANCES}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  MAINTENANCE_VIEW: (maintenanceId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.MAINTENANCES
      }/${maintenanceId || ":maintenanceId"}/${ROUTE_PARTS.VIEW}`,
    ),
  MAINTENANCE_EDIT: (maintenanceId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.MAINTENANCES
      }/${maintenanceId || ":maintenanceId"}/${ROUTE_PARTS.EDIT}`,
    ),

  DEPARTMENTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DEPARTMENTS}`,
  ),
  DEPARTMENT_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DEPARTMENTS}/${ROUTE_PARTS.CREATE}`,
  ),
  DEPARTMENT_VIEW: (departmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DEPARTMENTS}/${
        departmentId || ":departmentId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  DEPARTMENT_EDIT: (departmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DEPARTMENTS}/${
        departmentId || ":departmentId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  LOCATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.LOCATIONS}`,
  ),
  LOCATION_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.LOCATIONS}/${ROUTE_PARTS.CREATE}`,
  ),
  LOCATION_VIEW: (departmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.LOCATIONS}/${
        departmentId || ":locationId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  LOCATION_EDIT: (departmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.LOCATIONS}/${
        departmentId || ":locationId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  SPOTS: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SPOTS}`),
  SPOT_CREATE: (queryParams?: SpotCreateUpdatePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SPOTS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  SPOT_VIEW: (departmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SPOTS}/${departmentId || ":spotId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  SPOT_EDIT: (departmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.SPOTS}/${departmentId || ":spotId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),

  ACCESSORY_CHECKS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.ACCESSORY_CHECKS}`,
  ),
  ACCESSORY_CHECK_CREATE: (queryParams?: AccessoryCheckCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.ACCESSORY_CHECKS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  ACCESSORY_CHECK_VIEW: (accessoryCheckId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.ACCESSORY_CHECKS
      }/${accessoryCheckId || ":accessoryCheckId"}/${ROUTE_PARTS.VIEW}`,
    ),
  ACCESSORY_CHECK_EDIT: (accessoryCheckId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.ACCESSORY_CHECKS
      }/${accessoryCheckId || ":accessoryCheckId"}/${ROUTE_PARTS.EDIT}`,
    ),

  REPAIR_OPERATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.REPAIR_OPERATIONS}`,
  ),
  REPAIR_OPERATION_CREATE: (queryParams?: RepairOperationCreateUpdatePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.REPAIR_OPERATIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  REPAIR_OPERATION_VIEW: (
    repairOperationId?: string | null,
    queryParams?: RepairOperationViewPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.REPAIR_OPERATIONS
      }/${repairOperationId || ":repairOperationId"}/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),
  REPAIR_OPERATION_EDIT: (repairOperationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OPERATIONS}/${
        ROUTE_PARTS.REPAIR_OPERATIONS
      }/${repairOperationId || ":repairOperationId"}/${ROUTE_PARTS.EDIT}`,
    ),

  DOCUMENTS: (queryParams?: DocumentsPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DOCUMENTS}`,
      queryParams,
    ),
  DOCUMENTS_DAMAGE_DETECTIONS: (isRouteDefinition = false) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DOCUMENTS}/${
        ROUTE_PARTS.DAMAGE_DETECTIONS
      }${
        isRouteDefinition
          ? ""
          : `?${UrlHelper.toURLSearchParams(
              {
                sourceType: EntitySourceType.DamageDetection,
              },
              true,
            )}`
      }`,
    ),
  DOCUMENTS_DAMAGE_COST_EVALUATIONS: (isRouteDefinition = false) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DOCUMENTS}/${
        ROUTE_PARTS.DAMAGE_COST_EVALUATIONS
      }${
        isRouteDefinition
          ? ""
          : `?${UrlHelper.toURLSearchParams(
              {
                sourceType: EntitySourceType.DamageCostEvaluation,
              },
              true,
            )}`
      }`,
    ),
  DOCUMENTS_ACCESSORY_CHECKS: (isRouteDefinition = false) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DOCUMENTS}/${ROUTE_PARTS.ACCESSORY_CHECKS}${
        isRouteDefinition
          ? ""
          : `?${UrlHelper.toURLSearchParams(
              {
                sourceType: EntitySourceType.AccessoryCheck,
              },
              true,
            )}`
      }`,
    ),
  DOCUMENT_VIEW: (documentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.DOCUMENTS}/${documentId || ":documentId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  CHAT_VIEW: (chatId?: string | null, queryParams?: ChatViewQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMMUNICATION}/${ROUTE_PARTS.CHATS}/${
        chatId || ":chatId"
      }/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),
  CHAT_VIEW_BY_SCOPE: (chatScope?: GeneralScopeDto | null, queryParams?: ChatViewQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.COMMUNICATION}/${ROUTE_PARTS.CHAT}/by-scope/${ROUTE_PARTS.VIEW}`,
      chatScope && queryParams
        ? { ...queryParams, chatScopeStr: GeneralScopeHelper.serializeScopeToString(chatScope) }
        : undefined,
    ),

  NEGOTIATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.NEGOTIATIONS}`,
  ), //
  NEGOTIATION_VIEW: (negotiationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.NEGOTIATIONS}/${
        negotiationId || ":negotiationId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  NEGOTIATION_EDIT: (negotiationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.NEGOTIATIONS}/${
        negotiationId || ":negotiationId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  FLOWS: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.FLOWS}`),

  ASSESSMENT_FLOWS: (queryParams?: AssessmentFlowsPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.FLOWS}/${ROUTE_PARTS.ASSESSMENT}`,
      queryParams,
    ),
  // REFERENCED_EXTERNALLY
  ASSESSMENT_FLOW_VIEW: (
    assessmentFlowId?: string | null,
    queryParams?: AssessmentFlowViewPageQueryParams,
  ) =>
    RoutePathBuilder.new()
      .withRoute(
        `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.FLOWS}/${ROUTE_PARTS.ASSESSMENT}/${
          assessmentFlowId || ":assessmentFlowId"
        }/${ROUTE_PARTS.VIEW}`,
      )
      .withQueryParams(queryParams)
      .build(),
  // REFERENCED_EXTERNALLY
  ASSESSMENT_FLOW_CHAT_VIEW: (
    assessmentFlowId?: string | null,
    queryParams?: AssessmentFlowChatPageQueryParams,
  ) =>
    RoutePathBuilder.new()
      .withArea(AppArea.Fleet)
      .withRoute(
        RoutePathBuilder.buildSimple(
          `/${ROUTE_PARTS.FLOWS}/${ROUTE_PARTS.ASSESSMENT}/${
            assessmentFlowId || ":assessmentFlowId"
          }/${ROUTE_PARTS.VIEW}/${ROUTE_PARTS.CHAT}`,
        ),
      )
      .withQueryParams(queryParams)
      .build(),

  REPAIR: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}`),

  REPAIR_MATERIALS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}`,
  ),
  REPAIR_MATERIAL_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}/${ROUTE_PARTS.CREATE}`,
  ),
  REPAIR_MATERIAL_EDIT: (repairMaterialId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}/${
        repairMaterialId || ":repairMaterialId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  REPAIR_MATERIAL_VIEW: (repairMaterialId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}/${
        repairMaterialId || ":repairMaterialId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  REPAIR_WORK_LIST: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}`,
  ),
  REPAIR_WORK_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}/${ROUTE_PARTS.CREATE}`,
  ),
  REPAIR_WORK_EDIT: (repairWorkId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}/${
        repairWorkId || ":repairWorkId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  REPAIR_WORK_VIEW: (repairWorkId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}/${
        repairWorkId || ":repairWorkId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  REPAIR_SPECS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}`,
  ),
  REPAIR_SPEC_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}/${ROUTE_PARTS.CREATE}`,
  ),
  REPAIR_SPEC_EDIT: (repairSpecId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}/${
        repairSpecId || ":repairSpecId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  REPAIR_SPEC_VIEW: (repairSpecId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}/${
        repairSpecId || ":repairSpecId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  REPAIR_SPARE_PARTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}`,
  ),
  REPAIR_SPARE_PART_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}/${ROUTE_PARTS.CREATE}`,
  ),
  REPAIR_SPARE_PART_EDIT: (repairSparePartId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}/${
        repairSparePartId || ":repairSparePartId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  REPAIR_SPARE_PART_VIEW: (repairSparePartId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}/${
        repairSparePartId || ":repairSparePartId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  PRODUCTS_INDEX: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}`,
  ),

  PRODUCT_SALES_LOCATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.SALES_LOCATIONS}`,
  ),
  PRODUCT_SALES_LOCATION_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.SALES_LOCATIONS}/${ROUTE_PARTS.CREATE}`,
  ),
  PRODUCT_SALES_LOCATION_VIEW: (productLocationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.SALES_LOCATIONS}/${
        productLocationId || ":productLocationId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  PRODUCT_SALES_LOCATION_EDIT: (productLocationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.SALES_LOCATIONS}/${
        productLocationId || ":productLocationId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  ASSETS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSETS}`,
  ),
  ASSET_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSETS}/${ROUTE_PARTS.CREATE}`,
  ),
  ASSET_VIEW: (assetId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSETS}/${
        assetId || ":assetId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  ASSET_EDIT: (assetId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSETS}/${
        assetId || ":assetId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  POOLS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}`,
  ),
  POOL_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${ROUTE_PARTS.CREATE}`,
  ),
  POOL_VIEW: (poolId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${
        poolId || ":poolId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  POOL_EDIT: (poolId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${
        poolId || ":poolId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  POOL_ITEMS: (poolId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${
        poolId || ":poolId"
      }/${ROUTE_PARTS.ITEMS}`,
    ),
  POOL_ITEM_CREATE: (poolId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${
        poolId || ":poolId"
      }/${ROUTE_PARTS.ITEMS}/${ROUTE_PARTS.CREATE}`,
    ),
  POOL_ITEM_VIEW: (poolId?: string | null, poolItemId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${
        poolId || ":poolId"
      }/${ROUTE_PARTS.ITEMS}/${poolItemId || ":poolItemId"}/${ROUTE_PARTS.VIEW}`,
    ),
  POOL_ITEM_EDIT: (poolId?: string | null, poolItemId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.POOLS}/${
        poolId || ":poolId"
      }/${ROUTE_PARTS.ITEMS}/${poolItemId || ":poolItemId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ASSET_SUBSCRIPTION_PLANS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSET_SUBSCRIPTION_PLANS}`,
  ),
  ASSET_SUBSCRIPTION_PLAN_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSET_SUBSCRIPTION_PLANS}/${ROUTE_PARTS.CREATE}`,
  ),
  ASSET_SUBSCRIPTION_PLAN_EDIT: (assetSubscriptionPlanId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${
        ROUTE_PARTS.ASSET_SUBSCRIPTION_PLANS
      }/${assetSubscriptionPlanId || ":assetSubscriptionPlanId"}/${ROUTE_PARTS.EDIT}`,
    ),
  ASSET_SUBSCRIPTION_PLAN_VIEW: (assetSubscriptionPlanId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${
        ROUTE_PARTS.ASSET_SUBSCRIPTION_PLANS
      }/${assetSubscriptionPlanId || ":assetSubscriptionPlanId"}/${ROUTE_PARTS.VIEW}`,
    ),

  ASSET_SUBSCRIPTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSET_SUBSCRIPTIONS}`,
  ),
  ASSET_SUBSCRIPTION_CREATE: (queryParams?: AssetSubscriptionUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${ROUTE_PARTS.ASSET_SUBSCRIPTIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  ASSET_SUBSCRIPTION_EDIT: (assetSubscriptionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${
        ROUTE_PARTS.ASSET_SUBSCRIPTIONS
      }/${assetSubscriptionId || ":assetSubscriptionId"}/${ROUTE_PARTS.EDIT}`,
    ),
  ASSET_SUBSCRIPTION_VIEW: (assetSubscriptionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PRODUCTS}/${
        ROUTE_PARTS.ASSET_SUBSCRIPTIONS
      }/${assetSubscriptionId || ":assetSubscriptionId"}/${ROUTE_PARTS.VIEW}`,
    ),

  GENERAL_EVENT_LOGS: (entityId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/general-event-log/entity/${entityId || ":entityId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  GENERAL_EVENT_LOG_VIEW: (eventLogId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/general-event-log/${eventLogId || ":eventLogId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  GENERAL_HISTORY_LIST: (queryParams?: GeneralHistoryListPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.GENERAL_HISTORY}`,
      queryParams,
    ),
  GENERAL_HISTORY_VIEW: (generalHistoryId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.GENERAL_HISTORY}/${
        generalHistoryId || ":generalHistoryId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  GENERAL_STATUS_HISTORY_LIST: (queryParams?: GeneralStatusHistoryListPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.GENERAL_STATUS_HISTORY}`,
      queryParams,
    ),
  GENERAL_STATUS_HISTORY_VIEW: (generalStatusHistoryId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.GENERAL_STATUS_HISTORY}/${
        generalStatusHistoryId || ":generalStatusHistoryId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  PARTIES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PARTIES}`,
  ),
  PARTY_VIEW: (partyId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PARTIES}/${partyId || ":partyId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  CUSTOM_TAGS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.TAGS}/${ROUTE_PARTS.CUSTOM}`,
  ),
  CUSTOM_TAG_CREATE: (queryParams?: CustomTagCreateUpdatePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.TAGS}/${ROUTE_PARTS.CUSTOM}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  CUSTOM_TAG_EDIT: (customTagId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.TAGS}/${
        ROUTE_PARTS.CUSTOM
      }/${customTagId || ":customTagId"}/${ROUTE_PARTS.EDIT}`,
    ),
  CUSTOM_TAG_VIEW: (customTagId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.OTHER}/${ROUTE_PARTS.TAGS}/${
        ROUTE_PARTS.CUSTOM
      }/${customTagId || ":customTagId"}/${ROUTE_PARTS.VIEW}`,
    ),

  ASSIGNMENTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.ASSIGNMENTS}`,
  ),
  ASSIGNMENT_CREATE: (queryParams?: AssignmentCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.ASSIGNMENTS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  ASSIGNMENT_VIEW: (assignmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.ASSIGNMENTS}/${assignmentId || ":assignmentId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  ASSIGNMENT_EDIT: (assignmentId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.ASSIGNMENTS}/${assignmentId || ":assignmentId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),

  // #endregion

  // #region Admin

  ADMIN_DASHBOARD: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_DASHBOARD}`,
  ),

  ADMIN_COMPANY_DASHBOARD: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_COMPANY_DASHBOARD}`,
  ),

  ADMIN_TENANTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_TENANTS}`,
  ),
  ADMIN_TENANT_VIEW: (tenantId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_TENANTS}/${tenantId || ":tenantId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  ADMIN_ROLES: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ROLES}`),
  ADMIN_ROLE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ROLES}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_ROLE_EDIT: (roleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ROLES}/${roleId || ":roleId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),
  ADMIN_ROLE_VIEW: (roleId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ROLES}/${roleId || ":roleId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  ADMIN_USERS: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.USERS}`),
  ADMIN_USER_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.USERS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_USER_EDIT: (userId?: string | null, queryParams?: AdminUserEditPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.USERS}/${userId || ":userId"}/${
        ROUTE_PARTS.EDIT
      }`,
      queryParams,
    ),
  ADMIN_USER_VIEW: (userId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.USERS}/${userId || ":userId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  ADMIN_USER_ROLES_EDIT: (
    userId?: string | null,
    queryParams?: AdminUserRolesEditPageQueryParams,
  ) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.USERS}/${userId || ":userId"}/roles/${
        ROUTE_PARTS.EDIT
      }`,
      queryParams,
    ),

  ADMIN_INVITES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INVITES}`,
  ),
  ADMIN_INVITES_CREATE_PERSONAL: (queryParams?: CreateNewPersonalInvitePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INVITES}/${ROUTE_PARTS.CREATE_NEW}/${ROUTE_PARTS.PERSONAL}`,
      queryParams,
    ),
  ADMIN_INVITES_CREATE_ANONYMOUS: (queryParams?: CreateNewAnonymousInvitePageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INVITES}/${ROUTE_PARTS.CREATE_NEW}/${ROUTE_PARTS.ANONYMOUS}`,
      queryParams,
    ),
  ADMIN_INVITE_VIEW: (inviteId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INVITES}/${inviteId || ":inviteId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  ADMIN_CUSTOM_SESSIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/custom-sessions`,
  ),
  ADMIN_CUSTOM_SESSION_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/custom-sessions/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_CUSTOM_SESSION_EDIT: (customSessionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/custom-sessions/${customSessionId || ":customSessionId"}/${
        ROUTE_PARTS.EDIT
      }`,
    ),
  ADMIN_CUSTOM_SESSION_VIEW: (customSessionId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/custom-sessions/${customSessionId || ":customSessionId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  ADMIN_NOTIFICATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_NOTIFICATIONS}`,
  ),
  ADMIN_NOTIFICATION_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_NOTIFICATIONS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_NOTIFICATIONS_VIEW: (notificationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_NOTIFICATIONS}/${
        notificationId || ":notificationId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_DOMAIN_EVENTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_DOMAIN_EVENTS}`,
  ),

  ADMIN_SUBSCRIPTION_PLANS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.SUBSCRIPTION_PLANS}`,
  ),
  ADMIN_SUBSCRIPTION_PLANS_EDIT: (subscriptionPlanId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_INVOICES}/${
        subscriptionPlanId || ":subscriptionPlanId"
      }/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_INVOICES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_INVOICES}`,
  ),
  ADMIN_INVOICE_VIEW: (invoiceId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_INVOICES}/${invoiceId || ":invoiceId"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),

  ADMIN_COUNTRIES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_COUNTRIES}`,
  ),
  ADMIN_CURRENCIES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_CURRENCIES}`,
  ),

  ADMIN_VEHICLE_TYPES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_TYPES}`,
  ),

  ADMIN_VEHICLE_FUEL_TYPES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_FUEL_TYPES}`,
  ),
  ADMIN_VEHICLE_FUEL_TYPE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_FUEL_TYPES}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_FUEL_TYPES_EDIT: (fuelTypeId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_FUEL_TYPES
      }/${fuelTypeId || ":fuelTypeId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_VEHICLE_MAKES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_MAKES}`,
  ),
  ADMIN_VEHICLE_MAKE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_MAKES}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_MAKES_EDIT: (makeId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_MAKES
      }/${makeId || ":makeId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_VEHICLE_MODELS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_MODELS}`,
  ),
  ADMIN_VEHICLE_MODEL_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_MODELS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_MODELS_EDIT: (modelId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_MODELS
      }/${modelId || ":modelId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_VEHICLE_GENERATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_GENERATIONS}`,
  ),
  ADMIN_VEHICLE_GENERATION_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_GENERATIONS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_GENERATION_EDIT: (generationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_GENERATIONS
      }/${generationId || ":generationId"}/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_VEHICLE_GENERATION_VIEW: (modificationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_GENERATIONS
      }/${modificationId || ":modificationId"}/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_VEHICLE_MODIFICATIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_MODIFICATIONS}`,
  ),
  ADMIN_VEHICLE_MODIFICATION_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_MODIFICATIONS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_MODIFICATION_EDIT: (modificationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_MODIFICATIONS
      }/${modificationId || ":modificationId"}/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_VEHICLE_MODIFICATION_VIEW: (modificationId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_MODIFICATIONS
      }/${modificationId || ":modificationId"}/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_VEHICLE_BODY_TYPES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_BODY_TYPES}`,
  ),
  ADMIN_VEHICLE_BODY_TYPE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_BODY_TYPES}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_BODY_TYPES_EDIT: (bodyTypeId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_BODY_TYPES
      }/${ROUTE_PARTS.EDIT}/${bodyTypeId || ":bodyTypeId"}`,
    ),

  ADMIN_DAMAGE_TYPES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_DAMAGE_TYPES}`,
  ),
  ADMIN_DAMAGE_TYPE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_DAMAGE_TYPES}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_DAMAGE_TYPES_EDIT: (damageTypeId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_DAMAGE_TYPES
      }/${damageTypeId || ":damageTypeId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_VEHICLE_PART_TYPES: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_PART_TYPES}`,
  ),
  ADMIN_VEHICLE_PART_TYPE_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_PART_TYPES}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_PART_TYPES_EDIT: (partTypeId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_PART_TYPES
      }/${partTypeId || ":partTypeId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_VEHICLE_VISUAL_MODELS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_VISUAL_MODELS}`,
  ),
  ADMIN_VEHICLE_VISUAL_MODEL_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${ROUTE_PARTS.ADMIN_VEHICLE_VISUAL_MODELS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_VEHICLE_VISUAL_MODELS_EDIT: (visualModelId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.ADMIN_REFERENCE_DATA}/${
        ROUTE_PARTS.ADMIN_VEHICLE_VISUAL_MODELS
      }/${visualModelId || ":visualModelId"}/${ROUTE_PARTS.EDIT}`,
    ),

  ADMIN_REPAIR_MATERIALS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}`,
  ),
  ADMIN_REPAIR_MATERIAL_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_REPAIR_MATERIAL_EDIT: (repairMaterialId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}/${
        repairMaterialId || ":repairMaterialId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_REPAIR_MATERIAL_VIEW: (repairMaterialId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.MATERIALS}/${
        repairMaterialId || ":repairMaterialId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_REPAIR_WORK_LIST: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}`,
  ),
  ADMIN_REPAIR_WORK_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_REPAIR_WORK_EDIT: (repairWorkId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}/${
        repairWorkId || ":repairWorkId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_REPAIR_WORK_VIEW: (repairWorkId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.WORK}/${
        repairWorkId || ":repairWorkId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_REPAIR_SPARE_PART_LIST: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}`,
  ),
  ADMIN_REPAIR_SPARE_PART_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_REPAIR_SPARE_PART_EDIT: (repairSparePartId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}/${
        repairSparePartId || ":repairSparePartId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_REPAIR_SPARE_PART_VIEW: (repairSparePartId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPARE_PARTS}/${
        repairSparePartId || ":repairSparePartId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_REPAIR_SPECS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}`,
  ),
  ADMIN_REPAIR_SPEC_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_REPAIR_SPEC_EDIT: (repairSpecId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}/${
        repairSpecId || ":repairSpecId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_REPAIR_SPEC_VIEW: (repairSpecId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.REPAIR}/${ROUTE_PARTS.SPECS}/${
        repairSpecId || ":repairSpecId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_TENANT_REQUESTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.COMPANY_TO_COMPANY}/${ROUTE_PARTS.COMPANY_REQUESTS}`,
  ),

  ADMIN_DAMAGE_DETECTIONS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_DETECTIONS}`,
  ),

  ADMIN_DAMAGE_DETECTION_CREATE: (queryParams?: DamageDetectionCreateUpdateQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.DAMAGE_DETECTIONS}/${ROUTE_PARTS.CREATE}`,
      queryParams,
    ),
  ADMIN_DAMAGE_DETECTION_VIEW: (damageDetectionId?: string | null, tenantId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.OPERATIONS}/${ROUTE_PARTS.TENANT}/${tenantId || ":tenantId"}/${
        ROUTE_PARTS.DAMAGE_DETECTIONS
      }/${damageDetectionId || ":damageDetectionId"}/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_FILES: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.FILES}`),
  ADMIN_FILE_VIEW: (fileId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.FILES}/${
        fileId || ":fileId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_INTEGRATION: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INTEGRATION}`,
  ),

  ADMIN_INTEGRATION_API_CLIENTS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.API_CLIENTS}`,
  ),
  ADMIN_INTEGRATION_API_CLIENT_CREATE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.API_CLIENTS}/${ROUTE_PARTS.CREATE}`,
  ),
  ADMIN_INTEGRATION_API_CLIENT_EDIT: (integrationApiClientId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.API_CLIENTS}/${
        integrationApiClientId || ":integrationApiClientId"
      }/${ROUTE_PARTS.EDIT}`,
    ),
  ADMIN_INTEGRATION_API_CLIENT_VIEW: (integrationApiClientId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/${ROUTE_PARTS.INTEGRATION}/${ROUTE_PARTS.API_CLIENTS}/${
        integrationApiClientId || ":integrationApiClientId"
      }/${ROUTE_PARTS.VIEW}`,
    ),

  ADMIN_SYSTEM_APPLICATION_ENUMS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/system/application-enums`,
  ),
  ADMIN_SYSTEM_APPLICATION_ENUM_VIEW: (enumName?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/system/application-enums/${enumName || ":enumName"}/${
        ROUTE_PARTS.VIEW
      }`,
    ),
  ADMIN_SYSTEM_CACHE: RoutePathBuilder.buildSimple(`/${ROUTE_PARTS.APP_AREA_ADMIN}/system/cache`),

  ADMIN_APP_UPDATE_INFOS: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_ADMIN}/app-update-info`,
  ),

  ADMIN_APP_UPDATE_INFO_VIEW: (queryParams?: AppUpdateInfoViewQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_ADMIN}/app-update-info/${ROUTE_PARTS.VIEW}`,
      queryParams,
    ),
  // #endregion

  // #region Fleet customer

  FLEET_CUSTOMER_PROFILE: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_CUSTOMER}/${ROUTE_PARTS.PROFILE}`,
  ),

  FLEET_PROFILE_PERSONAL_INFO_EDIT: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PROFILE}/${ROUTE_PARTS.PERSONAL_INFO}`,
  ),

  FLEET_PROFILE_SETTINGS_EDIT: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_APP}/${ROUTE_PARTS.PROFILE}/${ROUTE_PARTS.SETTINGS}`,
  ),

  FLEET_CUSTOMER_DASHBOARD: RoutePathBuilder.buildSimple(
    `/${ROUTE_PARTS.APP_AREA_FLEET_CUSTOMER}/${ROUTE_PARTS.FLEET_CUSTOMER_DASHBOARD}`,
  ),

  FLEET_CUSTOMER_ASSESSMENT_FLOWS: (queryParams?: AssessmentFlowsPageQueryParams) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_CUSTOMER}/${ROUTE_PARTS.FLOWS}/${ROUTE_PARTS.ASSESSMENT}`,
      queryParams,
    ),
  FLEET_CUSTOMER_ASSESSMENT_FLOW_VIEW: (assessmentFlowId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_CUSTOMER}/${ROUTE_PARTS.FLOWS}/${ROUTE_PARTS.ASSESSMENT}/${
        assessmentFlowId || ":assessmentFlowId"
      }/${ROUTE_PARTS.VIEW}`,
    ),
  FLEET_CUSTOMER_ASSESSMENT_FLOW_CHAT_VIEW: (assessmentFlowId?: string | null) =>
    RoutePathBuilder.buildSimple(
      `/${ROUTE_PARTS.APP_AREA_FLEET_CUSTOMER}/${ROUTE_PARTS.FLOWS}/${ROUTE_PARTS.ASSESSMENT}/${
        assessmentFlowId || ":assessmentFlowId"
      }/${ROUTE_PARTS.VIEW}/${ROUTE_PARTS.CHAT}`,
    ),

  // #endregion
};
