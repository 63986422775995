import { isNil } from "lodash-es";
import { useMemo } from "react";

import { GeneralTaxDto, GeneralTaxInputDto, TaxType, TaxValueType } from "@/core/api/generated";

import { useTenantProfile } from "./entity/tenant/useTenantProfile";
import { useCurrentCurrency } from "./useCurrentCurrency";

/** Returns current tax from tenant or user profile, or default tax. */
export const useCurrentTax = ({
  useDefault = true,
}: {
  useDefault?: boolean;
} = {}): GeneralTaxDto | GeneralTaxInputDto | undefined => {
  const tenantProfile = useTenantProfile();
  const currentCurrency = useCurrentCurrency({ useDefault: useDefault });

  const defaultTax = useMemo(
    () => ({
      type: TaxType.Custom,
      valueType: TaxValueType.Percent,
      percent: 0,
      currency: currentCurrency,
    }),
    [currentCurrency],
  );

  if (!tenantProfile) {
    return undefined;
  }
  return isNil(tenantProfile) && !useDefault
    ? undefined
    : tenantProfile?.settings?.tax || defaultTax;
};
