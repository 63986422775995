import { FeatureNames } from "../featureManagement/types";

export const FeatureName = {
  RealtimeDataUpdates: "RealtimeDataUpdates",
  ChatOnlineStatus: "ChatOnlineStatus",
  ChatActivity: "ChatActivity",
  ChatTypingIndicatorEnabled: "ChatTypingIndicatorEnabled",
  ChatMessageReadStatus: "ChatMessageReadStatus",
  RepairSpecCustomItemAdd: "RepairSpecCustomItemAdd",
  /** Cross-tenant access to my repair specs, materials, etc. */
  CrossTenantRepairEntityAccess: "CrossTenantRepairEntityAccess",
  AiAssistant: "AiAssistant",
} as const;
export type FeatureName = (typeof FeatureName)[keyof typeof FeatureName];

export const featureNames: FeatureNames = FeatureName;
