import { Box, Button, Popover, PopoverProps, Stack, SxProps, Theme } from "@mui/material";
import { isNil } from "lodash-es";
import { Children, ReactNode, useMemo, useRef, useState } from "react";

import { useTriggerRender } from "@/common/hooks/render/useTriggerRender";

export interface ListItemGroupProps {
  /**
   * normal - show limited content and full content in the popover.
   * compact - show counter and full content in the popover.
   */
  variant?: "normal" | "compact";
  /** Where to show full content when expanding the list. */
  expandVariant?: "normal" | "popover";
  direction?: "column" | "row";
  /** Spacing between items. */
  spacing?: number;
  /** Max visible items. */
  maxItems?: number;
  /** To work properly must be a list of components.
   * E.g. <ListItemGroup><Item /><Item /><Item /></ListItemGroup>. */
  children: ReactNode;
  sx?: SxProps<Theme>;
  popoverProps?: Partial<PopoverProps>;
  popoverContentSx?: SxProps<Theme>;
}

/** Groups list items allowing to limit number of displayed items. */
export default function ListItemGroup({
  variant = "normal",
  direction = "column",
  spacing = 1,
  maxItems,
  children,
  sx,
  popoverProps,
  popoverContentSx,
}: ListItemGroupProps) {
  const { triggerRender } = useTriggerRender();

  const [maxItemsComputed, setMaxItemsComputed] = useState<number | undefined>(maxItems);
  const [isExpanded, setIsExpanded] = useState(false);

  const [popoverOpenAnchorEl, setPopoverOpenAnchorEl] = useState<HTMLElement | null>(null);
  const isMousedEnteredPopoverRef = useRef(false);
  const isPopoverOpen = Boolean(popoverOpenAnchorEl);

  const childrenList = useMemo(() => Children.toArray(children), [children]);
  const childrenListSliced = useMemo(
    () =>
      Children.map(childrenList, (x, i) =>
        isNil(maxItemsComputed) || i < maxItemsComputed ? x : null,
      ).filter((x) => !isNil(x)),
    [maxItemsComputed],
  );
  const counters = useMemo(
    () => ({
      totalCount: childrenList.length,
      visibleCount: childrenListSliced.length,
      hiddenCount: Math.abs(childrenList.length - childrenListSliced.length),
    }),
    [childrenList, childrenListSliced],
  );
  const isShowMore = useMemo(
    () => !!maxItems && maxItems < counters.totalCount,
    [maxItems, counters],
  );

  const handleExpand = () => {
    const newValue = !isExpanded;
    if (newValue) {
      setMaxItemsComputed(counters.totalCount);
    } else {
      setMaxItemsComputed(maxItems);
    }
    setIsExpanded(newValue);
  };

  return (
    <Stack spacing={spacing} sx={sx}>
      {/* Normal - show limited content and full content in popover. */}
      {variant === "normal" && (
        <Stack
          direction={direction}
          spacing={spacing}
          sx={{ ...(direction === "row" && { flexWrap: "wrap" }) }}
        >
          {childrenListSliced.map((x, i) => (
            <Box key={i} component='span'>
              {x}
            </Box>
          ))}

          {isShowMore && (
            <Box component='span'>
              <Button variant='outlined' color='text' size='small' onClick={handleExpand}>
                {isExpanded ? "Show less" : `+${counters.hiddenCount}`}
              </Button>
            </Box>
          )}
        </Stack>
      )}

      {/* Compact - show counter and full content in popover. */}
      {variant === "compact" && (
        <Box component='span'>
          <Button
            variant='outlined'
            color='text'
            size='small'
            onClick={(e) => {
              setPopoverOpenAnchorEl(e.currentTarget!);
            }}
            onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) =>
              setPopoverOpenAnchorEl(e.currentTarget!)
            }
          >
            {counters.totalCount}
          </Button>
        </Box>
      )}

      {/* All items popover */}
      <Popover
        {...popoverProps}
        sx={{
          // NB: pointerEvents are required for scrolling in Popover
          // pointerEvents: "none",
          ...popoverProps?.sx,
        }}
        open={isPopoverOpen}
        anchorEl={popoverOpenAnchorEl}
        onClose={() => setPopoverOpenAnchorEl(null)}
        anchorOrigin={
          popoverProps?.anchorOrigin || {
            vertical: "top",
            horizontal: "right",
          }
        }
        disableRestoreFocus
      >
        <Box
          sx={{
            maxWidth: { sm: "90vw" },
            maxHeight: "90vh",
            overflowY: "auto",
            py: 1,
            px: 2,
            ...popoverContentSx,
          }}
          onMouseEnter={(e: React.MouseEvent<HTMLSpanElement>) => {
            if (isPopoverOpen) {
              isMousedEnteredPopoverRef.current = true;
              triggerRender();
            }
          }}
          onMouseLeave={() => {
            if (isPopoverOpen) {
              isMousedEnteredPopoverRef.current = false;
              setPopoverOpenAnchorEl(null);
              triggerRender();
            }
          }}
        >
          <Stack spacing={spacing}>
            {childrenList.map((x, i) => (
              <Box key={i} component='span'>
                {x}
              </Box>
            ))}
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
}
