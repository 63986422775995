import { Box, Stack } from "@mui/material";
import { isNil } from "lodash-es";

import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import { VehicleSpecDto } from "@/core/api/generated";

import VehiclePriceDisplay from "./VehiclePriceDisplay";

interface Props {
  spec: VehicleSpecDto | null | undefined;
  columnCount?: number;
  hideVehicleRelatedDuplicateFields?: boolean;
}

export default function VehicleSpecDisplay({
  spec,
  columnCount = 4,
  hideVehicleRelatedDuplicateFields,
}: Props) {
  if (!spec) {
    return null;
  }
  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Make' iconOf='vehicle'>
            {spec?.make?.name}
          </InfoTile>
        )}

        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Model' iconOf='vehicleSide'>
            {spec?.model?.name}
          </InfoTile>
        )}

        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Generation' iconOf='clock' isEmpty={!spec?.generation}>
            {spec?.generation?.name}
          </InfoTile>
        )}

        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Modification' iconOf='modification' isEmpty={!spec?.modification}>
            {spec?.modification?.name}
          </InfoTile>
        )}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Type' iconOf='data' isEmpty={!spec?.type}>
            <InlineApiEnumValue type='VehicleType' value={spec?.type} />
          </InfoTile>
        )}

        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Size' iconOf='data' isEmpty={!spec?.size}>
            <InlineApiEnumValue type='VehicleSize' value={spec?.size} />
          </InfoTile>
        )}

        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Body type' iconOf='repairCar' isEmpty={!spec?.bodyType}>
            <InlineApiEnumValue type='VehicleBodyType' value={spec?.bodyType} />
          </InfoTile>
        )}

        {!hideVehicleRelatedDuplicateFields && (
          <InfoTile label='Fuel type' iconOf='fuel' isEmpty={!spec?.fuelType}>
            <InlineApiEnumValue type='VehicleFuelType' value={spec?.fuelType} />
          </InfoTile>
        )}

        <InfoTile label='Gearbox type' iconOf='data' isEmpty={!spec?.gearboxType}>
          <InlineApiEnumValue type='VehicleGearboxType' value={spec?.gearboxType} />
        </InfoTile>

        <InfoTile label='Drivetrain type' iconOf='data' isEmpty={!spec?.drivetrainType}>
          <InlineApiEnumValue type='VehicleDrivetrainType' value={spec?.drivetrainType} />
        </InfoTile>

        <InfoTile
          label='Purchase price'
          iconOf='price'
          isEmpty={isNil(spec?.purchasePrice?.price ?? spec?.purchasingCost)}
        >
          <VehiclePriceDisplay
            price={spec?.purchasePrice}
            fallbackPrice={{ price: spec?.purchasingCost ?? undefined }}
          />
        </InfoTile>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile label='Manufacture year' iconOf='data' isEmpty={isNil(spec?.manufactureYear)}>
          {spec?.manufactureYear}
        </InfoTile>

        <InfoTile label='Number of seats' iconOf='data' isEmpty={isNil(spec?.numberOfSeats)}>
          {spec?.numberOfSeats}
        </InfoTile>

        <InfoTile label='Number of doors' iconOf='data' isEmpty={isNil(spec?.numberOfDoors)}>
          {spec?.numberOfDoors}
        </InfoTile>

        <InfoTile label='Boot capacity L' iconOf='data' isEmpty={isNil(spec?.bootCapacityL)}>
          {spec?.bootCapacityL}
        </InfoTile>

        <InfoTile label='CO2 emissions g/km' iconOf='data' isEmpty={isNil(spec?.cO2EmissionsGKm)}>
          {spec?.cO2EmissionsGKm}
        </InfoTile>

        <InfoTile label='Horsepower' iconOf='data' isEmpty={isNil(spec?.horsepower)}>
          {spec?.horsepower}
        </InfoTile>

        <InfoTile
          label='Fuel consumption L/100km'
          iconOf='data'
          isEmpty={isNil(spec?.fuelConsumption)}
        >
          {spec?.fuelConsumption?.lPer100Km}
        </InfoTile>

        <InfoTile
          label='Battery capacity kW/h'
          iconOf='data'
          isEmpty={isNil(spec?.batteryCapacityKWh)}
        >
          {spec?.batteryCapacityKWh}
        </InfoTile>

        <InfoTile label='Electric range km' iconOf='data' isEmpty={isNil(spec?.electricRangeKm)}>
          {spec?.electricRangeKm}
        </InfoTile>

        <InfoTile label='Battery' iconOf='data' isEmpty={isNil(spec?.hasBattery)}>
          <BooleanValue value={spec?.hasBattery} />
        </InfoTile>

        <InfoTile
          label='Primary propulsion type'
          iconOf='data'
          isEmpty={!spec?.primaryPropulsionType}
        >
          <InlineApiEnumValue type='VehiclePropulsionType' value={spec?.primaryPropulsionType} />
        </InfoTile>

        <InfoTile
          label='Secondary propulsion type'
          iconOf='data'
          isEmpty={!spec?.secondaryPropulsionType}
        >
          <InlineApiEnumValue type='VehiclePropulsionType' value={spec?.secondaryPropulsionType} />
        </InfoTile>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          gridTemplateRows: "auto",
          rowGap: 1,
          columnGap: 1,
        }}
      >
        <InfoTile
          label='Air conditioner (AC)'
          iconOf='data'
          isEmpty={isNil(spec?.hasAirConditioner)}
        >
          <BooleanValue value={spec?.hasAirConditioner} />
        </InfoTile>

        <InfoTile label='Tow hook' iconOf='data' isEmpty={isNil(spec?.hasTowHook)}>
          <BooleanValue value={spec?.hasTowHook} />
        </InfoTile>

        <InfoTile label='Tow bar' iconOf='data' isEmpty={isNil(spec?.hasTowBar)}>
          <BooleanValue value={spec?.hasTowBar} />
        </InfoTile>

        <InfoTile label='Tow rope' iconOf='data' isEmpty={isNil(spec?.hasTowRope)}>
          <BooleanValue value={spec?.hasTowRope} />
        </InfoTile>
      </Box>
    </Stack>
  );
}
