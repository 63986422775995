import {
  Alert,
  Box,
  Button,
  FormControl,
  FormHelperText,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, getIn } from "formik";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import * as Yup from "yup";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssignmentContractInputDto,
  AssignmentCreateDto,
  AssignmentDto,
  AssignmentPartyRoleType,
  AssignmentPartyType,
  AssignmentStage,
  AssignmentStepDto,
  AssignmentStepInputDto,
  AssignmentStepType,
  AssignmentTimePeriodType,
  AssignmentTransportationDto,
  AssignmentTransportationInputDto,
  AssignmentType,
  AssignmentUpdateDto,
  EntityType,
} from "@/core/api/generated";

import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import ContractAutocomplete from "../Contract/ContractAutocomplete";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import AssignmentPartyInput from "./AssignmentPartyInput";
import AssignmentStepInput from "./AssignmentStepInput";
import AssignmentTimePeriodInput from "./AssignmentTimePeriodInput";

type DefaultValues = {
  name?: string;
  vehicleId?: AssignmentCreateDto["vehicleId"];
};

export interface AssignmentCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<AssignmentDto, DefaultValues> {
  assignmentId?: string;
}

export type AssignmentCreateUpdateProps = AssignmentCreateUpdateOwnProps;

export default function AssignmentCreateUpdate({
  assignmentId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: AssignmentCreateUpdateProps) {
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const isCreate = !assignmentId;
  const isEdit = !!assignmentId;

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const tenantProfile = useTenantProfile();

  const assignmentRequest = useApiRequest(
    apiClient.assignmentsApi.apiV1AssignmentsAssignmentIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assignmentId: assignmentId!,
    },
    {
      deps: [assignmentId],
      skip: !assignmentId,
    },
  );
  const assignment = assignmentRequest?.data;

  const [createMetaType, setCreateMetaType] = useState<AssignmentType | undefined | null>(
    AssignmentType.Transportation,
  );

  const createMetaRequest = useApiRequest(
    apiClient.assignmentsApi.apiV1AssignmentsCreateMetaGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      type: createMetaType!,
    },
    {
      deps: [],
    },
  );
  const createMeta = createMetaRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: assignment && {
      idValue: assignment.id!,
      newTitle: assignment.localNumber || "",
    },
  });

  const canEdit =
    isCreate || (isEdit && assignment?.stageHistory?.stage !== AssignmentStage.Completed);

  const isInitialLoading = assignmentRequest.isLoading;

  if (!tenantProfile || isInitialLoading) {
    return <LinearProgress />;
  }

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Assignment}
      entityId={assignmentId}
      entity={assignment}
      entityRequest={assignmentRequest}
      isIniting={assignmentRequest.isLoading || !profile}
    >
      <Formik<
        BaseFormikValues &
          AssignmentCreateDto &
          AssignmentUpdateDto & {
            vehicle?: AssignmentDto["vehicle"];
            isAttachmentFilesHaveErrors: boolean;
            initialAttachments: AssignmentDto["attachments"];
            contract?: AssignmentDto["contract"] & AssignmentContractInputDto;
            transportation?: AssignmentDto["transportation"] &
              AssignmentTransportationInputDto & {
                pickUp: AssignmentTransportationDto["pickUp"] &
                  AssignmentStepInputDto & {
                    initialAttachments?: AssignmentStepDto["attachments"];
                    isAttachmentFilesHaveErrors?: boolean;
                  };
                dropOff: AssignmentTransportationDto["dropOff"] &
                  AssignmentStepInputDto & {
                    initialAttachments?: AssignmentStepDto["attachments"];
                    isAttachmentFilesHaveErrors?: boolean;
                  };
              };
          }
      >
        initialValues={{
          type: assignment?.type || undefined,
          name: assignment?.name || defaultValues?.name || "",
          description: assignment?.description || "",
          assigner: assignment?.assigner
            ? {
                type: assignment.assigner.type,
                roleType: assignment.assigner.roleType,
                userId: assignment.assigner.user?.id || undefined,
                tenantId: assignment.assigner.tenant?.id || undefined,
                departmentId: assignment.assigner.department?.id || undefined,
                locationId: assignment.assigner.location?.id || undefined,
                supplierId: assignment.assigner.supplier?.id || undefined,
                customerId: assignment.assigner.customer?.id || undefined,
                custom: assignment.assigner.custom
                  ? {
                      name: assignment.assigner.custom?.name || undefined,
                      contact: {
                        email: assignment.assigner.custom?.contact?.email || undefined,
                        phoneNumber: assignment.assigner.custom?.contact?.phoneNumber || undefined,
                        personName: {
                          firstName:
                            assignment.assigner.custom?.contact?.personName?.firstName || undefined,
                          lastName:
                            assignment.assigner.custom?.contact?.personName?.lastName || undefined,
                        },
                      },
                    }
                  : undefined,
              }
            : {},
          assignee: assignment?.assignee
            ? {
                type: assignment.assignee.type,
                roleType: assignment.assignee.roleType,
                userId: assignment.assignee.user?.id || undefined,
                tenantId: assignment.assignee.tenant?.id || undefined,
                departmentId: assignment.assignee.department?.id || undefined,
                locationId: assignment.assignee.location?.id || undefined,
                supplierId: assignment.assignee.supplier?.id || undefined,
                customerId: assignment.assignee.customer?.id || undefined,
                custom: assignment.assignee.custom
                  ? {
                      name: assignment.assignee.custom?.name || undefined,
                      contact: {
                        email: assignment.assignee.custom?.contact?.email || undefined,
                        phoneNumber: assignment.assignee.custom?.contact?.phoneNumber || undefined,
                        personName: {
                          firstName:
                            assignment.assignee.custom?.contact?.personName?.firstName || undefined,
                          lastName:
                            assignment.assignee.custom?.contact?.personName?.lastName || undefined,
                        },
                      },
                    }
                  : undefined,
              }
            : {},
          origin: assignment?.origin
            ? {
                type: assignment.origin.type,
                roleType: assignment.origin.roleType,
                userId: assignment.origin.user?.id || undefined,
                tenantId: assignment.origin.tenant?.id || undefined,
                departmentId: assignment.origin.department?.id || undefined,
                locationId: assignment.origin.location?.id || undefined,
                supplierId: assignment.origin.supplier?.id || undefined,
                customerId: assignment.origin.customer?.id || undefined,
                custom: assignment.origin.custom
                  ? {
                      name: assignment.origin.custom?.name || undefined,
                      contact: {
                        email: assignment.origin.custom?.contact?.email || undefined,
                        phoneNumber: assignment.origin.custom?.contact?.phoneNumber,
                        personName: {
                          firstName:
                            assignment.origin.custom?.contact?.personName?.firstName || undefined,
                          lastName:
                            assignment.origin.custom?.contact?.personName?.lastName || undefined,
                        },
                      },
                    }
                  : undefined,
              }
            : undefined,
          destination: assignment?.destination
            ? {
                type: assignment.destination.type,
                roleType: assignment.destination.roleType,
                userId: assignment.destination.user?.id || undefined,
                tenantId: assignment.destination.tenant?.id || undefined,
                departmentId: assignment.destination.department?.id || undefined,
                locationId: assignment.destination.location?.id || undefined,
                supplierId: assignment.destination.supplier?.id || undefined,
                customerId: assignment.destination.customer?.id || undefined,
                custom: assignment.destination.custom
                  ? {
                      name: assignment.destination.custom?.name || undefined,
                      contact: {
                        email: assignment.destination.custom?.contact?.email || undefined,
                        phoneNumber:
                          assignment.destination.custom?.contact?.phoneNumber || undefined,
                        personName: {
                          firstName:
                            assignment.destination.custom?.contact?.personName?.firstName ||
                            undefined,
                          lastName:
                            assignment.destination.custom?.contact?.personName?.lastName ||
                            undefined,
                        },
                      },
                    }
                  : undefined,
              }
            : undefined,
          driver: assignment?.driver
            ? {
                type: assignment.driver.type,
                roleType: assignment.driver.roleType,
                userId: assignment.driver.user?.id || undefined,
                tenantId: assignment.driver.tenant?.id || undefined,
                departmentId: assignment.driver.department?.id || undefined,
                locationId: assignment.driver.location?.id || undefined,
                supplierId: assignment.driver.supplier?.id || undefined,
                customerId: assignment.driver.customer?.id || undefined,
                custom: assignment.driver.custom
                  ? {
                      name: assignment.driver.custom?.name || undefined,
                      contact: {
                        email: assignment.driver.custom?.contact?.email || undefined,
                        phoneNumber: assignment.driver.custom?.contact?.phoneNumber || undefined,
                        personName: {
                          firstName:
                            assignment.driver.custom?.contact?.personName?.firstName || undefined,
                          lastName:
                            assignment.driver.custom?.contact?.personName?.lastName || undefined,
                        },
                      },
                    }
                  : undefined,
              }
            : undefined,
          period: assignment?.period
            ? {
                type: assignment.period.type || undefined,
                startsAt: assignment.period.startsAt || undefined,
                endsAt: assignment.period.endsAt || undefined,
                dueAt: assignment.period.dueAt || undefined,
              }
            : undefined,
          vehicleId: assignment?.vehicle?.id || defaultValues?.vehicleId || undefined,
          vehicle: assignment?.vehicle || undefined,
          contract: assignment?.contract
            ? {
                contractId: assignment?.contract?.contract?.id || undefined,
                externalNumber: assignment?.contract?.externalNumber || undefined,
              }
            : undefined,
          notes: assignment?.notes || "",
          initialAttachments: assignment?.attachments || undefined,
          isAttachmentFilesHaveErrors: false,
          attachments:
            assignment?.attachments && assignment.attachments.length > 0
              ? assignment.attachments
              : undefined,
          transportation: {
            pickUp: assignment?.transportation?.pickUp
              ? {
                  type: assignment.transportation.pickUp?.type || undefined,
                  name: assignment.transportation.pickUp?.name || undefined,
                  date: assignment.transportation.pickUp?.date || undefined,
                  location: assignment.transportation.pickUp?.location
                    ? {
                        type: assignment.transportation.pickUp?.location.type || undefined,
                        spotId: assignment.transportation.pickUp?.location.spot?.id || undefined,
                        address: assignment.transportation.pickUp?.location.address
                          ? {
                              country:
                                assignment.transportation.pickUp?.location?.address?.country ||
                                undefined,
                              state:
                                assignment.transportation.pickUp?.location?.address?.state ||
                                undefined,
                              city:
                                assignment.transportation.pickUp?.location?.address?.city ||
                                undefined,
                              line1:
                                assignment.transportation.pickUp?.location?.address?.line1 ||
                                undefined,
                              line2:
                                assignment.transportation.pickUp?.location?.address?.line2 ||
                                undefined,
                              postalCode:
                                assignment.transportation.pickUp?.location?.address?.postalCode ||
                                undefined,
                            }
                          : undefined,
                      }
                    : {},
                  notes: assignment.transportation.pickUp?.notes || undefined,
                  attachments:
                    assignment.transportation.pickUp?.attachments &&
                    assignment.transportation.pickUp?.attachments.length > 0
                      ? assignment.transportation.pickUp?.attachments
                      : undefined,
                  settings: assignment?.transportation?.pickUp?.settings || undefined,
                  operations: assignment?.transportation.pickUp.operations?.map((x) => ({
                    ...x,
                    type: x.type,
                    accessoryCheckId: x.accessoryCheckId,
                    assessmentId: x.assessmentId,
                    damageCostEvaluationId: x.damageCostEvaluationId,
                    damageDetectionId: x.damageDetectionId,
                    maintenanceId: x.maintenanceId,
                    repairOperationId: x.repairOperationId,
                    visualInspectionId: x.visualInspectionId,
                    washId: x.washId,
                    wheelOperationId: x.wheelOperationId,
                  })),
                  initialAttachments: assignment?.transportation?.pickUp?.attachments || undefined,
                  isAttachmentFilesHaveErrors: false,
                }
              : { location: {} },
            dropOff: assignment?.transportation?.dropOff
              ? {
                  type: assignment.transportation.dropOff?.type || undefined,
                  name: assignment.transportation.dropOff?.name || undefined,
                  date: assignment.transportation.dropOff?.date || undefined,
                  location: assignment.transportation.dropOff?.location
                    ? {
                        type: assignment.transportation.dropOff?.location.type || undefined,
                        spotId: assignment.transportation.dropOff?.location.spot?.id || undefined,
                        address: assignment.transportation.dropOff?.location.address
                          ? {
                              country:
                                assignment.transportation.dropOff?.location?.address?.country ||
                                undefined,
                              state:
                                assignment.transportation.dropOff?.location?.address?.state ||
                                undefined,
                              city:
                                assignment.transportation.dropOff?.location?.address?.city ||
                                undefined,
                              line1:
                                assignment.transportation.dropOff?.location?.address?.line1 ||
                                undefined,
                              line2:
                                assignment.transportation.dropOff?.location?.address?.line2 ||
                                undefined,
                              postalCode:
                                assignment.transportation.dropOff?.location?.address?.postalCode ||
                                undefined,
                            }
                          : undefined,
                      }
                    : {},
                  notes: assignment.transportation.dropOff?.notes || undefined,
                  attachments:
                    assignment.transportation.dropOff?.attachments &&
                    assignment.transportation.dropOff?.attachments.length > 0
                      ? assignment.transportation.dropOff?.attachments
                      : undefined,
                  settings: assignment?.transportation?.dropOff?.settings || undefined,
                  operations: assignment?.transportation.dropOff.operations?.map((x) => ({
                    ...x,
                    type: x.type,
                    accessoryCheckId: x.accessoryCheckId,
                    assessmentId: x.assessmentId,
                    damageCostEvaluationId: x.damageCostEvaluationId,
                    damageDetectionId: x.damageDetectionId,
                    maintenanceId: x.maintenanceId,
                    repairOperationId: x.repairOperationId,
                    visualInspectionId: x.visualInspectionId,
                    washId: x.washId,
                    wheelOperationId: x.wheelOperationId,
                  })),
                  initialAttachments: assignment?.transportation?.dropOff?.attachments || undefined,
                  isAttachmentFilesHaveErrors: false,
                }
              : { location: {} },
          },
          tags: assignment?.tags || undefined,
          newStage: undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.assignmentsApi.apiV1AssignmentsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                assignmentCreateDto: {
                  ..._.omit(values, ["submit"]),
                  attachments: values.attachments,
                  period: values.period
                    ? {
                        ...values.period,
                        startsAt: values.period.startsAt
                          ? moment(values.period.startsAt).utc().format()
                          : undefined,
                        endsAt: values.period.endsAt
                          ? moment(values.period.endsAt).utc().format()
                          : undefined,
                        dueAt: values.period.dueAt
                          ? moment(values.period.dueAt).utc().format()
                          : undefined,
                      }
                    : undefined,
                  transportation: values.transportation
                    ? {
                        ...values.transportation,
                        pickUp: values.transportation.pickUp
                          ? {
                              ...values.transportation.pickUp,
                              date: values.transportation.pickUp.date
                                ? moment(values.transportation.pickUp.date).utc().format()
                                : undefined,
                            }
                          : undefined,
                        dropOff: values.transportation.dropOff
                          ? {
                              ...values.transportation.dropOff,
                              date: values.transportation.dropOff.date
                                ? moment(values.transportation.dropOff.date).utc().format()
                                : undefined,
                            }
                          : undefined,
                      }
                    : undefined,
                },
              });
              enqueueSnackbar("Assignment created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response = await apiClient.assignmentsApi.apiV1AssignmentsAssignmentIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                assignmentId: assignmentId!,
                assignmentUpdateDto: {
                  ..._.omit(values, ["submit"]),
                  attachments: values.attachments,
                  period: values.period
                    ? {
                        ...values.period,
                        startsAt: values.period.startsAt
                          ? moment(values.period.startsAt).utc().format()
                          : undefined,
                        endsAt: values.period.endsAt
                          ? moment(values.period.endsAt).utc().format()
                          : undefined,
                        dueAt: values.period.dueAt
                          ? moment(values.period.dueAt).utc().format()
                          : undefined,
                      }
                    : undefined,
                  transportation: values.transportation
                    ? {
                        ...values.transportation,
                        pickUp: values.transportation.pickUp
                          ? {
                              ...values.transportation.pickUp,
                              date: values.transportation.pickUp.date
                                ? moment(values.transportation.pickUp.date).utc().format()
                                : undefined,
                            }
                          : undefined,
                        dropOff: values.transportation.dropOff
                          ? {
                              ...values.transportation.dropOff,
                              date: values.transportation.dropOff.date
                                ? moment(values.transportation.dropOff.date).utc().format()
                                : undefined,
                            }
                          : undefined,
                      }
                    : undefined,
                },
              });
              enqueueSnackbar("Assignment updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formikProps) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          } = formikProps;
          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormContentBlock gap={1}>
                {!canEdit && (
                  <Alert severity='info'>
                    {`This assignment is completed and can't be edited.`}
                  </Alert>
                )}

                {/* Tags */}
                <FormControl margin='dense' fullWidth>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                      isIncludeNested: false,
                    })}
                  </FormHelperText>
                </FormControl>

                <Stack>
                  {!isCreate && (
                    <TextField
                      fullWidth
                      disabled
                      label='Number'
                      margin='dense'
                      type='text'
                      value={assignment?.localNumber}
                      variant='outlined'
                    />
                  )}

                  {/* Type */}
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.type && errors.type)}
                  >
                    <ApiEnumAutocomplete
                      type='AssignmentType'
                      required
                      disabled={!canEdit}
                      value={values.type || undefined}
                      textFieldProps={{
                        label: "Type",
                        error: Boolean(errors.type),
                        helperText: errors.type,
                      }}
                      onChange={(newValue) => {
                        setFieldValue("type", newValue);
                        setCreateMetaType(newValue);
                      }}
                    />
                  </FormControl>

                  {/* Name */}
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    required
                    label='Name'
                    margin='dense'
                    name='name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type='text'
                    value={values.name || ""}
                    variant='outlined'
                  />

                  {/* Description */}
                  <AppTextArea
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    mode='description'
                    margin='dense'
                    name='description'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description || ""}
                    variant='outlined'
                  />

                  {/* Vehicle */}
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.vehicleId && errors.vehicleId)}
                  >
                    <VehicleAutocompleteOrCreate
                      autocompleteProps={{
                        disabled: !isCreate,
                        entityId: values.vehicleId,
                        isPreload: isCreate,
                        required: createMeta?.isVehicleRequired,
                        textFieldProps: {
                          error: Boolean(errors.vehicleId),
                          helperText: errors.vehicleId,
                        },
                        onChange: (newValue) => {
                          setFieldValue(`vehicleId`, newValue?.id);
                          setFieldValue(`vehicle`, newValue || undefined);
                          if (newValue) {
                            setFieldValue(`contract.contractId`, undefined);
                            setFieldValue(`contract.contract`, undefined);
                          }
                        },
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => {
                        setFieldValue(`vehicleId`, newValue?.id);
                        setFieldValue(`vehicle`, newValue || undefined);
                        if (newValue) {
                          setFieldValue(`contract.contractId`, undefined);
                          setFieldValue(`contract.contract`, undefined);
                        }
                      }}
                    />
                  </FormControl>

                  {/* Contract */}
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(
                      getIn(touched, `contract.contractId`) && getIn(errors, `contract.contractId`),
                    )}
                  >
                    <ContractAutocomplete
                      entityId={values?.contract?.contractId}
                      searchFilters={{
                        vehicleId: values.vehicleId || undefined,
                      }}
                      disabled={!isCreate}
                      onChange={async (newValue) => {
                        setFieldValue(`contract.contractId`, newValue?.id || undefined);
                        if (!values?.contract?.externalNumber) {
                          setFieldValue(
                            "contract.externalNumber",
                            newValue?.externalNumber || undefined,
                          );
                        }
                        if (!values?.vehicleId) {
                          setFieldValue(`vehicleId`, newValue?.vehicle?.id || undefined);
                          setFieldValue(`vehicle`, newValue?.vehicle || undefined);
                        }
                      }}
                    />
                    <FormHelperText>
                      {getIn(touched, `contract.contractId`) &&
                        getIn(errors, `contract.contractId`)}
                    </FormHelperText>
                  </FormControl>

                  <TextField
                    error={Boolean(
                      getIn(touched, `contract.externalNumber`) &&
                        getIn(errors, `contract.externalNumber`),
                    )}
                    fullWidth
                    helperText={
                      getIn(touched, `contract.externalNumber`) &&
                      getIn(errors, `contract.externalNumber`)
                    }
                    label='External reference'
                    margin='dense'
                    name='externalNumber'
                    onChange={(newValue) => {
                      setFieldValue("contract.externalNumber", newValue.target.value);
                    }}
                    type='text'
                    value={values?.contract?.externalNumber || ""}
                    variant='outlined'
                  />

                  {/* Period */}
                  <AssignmentTimePeriodInput
                    formikProps={FormikHelper.getSubProps(formikProps, "period", (v) => v.period)}
                    disabled={!canEdit}
                    assignmentTimePeriodType={AssignmentTimePeriodType.Deadline}
                  />
                </Stack>

                {/* Parties */}
                <Typography variant='h6' sx={{ mb: 2, fontWeight: "bold" }}>
                  Assignment Parties
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    columnGap: 2,
                  }}
                >
                  {/* Assigner */}
                  <FormControl>
                    <AssignmentPartyInput
                      formikProps={FormikHelper.getSubProps(
                        formikProps,
                        "assigner",
                        (v) => v.assigner,
                      )}
                      assignmentPartyTypes={[AssignmentPartyType.User]}
                      assignmentPartyRoleType={AssignmentPartyRoleType.Assigner}
                      userId={profile?.id}
                      required
                      disabled={!canEdit}
                    />
                  </FormControl>

                  {/* Assignee */}
                  <FormControl>
                    <AssignmentPartyInput
                      formikProps={FormikHelper.getSubProps(
                        formikProps,
                        "assignee",
                        (v) => v.assignee,
                      )}
                      assignmentPartyTypes={[
                        AssignmentPartyType.User,
                        AssignmentPartyType.Company,
                        AssignmentPartyType.Department,
                        AssignmentPartyType.Location,
                        AssignmentPartyType.Supplier,
                      ]}
                      assignmentPartyRoleType={AssignmentPartyRoleType.Assignee}
                      required
                      disabled={!canEdit}
                    />
                  </FormControl>

                  {/* Origin */}
                  <FormControl>
                    <AssignmentPartyInput
                      formikProps={FormikHelper.getSubProps(formikProps, "origin", (v) => v.origin)}
                      assignmentPartyTypes={[
                        AssignmentPartyType.Company,
                        AssignmentPartyType.Department,
                        AssignmentPartyType.Location,
                        AssignmentPartyType.Supplier,
                        AssignmentPartyType.Customer,
                        AssignmentPartyType.Custom,
                      ]}
                      assignmentPartyRoleType={AssignmentPartyRoleType.Origin}
                      disabled={!canEdit}
                    />
                  </FormControl>

                  {/* Destination */}
                  <FormControl>
                    <AssignmentPartyInput
                      formikProps={FormikHelper.getSubProps(
                        formikProps,
                        "destination",
                        (v) => v.destination,
                      )}
                      assignmentPartyTypes={[
                        AssignmentPartyType.Company,
                        AssignmentPartyType.Department,
                        AssignmentPartyType.Location,
                        AssignmentPartyType.Supplier,
                        AssignmentPartyType.Customer,
                        AssignmentPartyType.Custom,
                      ]}
                      assignmentPartyRoleType={AssignmentPartyRoleType.Destination}
                      disabled={!canEdit}
                    />
                  </FormControl>

                  {/* Driver */}
                  <FormControl>
                    <AssignmentPartyInput
                      formikProps={FormikHelper.getSubProps(formikProps, "driver", (v) => v.driver)}
                      assignmentPartyTypes={[AssignmentPartyType.User, AssignmentPartyType.Custom]}
                      assignmentPartyRoleType={AssignmentPartyRoleType.Driver}
                      disabled={!canEdit}
                    />
                  </FormControl>
                </Box>

                {/* Transportation */}
                {values.type === AssignmentType.Transportation && (
                  <Stack>
                    <Typography variant='h6' sx={{ mb: 2, fontWeight: "bold" }}>
                      Transportation
                    </Typography>

                    {/* Pick Up */}
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: (
                          <Typography variant='subtitle1' sx={{ mt: 1, mb: 1 }}>
                            Pick Up
                          </Typography>
                        ),
                      }}
                    >
                      <AssignmentStepInput
                        formikProps={FormikHelper.getSubProps(
                          formikProps,
                          "transportation.pickUp",
                          (v) => v.transportation?.pickUp,
                        )}
                        assignmentStepType={AssignmentStepType.PickUp}
                        vehicle={values.vehicle}
                        contract={values.contract?.contract}
                        assignmentId={assignmentId}
                        setIsAttachmentFilesUploading={setIsAttachmentFilesUploading}
                        disabled={!canEdit}
                      />
                    </FoldableBlock>

                    {/* Drop Off */}
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: (
                          <Typography variant='subtitle1' sx={{ mt: 2, mb: 1 }}>
                            Drop Off
                          </Typography>
                        ),
                      }}
                    >
                      <AssignmentStepInput
                        formikProps={FormikHelper.getSubProps(
                          formikProps,
                          "transportation.dropOff",
                          (v) => v.transportation?.dropOff,
                        )}
                        assignmentStepType={AssignmentStepType.DropOff}
                        vehicle={values.vehicle}
                        contract={values.contract?.contract}
                        setIsAttachmentFilesUploading={setIsAttachmentFilesUploading}
                        disabled={!canEdit}
                      />
                    </FoldableBlock>
                  </Stack>
                )}

                <FoldableBlock
                  defaultIsFolded={false}
                  trigger={{
                    label: (
                      <Typography component='span' variant='h6'>
                        Other
                      </Typography>
                    ),
                  }}
                >
                  <Stack spacing={2}>
                    <AppTextArea
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      mode='notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant='outlined'
                    />

                    <FormControl fullWidth component='fieldset' margin='dense'>
                      <FileUploader
                        multiple
                        defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                        onChange={(newFiles) => {
                          setFieldValue(
                            `attachments`,
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                        onValidationStatusChange={(filesValidationStatus) => {
                          if (filesValidationStatus)
                            setFieldValue(
                              "isAttachmentFilesHaveErrors",
                              Object.values(filesValidationStatus).some((x) => x === false),
                            );
                        }}
                      />
                    </FormControl>
                  </Stack>
                </FoldableBlock>
              </FormContentBlock>

              <GeneralValidationError errors={errors} />

              <FormActions>
                <Button
                  onClick={() => handleSubmit()}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  variant='contained'
                  disabled={
                    isAttachmentFilesUploading ||
                    !canEdit ||
                    values.isAttachmentFilesHaveErrors ||
                    values.transportation?.pickUp.isAttachmentFilesHaveErrors ||
                    values.transportation?.dropOff.isAttachmentFilesHaveErrors
                  }
                >
                  Save
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
