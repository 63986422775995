import {
  Box,
  CircularProgress,
  CssBaseline,
  Fade,
  Stack,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { isNil } from "lodash-es";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import BrowserNotSupportedChecker from "@/common/components/BrowserNotSupportedChecker/BrowserNotSupportedChecker";
import { THEMES } from "@/common/constants/common";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useAppDispatch, useAppSelector } from "@/common/hooks/redux";
import { useApiConnectionStatus } from "@/common/hooks/useApiConnectionStatus";
import {
  InternetConnectionStatus,
  useInternetConnectionStatus,
} from "@/common/hooks/useInternetConnectionStatus";
import { Stopwatch } from "@/common/stopwatch";
import { ApiConnectionStatus } from "@/core/api/ApiClient";
import { useFirebaseMessaging } from "@/firebase";
import * as appCommonSlice from "@/store/appCommon/slice";

import { createCustomTheme } from "../theme";
import Content from "./Content/Content";

const appErrorTimeoutMs = 20_000;
const stillLoadingTimeoutMs = 7_000;

export interface AppProps {
  onLoaded?: (loadingStopwatch: Stopwatch) => void;
}

/**
 * !!!!!!!!!!!!
 * NB: Let's do not do redirects here (except redirects to general error pages).
 * NB: By redirecting from here you can easily break other pages without knowing.
 */
function App({ onLoaded }: AppProps) {
  const dispatch = useAppDispatch();

  const settings = useAppSelector((x) => x.app.settings);
  const isAppInitialized = useAppSelector((x) => x.app.isAppInitialized);
  const isAuthenticated = useAppSelector((x) => x.auth.isAuthenticated);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  if (!localStorage.getItem("theme")) {
    if (prefersDarkMode) {
      dispatch(appCommonSlice._themeChange(THEMES.DARK));
    } else {
      dispatch(appCommonSlice._themeChange(THEMES.LIGHT));
    }
  }
  const theme = createCustomTheme({
    theme: settings.theme,
    responsiveFontSizes: false,
  });
  const history = useHistory();
  const internetStatus = useInternetConnectionStatus();
  const apiStatus = useApiConnectionStatus({ withRecurringCheck: true });
  const authorizationInfo = useAuthorizationInfo();

  const [isAppLoading, setIsAppLoading] = useState(true);
  const [isStillLoadingVisible, setIsStillLoadingVisible] = useState(false);
  const appErrorTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const stopwatchRef = useRef<Stopwatch>(Stopwatch.startNew());

  const isPermissionsReady = authorizationInfo.isPermissionsReady;

  useFirebaseMessaging(isAuthenticated);

  // console.log("app.", {
  //   isAppLoading,
  //   isAppInitialized,
  //   isAuthenticated,
  //   isPermissionsReady,
  //   internetStatus,
  //   apiStatus,
  //   isStillLoadingVisible,
  // });

  useEffect(() => {
    stopwatchRef.current.start();
    dispatch(appCommonSlice.appInitialized());
  }, []);

  useEffect(() => {
    if (isAppLoading) {
      setIsAppLoading(
        !isAppInitialized ||
          isNil(isAuthenticated) ||
          !isPermissionsReady ||
          internetStatus.isDisconnected ||
          apiStatus.isDisconnected,
      );
    }
  }, [
    isAppLoading,
    isAppInitialized,
    isAuthenticated,
    isPermissionsReady,
    internetStatus.status,
    apiStatus.status,
  ]);

  // notify user that app is still loading
  useEffect(() => {
    setTimeout(() => {
      setIsStillLoadingVisible(true);
    }, stillLoadingTimeoutMs);
  }, []);

  // show error page if app is still loading (e.g. no internet).
  useEffect(() => {
    clearTimeout(appErrorTimeoutRef.current);
    appErrorTimeoutRef.current = undefined;

    if (isAppLoading) {
      appErrorTimeoutRef.current = setTimeout(() => {
        // console.error(`App load error after ${appErrorTimeoutMs} ms.`, {
        //   internetStatus: internetStatus.getStatus(),
        //   apiStatus: apiStatus.getStatus(),
        // });
        // appInitialized();
        // isNil(isAuthenticated) && _authenticateFailed();
        setIsAppLoading(false);
        if (internetStatus.getStatus() === InternetConnectionStatus.Disconnected) {
          history.push(ROUTE_PATH.ERROR_NO_INTERNET);
          return;
        }
        if (apiStatus.getStatus() === ApiConnectionStatus.Disconnected) {
          history.push(ROUTE_PATH.ERROR_NO_API_CONNECTION(history.location.pathname));
          return;
        }
      }, appErrorTimeoutMs);
    }
  }, []);

  useEffect(() => {
    if (!isAppLoading) {
      stopwatchRef.current.stop();
      onLoaded && onLoaded(stopwatchRef.current);
      console.info(`Startup. App loaded.`, {
        elapsedMilliseconds: stopwatchRef.current.elapsedMilliseconds,
        elapsedSeconds: stopwatchRef.current.elapsedSeconds,
      });
    }
  }, [isAppLoading, onLoaded]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserNotSupportedChecker />

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          minHeight: "100%",
          maxHeight: "100%",
        }}
      >
        {/* Fullscreen loader */}
        {isAppLoading && (
          <Stack
            direction='column'
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{
              width: "100vw",
              height: "100vh",
            }}
          >
            <CircularProgress size='50px' color='primary' />

            {isStillLoadingVisible && (
              <Fade in={isStillLoadingVisible}>
                <Typography component='div' variant='body2'>
                  App is still loading. Please wait.
                </Typography>
              </Fade>
            )}
          </Stack>
        )}

        {/* Content */}
        {!isAppLoading && <Content />}
      </Box>
    </ThemeProvider>
  );
}
export default Sentry.withProfiler(App);
