import { Box, InputAdornment, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { isEmpty, isNil } from "lodash-es";
import { useMemo } from "react";

import { BaseEntitySearchAutocompleteInheritableProps } from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { enumService } from "@/common/services/enum";
import {
  EntityType,
  IBaseEntityDto,
  PoolDto,
  PoolItemCandidateEntityInputDto,
  PoolItemEntityType,
} from "@/core/api/generated";

import DropdownButton from "../../Button/DropdownButton";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import { AccessoryAutocompleteProps } from "../Accessory/AccessoryAutocomplete";
import AccessoryAutocompleteOrCreate from "../Accessory/AccessoryAutocompleteOrCreate";
import { AccessoryCreateUpdateProps } from "../Accessory/AccessoryCreateUpdate";
import { AssetAutocompleteProps } from "../Asset/AssetAutocomplete";
import AssetAutocompleteOrCreate from "../Asset/AssetAutocompleteOrCreate";
import { AssetCreateUpdateProps } from "../Asset/AssetCreateUpdate";
import { VehicleAutocompleteProps } from "../Vehicle/VehicleAutocomplete";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import { VehicleCreateUpdateProps } from "../Vehicle/VehicleCreateUpdate";
import { BaseEntityAutocompleteOrCreateInheritableProps } from "../components/BaseEntityAutocompleteOrCreate";
import BaseEntityAutocompletePlaceholder from "../components/BaseEntityAutocompletePlaceholder";
import { BaseEntityCreateUpdateInheritableProps } from "../components/BaseEntityCreateUpdate";

type EntityCreateUpdateDefaultValues = Exclude<
  AssetCreateUpdateProps["defaultValues"] &
    VehicleCreateUpdateProps["defaultValues"] &
    AccessoryCreateUpdateProps["defaultValues"],
  null | undefined
>;

type EntityAutocompleteProps = BaseEntitySearchAutocompleteInheritableProps<IBaseEntityDto> & {
  searchFilters?: AssetAutocompleteProps["searchFilters"] &
    VehicleAutocompleteProps["searchFilters"] &
    AccessoryAutocompleteProps["searchFilters"] & {
      notForPoolId?: string | null;
      excludeIds?: string[] | null;
    };
};

type EntityAutocompleteOrCreateProps = BaseEntityAutocompleteOrCreateInheritableProps<
  IBaseEntityDto,
  EntityCreateUpdateDefaultValues,
  EntityAutocompleteProps,
  BaseEntityCreateUpdateInheritableProps<IBaseEntityDto, EntityCreateUpdateDefaultValues>
>;

export interface Props {
  pool: PoolDto;
  value: PoolItemCandidateEntityInputDto | null | undefined;
  /** Entity types that can be selected. */
  onlyEntityTypes?: PoolItemEntityType[];
  excludeEntityTypes?: PoolItemEntityType[];
  entityTypeInputProps?: {
    disabled?: boolean;
  };
  entityAutocompleteOrCreateProps?: Partial<EntityAutocompleteOrCreateProps>;
  disabled?: boolean;
  onChange?: (newValue: PoolItemCandidateEntityInputDto | null | undefined) => void;
}

/** Based on entity type renders corresponding autocomplete with correct filters. */
export default function PoolItemCandidateEntityAutocompleteOrCreate({
  pool,
  value,
  onlyEntityTypes,
  excludeEntityTypes,
  entityTypeInputProps,
  entityAutocompleteOrCreateProps,
  disabled,
  onChange,
}: Props) {
  const poolItemEntityTypes = useMemo(
    () =>
      enumService.getEnumValues("PoolItemEntityType", {
        only: onlyEntityTypes,
        except: excludeEntityTypes,
        exceptNone: true,
      }),
    [onlyEntityTypes, excludeEntityTypes],
  );

  const handleEntityTypeChange = (entityType: PoolItemEntityType | null | undefined) => {
    const newValue = {
      ...value,
      entityType: entityType || undefined,
      entityId: value?.entityType !== entityType ? undefined : value?.entityType,
    };
    onChange && onChange(!newValue.entityType && !newValue.entityId ? undefined : newValue);
  };

  const handleEntityIdChange = (entityId: string | null | undefined) => {
    onChange && onChange(value ? { ...value, entityId: entityId || undefined } : undefined);
  };

  const entityTypeInput = (
    <DropdownButton
      sx={{
        fontWeight: "inherit",
      }}
      size={"small"}
      disabled={entityTypeInputProps?.disabled || isEmpty(poolItemEntityTypes)}
      buttonProps={{
        disableFocusRipple: true,
        disableElevation: true,
        disableRipple: true,
        disableTouchRipple: true,
      }}
      dropdownContent={
        <MenuList>
          {poolItemEntityTypes.map((enumValue, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleEntityTypeChange(enumValue);
              }}
            >
              <ListItemIcon>
                <AppIcon of='entity' />
              </ListItemIcon>
              <ListItemText>
                <InlineApiEnumValue type='PoolItemEntityType' value={enumValue} />
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      }
    >
      {!value?.entityType && "Select entity type"}
      {value?.entityType && (
        <InlineApiEnumValue type='PoolItemEntityType' value={value?.entityType} />
      )}
    </DropdownButton>
  );

  const entityAutocompleteOrCreatePropsComputed = useMemo<EntityAutocompleteOrCreateProps>(
    () => ({
      autocompleteProps: {
        ...entityAutocompleteOrCreateProps?.autocompleteProps,
        disabled: disabled || isNil(value?.entityType),
        withCreate: true,
        entityId: value?.entityId,
        entity: undefined,
        textFieldProps: {
          InputProps: {
            startAdornment: (
              <InputAdornment sx={{ mx: 0 }} position='start'>
                {entityTypeInput}
              </InputAdornment>
            ),
          },
        },
        onChange: (newValue) => {
          handleEntityIdChange(newValue?.id);
        },

        searchFilters: {
          ...entityAutocompleteOrCreateProps?.autocompleteProps?.searchFilters,

          // only suitable candidate entities
          poolsMeta: {
            onlySuitableCandidatesForPoolId: pool.id,
          },

          // by Asset entity type
          ...(pool.itemEntitySubType?.entityType === EntityType.Asset
            ? { entityType: pool.itemEntitySubType?.assetEntityType || undefined }
            : undefined),
        },
      },
      createFormPlacement: "modal",
      onCreate: (newValue) => {
        handleEntityIdChange(newValue?.id);
      },
    }),
    [value?.entityId, disabled, entityAutocompleteOrCreateProps, entityTypeInput],
  );

  return (
    <Box>
      {/* Select PoolItemEntityType */}
      {!value?.entityType && (
        <BaseEntityAutocompletePlaceholder
          {...entityAutocompleteOrCreatePropsComputed.autocompleteProps}
          withCreate={false}
        />
      )}

      {/* Select CandidateEntity */}
      {value?.entityType && (
        <>
          {renderIf()
            .if(value.entityType === PoolItemEntityType.Accessory)
            .then(<AccessoryAutocompleteOrCreate {...entityAutocompleteOrCreatePropsComputed} />)
            .elseif(value.entityType === PoolItemEntityType.Vehicle)
            .then(<VehicleAutocompleteOrCreate {...entityAutocompleteOrCreatePropsComputed} />)
            .elseif(value.entityType === PoolItemEntityType.Asset)
            .then(<AssetAutocompleteOrCreate {...entityAutocompleteOrCreatePropsComputed} />)
            .else(<>Unknown entity type {value.entityType}.</>)
            .render()}
        </>
      )}
    </Box>
  );
}
