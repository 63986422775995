import { Box } from "@mui/material";
import { Ref, forwardRef, useImperativeHandle, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { useChatCurrentParticipant } from "@/common/hooks/communication/useChatCurrentParticipant";
import { useAppDispatch, useAppThunkDispatch } from "@/common/hooks/redux";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { ChatDto, ChatMessageNodeType, ChatMessageRootNodeDto } from "@/core/api/generated";
import * as chatMessagesSlice from "@/store/communication/chatMessagesSlice";
import * as chatsSlice from "@/store/communication/chatsSlice";

import { ChatSize } from "../Chat/ChatDisplay";
import ChatMessageInput, {
  ChatMessageInputFeaturesProps,
  ChatMessageInputProps,
} from "./Input/ChatMessageInput";

export type ChatMessageSenderHandle = {
  sendMessageAsText: (content: string) => Promise<void>;
};

interface OwnProps {
  chat: ChatDto;
  uploadedAttachments?: FileItem[];
  secondaryActions?: ChatMessageInputProps["secondaryActions"];
  features?: ChatMessageInputFeaturesProps;
  size?: ChatSize;
  disabled?: boolean;
  renderAttachments?: ChatMessageInputProps["renderAttachments"];
  onPasteFiles?: ChatMessageInputProps["onPasteFiles"];
  getUploadedAttachments?: () => FileItem[];
  clearUploadedAttachments?: () => void;
  onSendMessage?: () => void;
}

type Props = OwnProps;

export default forwardRef(function ChatMessageSender(
  {
    chat,
    uploadedAttachments,
    secondaryActions,
    features,
    size,
    disabled,
    renderAttachments,
    onPasteFiles,
    getUploadedAttachments,
    clearUploadedAttachments,
    onSendMessage,
  }: Props,
  ref: Ref<ChatMessageSenderHandle>,
) {
  const dispatch = useAppDispatch();
  const thunkDispatch = useAppThunkDispatch();

  const currentParticipant = useChatCurrentParticipant(chat.id);

  const [validation, setValidation] = useState<ValidationInfo>(new ValidationInfo());

  const handleSendMessage = async (data: { body: string; rootNode: ChatMessageRootNodeDto }) => {
    try {
      await thunkDispatch(
        chatMessagesSlice.sendChatMessage({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          chatId: chat.id!,
          sendChatMessageDto: {
            participantId: currentParticipant?.id,
            body: data.body,
            rootNode: data.rootNode,
            attachments: ((getUploadedAttachments && getUploadedAttachments()) || [])
              ?.filter((x) => !!x.file)
              ?.map((x) => FileItem.toGeneralAttachmentInputDto(x)),
          },
        }),
      );
      setValidation(new ValidationInfo());
      clearUploadedAttachments && clearUploadedAttachments();
      onSendMessage && onSendMessage();
    } catch (err) {
      setValidation(ValidationHelper.handleApiErrorResponse(err));
      throw err;
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      sendMessageAsText: async (content: string) => {
        try {
          await handleSendMessage({
            body: content,
            rootNode: {
              nodes: [
                {
                  type: ChatMessageNodeType.Text,
                  text: {
                    text: content,
                  },
                },
              ],
            },
          });
        } catch (err) {
          console.error("Error sending message as text", err);
        }
      },
    }),
    [],
  );

  return (
    <Box>
      <ChatMessageInput
        chatId={chat.id!}
        disabled={!chat.settings!.allowSendMessages || disabled}
        size={size}
        placeholder='Write a message...'
        actionText='Reply'
        validation={validation}
        hasUploadedAttachments={uploadedAttachments && uploadedAttachments.length !== 0}
        secondaryActions={secondaryActions}
        features={features}
        renderAttachments={renderAttachments}
        onFocus={() =>
          dispatch(
            chatsSlice.setChatIsEnterMessageAreaFocused({
              chatId: chat.id!,
              isEnterMessageAreaFocused: true,
            }),
          )
        }
        onBlur={() =>
          dispatch(
            chatsSlice.setChatIsEnterMessageAreaFocused({
              chatId: chat.id!,
              isEnterMessageAreaFocused: false,
            }),
          )
        }
        onPasteFiles={onPasteFiles}
        onAction={handleSendMessage}
      />
    </Box>
  );
});
