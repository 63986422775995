import { Stack, StackProps } from "@mui/material";

interface Props extends StackProps {}

export default function PageTabContent({ children, sx, ...otherProps }: Props) {
  return (
    <Stack
      className='AppPageTabContent'
      gap={1.5}
      {...otherProps}
      sx={{
        width: "100%",
        height: "fit-content",
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
