import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { debounce, throttle } from "lodash-es";

import { ProfileDto, ProfileRolesDto } from "../../core/api/generated";
import { apiClient } from "./../../core/api/ApiClient";
import { AppDispatchType, AppThunk } from "./../index";

interface ProfileState {
  loading: {
    get?: boolean;
    getRoles?: boolean;
    update?: boolean;
  };
  profile?: ProfileDto;
  profileRoles?: ProfileRolesDto;
}

const initialState: ProfileState = {
  loading: {},
  profile: undefined,
  profileRoles: undefined,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ProfileState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _profileFetched: (state, action: PayloadAction<ProfileDto>) => {
      state.profile = action.payload;
    },
    _profileRolesFetched: (state, action: PayloadAction<ProfileRolesDto>) => {
      state.profileRoles = action.payload;
    },
    _profileUpdated: (state, action: PayloadAction<ProfileDto>) => {
      state.profile = action.payload;
    },
    profileUpdated: (state, action: PayloadAction<ProfileDto>) => {
      state.profile = action.payload;
    },
  },
});

export const {
  setLoading,
  _profileFetched,
  _profileRolesFetched,
  _profileUpdated,
  profileUpdated,
} = profileSlice.actions;

export const profileReducer = profileSlice.reducer;

// actions

const _getProfile = async (dispatch: AppDispatchType): Promise<ProfileDto> => {
  dispatch(
    setLoading({
      get: true,
    }),
  );
  try {
    const response = await apiClient.profileApi.apiV1ProfileGet({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    });
    await dispatch(_profileFetched(response.data));
    return response.data;
  } finally {
    dispatch(
      setLoading({
        get: false,
      }),
    );
  }
};
const _getProfileDebounce = debounce(_getProfile, 1000, {
  leading: false,
  trailing: true,
});
const _getProfileThrottle = throttle(_getProfile, 1000, {
  leading: true,
  trailing: false,
});

export const getProfile = (): AppThunk<Promise<ProfileDto>> => async (dispatch) => {
  return await _getProfile(dispatch);
};
export const getProfileDebounce = (): AppThunk<Promise<ProfileDto>> => async (dispatch) => {
  return (await _getProfileDebounce(dispatch))!;
};
export const getProfileThrottle = (): AppThunk<Promise<ProfileDto>> => async (dispatch) => {
  return (await _getProfileThrottle(dispatch))!;
};

export const getProfileRoles =
  (
    ...args: Parameters<typeof apiClient.profileApi.apiV1ProfileRolesGet>
  ): AppThunk<Promise<ProfileRolesDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getRoles: true,
      }),
    );
    try {
      const response = await apiClient.profileApi.apiV1ProfileRolesGet(...args);
      await dispatch(_profileRolesFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getRoles: false,
        }),
      );
    }
  };

export const updateProfile =
  (
    ...args: Parameters<typeof apiClient.profileApi.apiV1ProfilePersonalInfoPut>
  ): AppThunk<Promise<ProfileDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        update: true,
      }),
    );
    try {
      const response = await apiClient.profileApi.apiV1ProfilePersonalInfoPut(...args);
      await dispatch(_profileUpdated(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          update: false,
        }),
      );
    }
  };
