/** Type safe alternative for TypeScript 'as' keyword, which suppresses type checks.
 * Helps casting anonymous objects inline to the target type.
 * Example:
 * interface A { a: number; }
 *
 * No type check:
 * doSomeWork({a: true} as A); // no TS error
 *
 * With type check 1:
 * const a: A = {a: true}; // TS error
 * doSomeWork(a);
 *
 * With type check) 2:
 * doSomeWork(cast<A>({a: true})); // TS error
 */
export function cast<TTarget>(value: TTarget): TTarget {
  return value;
}
