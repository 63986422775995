import {
  Badge,
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { isNil } from "lodash-es";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { FeatureName } from "@/common/constants/featureName";
import { RepairHelper } from "@/common/helpers/entity/repair";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { featureManager } from "@/config/features";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, RepairSpecDto, RepairSpecGetPaginatedDto } from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import GeneralPriceSummaryInline from "../../PriceSummary/GeneralPriceSummaryInline";
import AppTypography from "../../Text/AppTypography";
import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "../components/BaseEntitySearchAutocomplete";
import BaseRepairCustomFilterPopover from "../components/repair/BaseRepairCustomFilterPopover";
import CreateUpdateRepairSpecModal from "./CreateUpdateRepairSpecModal";
import RepairSpecCrossTenantSelectorPopover from "./RepairSpecCrossTenantSelectorPopover";
import RepairSpecDetalizationInline from "./RepairSpecDetalizationInline";
import { getRepairSpecInlineText } from "./RepairSpecInline";
import RepairSpecViewModal from "./View/RepairSpecViewModal";

const defaultDisplayProps = {
  viewInModal: true,
  editInModal: true,
  crossTenantSelector: true,
  customFilter: true,
};

type RepairSpecAutocompleteOption = BaseAutocompleteOption<RepairSpecDto>;

export const getRepairSpecTitle = (data: RepairSpecDto): string => getRepairSpecInlineText(data);

const repairSpecToOption = (data: RepairSpecDto): RepairSpecAutocompleteOption => ({
  id: data.id!,
  title: getRepairSpecTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface RepairSpecAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<RepairSpecDto> {
  searchFilters?: Partial<Omit<RepairSpecGetPaginatedDto, "search" | "includeIds">>;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function RepairSpecAutocomplete({
  searchFilters,
  displayProps,

  isPreload = true,
  ...otherProps
}: RepairSpecAutocompleteProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const authorizationInfo = useAuthorizationInfo();

  const [savedFlags, setSavedFlags] = useState(() => {
    return RepairHelper.getRepairSpecAutocompleteSettings();
  });

  const [filtersApplied, setFiltersApplied] = useState({
    damageType: !!savedFlags.filters.damageType,
    partType: !!savedFlags.filters.partType,
    make: !!savedFlags.filters.make,
    model: !!savedFlags.filters.model,
  });

  const [ignoreCanLoadOnce, setIgnoreCanLoadOnce] = useState(false);

  return (
    <>
      <BaseEntitySearchAutocomplete
        sx={{ minWidth: 200, flex: 1 }}
        entityToOption={repairSpecToOption}
        isPreload={isPreload}
        ignoreCanLoadCondition={ignoreCanLoadOnce}
        onLoaded={() => {
          setIgnoreCanLoadOnce(false);
        }}
        request={{
          requestFunc: apiClient.repairSpecsApi.apiV1RepairSpecsGetPost,
          limit: 25,
          parameters: {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            repairSpecGetPaginatedDto: {
              ...searchFilters,
              customFilter: {
                makeId: filtersApplied.make ? searchFilters?.customFilter?.makeId : undefined,
                modelId: filtersApplied.model ? searchFilters?.customFilter?.modelId : undefined,
                partTypeId: filtersApplied.partType
                  ? searchFilters?.customFilter?.partTypeId
                  : undefined,
                damageTypeId: filtersApplied.damageType
                  ? searchFilters?.customFilter?.damageTypeId
                  : undefined,
              },
            },
          },
          combineParameters: (params, newParams) => ({
            ...params,
            repairSpecGetPaginatedDto: {
              ...params.repairSpecGetPaginatedDto,
              ...newParams,
            },
          }),
          deps: [searchFilters, filtersApplied],
        }}
        label='Repair spec'
        placeholder='Search for repair spec...'
        renderOption={(props, option) => {
          return (
            <ListItem {...props}>
              <ListItemIcon>
                {renderIf()
                  .if(option.optionType === AutocompleteOptionType.Normal)
                  .then(<AppIcon of='repairSpec' />)
                  .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                  .then(<AppIcon of='add' />)
                  .render()}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                      <Box>{option.data?.name || option.title}</Box>

                      {!isNil(option.data?.matchMeta?.score) && (
                        <Box>
                          <Tooltip title='The bigger the match score the better the result suits your request.'>
                            <Typography variant='caption'>
                              <Chip
                                size='extraSmall'
                                variant='filled'
                                label={<>Match score: {option.data?.matchMeta?.score} / 100</>}
                              />
                            </Typography>
                          </Tooltip>
                        </Box>
                      )}
                    </Stack>
                  </Typography>
                }
                secondary={
                  option.data && (
                    <Box>
                      <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                        {option.data?.partType?.name} - {option.data?.damageType?.name}
                      </AppTypography>
                      {option.data.detalization && (
                        <AppTypography
                          ellipsing={{ enabled: true }}
                          component='div'
                          variant='body2'
                        >
                          <RepairSpecDetalizationInline detalization={option.data.detalization} />
                        </AppTypography>
                      )}
                      <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                        {`${enumService.getEnumValueName(
                          "RepairType",
                          option.data?.repairType,
                        )} repair`}
                      </AppTypography>
                      <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                        <GeneralPriceSummaryInline
                          summary={{
                            currency: option.data?.currency,
                            subTotal: option.data?.subTotal,
                            discount: option.data?.discount,
                            tax: option.data?.tax,
                            total: option.data?.total,
                          }}
                        />
                      </AppTypography>

                      {RepairHelper.isExternal({
                        tenantId: currentTenant?.id,
                        repairEntity: option.data,
                      }) && (
                        <Box>
                          <Chip
                            variant='outlined'
                            size='extraSmall'
                            label={
                              <>
                                <AppIcon of='external' inText /> External
                              </>
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  )
                }
              />
            </ListItem>
          );
        }}
        entityViewModal={{
          if:
            displayProps?.viewInModal &&
            authorizationInfo.hasPermissions([AppPermission.RepairCatalogRead]),
          renderModal: (params) => (
            <RepairSpecViewModal
              {...params.dialogProps}
              viewProps={{
                repairSpec: params.entity,
              }}
            />
          ),
        }}
        entityEditModal={{
          if:
            displayProps?.editInModal &&
            authorizationInfo.hasPermissions([AppPermission.RepairCatalogManage]),
          renderModal: (params) => (
            <CreateUpdateRepairSpecModal
              {...params.dialogProps}
              createUpdateProps={{
                repairSpecId: params.entity.id,
                defaultValues: {
                  data: params.entity,
                },
                onSave: (newValue) => {
                  params.handleEntityUpdated(newValue);
                  params.dialogProps?.onClose?.({}, "escapeKeyDown");
                },
              }}
            />
          ),
        }}
        endAdornmentActions={{
          actions: [
            {
              if:
                displayProps?.crossTenantSelector &&
                featureManager.isEnabled(FeatureName.CrossTenantRepairEntityAccess),
              renderAction: (params) => (
                <RepairSpecCrossTenantSelectorPopover
                  clickBehavior={{}}
                  trigger={
                    <AppIconButton
                      {...params.iconButtonProps}
                      title='My cross-company repair specs'
                    >
                      <AppIcon of='crossTenantBrowse' />
                    </AppIconButton>
                  }
                  selectorProps={{
                    repairSpecAutocompleteProps: {
                      searchFilters: { match: searchFilters?.match },
                    },
                    onSave: (newValue) => {
                      console.log("Cross-tenant repair spec selected", newValue);
                      if (newValue?.repairEntity) {
                        params.handleEntitySelected(newValue?.repairEntity);
                      }
                    },
                    onCancel: () => {},
                  }}
                />
              ),
            },
            {
              if: displayProps?.customFilter,
              renderAction: (params) => {
                const activeFiltersCount = Object.values(filtersApplied).filter(Boolean).length;
                const isAnyFilterActive = activeFiltersCount > 0;

                return (
                  <BaseRepairCustomFilterPopover
                    trigger={
                      <AppIconButton
                        {...params.iconButtonProps}
                        color={isAnyFilterActive ? "primary" : "default"}
                        title='Custom Filter'
                      >
                        <AppIcon of='filter' />
                        {isAnyFilterActive && (
                          <Badge
                            badgeContent={activeFiltersCount}
                            color='primary'
                            sx={{ position: "absolute", top: 0, right: 0 }}
                          />
                        )}
                      </AppIconButton>
                    }
                    availableFilters={{
                      damageType: true,
                      partType: true,
                      make: true,
                      model: true,
                    }}
                    initialFlags={filtersApplied}
                    onSave={(newFlags) => {
                      setIgnoreCanLoadOnce(true);
                      setFiltersApplied((prev) => ({
                        ...prev,
                        ...newFlags,
                      }));

                      const mergedSettings = {
                        ...savedFlags,
                        filters: {
                          ...filtersApplied,
                          ...newFlags,
                        },
                      };

                      setSavedFlags(mergedSettings);

                      RepairHelper.saveRepairSpecAutocompleteSettings(mergedSettings);
                    }}
                    onCancel={() => {}}
                  />
                );
              },
            },
          ],
        }}
        {...otherProps}
      />
    </>
  );
}
