import { apiClient } from "@/core/api/ApiClient";
import { VehicleDto } from "@/core/api/generated";

import FormikComputedField from "../../Form/Formik/FormikComputedField";

export type AffiliationInfoDisplayVariant = "normal" | "compact";

interface Props {
  values: {
    vehicleId: Nil<string>;
    vehicle: Nil<VehicleDto>;
    departmentId: Nil<string>;
    locationId: Nil<string>;
  };
  onComputed: (computed: {
    vehicle: Nil<VehicleDto>;
    departmentId: Nil<string>;
    locationId: Nil<string>;
  }) => void;
}

export default function InferEntityAffiliationFromVehicleFormikComputedField({
  values,
  onComputed,
}: Props) {
  return (
    <FormikComputedField<
      typeof values,
      Pick<typeof values, "vehicle" | "departmentId" | "locationId">
    >
      isEnabled={(v) => !v.departmentId || !v.locationId}
      debouncedDeps={{
        deps: [values?.vehicleId],
        wait: 100,
        options: { leading: false, trailing: true },
      }}
      compute={async (v) => {
        let vehicle2 = v.vehicle;
        if (!vehicle2 && v.vehicleId) {
          const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            vehicleId: v.vehicleId!,
          });
          vehicle2 = response.data;
        }

        return {
          vehicle: vehicle2,
          departmentId: vehicle2?.departmentIds?.at(0),
          locationId: vehicle2?.locationIds?.at(0),
        };
      }}
      onComputed={(computed) => {
        onComputed(computed);
      }}
    />
  );
}
