import { Box, SxProps, Theme, Typography } from "@mui/material";
import { isNil, round } from "lodash-es";

import { NUMBER_PRECISION } from "@/common/constants/common";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import {
  CurrencyCode,
  GeneralCurrencyDto,
  GeneralCurrencyInputDto,
  GeneralTaxDto,
  GeneralTaxInputDto,
  TaxValueType,
} from "@/core/api/generated";

import InlineApiEnumValue from "../../../Enum/InlineApiEnumValue";
import CurrencyValue from "../../../Form/Display/CurrencyValue";
import PercentValue from "../../../Form/Display/PercentValue";

type Props =
  | {
      tax?: GeneralTaxDto | GeneralTaxInputDto | null;
      detailsPlacement?: "inline" | "tooltip";
      currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
      currencyCode?: CurrencyCode | null;
      sx?: SxProps<Theme>;
      showAsCompoundValue?: undefined;
      forValue?: never;
    }
  | {
      tax?: GeneralTaxDto | GeneralTaxInputDto | null;
      detailsPlacement?: "inline" | "tooltip";
      currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
      currencyCode?: CurrencyCode | null;
      sx?: SxProps<Theme>;
      showAsCompoundValue: boolean;
      forValue: {
        subTotal: number;
      };
    };

export default function GeneralTaxDisplay({
  tax,
  detailsPlacement = undefined,
  currency,
  currencyCode,
  sx,
  showAsCompoundValue,
  forValue,
}: Props) {
  const currentCurrency = useCurrentCurrency();
  const currencyComputed: GeneralCurrencyDto | undefined =
    currency || (currencyCode && { code: currencyCode }) || tax?.currency || currentCurrency;
  const isEmpty = !tax || (isNil(tax?.percent) && isNil(tax?.value));

  const taxValue =
    (tax?.valueType === TaxValueType.Value && tax?.value) ||
    (tax?.valueType === TaxValueType.Percent &&
      round((forValue?.subTotal || 1) * (tax?.percent || 0), NUMBER_PRECISION.PERCENT)) ||
    0;
  const taxPercent =
    (tax?.valueType === TaxValueType.Value &&
      round((tax?.value || 0) / (forValue?.subTotal || 1), NUMBER_PRECISION.PERCENT)) ||
    (tax?.valueType === TaxValueType.Percent && tax?.percent) ||
    0;

  return (
    <Box component='span' sx={sx}>
      {isEmpty && `-`}

      {!isEmpty && (
        <>
          {!showAsCompoundValue && (
            <>
              {tax?.valueType === TaxValueType.Percent && <PercentValue value={tax?.percent} />}
              {tax?.valueType === TaxValueType.Value && (
                <CurrencyValue value={tax?.value} currency={currencyComputed} />
              )}
            </>
          )}

          {showAsCompoundValue && (
            <>
              <PercentValue value={taxPercent} />
              {" or "}
              <CurrencyValue value={taxValue} currency={currencyComputed} />
            </>
          )}

          {detailsPlacement === "inline" && (
            <Typography component='span' variant='caption' sx={{ wordBreak: "break-all" }}>
              {" ("}
              <InlineApiEnumValue type='TaxType' value={tax?.type} />
              {")"}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
}
