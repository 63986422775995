import {
  CreateRepairSpecDto,
  RepairMaterialDto,
  RepairSpecDataDto,
  RepairSpecDto,
  RepairSpecSparePartDto,
  RepairWorkDto,
  UpdateRepairSpecDto,
} from "@/core/api/generated";

export class RepairSpecHelper {
  /** Checks if there is any currency mismatch between RepairSpec and RepairMaterial/RepairWork. */
  public static isCurrencyMismatch({
    repairSpec,
    repairMaterial,
    repairWork,
    repairSparePart,
  }: {
    repairSpec: RepairSpecDto | CreateRepairSpecDto | UpdateRepairSpecDto | null | undefined;
    repairMaterial?: RepairMaterialDto | null;
    repairWork?: RepairWorkDto | null;
    repairSparePart?: RepairSpecSparePartDto | null;
  }): boolean {
    const currency1 = repairSpec?.currency;
    const currency2 = repairMaterial?.currency || repairWork?.currency || repairSparePart?.currency;

    return (currency1 && currency2 && currency1.code !== currency2.code) ?? false;
  }

  public static isSourceExternal(params: {
    tenantId: Nil<string>;
    repairSpec: Nil<RepairSpecDto | RepairSpecDataDto>;
  }): boolean {
    return (
      !!params.tenantId &&
      !!params.repairSpec?.sourceTenantId &&
      params.tenantId !== params.repairSpec.sourceTenantId
    );
  }
}
