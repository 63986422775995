import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import { debounce } from "lodash-es";
import { useCallback, useEffect, useMemo, useState } from "react";

import FoldableBlock from "@/common/components/Display/FoldableBlock";
import AccessoriesSelectOrCreate from "@/common/components/Entity/Accessory/AccessoriesSelectOrCreate";
import FileUploader from "@/common/components/Files/FileUploader";
import AppTextArea from "@/common/components/Form/Input/AppTextArea";
import MileageInput from "@/common/components/Form/Input/MileageInput";
import PlateNoInput from "@/common/components/Form/Input/PlateNoInput";
import { FileItem } from "@/common/fileItem";
import { VehicleHelper } from "@/common/helpers/entity/vehicle";
import { FormikHelper } from "@/common/helpers/formik";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";

import EntityAffiliationInput from "../../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../../General/GeneralTag/GeneralAttachedTagsInput";
import GeneralStatusInput from "../../GeneralStatus/common/GeneralStatusInput";
import { VehicleFormikValues } from "../VehicleCreateUpdate";
import DefaultVehicleVisualModelInput from "./DefaultVehicleVisualModelInput";
import VehicleBatteryStateInput from "./VehicleBatteryStateInput";
import VehicleSpecInput from "./VehicleSpecInput";

interface Props {
  vehicleId?: string | null;
}

export default function VehicleGeneralInputs({ vehicleId }: Props) {
  const formikContext = useFormikContext<VehicleFormikValues>();
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } = formikContext;
  const { enqueueSnackbar } = useAppSnackbar();
  const tenantProfile = useTenantProfile();
  const specFormikProps = FormikHelper.getSubProps(formikContext, "spec", (v) => v.spec);
  const batteryStateFormikProps = FormikHelper.getSubProps(
    formikContext,
    "batteryState",
    (v) => v.batteryState,
  );
  const statusFormikProps = FormikHelper.getSubProps(formikContext, "status", (v) => v.status);

  const countryCode = tenantProfile?.settings?.country?.alpha2Code ?? "";
  const isCreate = !vehicleId;

  const plateNumber = (values.plateNo?.plateNo ?? "").trim();
  const identificationNumber = (values.identificationNumber ?? "").trim();

  const [prefillLoading, setPrefillLoading] = useState(false);
  const [lastFetchedPlate, setLastFetchedPlate] = useState<string | null>(null);
  const [lastFetchedVin, setLastFetchedVin] = useState<string | null>(null);
  const fetchPrefillData = useCallback(
    async (plateNo: string, vin: string) => {
      try {
        setPrefillLoading(true);

        const response = await apiClient.vehiclesApi.apiV1VehiclesVehicleDataPrefillGet({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          plateNo: plateNo || undefined,
          identificationNumber: vin || undefined,
        });

        if (response) {
          // Update form values based on response
          if (response.data.plateNo) {
            setFieldValue("plateNo", response.data.plateNo);
            setLastFetchedPlate(response.data.plateNo.plateNo!);
          }
          if (response.data.identificationNumber) {
            setFieldValue("identificationNumber", response.data.identificationNumber);
            setLastFetchedVin(response.data.identificationNumber);
          }
          if (response.data.make) {
            setFieldValue("spec.makeId", response.data.make.id);
          }
          if (response.data.model) {
            setFieldValue("spec.modelId", response.data.model.id);
          }
          if (response.data.bodyType) {
            setFieldValue("spec.bodyType", response.data.bodyType);
          }

          enqueueSnackbar("Vehicle data found successfully! We’ve auto-filled the data below.", {
            variant: "success",
          });
        }
      } catch (error) {
        setLastFetchedPlate(plateNumber);
        setLastFetchedVin(identificationNumber);
        const validation2 = ValidationHelper.handleApiErrorResponse(error);
        if (validation2.hasErrors) {
          enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
        }
      } finally {
        setPrefillLoading(false);
      }
    },
    [enqueueSnackbar, setFieldValue],
  );

  /**
   * Wrap our API-fetching function with debounce.
   * We'll only create this debounced version once (or when dependencies change).
   */
  const debouncedFetch = useMemo(() => debounce(fetchPrefillData, 1000), [fetchPrefillData]);

  useEffect(() => {
    const allowedCountries = VehicleHelper.getAllowedCountriesAlpha2CodesForPrefill();
    // Only trigger prefill if we are in "Create" mode and tenant country is in allowed list
    if (!isCreate || !allowedCountries.includes(countryCode)) {
      return;
    }

    // If both or neither are provided, skip
    if (
      (plateNumber === lastFetchedPlate && identificationNumber === lastFetchedVin) ||
      (!plateNumber && !identificationNumber)
    ) {
      setPrefillLoading(false);
      return;
    }

    // Don’t trigger if these are the *same* as last fetched
    if (
      (plateNumber === lastFetchedPlate && identificationNumber === "") ||
      (identificationNumber === lastFetchedVin && plateNumber === "")
    ) {
      setPrefillLoading(false);
      return;
    }

    if (plateNumber) {
      const isValid = VehicleHelper.isPlateNoFormatValid(countryCode, plateNumber);
      if (!isValid) {
        setPrefillLoading(false);
        return;
      }
    }

    if (identificationNumber) {
      const isValid = VehicleHelper.isIdentificationNumberValid(identificationNumber);
      if (!isValid) {
        setPrefillLoading(false);
        return;
      }
    }

    // Fire our debounced API call
    debouncedFetch(plateNumber, identificationNumber);

    // Cleanup: cancel any pending debounced calls if inputs change quickly
    return () => {
      debouncedFetch.cancel();
    };
  }, [isCreate, countryCode, plateNumber, identificationNumber]);

  return (
    <Stack spacing={1}>
      {/* Tags */}
      <FormControl margin='dense' fullWidth>
        <GeneralAttachedTagsInput
          value={values.tags}
          onChange={(newValue) => {
            setFieldValue("tags", newValue);
          }}
        />
        <FormHelperText error>
          {ValidationHelper.getFormikErrorsAsString(errors.tags, {
            isIncludeNested: false,
          })}
        </FormHelperText>
      </FormControl>

      {/* Plate, VIN */}
      <Stack
        direction={{
          md: "row",
          xxs: "column",
        }}
        spacing={{
          md: 1,
          xxs: 0,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <PlateNoInput
            fullWidth
            error={Boolean(errors?.plateNo)}
            helperText={ValidationHelper.getFormikErrorsAsString(errors?.plateNo)}
            label='Plate No'
            margin='dense'
            name='plateNo'
            onBlur={handleBlur}
            type='text'
            variant='outlined'
            color='primary'
            placeholder='AB1234'
            value={values.plateNo}
            onChange={(newValue) => {
              setFieldValue("plateNo", newValue);
            }}
          />
        </Box>

        <Box sx={{ flex: 1 }}>
          <TextField
            fullWidth
            error={Boolean(touched.identificationNumber && errors.identificationNumber)}
            helperText={touched.identificationNumber && errors.identificationNumber}
            label='Identification number (VIN)'
            margin='dense'
            name='identificationNumber'
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.value || undefined,
                },
              });
            }}
            type='text'
            value={values.identificationNumber || ""}
            variant='outlined'
          />
        </Box>
      </Stack>

      {prefillLoading && (
        <div style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 4 }}>
          <CircularProgress size={20} />
          <FormHelperText>
            {`Searching for your vehicle data by ${
              values.plateNo?.plateNo || values.identificationNumber
            }. This may take a moment.`}
          </FormHelperText>
        </div>
      )}

      {/* Entity affiliation */}
      <EntityAffiliationInput
        department={{
          departmentId: values.departmentId,
          onChange: (d) => {
            setFieldValue("departmentId", d?.id);
            setFieldValue("locationId", undefined);
          },
          error: errors.departmentId,
        }}
        location={{
          locationId: values.locationId,
          onChange: (l) => {
            setFieldValue("locationId", l?.id);
          },
          searchFilters: { departmentId: values.departmentId },
          createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
          isAutoSelectSingleOption: false,
          error: errors.locationId,
          disabled: !values.departmentId,
        }}
      />

      <FormControl margin='dense' fullWidth>
        <GeneralStatusInput
          statusEnumTypeName='VehicleStatus'
          currentValue={values.currentStatus}
          formikProps={statusFormikProps}
        />
        {/* <GeneralStatusDisplayAndInput value={values.newStatus} /> */}
        <FormHelperText error>
          {ValidationHelper.getFormikErrorsAsString(errors.status, {
            isIncludeNested: false,
          })}
        </FormHelperText>
      </FormControl>

      {/* Spec */}
      <FoldableBlock
        defaultIsFolded={false}
        trigger={{
          label: (
            <Typography component='span' variant='subtitle1'>
              Spec *
            </Typography>
          ),
        }}
      >
        <VehicleSpecInput formikProps={specFormikProps} />
      </FoldableBlock>

      {/* Battery state */}
      <FoldableBlock
        defaultIsFolded
        trigger={{
          label: (
            <Typography component='span' variant='subtitle1'>
              Battery state
            </Typography>
          ),
        }}
      >
        <VehicleBatteryStateInput formikProps={batteryStateFormikProps} />
      </FoldableBlock>

      {/* Other fields */}
      <FoldableBlock
        defaultIsFolded={false}
        trigger={{
          label: (
            <Typography component='span' variant='subtitle1'>
              Other
            </Typography>
          ),
        }}
      >
        {/* Mileage */}
        <MileageInput
          error={Boolean(touched.mileage && errors.mileage)}
          fullWidth
          vehicleId={vehicleId ?? undefined}
          name='mileage'
          value={values.mileage}
          onBlur={handleBlur}
          onChange={(newValue) => {
            setFieldValue("mileage", newValue);
          }}
        />

        <FormControl margin='dense' fullWidth error={Boolean(errors.accessoryIds)}>
          <AccessoriesSelectOrCreate
            autocompleteProps={{
              withCreate: true,
              entityIds: values.accessoryIds,
              entities: undefined,
              onChange: (newValues) =>
                setFieldValue("accessoryIds", newValues?.map((x) => x.id!) || []),
            }}
            onCreate={(newValue) => {
              setFieldValue("accessoryIds", [...values.accessoryIds!, newValue.id]);
            }}
          />
          <FormHelperText>
            {errors.accessoryIds && ValidationHelper.getFormikErrorsAsString(errors.accessoryIds)}
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin='dense'>
          <DefaultVehicleVisualModelInput
            defaultVisualModelId={values.defaultVisualModelId}
            isDefaultVisualModelSelectedManually={values.isDefaultVisualModelSelectedManually}
            spec={values.spec}
            onChange={(newValue) => {
              setFieldValue("defaultVisualModelId", newValue?.defaultVisualModel?.id);
              setFieldValue(
                "isDefaultVisualModelSelectedManually",
                newValue?.isDefaultVisualModelSelectedManually,
              );
            }}
          />
        </FormControl>

        <FormControl fullWidth margin='dense'>
          <FileUploader
            multiple
            defaultFiles={FileItem.createManyFrom(
              values.uploadedAttachments || values.initialAttachments || values.attachments,
            )}
            onChange={(newFiles) => {
              setFieldValue("attachments", FileItem.toManyGeneralAttachmentInputDto(newFiles));
              setFieldValue("uploadedAttachments", newFiles);
            }}
            onUploadStarted={() => {
              setFieldValue("isAttachmentFilesUploading", true);
            }}
            onUploadFinished={() => {
              setFieldValue("isAttachmentFilesUploading", false);
            }}
            onValidationStatusChange={(filesValidationStatus) => {
              if (filesValidationStatus)
                setFieldValue(
                  "isAttachmentFilesHaveErrors",
                  Object.values(filesValidationStatus).some((x) => x === false),
                );
            }}
          />
        </FormControl>

        <FormControl margin='dense' fullWidth error={Boolean(touched.notes && errors.notes)}>
          <AppTextArea
            error={Boolean(touched.notes && errors.notes)}
            fullWidth
            helperText={touched.notes && errors.notes}
            mode='notes'
            name='notes'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.notes}
            variant='outlined'
          />
        </FormControl>
      </FoldableBlock>
    </Stack>
  );
}
