import { FormControl, MenuItem, Pagination, Select, Stack, Typography } from "@mui/material";
import { isNil } from "lodash-es";
import { useMemo } from "react";

import { PaginationHelper } from "@/common/helpers/pagination";
import { PaginationInfoDto } from "@/core/api/generated";

import PaginationInfoDisplay from "./PaginationInfoDisplay";

interface Props {
  paginationInfo?: PaginationInfoDto;
  maxPageSize?: number;
  onChange?: (newValue: PaginationInfoDto) => void | Promise<void>;
}

export default function AppPagination({ paginationInfo, maxPageSize, onChange }: Props) {
  const pageSizeOptions = useMemo(
    () =>
      PaginationHelper.pageSizeOptions.filter((x) =>
        !isNil(maxPageSize) ? x <= maxPageSize : true,
      ),
    [maxPageSize],
  );
  const defaultPageSize = useMemo(
    () =>
      pageSizeOptions.includes(PaginationHelper.defaultPageSize)
        ? PaginationHelper.defaultPageSize
        : pageSizeOptions.at(0),
    [pageSizeOptions],
  );

  const pageSize = useMemo<number | undefined>(() => {
    const tempPageSize = paginationInfo?.limit ?? 0;
    return pageSizeOptions.includes(tempPageSize) ? tempPageSize : defaultPageSize;
  }, [paginationInfo, pageSizeOptions]);

  if (!paginationInfo) {
    return null;
  }

  return (
    <Stack
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
        mt: "auto !important",
      }}
      direction='row'
      spacing={4}
    >
      <PaginationInfoDisplay info={paginationInfo} />

      <Pagination
        shape='rounded'
        color='primary'
        size='large'
        count={paginationInfo.totalPages ?? 1}
        page={(paginationInfo.currentPage ?? 0) + 1}
        onChange={(_e, newPage) => {
          const newPageZeroBased = Math.max(newPage - 1, 0); // NB: starts with 1
          onChange &&
            onChange({
              ...paginationInfo,
              offset: newPageZeroBased * (paginationInfo.limit ?? 0),
              limit: paginationInfo.limit ?? 0,
              currentPage: newPageZeroBased,
            });
        }}
      />

      {/* Page size select */}
      <Stack
        sx={{
          alignItems: "center",
        }}
        direction='row'
        spacing={1}
      >
        <Typography variant='body2'>Page size:</Typography>
        <FormControl sx={{ minWidth: 80 }} variant='standard' size='small' margin='none'>
          <Select
            value={pageSize}
            onChange={(e) => {
              // reset pagination when page size is changed
              onChange &&
                onChange({ ...paginationInfo, offset: 0, limit: +e.target.value, currentPage: 0 });
            }}
          >
            {pageSizeOptions.map((x) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
}
