import { useEffect } from "react";

import { TenantProfileDto } from "@/core/api/generated";
import { fetchTenantProfile } from "@/store/tenantProfile/slice";

import { useAppSelector, useAppThunkDispatch } from "../../redux";
import { useCurrentTenantInfo } from "./useCurrentTenantInfo";

/** Returns currently resolved tenant profile (loads it if not loaded). */
export const useTenantProfile = (params?: { isRefetch?: boolean }): Nil<TenantProfileDto> => {
  const dispatch = useAppThunkDispatch();
  const tenantInfo = useCurrentTenantInfo();
  const profile = useAppSelector((x) => x.tenantProfile.profile);

  useEffect(() => {
    if (!profile && tenantInfo?.identifier) {
      dispatch(fetchTenantProfile({ ...params }));
    }
  }, [tenantInfo]);

  return profile;
};
