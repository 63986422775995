import { RepairMaterialDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelectorPopover, {
  BaseRepairEntityCrossTenantSelectorPopoverInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelectorPopover";
import RepairMaterialCrossTenantSelector, {
  RepairMaterialCrossTenantSelectorProps,
} from "./RepairMaterialCrossTenantSelector";

interface Props
  extends BaseRepairEntityCrossTenantSelectorPopoverInheritableProps<RepairMaterialDto> {
  selectorProps: RepairMaterialCrossTenantSelectorProps;
}

export default function RepairMaterialCrossTenantSelectorPopover({
  selectorProps,
  ...otherProps
}: Props) {
  return (
    <BaseRepairEntityCrossTenantSelectorPopover<RepairMaterialDto>
      selectorProps={selectorProps}
      renderSelector={(params) => <RepairMaterialCrossTenantSelector {...params.selectorProps} />}
      {...otherProps}
    />
  );
}
