import { Stack } from "@mui/material";
import { isEmpty, isNil } from "lodash-es";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import NoFilesAlert from "@/common/components/AppAlerts/NoFilesAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AccessoryGrid from "@/common/components/Entity/Accessory/AccessoryGrid";
import GeneralStrictEntityRelationLink from "@/common/components/Entity/General/Display/GeneralStrictEntityRelationLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AssetDto, EntityType } from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import TenantLink from "../../../Tenant/TenantLink";

interface Props {
  asset: AssetDto;
  onUpdate?: () => void;
}

export default function OverviewTabContent({ asset, onUpdate }: Props) {
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);
  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={asset} />}
          />
        }
      >
        <ViewContentBlock spacing={1}>
          <FieldValue label='Entity type' isEmpty={!asset?.entityType}>
            <InlineApiEnumValue type='AssetEntityType' value={asset.entityType} />
          </FieldValue>

          <FieldValue label='Entity' isEmpty={!asset?.entity}>
            <GeneralStrictEntityRelationLink value={asset.entity} />
          </FieldValue>

          <FieldValue label='Name' isEmpty={!asset?.name}>
            {asset?.name}
          </FieldValue>

          <FieldValue label='Description' isEmpty={!asset?.description}>
            {asset?.description}
          </FieldValue>

          <FieldValue label='Owner' isEmpty={!asset?.ownerTenantId}>
            <TenantLink entity={undefined} entityId={asset.ownerTenantId} />
          </FieldValue>
        </ViewContentBlock>

        {/* Affiliation */}
        <ViewContentBlock>
          <ViewContentBlockHeader>
            Affiliation{" "}
            <AppIconButton
              tooltipProps={{ title: "Edit entity affiliation" }}
              onClick={() => setIsUpdateAffiliationModalOpened(true)}
            >
              <AppIcon of='edit' fontSize='small' />
            </AppIconButton>
          </ViewContentBlockHeader>
          <AffiliationInfoDisplay
            tenantId={asset.tenantId}
            departmentIds={asset.departmentIds}
            locationIds={asset.locationIds}
            sx={{ mt: 1 }}
          />
          <UpdateEntityAffiliationCascadeModal
            open={isUpdateAffiliationModalOpened}
            onClose={() => setIsUpdateAffiliationModalOpened(false)}
            entityType={EntityType.Asset}
            entityId={asset.id}
            onUpdate={onUpdate}
          />
        </ViewContentBlock>

        {/* Accessories */}
        <ViewContentBlock>
          <ViewContentBlockHeader>Accessories</ViewContentBlockHeader>

          {isEmpty(asset?.accessories) && <NoDataAlert />}

          <AccessoryGrid accessories={asset?.accessories} />
        </ViewContentBlock>

        {/* Attachments */}
        <ViewContentBlock>
          <ViewContentBlockHeader>Attachments</ViewContentBlockHeader>

          {renderIf()
            .if(!!asset?.attachments && asset.attachments.length !== 0)
            .then(
              <FileListView
                files={FileItem.createManyFrom(asset?.attachments)}
                maxVisibleFiles={10}
                itemWidth={250}
                itemHeight={190}
                sx={{ mt: 2, width: "100%" }}
              />,
            )
            .else(<NoFilesAlert title='No attachments' description={undefined} />)
            .render()}
        </ViewContentBlock>

        {/* Settings */}
        <ViewContentBlock>
          <ViewContentBlockHeader>Settings</ViewContentBlockHeader>

          {isEmpty(asset?.settings) && <NoDataAlert />}

          {asset?.settings && (
            <Stack spacing={1}>
              <FieldValue label='Sync spec' isEmpty={isNil(asset?.settings?.isSyncSpec)}>
                <BooleanValue value={asset.settings?.isSyncSpec} />
              </FieldValue>
            </Stack>
          )}
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
