import AudioFileIcon from "@mui/icons-material/AudioFile";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import VideoFileIcon from "@mui/icons-material/VideoFile";
import { SvgIconTypeMap, SxProps, Theme } from "@mui/material";

import { FileHelper } from "@/common/helpers/file";
import { MimeBaseType } from "@/core/api/generated";

import PdfIcon from "../Icons/Svg/PdfIcon";

export interface Props {
  mimeType?: string;
  color?: SvgIconTypeMap["props"]["color"];
  fontSize?: SvgIconTypeMap["props"]["fontSize"];
  sx?: SxProps<Theme>;
}

export default function FileIcon({ mimeType, color, fontSize, sx }: Props) {
  const baseMimeType = FileHelper.getBaseMimeType(mimeType);

  const iconMap: Partial<Record<string, React.ComponentType<any>>> = {
    [MimeBaseType.Image]: ImageIcon,
    [MimeBaseType.Audio]: AudioFileIcon,
    [MimeBaseType.Video]: VideoFileIcon,
    [MimeBaseType.Text]: InsertDriveFileIcon,
    ["application/pdf"]: PdfIcon,
  };

  const IconEl = iconMap[mimeType!] || iconMap[baseMimeType] || InsertDriveFileIcon;

  return <IconEl color={color} fontSize={fontSize} sx={sx} />;
}
