export class LocalStorageKey {
  public static enumCatalog = "enumCatalog";
  public static lastAuthenticatedUserInfo = "lastAuthenticatedUserInfo";
  public static userI18nInfo = "userI18nInfo";
  public static requestParamsStateAggregated = "requestParamsStateAggregated";
  public static tabularStateAggregated = "tabularStateAggregated";
  public static viewParamsStateAggregated = "viewParamsStateAggregated";
  public static teslaAuth = "teslaAuth";
  /** @deprecated Use `repairEntitySettings`. Delete after 2025-02-25  */
  public static repairSpecSettings = "repairSpecSettings";
  public static repairEntitySettings = "repairEntitySettings";
}
