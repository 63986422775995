import { Chip } from "@mui/material";
import { ReactNode, useMemo } from "react";

import { EntityHelper } from "@/common/helpers/entity";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralAttachedTagsDto, IBaseEntityDto, TagEntityType } from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../Entity/General/GeneralTag/GeneralAttachedTagsDisplay";
import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import TextLineSkeleton from "../Skeleton/TextLineSkeleton";

interface Props {
  entityType: TagEntityType;
  entity: IBaseEntityDto | null | undefined;
  entityId?: string | null;
  noDataPlaceholder?: ReactNode;
  /** Set to non-nullable value to enable edit. */
  edit?: {
    onSaved: (newTags: GeneralAttachedTagsDto | null | undefined) => void | Promise<void>;
  };
}

/** Displays entity tags. */
export default function EntityTagsInfoDisplay({
  entityType,
  entity,
  entityId,
  noDataPlaceholder,
  edit,
}: Props) {
  const entityIdComputed = useMemo(() => entityId || entity?.id, [entityId, entity]);
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);

  const shouldFetchTags = !entityMeta?.tags && !!entityId;
  const entityTagsRequest = useApiRequest(
    apiClient.generalTagsApi.apiV1TagsGeneralForEntityGetGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType: entityType,
      entityId: entityId || "",
    },
    {
      deps: [entityType, entityId],
      skip: !shouldFetchTags,
    },
  );
  const entityTags = entityTagsRequest?.data;

  const tags = useMemo(() => entityTags || entityMeta?.tags, [entityMeta, entityTags]);

  if (entityTagsRequest.isLoading && !entityTagsRequest.isFirstEnded) {
    return <TextLineSkeleton />;
  }

  return (
    <>
      {/* {isEmpty(tags?.tags) && (noDataPlaceholder || <NoDataAlert title='No tags' />)} */}

      <AppPopover
        hoverBehavior={{
          closeBehavior: "onTriggerOrContentMouseLeave",
        }}
        trigger={
          <Chip
            variant='filled'
            color='default'
            size='medium'
            sx={{ minWidth: "32px" }}
            label={tags?.tags?.length ?? 0}
          />
        }
        contentSx={{
          minWidth: {
            xxs: "95vw",
            md: 200,
          },
          maxWidth: {
            xxs: "95vw",
            md: 300,
          },
        }}
      >
        <AppPopoverContent>
          <GeneralAttachedTagsDisplay
            tags={tags}
            edit={
              edit && entityIdComputed
                ? {
                    entityType: entityType,
                    entityId: entityIdComputed,
                    onSaved: edit.onSaved,
                  }
                : undefined
            }
          />
        </AppPopoverContent>
      </AppPopover>
    </>
  );
}
