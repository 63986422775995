import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { TenantDto } from "@/core/api/generated";

import { AppThunk } from "..";

interface TenantsState {
  loading: {
    getCurrentTenant?: boolean;
    getTenants?: boolean;
    importFromCatalog?: boolean;
  };
  /** Automatically resolved tenant*/
  currentTenant?: TenantDto;
  /** Tenant selected by user */
  selectedTenant?: TenantDto;
}

const initialState: TenantsState = {
  loading: {},
  currentTenant: undefined,
  selectedTenant: undefined,
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<TenantsState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _currentTenantFetched: (state, action: PayloadAction<TenantDto>) => {
      state.currentTenant = action.payload;
    },
    _currentTenantCleared: (state) => {
      state.currentTenant = undefined;
    },
    _currentTenantSelected: (state, action: PayloadAction<TenantDto>) => {
      state.selectedTenant = action.payload;
    },
  },
});

export const { setLoading, _currentTenantFetched, _currentTenantCleared, _currentTenantSelected } =
  tenantsSlice.actions;

export const tenantsReducer = tenantsSlice.reducer;

export const selectTenant =
  (tenant: TenantDto): AppThunk =>
  async (dispatch) => {
    await dispatch(_currentTenantSelected(tenant));
  };

export const fetchCurrentTenant =
  (params?: { isRefetch?: boolean }): AppThunk =>
  async (dispatch, getState) => {
    // enure fetched once
    const isLoading = getState().tenants.loading.getCurrentTenant;
    const currentTenant = getState().tenants.currentTenant;
    if ((isLoading || currentTenant) && !params?.isRefetch) {
      return currentTenant;
    }

    dispatch(
      setLoading({
        getCurrentTenant: true,
      }),
    );

    try {
      const response = await apiClient.profileApi.apiV1ProfileTenantsCurrentGet({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      });
      await dispatch(_currentTenantFetched(response.data));

      // ensure current tenant is set as selected
      if (getState().tenants.selectedTenant?.id !== response.data?.id) {
        await dispatch(selectTenant(response.data));
      }
    } finally {
      dispatch(
        setLoading({
          getCurrentTenant: false,
        }),
      );
    }
  };

export const clearCurrentTenant = (): AppThunk => async (dispatch) => {
  dispatch(_currentTenantCleared());
};
