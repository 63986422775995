import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { debounce } from "lodash-es";
import moment from "moment";
import uuid4 from "uuid4";

import { DatetimeHelper } from "@/common/helpers/datetime";
import { apiClient } from "@/core/api/ApiClient";
import {
  ChatActivityOverviewDto,
  ChatActivityOverviewParticipantInfoDto,
  ChatActivityPerformedDto,
  ChatEventDto,
  ChatMessageDto,
  GeneralScopeRequestDto,
} from "@/core/api/generated";

import { AppDispatchType, AppThunk } from "..";

function recalculateChatActivity(activity: ChatActivityOfCurrentUser): void {
  const lastMessageAt = (activity.lastMessageAt && moment(activity.lastMessageAt)) || undefined;
  const lastEventAt = (activity.lastEventAt && moment(activity.lastEventAt)) || undefined;
  const lastUserInteractionAt =
    (activity.currentParticipant?.lastUserInteractionAt &&
      moment(activity.currentParticipant.lastUserInteractionAt)) ||
    undefined;

  const participantLastActivityAt =
    (activity.currentParticipant?.lastActivityAt &&
      moment(activity.currentParticipant.lastActivityAt)) ||
    undefined;

  const lastUserActivityAt =
    lastUserInteractionAt || participantLastActivityAt || DatetimeHelper.getMinDateAsMoment();

  activity.hasNewMessages = lastMessageAt?.isAfter(lastUserActivityAt) || false;
  activity.hasNewEvents = lastEventAt?.isAfter(lastUserActivityAt) || false;
  activity.hasNewActivity = activity.hasNewMessages || activity.hasNewEvents;
}

/** Activity of current user */
export interface ChatActivityOfCurrentUser {
  lastActivityAt?: ChatActivityOverviewDto["lastActivityAt"];
  lastEventAt?: ChatActivityOverviewDto["lastEventAt"];
  lastMessageAt?: ChatActivityOverviewDto["lastMessageAt"];

  currentParticipant?: {
    lastActivityAt?: ChatActivityOverviewParticipantInfoDto["lastActivityAt"];
    lastEventAt?: ChatActivityOverviewParticipantInfoDto["lastEventAt"];
    lastMessageAt?: ChatActivityOverviewParticipantInfoDto["lastMessageAt"];

    /** Whether user currently interacting with the chat (clicking, typing, chat is focused, etc). */
    isUserInteracting?: boolean;
    lastUserInteractionAt?: string | null;
  };

  // relatively to current participant
  hasNewActivity: boolean;
  hasNewMessages: boolean;
  hasNewEvents: boolean;
}

export interface ChatActivityOverviewState {
  /** chatId:data */
  chatActivityMap: Record<string, ChatActivityOfCurrentUser>;
}

const internalState = {
  /** {{chatId}: boolean} */
  queuedGetChatActivityOverviewMap: {} as Record<string, boolean>,
  /** {{randomId}: scope} */
  queuedGetChatActivityOverviewByScopesMap: {} as Record<string, GeneralScopeRequestDto>,
};

const initialState: ChatActivityOverviewState = {
  chatActivityMap: {},
};

const chatActivityOverviewSlice = createSlice({
  name: "chatActivityOverview",
  initialState,
  reducers: {
    _chatActivityOverviewFetched: (state, action: PayloadAction<ChatActivityOverviewDto>) => {
      let activity = state.chatActivityMap[action.payload.chatId!] || {};

      activity = {
        ...activity,
        ...action.payload,
      };
      activity.currentParticipant = activity.currentParticipant || {};
      activity.currentParticipant = {
        ...activity.currentParticipant,
        ...(action.payload.currentParticipant || {}),
      };

      recalculateChatActivity(activity);
      state.chatActivityMap[action.payload.chatId!] = activity;
    },

    trackChatUserInteractionStarted: (
      state,
      action: PayloadAction<{ chatId?: string | null; participantId?: string | null }>,
    ) => {
      if (!action.payload.chatId) {
        return;
      }
      const activity = state.chatActivityMap[action.payload.chatId] || {};
      activity.currentParticipant = activity.currentParticipant || {};
      activity.currentParticipant.isUserInteracting = true;
      activity.currentParticipant.lastUserInteractionAt = moment().format();
      recalculateChatActivity(activity);
      state.chatActivityMap[action.payload.chatId] = activity;
    },
    trackChatUserInteractionEnded: (state, action: PayloadAction<{ chatId?: string | null }>) => {
      if (!action.payload.chatId) {
        return;
      }
      const activity = state.chatActivityMap[action.payload.chatId] || {};
      activity.currentParticipant = activity.currentParticipant || {};
      activity.currentParticipant.isUserInteracting = false;
      activity.currentParticipant.lastUserInteractionAt = moment().format();
      recalculateChatActivity(activity);
      state.chatActivityMap[action.payload.chatId] = activity;
    },
    trackMyChatActivity: (
      state,
      action: PayloadAction<{
        chatId?: string | null;
        message?: ChatMessageDto;
        event?: ChatEventDto;
      }>,
    ) => {
      if (!action.payload.chatId) {
        return;
      }
      const activity = state.chatActivityMap[action.payload.chatId] || {};
      activity.currentParticipant = activity.currentParticipant || {};

      const date =
        action.payload.message?.createdAt || action.payload.event?.createdAt || undefined;
      activity.lastActivityAt = date ? moment(date).format() : moment().format();

      if (action.payload.message) {
        activity.lastMessageAt = moment(action.payload.message.createdAt).format();
      }
      if (action.payload.event) {
        activity.lastEventAt = moment(action.payload.event.createdAt).format();
      }
      recalculateChatActivity(activity);
      state.chatActivityMap[action.payload.chatId] = activity;
    },
    chatActivityPerformed: (state, action: PayloadAction<ChatActivityPerformedDto>) => {
      const activity = state.chatActivityMap[action.payload.chatId!] || {};

      activity.lastActivityAt = action.payload.activityOverview!.lastActivityAt;
      activity.lastEventAt = action.payload.activityOverview!.lastEventAt;
      activity.lastMessageAt = action.payload.activityOverview!.lastMessageAt;

      recalculateChatActivity(activity);
      state.chatActivityMap[action.payload.chatId!] = activity;
    },
  },
});

export const {
  _chatActivityOverviewFetched,
  trackChatUserInteractionStarted,
  trackChatUserInteractionEnded,
  trackMyChatActivity,
  chatActivityPerformed,
} = chatActivityOverviewSlice.actions;

export const chatActivityOverviewReducer = chatActivityOverviewSlice.reducer;

export const getChatActivityOverview =
  (
    ...arg: Parameters<
      typeof apiClient.chatActivityOverviewsApi.apiV1ChatActivityOverviewsByChatChatIdGet
    >
  ): AppThunk<Promise<ChatActivityOverviewDto>> =>
  async (dispatch) => {
    const response =
      await apiClient.chatActivityOverviewsApi.apiV1ChatActivityOverviewsByChatChatIdGet(...arg);
    if (response.data) {
      await dispatch(_chatActivityOverviewFetched(response.data));
    }
    return response.data;
  };

export const getChatActivityOverviewByScope =
  (
    ...arg: Parameters<
      typeof apiClient.chatActivityOverviewsApi.apiV1ChatActivityOverviewsGetByScopePost
    >
  ): AppThunk<Promise<ChatActivityOverviewDto>> =>
  async (dispatch) => {
    const response =
      await apiClient.chatActivityOverviewsApi.apiV1ChatActivityOverviewsGetByScopePost(...arg);
    if (response.data) {
      await dispatch(_chatActivityOverviewFetched(response.data));
    }
    return response.data;
  };

const fetchQueuedGetChatActivityOverviews = async (dispatch: AppDispatchType) => {
  const chatIds = Object.keys(internalState.queuedGetChatActivityOverviewMap);
  if (chatIds.length === 0) {
    return;
  }

  const response = await apiClient.chatActivityOverviewsApi.apiV1ChatActivityOverviewsByChatsGet({
    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    chatIds: chatIds,
  });
  if (response.data && response.data.length !== 0) {
    response.data.forEach((data) => {
      dispatch(_chatActivityOverviewFetched(data));
    });
  }
  chatIds.forEach((chatId) => {
    delete internalState.queuedGetChatActivityOverviewMap[chatId];
  });
};
const fetchQueuedGetChatActivityOverviewsDebounce = debounce(
  fetchQueuedGetChatActivityOverviews,
  1000,
  { leading: false, trailing: true },
);

const fetchQueuedGetChatActivityOverviewByScopes = async (dispatch: AppDispatchType) => {
  const keys = Object.keys(internalState.queuedGetChatActivityOverviewByScopesMap);
  const scopeRequests = Object.values(internalState.queuedGetChatActivityOverviewByScopesMap);
  if (scopeRequests.length === 0) {
    return;
  }

  const response =
    await apiClient.chatActivityOverviewsApi.apiV1ChatActivityOverviewsGetByScopesPost({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      getChatActivityOverviewByScopesDto: {
        scopes: scopeRequests,
      },
    });
  if (response.data && response.data.length !== 0) {
    response.data.forEach((data) => {
      dispatch(_chatActivityOverviewFetched(data));
    });
  }
  keys.forEach((key) => {
    delete internalState.queuedGetChatActivityOverviewMap[key];
  });
};
const fetchQueuedGetChatActivityOverviewByScopesDebounce = debounce(
  fetchQueuedGetChatActivityOverviewByScopes,
  1000,
  { leading: false, trailing: true },
);

export const queueGetChatActivityOverview =
  (chatId: string): AppThunk<void> =>
  (dispatch) => {
    internalState.queuedGetChatActivityOverviewMap[chatId] = true;
    fetchQueuedGetChatActivityOverviewsDebounce(dispatch);
  };

export const queueGetChatActivityOverviewByScope =
  (scopeRequest: GeneralScopeRequestDto): AppThunk<void> =>
  (dispatch) => {
    internalState.queuedGetChatActivityOverviewByScopesMap[uuid4()] = scopeRequest;
    fetchQueuedGetChatActivityOverviewByScopesDebounce(dispatch);
  };
