import { Box } from "@mui/material";
import { isNil } from "lodash-es";
import { useMemo } from "react";

import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import RepairMaterialLink from "@/common/components/Entity/RepairMaterial/RepairMaterialLink";
import RepairWorkLink from "@/common/components/Entity/RepairWork/RepairWorkLink";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import { IdHelper } from "@/common/helpers/id";
import { renderIf } from "@/common/helpers/render/renderIf";
import { getTypedPath } from "@/common/helpers/typedPath";
import {
  RepairSpecDataDto,
  RepairSpecDto,
  RepairSpecItemDataDto,
  RepairSpecItemType,
} from "@/core/api/generated";

import DataTabular from "../../DataTabular/DataTabular";
import GeneralDiscountDisplay from "../General/GeneralDiscount/GeneralDiscountDisplay";
import RepairSparePartLink from "../RepairSparePart/RepairSparePartLink";
import RepairSpecItemTypeInline from "./RepairSpecItemTypeInline";

interface OwnProps {
  repairSpec: RepairSpecDto | RepairSpecDataDto;
}

type Props = OwnProps;

export default function RepairSpecItemDataList({ repairSpec }: Props) {
  // Use useMemo to cache localId for items without a backend id
  const itemsWithLocalIds = useMemo(() => {
    return (
      repairSpec.items?.map((item) => ({
        ...item,
        localId: item.id ? undefined : IdHelper.newUuid4(),
      })) ?? []
    );
  }, [repairSpec.items]);

  return (
    <Box>
      <DataTabular
        columns={[
          {
            field: "type",
            title: "Type",
            width: 150,
            renderCell: (item) => (
              <Box>
                <RepairSpecItemTypeInline type={item.type} sourceType={item.sourceType} />
              </Box>
            ),
          },
          {
            field: "typeDetailed",
            title: "Name",
            width: 200,
            renderCell: (item) => (
              <>
                {renderIf()
                  .if(item.type === RepairSpecItemType.Material)
                  .then(<RepairMaterialLink entity={item.material} withIcon withTooltip />)
                  .elseif(item.type === RepairSpecItemType.Work)
                  .then(<RepairWorkLink entity={item.work} withIcon withTooltip />)
                  .elseif(item.type === RepairSpecItemType.SparePart)
                  .then(
                    <RepairSparePartLink entity={item.sparePart?.sparePart} withIcon withTooltip />,
                  )
                  .elseif(item.type === RepairSpecItemType.Custom)
                  .then(<Box>{item.custom?.name || "-"}</Box>)
                  .else("-")
                  .render()}
              </>
            ),
          },
          {
            field: "isDividable",
            title: "Is dividable",
            width: 100,
            renderCell: (item) => <BooleanValue value={item.isDividable} />,
          },
          {
            field: "price",
            title: "Price",
            width: 100,
            renderCell: (item) => (
              <CurrencyValue
                value={item.price}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
              />
            ),
          },
          {
            field: getTypedPath<RepairSpecItemDataDto>().expendablesPercent.$path,
            title: "Expendables",
            width: 140,
            renderCell: (item) =>
              !isNil(item.expendablesPercent) ? (
                <PercentValue value={item.expendablesPercent} />
              ) : (
                "-"
              ),
          },
          {
            field: "spotFactor",
            title: "Spot factor",
            width: 100,
            renderCell: (item) => <PercentValue value={item.spotFactor} />,
          },
          {
            field: "amount",
            title: "Amount",
            width: 100,
            renderCell: (item) => <>{item.amount}</>,
          },
          {
            field: "subTotal",
            title: "Sub total",
            width: 100,
            renderCell: (item) => (
              <CurrencyValue
                value={item.subTotal}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
              />
            ),
          },
          {
            field: "discount",
            title: "Discount",
            width: 100,
            renderCell: (item) => (
              <GeneralDiscountDisplay
                discount={item.discount}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
                detailsPlacement='tooltip'
              />
            ),
          },
          {
            field: "tax",
            title: "Tax",
            width: 100,
            renderCell: (item) => (
              <GeneralTaxDisplay
                tax={item.tax}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
                detailsPlacement='tooltip'
              />
            ),
          },
          {
            field: "total",
            title: "Total",
            width: 100,
            renderCell: (item) => (
              <CurrencyValue
                value={item.total}
                currency={
                  item.material?.currency || item.work?.currency || item.sparePart?.currency
                }
              />
            ),
          },
        ]}
        rows={itemsWithLocalIds}
        getRowId={(item) => item.id ?? item.localId!}
        rowTo={undefined}
        borderVariant='bordered'
        // getRowHeight={() => "auto"}
        isColumnSelectorEnabled={false}
        isColumnMenuEnabled={false}
        isPaginationEnabled={false}
        // renderRowStart={(item) =>
        //   RepairSpecHelper.isCurrencyMismatch({
        //     repairSpec,
        //     repairMaterial: item.material,
        //     repairWork: item.work,
        //   }) ? (
        //     <CurrencyMismatchAlert
        //       repairSpec={repairSpec}
        //       repairMaterial={item.material}
        //       repairWork={item.work}
        //     />
        //   ) : undefined
        // }
      />
    </Box>
  );
}
