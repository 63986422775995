import { isBoolean, isFunction, isNil, isNumber } from "lodash-es";
import moment, { Moment } from "moment";

export type ShortPollingIntervalMs<TData> =
  | number
  | ((context: ShortPollingContext<TData>) => number | null);

export type ShortPollingIfCondition<TData> =
  | boolean
  | ((context: ShortPollingContext<TData>) => boolean);

export type ShortPollingRequestFunc<TData> = (...args: any[]) => Promise<void>;

export interface ShortPollingContext<TData> {
  /** The number of requests sent so far. */
  // readonly requestCount: number;
  /** The number of Short Polling requests sent so far. */
  readonly pollRequestCount: number;
  /** Datetime of the very first request. */
  // readonly firstRequestStartedAt: Moment | null;
  /** Datetime of the last request. */
  // readonly lastRequestStartedAt: Moment | null;
  /** Datetime of the very first request. */
  readonly firstPollRequestStartedAt: Moment | null;
  /** Datetime of the last request. */
  readonly lastPollRequestStartedAt: Moment | null;
  /** The amount of time in milliseconds elapsed from the first request so far. */
  // readonly elapsedMs: number;
  /** Data from HTTP response body. */
  readonly data?: TData | null;
}

export interface ShortPollingOptions<TData> {
  /** Polling interval.
   * The amount of time in milliseconds to wait before the next poll request. `null` tells to stop polling.
   * Counts from the time of the last request.
   * If request happens earlier due to deps change polling is re-scheduled. */
  intervalMs: ShortPollingIntervalMs<TData>;
  /** Allows to start/end polling based on some condition.
   *  If boolean is provided, it's tracked for change.
   *  If function is provided, it's mot tracked for change.
   *  If true, null or undefined - start polling.
   *  If false - end polling.
   */
  if?: ShortPollingIfCondition<TData>;
  /** Whether additional logs enabled. */
  isLoggingEnabled?: boolean;
  /** Called when poll request starts. */
  onRequestStart?: () => void;
  /** Called when poll request ends. */
  onRequestEnd?: () => void;
}

export class ShortPolling<TData = unknown> {
  private logPrefix: string = "ShortPolling.";
  private isLoggingEnabled: boolean = false;

  private options: ShortPollingOptions<TData>;
  private isStarted: boolean = false;
  private prevIntervalMs: number | null = null;
  private firstPollRequestStartedAt: Moment | null = null;
  private lastPollRequestStartedAt: Moment | null = null;
  private pollIntervalHandle: NodeJS.Timeout | null = null;
  private pollRequestCount: number = 0;
  private data: TData | null = null;
  private requestFunc: ShortPollingRequestFunc<TData> | null = null;

  constructor(options: ShortPollingOptions<TData>) {
    this.isLoggingEnabled = isNil(options.isLoggingEnabled) ? true : options.isLoggingEnabled;
    this.options = options;
  }

  public setData(data: TData | null): void {
    this.data = data;
  }

  public setRequestFunc(requestFunc: ShortPollingRequestFunc<TData>): void {
    this.requestFunc = requestFunc;
  }

  public getContext(): ShortPollingContext<TData> {
    return {
      pollRequestCount: this.pollRequestCount,
      firstPollRequestStartedAt: this.firstPollRequestStartedAt,
      lastPollRequestStartedAt: this.lastPollRequestStartedAt,
      data: this.data,
    };
  }

  public getPollInterval(intervalMs: ShortPollingIntervalMs<TData>): number | null {
    return (
      (isNumber(intervalMs) && intervalMs) ||
      (isFunction(intervalMs) && intervalMs(this.getContext())) ||
      null
    );
  }

  public getPollIfBooleanCondition(
    ifCondition: ShortPollingIfCondition<TData> | undefined | null,
  ): boolean | null {
    if (isBoolean(ifCondition)) {
      return ifCondition;
    } else {
      return null;
    }
  }

  public getPollIfCondition(
    ifCondition: ShortPollingIfCondition<TData> | undefined | null,
  ): boolean | null {
    if (isNil(ifCondition)) {
      return null;
    } else if (isFunction(ifCondition)) {
      return ifCondition(this.getContext());
    } else if (isBoolean(ifCondition)) {
      return ifCondition;
    } else {
      return null;
    }
  }

  public start(): void {
    if (this.pollIntervalHandle) {
      return;
    }

    const intervalMs = this.getPollInterval(this.options.intervalMs);
    const ifBooleanCondition = this.getPollIfBooleanCondition(this.options.if);

    const shouldStart = !isNil(intervalMs) && ifBooleanCondition !== false;
    if (!shouldStart) {
      return;
    }

    this.log(this.logPrefix, "Start.", { intervalMs, ifBooleanCondition });
    this.pollIntervalHandle = setInterval(async () => {
      const ifCondition = this.getPollIfCondition(this.options.if);
      this.log(this.logPrefix, `Check if poll request should be executed.`, { ifCondition });
      if (ifCondition === false) {
        return;
      }

      await this.executeRequest();

      const newIntervalMs = this.getPollInterval(this.options.intervalMs);
      if (this.prevIntervalMs !== newIntervalMs) {
        this.restart();
      }
    }, intervalMs || 0);

    this.prevIntervalMs = intervalMs;
  }

  public end(): void {
    this.log(this.logPrefix, "End.");
    clearInterval(this.pollIntervalHandle || undefined);
    this.pollIntervalHandle = null;
  }

  public restart(): void {
    const intervalMs = this.getPollInterval(this.options.intervalMs);
    const ifCondition = this.getPollIfCondition(this.options.if);
    this.log(this.logPrefix, "Restart.", { intervalMs, ifCondition });

    const shouldStop = isNil(intervalMs) || ifCondition === false;
    const shouldReSchedule = !shouldStop;

    if (shouldStop) {
      this.end();
    } else if (shouldReSchedule) {
      this.start();
    }
  }

  private async executeRequest(): Promise<void> {
    if (this.isStarted === true || !this.requestFunc) {
      return;
    }

    this.log(this.logPrefix, `Executing short poll request №${this.pollRequestCount + 1}.`);
    this.isStarted = true;
    if (isNil(this.firstPollRequestStartedAt)) {
      this.firstPollRequestStartedAt = moment();
    }
    this.lastPollRequestStartedAt = moment();
    this.options.onRequestStart && this.options.onRequestStart();

    try {
      await this.requestFunc();
    } finally {
      this.isStarted = false;
      this.pollRequestCount += 1;
      this.options.onRequestEnd && this.options.onRequestEnd();
    }
  }

  private log(...args: Parameters<typeof console.log>): void {
    if (this.isLoggingEnabled) {
      console.log(...args);
    }
  }
}
