import { Box, CircularProgress } from "@mui/material";
import { Suspense, lazy } from "react";
import { Switch } from "react-router";

import { RoutePathBuilder } from "@/common/builders/routePath";
import AppRoute from "@/common/components/Route/AppRoute";

import { ROUTE_PATH } from "../../common/constants/routing";
import AppGuards from "../BaseApp/AppGuards/AppGuards";
import GlobalAlerts from "../BaseApp/GlobalAlerts/GlobalAlerts";
import BaseAppContent from "./BaseAppContent";

// const Loadable = (Component: React.ComponentType) => (props: any) =>
//   (
//     <Suspense fallback={<CircularProgress />}>
//       <Component {...props} />
//     </Suspense>
//   );

// const Login = Loadable(lazy(() => import("../Auth/Login/Login.container")));

const FleetAppContent = lazy(() => import("./FleetAppContent"));
const FleetCustomerAppContent = lazy(() => import("./FleetCustomerAppContent"));
const AdminAppContent = lazy(() => import("./AdminAppContent"));

export default function Content() {
  return (
    <>
      <AppGuards />

      {/* Global alerts */}
      <Box sx={{ width: "100%" }}>
        <GlobalAlerts />
      </Box>

      <Suspense fallback={<CircularProgress />}>
        <Switch>
          {/* FleetApp area */}
          <AppRoute
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_FLEET_APP)}
            render={() => <FleetAppContent />}
          />

          {/* FleetCustomerApp area */}
          <AppRoute
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_FLEET_CUSTOMER)}
            render={() => <FleetCustomerAppContent />}
          />

          {/* AdminApp area */}
          <AppRoute
            path={RoutePathBuilder.toDefinition(ROUTE_PATH.APP_AREA_ADMIN)}
            render={() => <AdminAppContent />}
          />

          {/* Base app (basic routes like auth, errors, etc) */}
          <BaseAppContent />

          {/* <AppRoute exact path='/' render={() => <RootRouteEntry />} /> */}

          {/* <AppRoute path="*" render={() => <NotFound />} /> */}
        </Switch>
      </Suspense>
    </>
  );
}
