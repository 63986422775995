import { FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { getIn } from "formik";
import moment from "moment";
import { useEffect } from "react";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { FileItem } from "@/common/fileItem";
import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import { ValidationHelper } from "@/common/validation";
import {
  AssignmentOperationType,
  AssignmentStepDto,
  AssignmentStepInputDto,
  AssignmentStepType,
  ContractReferenceDto,
  VehicleReferenceDto,
} from "@/core/api/generated";

import FoldableBlock from "../../Display/FoldableBlock";
import FileUploader from "../../Files/FileUploader";
import AppTextArea from "../../Form/Input/AppTextArea";
import VisualInspectionAutocompleteOrCreate from "../VisualInspection/VisualInspectionAutocompleteOrCreate";
import AssignmentStepLocationInput from "./AssignmentStepLocationInput";
import AssignmentStepSettingsInput from "./AssignmentStepSettingsInput";

interface Props {
  formikProps: CustomFormikSubProps<
    | (AssignmentStepInputDto & {
        initialAttachments?: AssignmentStepDto["attachments"];
        isAttachmentFilesHaveErrors?: boolean;
      })
    | null
    | undefined
  >;
  required?: boolean;
  disabled?: boolean;
  assignmentStepType?: AssignmentStepType | undefined | null;
  vehicle?: VehicleReferenceDto | null | undefined;
  contract?: ContractReferenceDto | null | undefined;
  assignmentId?: string | null | undefined;
  setIsAttachmentFilesUploading: (isUploading: boolean) => void;
}
export default function AssignmentStepInput({
  formikProps,
  assignmentStepType,
  required,
  disabled,
  vehicle,
  contract,
  setIsAttachmentFilesUploading,
}: Props) {
  const { values, touched, errors, setFieldValue } = formikProps;

  //set type
  useEffect(() => {
    if (assignmentStepType && values?.type !== assignmentStepType) {
      setFieldValue("type", assignmentStepType);
    }
  }, [assignmentStepType, values?.type, setFieldValue]);
  console.log("values", values);
  return (
    <>
      <TextField
        error={Boolean(touched?.name && errors?.name)}
        fullWidth
        helperText={touched?.name && errors?.name}
        required={required}
        disabled={disabled}
        label='Name'
        margin='dense'
        name='name'
        onChange={(newValue) => setFieldValue("name", newValue.target.value)}
        type='text'
        value={values?.name || ""}
        variant='outlined'
      />

      <FormControl margin='dense' fullWidth>
        <MobileDateTimePicker
          ampm={false}
          label='Date'
          disabled={disabled}
          value={(values?.date && moment(values?.date)) || null}
          format={DATETIME_FORMATS.DISPLAY_DATETIME}
          onChange={(newValue) => {
            setFieldValue("date", newValue?.format() || null);
          }}
          slots={{
            textField: (params) => (
              <TextField {...params} error={Boolean(errors?.date)} helperText={errors?.date} />
            ),
          }}
        />
      </FormControl>

      <FormControl margin='dense' fullWidth>
        <AssignmentStepLocationInput
          formikProps={FormikHelper.getSubProps(formikProps, "location", (v) => v.location)}
          disabled={disabled}
        />
      </FormControl>
      <FormControl
        margin='dense'
        fullWidth
        error={Boolean(getIn(errors, `operations[0].visualInspectionId`))}
      >
        <VisualInspectionAutocompleteOrCreate
          autocompleteProps={{
            required: required,
            disabled: disabled,
            entityId: values?.operations?.[0]?.visualInspectionId ?? undefined,
            searchFilters: {
              contractId: contract?.id,
              vehicleId: vehicle?.id,
              //TODO
              //assignmentId: assignmentId,
            },
            onChange: (newValue) => {
              setFieldValue("operations", [
                {
                  type: AssignmentOperationType.VisualInspection,
                  visualInspectionId: newValue?.id ?? null,
                },
              ]);
            },
          }}
          createFormPlacement='modal'
          onCreate={(newValue) => {
            setFieldValue("operations", [
              {
                type: AssignmentOperationType.VisualInspection,
                visualInspectionId: newValue?.id ?? null,
              },
            ]);
          }}
        />
        <FormHelperText error>
          {ValidationHelper.getFormikErrorsAsString(
            getIn(errors, `operations[0].visualInspectionId`),
            {
              isIncludeNested: false,
            },
          )}
        </FormHelperText>
      </FormControl>
      <FormControl margin='none' fullWidth error={Boolean(touched?.settings && errors?.settings)}>
        <AssignmentStepSettingsInput
          formikProps={FormikHelper.getSubProps(formikProps, "settings", (v) => v.settings)}
          required={required}
          disabled={disabled}
        />
        <FormHelperText>{touched?.settings && errors?.settings}</FormHelperText>
      </FormControl>
      {/* Other */}
      <FoldableBlock
        defaultIsFolded={false}
        trigger={{
          label: <Typography variant='subtitle1'>Other</Typography>,
        }}
      >
        <AppTextArea
          error={Boolean(touched?.notes && errors?.notes)}
          fullWidth
          helperText={touched?.notes && errors?.notes}
          mode='notes'
          margin='dense'
          name='notes'
          onChange={(newValue) => setFieldValue("notes", newValue.target.value)}
          value={values?.notes}
          variant='outlined'
        />

        <FormControl fullWidth component='fieldset' margin='dense'>
          <FileUploader
            multiple
            defaultFiles={FileItem.createManyFrom(values?.initialAttachments)}
            onChange={(newFiles) => {
              setFieldValue(`attachments`, FileItem.toManyGeneralAttachmentInputDto(newFiles));
            }}
            onUploadStarted={() => {
              setIsAttachmentFilesUploading(true);
            }}
            onUploadFinished={() => {
              setIsAttachmentFilesUploading(false);
            }}
            onValidationStatusChange={(filesValidationStatus) => {
              if (filesValidationStatus)
                setFieldValue(
                  "isAttachmentFilesHaveErrors",
                  Object.values(filesValidationStatus).some((x) => x === false),
                );
            }}
          />
        </FormControl>
      </FoldableBlock>
    </>
  );
}
