import { RepairSpecDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelector, {
  BaseRepairEntityCrossTenantSelectorInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelector";
import RepairSpecAutocomplete, { RepairSpecAutocompleteProps } from "./RepairSpecAutocomplete";

export type RepairSpecCrossTenantSelectorProps =
  BaseRepairEntityCrossTenantSelectorInheritableProps<RepairSpecDto> & {
    repairSpecAutocompleteProps?: Partial<RepairSpecAutocompleteProps>;
  };

export default function RepairSpecCrossTenantSelector({
  repairSpecAutocompleteProps,
  ...otherProps
}: RepairSpecCrossTenantSelectorProps) {
  return (
    <BaseRepairEntityCrossTenantSelector<RepairSpecDto>
      repairEntityName={{
        singular: "repair spec",
        plural: "repair specs",
      }}
      renderRepairEntityAutocomplete={(params) => (
        <RepairSpecAutocomplete
          {...params.autocompleteProps}
          {...repairSpecAutocompleteProps}
          searchFilters={{
            ...params.searchFilters,
            ...repairSpecAutocompleteProps?.searchFilters,
          }}
          displayProps={{
            ...params.displayProps,
            ...repairSpecAutocompleteProps?.displayProps,
          }}
        />
      )}
      {...otherProps}
    />
  );
}
