import { FormControl, FormHelperText, Stack, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useState } from "react";

import { ValidationHelper } from "@/common/validation";
import { DepartmentDto, GeneralAddressDto, LocationDto } from "@/core/api/generated";

import { DepartmentAutocompleteProps } from "../Department/DepartmentAutocomplete";
import DepartmentAutocompleteOrCreate from "../Department/DepartmentAutocompleteOrCreate";
import { DepartmentCreateUpdateProps } from "../Department/DepartmentCreateUpdate";
import { LocationAutocompleteProps } from "../Location/LocationAutocomplete";
import LocationAutocompleteOrCreate from "../Location/LocationAutocompleteOrCreate";
import { LocationCreateUpdateProps } from "../Location/LocationCreateUpdate";

interface BaseAffiliationInputProps<
  TEntityDto,
  //  TDefaultValues
  TCreateUpdateProps,
> {
  error?: string;
  disabled?: boolean;
  onChange?: (id: TEntityDto | undefined) => void;
  createUpdateProps?: TCreateUpdateProps;
  // createUpdateDefaultValues?: TDefaultValues;
}

interface DepartmentAffiliationInputProps
  extends BaseAffiliationInputProps<
      DepartmentDto,
      //DepartmentCreateUpdateDefaultValues
      DepartmentCreateUpdateProps
    >,
    Omit<DepartmentAutocompleteProps, "onChange" | "value"> {
  departmentId?: string | null;
}

interface LocationAffiliationInputProps
  extends BaseAffiliationInputProps<
      LocationDto,
      //LocationCreateUpdateDefaultValues
      LocationCreateUpdateProps
    >,
    Omit<LocationAutocompleteProps, "onChange" | "value"> {
  locationId?: string | null;
}

interface EntityAffiliationInputProps {
  department: DepartmentAffiliationInputProps;
  location: LocationAffiliationInputProps;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export default function EntityAffiliationInput({
  department,
  location,
  disabled,
  sx,
}: EntityAffiliationInputProps) {
  const [departmentAddress, setDepartmentAddress] = useState<GeneralAddressDto | null>(null);
  return (
    <FormControl margin='dense' fullWidth error={Boolean(department.error)}>
      <Stack
        direction={{
          md: "row",
          xxs: "column",
        }}
        spacing={{
          md: 1,
          xxs: 0,
        }}
        sx={{ ...sx }}
      >
        <FormControl margin='dense' fullWidth error={Boolean(department.error)}>
          <DepartmentAutocompleteOrCreate
            autocompleteProps={{
              entityId: department.departmentId,
              disabled: disabled || department.disabled,
              isAutoSelectSingleOption: department.isAutoSelectSingleOption,
              isPreload: false, // don't preload as these inputs not frequently accessed
              searchFilters: department.searchFilters,
              textFieldProps: department.textFieldProps,
              onChange: (d) => {
                department.onChange && department.onChange(d || undefined);
                d?.address && setDepartmentAddress(d.address);
              },
            }}
            createFormPlacement='modal'
            createUpdateProps={{ ...department.createUpdateProps }}
            onCreate={(newValue) => {
              department.onChange && department.onChange(newValue || undefined);
              newValue?.address && setDepartmentAddress(newValue.address);
            }}
          />
          {department.error && (
            <FormHelperText error>
              {ValidationHelper.getFormikErrorsAsString(department.error)}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl margin='dense' fullWidth error={Boolean(location.error)}>
          <LocationAutocompleteOrCreate
            autocompleteProps={{
              entityId: location.locationId,
              disabled: disabled || location.disabled,
              isAutoSelectSingleOption: location.isAutoSelectSingleOption,
              isPreload: false, // don't preload as these inputs not frequently accessed
              searchFilters: location.searchFilters,
              textFieldProps: location.textFieldProps,
              onChange: (l) => {
                location.onChange && location.onChange(l || undefined);
              },
            }}
            createFormPlacement='modal'
            createUpdateProps={{
              ...location.createUpdateProps,
              defaultValues: {
                ...location.createUpdateProps?.defaultValues,
                // Assuming department.createUpdateProps.defaultValues.address holds the desired address
                address: departmentAddress!,
              },
            }}
            onCreate={(newValue) => {
              location.onChange && location.onChange(newValue || undefined);
            }}
          />
          {location.error && (
            <FormHelperText error>
              {ValidationHelper.getFormikErrorsAsString(location.error)}
            </FormHelperText>
          )}
        </FormControl>
      </Stack>
    </FormControl>
  );
}
