import { isEmpty, isNil } from "lodash-es";
import { Moment } from "moment";
import moment from "moment-timezone";

import { UnitOfTime } from "@/core/api/generated";

import { DATETIME_FORMATS } from "../constants/common";
import { DurationParts, DurationRepresentation } from "../ts/duration";
import { TimeSpan } from "../ts/timespan";
import { TextHelper } from "./text";

const minDate = new Date(-8640000000000000);
const maxDate = new Date(8640000000000000);

const iso8601DurationRegex = /^P(\d+Y)?(\d+M)?(\d+D)?(T(\d+H)?(\d+M)?(\d+S)?)?$/;
const dotNetTimeSpamRegex =
  /^(?<days>\d+(\.|,|:){1})?(?<time>\d+:\d+:\d+)(?<milliseconds>(\.|,){1}\d+)?$/;

export interface DateRangeDurationHumanizeOptions {
  /** By default, the return string is describing a duration a month (suffix-less). If you want an oriented duration in a month, a month ago (with suffix), pass in true.
   * @default false
   */
  isSuffix?: boolean;
}

export interface DurationHumanizeOptions {
  /** By default, the return string is describing a duration a month (suffix-less). If you want an oriented duration in a month, a month ago (with suffix), pass in true.
   * @default false
   */
  isSuffix?: boolean;
}

export class DatetimeHelper {
  /** Returns min date value. */
  public static getMinDate(): Date {
    return minDate;
  }

  /** Returns min date value. */
  public static getMinDateAsMoment(): Moment {
    return moment(minDate);
  }

  /** Returns max date value. */
  public static getMaxDate(): Date {
    return maxDate;
  }

  /** Returns max date value. */
  public static getMaxDateAsMoment(): Moment {
    return moment(maxDate);
  }

  /** Formats datetime. */
  public static formatDatetime(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }
    return moment(date).format(DATETIME_FORMATS.DISPLAY_DATETIME);
  }

  /** Formats datetime. */
  public static formatDate(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }
    return moment(date).format(DATETIME_FORMATS.DISPLAY_DATE);
  }

  /** Returns duration (always positive) between two dates in user-friendly format.
   *  E.g.: a few seconds, 2 hours, 1 minute, 3 minutes, etc.
   * https://momentjs.com/docs/#/displaying/from/ */
  public static humanizeDateRangeDuration(
    start: string | Moment | null | undefined,
    end: string | Moment | null | undefined,
    options?: DateRangeDurationHumanizeOptions,
  ): string {
    if (!start || !end) {
      return "";
    }

    const startM = moment(start);
    const endM = moment(end);

    // order in [start, end)
    const startM2 = startM.isSameOrBefore(endM) ? startM : endM;
    const endM2 = startM.isAfter(endM) ? startM : endM;

    return startM2.to(endM2, options?.isSuffix ? !options.isSuffix : true);
  }

  /** Returns duration from now to specified date in user-friendly format.
   *  Supports both past and future date.
   *  E.g.: a few seconds ago, 2 hours ago, in 1 minute, in 3 minutes, etc.
   * https://momentjs.com/docs/#/displaying/from/ */
  public static humanizeDateRangeDurationFromNow(
    date: string | Moment | null | undefined,
    options?: DateRangeDurationHumanizeOptions,
  ): string {
    if (!date) {
      return "";
    }

    const dateM = moment(date);
    const nowM = moment();

    const isNow = dateM.isSame(nowM);
    const isPast = dateM.isBefore(nowM);
    const isFuture = dateM.isAfter(nowM);
    const nowText = options?.isSuffix
      ? "just now"
      : dateM.from(nowM, options?.isSuffix ? !options.isSuffix : true);

    return isNow
      ? nowText
      : isPast
        ? dateM.from(nowM, options?.isSuffix ? !options.isSuffix : true)
        : isFuture
          ? nowM.to(dateM, options?.isSuffix ? !options.isSuffix : true)
          : "unknown";
  }

  public static humanizeDateDayFromNow(date?: string | Moment | null): string {
    if (!date) {
      return "";
    }

    const dateM = moment.utc(date).local().startOf("day");
    const todayDateM = moment().startOf("day");

    const diffDays = todayDateM.diff(dateM, "days");
    const diffYears = todayDateM.diff(dateM, "years");

    if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffYears === 0) {
      return dateM.format("MMMM D");
    } else {
      return dateM.format("MMMM D, YYYY");
    }
  }

  //#region Duration

  // NB:
  // As of 2.1.0, moment supports parsing .NET style time spans. https://momentjs.com/docs/#/durations/
  // As of 2.3.0, moment also supports parsing ISO 8601 durations.

  /** Detects DurationRepresentation from provided value.
   *  E.g. either ISO 8601 duration or .NET TimeSpan.
   */
  public static detectDurationRepresentation(value: string): DurationRepresentation {
    if (this.isISO8601Duration(value)) {
      return DurationRepresentation.ISO8601;
    } else if (this.isDotNetTimeSpan(value)) {
      return DurationRepresentation.DotNetTimeSpan;
    } else {
      throw new Error(`Invalid duration value '${value}'. Representation can't be detected.`);
    }
  }

  /** Checks provided value is ISO 8601 duration string.
   *  E.g. P1Y2M10DT2H30M.
   *  https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   *  https://momentjs.com/docs/#/durations/as-iso-string/
   */
  public static isISO8601Duration(value: string | null | undefined): boolean {
    return (
      !isNil(value) &&
      value.length > 0 &&
      iso8601DurationRegex.test(value) &&
      value !== "P" &&
      value !== "PT"
    );
  }

  /** Checks provided value is .NET TImeSpan string.
   * E.g. 7.23:59:59.999, 14:30:15, 1:14:30:15,0000000
   *  https://learn.microsoft.com/en-us/dotnet/api/system.timespan.tostring?view=net-7.0
   */
  public static isDotNetTimeSpan(value: string | null | undefined): boolean {
    return !isNil(value) && value.length > 0 && dotNetTimeSpamRegex.test(value);
  }

  public static toDurationString(
    duration: DurationParts,
    representation: DurationRepresentation,
  ): string {
    if (representation === DurationRepresentation.ISO8601) {
      return DatetimeHelper.toISO8601DurationString(duration);
    } else if (representation === DurationRepresentation.DotNetTimeSpan) {
      return DatetimeHelper.toDotNetTimeSpanString(duration);
    } else {
      throw new Error(`Unknown duration representation '${representation}'.`);
    }
  }

  /** E.g. P1Y2M10DT2H30M. */
  public static toISO8601DurationString(
    duration: Partial<Record<UnitOfTime, number | undefined>>,
  ): string {
    // const year = duration[UnitOfTime.Year];

    const dateParts = [
      duration[UnitOfTime.Year] ? `${duration[UnitOfTime.Year]}Y` : null,
      duration[UnitOfTime.Month] ? `${duration[UnitOfTime.Month]}M` : null,
      duration[UnitOfTime.Day] ? `${duration[UnitOfTime.Day]}D` : null,
    ]
      .filter((x) => !!x)
      .map((x) => x!);

    const timeParts = [
      duration[UnitOfTime.Hour] ? `${duration[UnitOfTime.Hour]}H` : null,
      duration[UnitOfTime.Minute] ? `${duration[UnitOfTime.Minute]}M` : null,
      duration[UnitOfTime.Second] ? `${duration[UnitOfTime.Second]}S` : null,
    ]
      .filter((x) => !!x)
      .map((x) => x!);

    return `P${dateParts.join("")}${timeParts.length !== 0 ? `T${timeParts.join("")}` : ""}`;
  }

  /** Always outputs in the similar format: 7.23:59:59.999 (dots and semicolons are used). */
  public static toDotNetTimeSpanString(
    duration: Partial<Record<UnitOfTime, number | undefined>>,
  ): string {
    return TimeSpan.fromDurationInput(duration).toString();
  }

  /** Parses duration string and converts to number in specified units. */
  public static parseDurationValueAsUnitOfTime(
    duration: string | null | undefined,
    unitOfTime: UnitOfTime,
  ): number | null {
    if (isNil(duration) || isEmpty(duration)) {
      return null;
    }

    if (this.isISO8601Duration(duration)) {
      // moment.duration parses both ISO and .NET TimeSpan representations
      // NB: has issues with converting 30 days to months. I.e. 90 days != 3 months.
      const momentDuration = moment.duration(duration);
      return TimeSpan.fromMomentDuration(momentDuration).toNumber(unitOfTime);
    } else if (this.isDotNetTimeSpan(duration)) {
      return TimeSpan.fromDurationValue(duration).toNumber(unitOfTime);
    } else {
      throw new Error(`Unknown duration value '${duration}'.`);
    }
  }

  /** Parses duration string, suggest the most suitable units and converts to number in suggested units. */
  public static parseDurationValueAsSuggestedUnitOfTime(duration: string | null | undefined): {
    duration: number | null;
    unitOfTime: UnitOfTime | null;
  } {
    if (isNil(duration) || isEmpty(duration)) {
      return { duration: null, unitOfTime: null };
    }

    const timeSpan =
      (this.isISO8601Duration(duration)
        ? TimeSpan.fromMomentDuration(moment.duration(duration))
        : undefined) ||
      (this.isDotNetTimeSpan(duration) ? TimeSpan.fromDurationValue(duration) : undefined) ||
      undefined;
    if (!timeSpan) {
      throw new Error(`Unknown duration value '${duration}'.`);
    }

    const unitOfTime = timeSpan.suggestUnitOfTime();

    return { duration: timeSpan.toNumber(unitOfTime), unitOfTime: unitOfTime };
  }

  // public static toDotNetTimeSpanStringFromMomentDuration(duration: moment.Duration): string {
  //   throw new Error("Not implemented");
  // }

  /** Returns duration in user-friendly format.
   * E.g.: a few seconds, 2 hours, 1 minute, 3 minutes, etc.
   * https://momentjs.com/docs/#/durations/humanize/
   * @param duration - ISO 8601 duration or .NET TimeSpan string */
  public static humanizeDuration(duration: Nil<string>, options?: DurationHumanizeOptions): string {
    if (!duration) {
      return "";
    }
    const durationM = moment.duration(duration);
    return durationM.humanize(options?.isSuffix);
  }

  /** Returns difference in days between two dates.
   * @returns null if any of the dates is null or undefined. */
  public static differenceInDays(
    date1?: string | Moment | null,
    date2?: string | Moment | null,
  ): number | null {
    if (!date1 || !date2) {
      return null;
    }

    const _date1 = moment(date1);
    const _date2 = moment(date2);

    return Math.abs(_date1.diff(_date2, "days"));
  }

  /** Returns difference in days between two dates in user-friendly format.
   * @returns null if any of the dates is null or undefined. */
  public static differenceInDaysHumanized(
    date1?: string | Moment | null,
    date2?: string | Moment | null,
  ): string {
    if (!date1 || !date2) {
      return "-";
    }

    const _date1 = moment(date1);
    const _date2 = moment(date2);
    const durationInDays = Math.abs(_date1.diff(_date2, "days"));

    const humanized = `${durationInDays} ${TextHelper.pluralize("days", durationInDays || 0)}`;

    return humanized;
  }

  //#endregion
}
