import { chain, isEmpty, isFunction, uniqBy } from "lodash-es";

import { AppBreadcrumbsReplacementsMap } from "../components/AppBreadcrumbs";
import { ROUTE_PARTS, ROUTE_PATH } from "../constants/routing";
import { AppBreadcrumbsReplacements, IAppBreadcrumb } from "../ts/breadcrumbs";
import { RegexHelper } from "./regex";
import { TextHelper } from "./text";

export class BreadcrumbHelper {
  public static formatPathPart(pathPart: string, prevPathPart?: string) {
    if (RegexHelper.isId(pathPart)) {
      const id = pathPart;
      // id usually refers to previous route part.
      // e.g. users/:id/files/:id/view
      if (prevPathPart && !isEmpty(prevPathPart)) {
        return TextHelper.humanize(TextHelper.unpluralize(prevPathPart)) + ` #${id}`;
      } else {
        return pathPart;
      }
    }

    return TextHelper.humanize(pathPart);
  }

  /** Builds breadcrumbs from page route path. */
  public static getByPath(path: string, options?: { includeHome?: boolean }): IAppBreadcrumb[] {
    const reg = new RegExp(`(?<pathRoot>(/app)|(/dev)|(/admin))(?<pathEnd>.*)`);
    const regexRes = reg.exec(path) as any;

    if (!regexRes || !regexRes.groups.pathEnd) {
      return [];
    }

    const pathWithoutRoot = regexRes.groups.pathEnd as string;
    const pathParts = pathWithoutRoot.split("/").filter((str) => !!str);

    const result: IAppBreadcrumb[] = [];
    for (let i = 0; i < pathParts.length; i++) {
      const pathPart = pathParts[i];
      const prevPathPart = i - 1 >= 0 ? pathParts[i - 1] : undefined;

      const pathPartFormatted = this.formatPathPart(pathPart, prevPathPart);

      const pth = [...pathParts];
      pth.splice(i + 1);
      const relativePath = [regexRes.groups.pathRoot, pth.join("/")].join("/");
      const isId = RegexHelper.isId(pathPart);

      const breadcrumb: IAppBreadcrumb = {
        title: pathPartFormatted,
        path: relativePath.toLowerCase(),
        pathPart: pathPart.toLowerCase(),
        to: relativePath.toLowerCase(),
        idValue: isId ? pathPart.toLowerCase() : undefined,
        isReplacementNeeded: isId,
        isReplacementApplied: false,
      };
      result.push(breadcrumb);
    }

    // add home as the first element
    if (options?.includeHome && !result.some((x) => x.path === "/")) {
      result.unshift({
        title: "Home",
        path: "/",
        pathPart: "",
        to: "/",
      });
    }

    // ensure explanatory elements reference correct page
    // E.g.
    // /user/1 and users/1/view
    // /user/1 and users/1/edit
    const explanatoryParts = [ROUTE_PARTS.VIEW, ROUTE_PARTS.EDIT].map((x) => x.toLowerCase());
    const idInRouteRegex = /:[\w\d]+/g; // /a/:id/c/:id
    const routePathValues = Object.values(ROUTE_PATH)
      .map((x) => (isFunction(x) ? x() : x.toString()))
      .map((x) => x.replaceAll(idInRouteRegex, ":tempId"));

    for (let i = 1; i < result.length; i++) {
      const element = result[i];
      const prevElement = result[i - 1];
      if (element.idValue) {
        element.to = `${element.to}/${ROUTE_PARTS.VIEW}`;
        continue;
      }
      if (!explanatoryParts.includes(element.pathPart)) {
        continue;
      }
      if (!prevElement.idValue) {
        continue;
      }

      const elementPathCleaned = RegexHelper.replaceAllIds(element.path, ":tempId");
      const prevElementPathCleaned = RegexHelper.replaceAllIds(prevElement.path, ":tempId");

      const elementRoute = routePathValues.find((x) => x === elementPathCleaned);
      const prevElementRoute = routePathValues.find((x) => x === prevElementPathCleaned);

      if (elementRoute) {
        prevElement.to = element.path;
        prevElement.to = element.path;
      }
      if (prevElementRoute) {
        element.to = prevElement.path;
        element.to = prevElement.path;
      }
    }

    return result.filter((b) => !!b.title);
  }

  /** Combines current replacements with new replacements. */
  public static combineReplacements(
    currentReplacements: AppBreadcrumbsReplacements,
    newReplacements: AppBreadcrumbsReplacements | null | undefined,
  ): AppBreadcrumbsReplacements {
    newReplacements ||= {};
    if (Object.keys(newReplacements).length === 0) {
      return currentReplacements;
    }

    const combinedReplacements: AppBreadcrumbsReplacements = {
      ...currentReplacements,
      ...newReplacements,
      idBreadcrumbs: uniqBy(
        (newReplacements.idBreadcrumbs || []).concat(currentReplacements.idBreadcrumbs || []),
        (x) => x.idValue,
      ),
    };

    return combinedReplacements;
  }

  /** Combines current replacements map with new replacements and return new map. */
  public static combineReplacementsMap(
    currentMap: AppBreadcrumbsReplacementsMap,
    replacements: AppBreadcrumbsReplacements | null | undefined,
  ): AppBreadcrumbsReplacementsMap {
    replacements ||= {};
    if (Object.keys(replacements).length === 0) {
      return currentMap;
    }

    const newReplacements = (replacements.idBreadcrumbs || []).concat(
      (replacements.idBreadcrumb && [replacements.idBreadcrumb]) || [],
    );
    const newReplacementsMap = chain(newReplacements)
      .keyBy((x) => x.idValue)
      .mapValues((x) => x)
      .value() as AppBreadcrumbsReplacementsMap;

    return {
      ...currentMap,
      ...newReplacementsMap,
    };
  }
}
