import { Box, Button, Grid2, LinearProgress, Stack, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { ReactNode, useState } from "react";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useEffectWithThrottle } from "@/common/hooks/effect/useEffectWithThrottle";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, RepairSparePartDto } from "@/core/api/generated";

import VehiclePartTypeBlock from "../../ReferenceData/VehiclePartType/VehiclePartTypeBlock";
import TenantLink from "../../Tenant/TenantLink";
import RepairSparePartMenu from "../RepairSparePartMenu";

const defaultHeaderProps = {
  withGoBack: true,
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps {
  repairSparePartId?: string | null;
  repairSparePart?: RepairSparePartDto;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  getFunc?: (params: {
    repairSparePartId: string;
  }) => Promise<AxiosResponse<RepairSparePartDto, unknown>>;
  deleteFunc?: (params: { repairSparePartId: string }) => Promise<AxiosResponse<unknown, unknown>>;
  onDeleted?: () => void;
}

export type RepairSparePartViewProps = OwnProps;

export default function RepairSparePartView({
  repairSparePartId,
  repairSparePart,
  headerProps,
  displayProps = defaultDisplayProps,
  getFunc,
  deleteFunc,
  onDeleted,
}: RepairSparePartViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const { hasPermissions } = useAuthorizationInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [repairSparePartFetched, setRepairSparePartFetched] = useState<
    RepairSparePartDto | undefined
  >(undefined);

  const _repairSparePart = repairSparePart || repairSparePartFetched || undefined;

  useEffectWithThrottle(
    async () => {
      if (!repairSparePart && repairSparePartId) {
        setIsLoading(true);
        try {
          const response = getFunc
            ? await getFunc({ repairSparePartId })
            : await apiClient.repairSparePartApi.apiV1RepairSparePartsRepairSparePartIdGet({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                repairSparePartId: repairSparePartId!,
              });
          setRepairSparePartFetched(response.data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    500,
    { leading: true, trailing: false },
    [repairSparePart, repairSparePartId, getFunc],
  );

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        _repairSparePart && {
          idValue: _repairSparePart.id!,
          newTitle: _repairSparePart.localNumber || "",
        }) ||
      undefined,
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!_repairSparePart) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <Box>
            {headerProps?.title || (
              <Typography component='div' variant='h1'>
                <span>Repair spare part</span>{" "}
                <AppLink
                  enabled={
                    headerProps?.withLink &&
                    hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])
                  }
                  to={ROUTE_PATH.REPAIR_SPARE_PART_VIEW(_repairSparePart.id)}
                >
                  <span>{_repairSparePart.localNumber}</span>
                </AppLink>
              </Typography>
            )}
          </Box>

          {displayProps?.actions && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              sx={{ flex: 1, justifyContent: "flex-end" }}
            >
              <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                <Button
                  component={AppLink}
                  to={ROUTE_PATH.REPAIR_SPARE_PART_EDIT(_repairSparePart?.id)}
                  variant='outlined'
                  color='text'
                  size='medium'
                  startIcon={<AppIcon of='edit' />}
                >
                  Edit
                </Button>
              </AuthorizedElement>

              {/* Menu */}
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <RepairSparePartMenu
                  entity={_repairSparePart}
                  onUpdate={(newValue) => newValue && setRepairSparePartFetched(newValue)}
                  onDelete={() => (onDeleted ? onDeleted() : history.goBack())}
                  deleteFunc={deleteFunc}
                  displayProps={{
                    actions: {
                      edit: false,
                      delete: true,
                    },
                  }}
                />
              </AuthorizedElement>
            </Stack>
          )}
        </Stack>
      }
    >
      <ViewContentBlock>
        {currentTenant?.id && _repairSparePart.tenantId !== currentTenant?.id && (
          <FieldValue label='Company' isEmpty={!_repairSparePart.tenantId}>
            <TenantLink entityId={_repairSparePart.tenantId} entity={undefined} />
          </FieldValue>
        )}

        <FieldValue label='Name'>{_repairSparePart.name}</FieldValue>
        <FieldValue label='Description'>{_repairSparePart.description}</FieldValue>
        <FieldValue label='Unit'>
          <InlineApiEnumValue
            type='MeasurementUnit'
            value={_repairSparePart.unit}
            withDescription
          />
        </FieldValue>
        <FieldValue label='Part number'>{_repairSparePart.partNumber}</FieldValue>
        <FieldValue label='Brand name'>{_repairSparePart.brandName}</FieldValue>
        <FieldValue label='Vehicle type'>
          <InlineApiEnumValue
            type='VehicleType'
            value={_repairSparePart.vehicleType}
            withDescription
          />
        </FieldValue>
        <FieldValue label='Currency'>
          <GeneralCurrencyDisplay currency={_repairSparePart.currency} />
        </FieldValue>
        <Box>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xxs: 12, md: 6 }}>
              <VehiclePartTypeBlock
                variant='reference'
                vehiclePartType={_repairSparePart.partType}
                withLink
                withDetailsToggle
                isDetailsVisible={false}
              />
            </Grid2>
          </Grid2>
        </Box>
      </ViewContentBlock>

      {/* Detalization */}
      <ViewContentBlock>
        <Typography component='div' variant='h3'>
          Detalizations
        </Typography>
        <Stack
          spacing={2}
          sx={{
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          {(_repairSparePart.detalizations || []).map((detail, index) => (
            <Box
              key={index}
              sx={{
                borderBottom: "1px solid #ccc",
                borderRadius: "4px",
                padding: 2,
                mb: 1,
              }}
            >
              <Grid2 container columnSpacing={2} rowSpacing={1}>
                <Grid2 size={{ xxs: 12, md: 12 }} minWidth={200}>
                  <Stack direction='column' spacing={1}>
                    <FieldValue label='Type'>
                      <InlineApiEnumValue
                        type='RepairSparePartDetalizationType'
                        value={detail.type}
                        withDescription
                      />
                    </FieldValue>

                    {detail.vehicleSize && (
                      <FieldValue label='Vehicle size'>
                        <InlineApiEnumValue
                          type='VehicleSize'
                          value={detail.vehicleSize}
                          withDescription
                        />
                      </FieldValue>
                    )}

                    {detail.bodyType && (
                      <FieldValue label='Body type'>
                        <InlineApiEnumValue
                          type='VehicleBodyType'
                          value={detail.bodyType}
                          withDescription
                        />
                      </FieldValue>
                    )}

                    {detail.make && <FieldValue label='Make'>{detail.make.name}</FieldValue>}

                    {detail.model && <FieldValue label='Model'>{detail.model.name}</FieldValue>}

                    {detail.generation && (
                      <FieldValue label='Generation'>{detail.generation.name}</FieldValue>
                    )}

                    {detail.modification && (
                      <FieldValue label='Modification'>{detail.modification.name}</FieldValue>
                    )}
                  </Stack>
                </Grid2>
              </Grid2>
            </Box>
          ))}
        </Stack>

        {_repairSparePart?.attachments?.length !== 0 && (
          <Box>
            <FileListView
              files={FileItem.createManyFrom(_repairSparePart?.attachments)}
              maxVisibleFiles={10}
              itemWidth={45}
            />
          </Box>
        )}

        <FieldValue label='Price'>
          <CurrencyValue value={_repairSparePart.price} currency={_repairSparePart.currency} />
        </FieldValue>
      </ViewContentBlock>

      {/* Summary */}
      <ViewContentBlock alignSelf={"flex-end"}>
        <GeneralPriceSummaryDisplay
          sx={{ minWidth: { xxs: "100%", md: "300px" } }}
          summary={{
            currency: _repairSparePart.currency,
            subTotal: _repairSparePart.subTotal,
            subTotalIncDiscount: _repairSparePart.subTotalIncDiscount,
            discount: _repairSparePart.discount,
            tax: _repairSparePart.tax,
            total: _repairSparePart.total,
          }}
        />
      </ViewContentBlock>
    </ViewLayoutV2>
  );
}
