import { Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import { useMemo } from "react";

import { EntityHelper } from "@/common/helpers/entity";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import FoldableBlock from "../../Display/FoldableBlock";
import DepartmentLink from "../Department/DepartmentLink";
import GeneralStrictEntityRelationInline from "../General/Display/GeneralStrictEntityRelationInline";
import LocationLink from "../Location/LocationLink";
import { TenantStructureMembershipOfEntityDisplayProps } from "./TenantStructureMembershipOfEntityDisplay";

export default function TenantStructureMembershipOfEntityFoldableBlock({
  entityType,
  entity,
}: TenantStructureMembershipOfEntityDisplayProps) {
  const entityMeta = useMemo(() => EntityHelper.getEntityMeta(entity), [entity]);
  const entityHaveSomeDepartmentLocation = useMemo(
    () => !isEmpty(entityMeta?.departmentIds) || !isEmpty(entityMeta?.locationIds),
    [entityMeta],
  );

  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Stack sx={{ width: "fit-content" }}>
          <FoldableBlock
            trigger={{
              size: "small",
              label: (
                <Typography variant='subtitle1'>
                  <Stack spacing={1} direction='row'>
                    <GeneralStrictEntityRelationInline
                      value={{
                        entityType: entityType,
                        entityId: entity.id || undefined,
                      }}
                      inlineProps={{
                        withIcon: true,
                      }}
                    />
                  </Stack>
                </Typography>
              ),
            }}
          >
            {!entityHaveSomeDepartmentLocation && (
              <NoDataAlert variant='inline' title='No membership' />
            )}

            {entityHaveSomeDepartmentLocation && (
              <>
                {/** department */}
                {!isEmpty(entityMeta?.departmentIds) && (
                  <>
                    <Typography sx={{ mb: 0.5 }} variant='body1'>
                      Departments:
                    </Typography>
                    {entityMeta!.departmentIds!.map((id, index) => (
                      <DepartmentLink key={index} entityId={id} />
                    ))}
                  </>
                )}
                {/** location */}
                {!isEmpty(entityMeta?.locationIds) && (
                  <>
                    <Typography sx={{ mb: 0.5 }} variant='body1'>
                      Locations:
                    </Typography>
                    {entityMeta!.locationIds!.map((id, index) => (
                      <LocationLink key={index} entityId={id} />
                    ))}
                  </>
                )}
              </>
            )}
          </FoldableBlock>
        </Stack>
      </Stack>
    </Stack>
  );
}
