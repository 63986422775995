/* eslint-disable @typescript-eslint/no-use-before-define */
import { isFunction } from "lodash-es";
import { ReactNode } from "react";

type ContentType = ReactNode | null;

/** Render helper for if/elseIf/else. Can be used as replacement for ternary operators. */
export const renderIf = (): ConditionBuilder => {
  return new ConditionBuilder();
};

class ConditionBuilder {
  public if(condition: boolean): IfConditionBuilder {
    return new IfConditionBuilder(condition);
  }
}

class IfConditionBuilder {
  private _condition: boolean;
  private _parentCondition?: ThenConditionBuilder;

  constructor(condition: boolean, parentCondition?: ThenConditionBuilder) {
    this._condition = condition;
    this._parentCondition = parentCondition;
  }

  public then(content: ContentType | (() => ContentType)): ThenConditionBuilder {
    let currentContent = isFunction(content) ? content() : content;
    currentContent = this._condition ? currentContent : null;
    if (this._parentCondition) {
      return new ThenConditionBuilder(
        this._parentCondition.condition,
        this._parentCondition.content,
      );
    }
    return new ThenConditionBuilder(this._condition, currentContent);
  }
}

class ThenConditionBuilder {
  public condition: boolean;
  public content: ContentType;

  constructor(condition: boolean, content: ContentType) {
    this.condition = condition;
    this.content = content;
  }

  public elseif(condition: boolean): IfConditionBuilder {
    const parentCondition = this.condition ? this : undefined;
    return new IfConditionBuilder(condition, parentCondition);
  }

  public else(content: ContentType | (() => ContentType)): ElseConditionBuilder {
    const currentContent = isFunction(content) ? content() : content;
    return new ElseConditionBuilder(this.condition ? this.content : currentContent);
  }

  public render(): ContentType {
    return this.content;
  }
}

class ElseConditionBuilder {
  private _content: ContentType;

  constructor(content: ContentType) {
    this._content = content;
  }

  public render(): ContentType {
    return this._content;
  }
}

/* eslint-enable @typescript-eslint/no-use-before-define */
