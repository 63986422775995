import { Fade, IconButton, IconButtonProps, Paper, Popper, Stack } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";

import AppIcon from "@/common/components/Icons/AppIcon";
import { tipsEventEmitter } from "@/common/eventEmmiters/tipsEventEmitter";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import tipsSchedulerService from "@/common/services/tipsSchedulerService";
import { apiClient } from "@/core/api/ApiClient";
import { AssistantTipDto } from "@/core/api/generated";
import * as appCommonSlice from "@/store/appCommon/slice";

import AppTooltip from "../../AppTooltip";
import AppButton from "../../Button/AppButton";
import AppTypography from "../../Text/AppTypography";

interface Props extends IconButtonProps {}
export default function AssistantActivator({ ...props }: Props) {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const dispatch = useAppThunkDispatch();
  const isAssistantOpened = useAppSelector((x) => x.app.isAssistantOpened);

  const [tip, setTip] = useState<AssistantTipDto | null>(null);
  const [isPopperButtonLoading, setIsPopperButtonLoading] = useState(false);

  const acquireTipAsync = useCallback(async () => {
    if (isAssistantOpened) {
      return;
    }
    try {
      // 1. Call API here and wait for response
      const response = await apiClient.assistantApi.apiV1AssistantTipGet({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      });
      setTip(response.data);
      tipsSchedulerService.saveLastTriggerAt();
    } catch (err) {
      console.log(err);
    }
  }, [isAssistantOpened]);

  useEffect(() => {
    const { off } = tipsEventEmitter.on2("show", () => {
      console.log("show event received");
      acquireTipAsync();
    });
    return off;
  }, [acquireTipAsync]);

  const handlePopperClose = useCallback(() => {
    setTip(null);
  }, []);

  const handleClick = useCallback(() => {
    handlePopperClose();
    dispatch(appCommonSlice.toggleAssistantBlock());
  }, [dispatch, appCommonSlice.toggleAssistantBlock]);

  const handlePopperSubmit = useCallback(async () => {
    setIsPopperButtonLoading(true);
    await apiClient.assistantApi.apiV1AssistantTipSubmitPost({
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assistantTipDto: tip!,
    });
    handlePopperClose();
    dispatch(appCommonSlice.toggleAssistantBlock());
    setIsPopperButtonLoading(false);
  }, [tip, dispatch, appCommonSlice.toggleAssistantBlock]);
  return (
    <>
      <Stack direction='row'>
        <AppTooltip title='Chat with AI assistant'>
          <IconButton {...props} ref={anchorRef} onClick={handleClick}>
            <AppIcon of='sparkles' sx={{ fill: (th) => th.palette.primary.main }} />
          </IconButton>
        </AppTooltip>
      </Stack>

      <Popper
        anchorEl={anchorRef.current}
        open={!!tip}
        transition
        placement='bottom'
        sx={{ zIndex: (th) => th.zIndex.modal }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                backgroundColor: (th) => th.palette.primary.main,
                color: (th) => th.palette.primary.contrastText,
                maxWidth: 300,
                p: 2,
                zIndex: (th) => th.zIndex.modal,
              }}
            >
              <AppTypography>
                <Markdown>{tip?.text}</Markdown>
              </AppTypography>
              <Stack direction='row' spacing={1} justifyContent='flex-end'>
                <AppButton
                  variant='contained'
                  onClick={handlePopperClose}
                  sx={{ backgroundColor: "white", color: (th) => th.palette.primary.main }}
                >
                  Close
                </AppButton>
                <AppButton
                  variant='contained'
                  loading={isPopperButtonLoading}
                  onClick={handlePopperSubmit}
                  sx={{ backgroundColor: "white", color: (th) => th.palette.primary.main }}
                >
                  Find out more
                </AppButton>
              </Stack>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}
