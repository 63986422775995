import { Box, Fade, Stack, SxProps, Theme } from "@mui/material";
import { isFunction } from "lodash-es";
import { ReactNode, useCallback, useMemo, useState } from "react";

import { AppButtonProps } from "../Button/AppButton";
import AppIconButton from "../Button/AppIconButton";
import AppIcon from "../Icons/AppIcon";
import AppTypography, { AppTypographyProps } from "../Text/AppTypography";

type FoldableReactNodeRenderFuncParams = {
  isFolded: boolean;
  setIsFolded: (newValue: boolean) => void;
  toggle: () => void;
};
type FoldableReactNodeRenderFunc = (params: FoldableReactNodeRenderFuncParams) => ReactNode;
type FoldableReactNode = ReactNode | FoldableReactNodeRenderFunc;

export interface FoldableBlockTriggerProps {
  /** Icon that display folded/unfolded state. */
  expandIcon?: FoldableReactNode;
  /** Custom icon. */
  icon?: FoldableReactNode;
  label?: FoldableReactNode;
  verticalPlacement?: "start" | "end" | "startAndEnd";
  horizontalAlignment?: "start" | "center" | "end";
  /** Custom trigger. */
  trigger?: FoldableReactNode;
  variant?: AppButtonProps["variant"];
  size?: AppButtonProps["size"];
  color?: "inherit" | "primary" | "text";
  disabled?: AppButtonProps["disabled"];
  typographyProps?: Partial<AppTypographyProps>;
  sx?: SxProps<Theme>;
}

export interface FoldableBlockContentProps {
  isUnmountOnExit?: boolean;
  sx?: SxProps<Theme>;
}

export interface FoldableBlockProps {
  trigger?: FoldableBlockTriggerProps;
  /** Foldable content props. */
  content?: FoldableBlockContentProps;
  defaultIsFolded?: boolean;
  spacing?: number;
  sx?: SxProps<Theme>;
  /** Content in foldable block. */
  children: ReactNode;
}

const defaultTriggerProps: FoldableBlockTriggerProps = {
  variant: "text",
  size: "large",
  color: "text",
  verticalPlacement: "start",
  horizontalAlignment: "start",
};

const defaultContentProps: FoldableBlockContentProps = {
  isUnmountOnExit: true,
};

/** Folds/unfolds content block when trigger is clicked. */
export default function FoldableBlock({
  trigger,
  content,
  defaultIsFolded = true,
  spacing,
  sx,
  children,
}: FoldableBlockProps): React.ReactNode {
  trigger = {
    ...defaultTriggerProps,
    ...trigger,
  };
  content = {
    ...defaultContentProps,
    ...content,
  };

  const [isFolded, setIsFolded] = useState<boolean>(defaultIsFolded ?? true);

  const toggle = useCallback(() => {
    setIsFolded((oldValue) => !oldValue);
  }, [setIsFolded]);

  const foldableReactNodeRenderFuncParams = useMemo<FoldableReactNodeRenderFuncParams>(
    () => ({
      isFolded,
      setIsFolded,
      toggle,
    }),
    [isFolded],
  );

  const renderFoldableReactNode = (node: FoldableReactNode): ReactNode => {
    return isFunction(node) ? node(foldableReactNodeRenderFuncParams) : node;
  };

  const renderTriggerIcon = () => {
    return (
      <>
        {trigger?.expandIcon && renderFoldableReactNode(trigger.expandIcon)}
        {!trigger?.expandIcon && (
          <>
            {isFolded && (
              <AppIconButton
                size={trigger.size}
                sx={{ border: "1px solid transparent" }}
                variant='default'
                color='default'
              >
                <AppIcon of='expandMore' sx={{ fontSize: "1.3rem" }} />
              </AppIconButton>
            )}

            {!isFolded && (
              <AppIconButton size={trigger.size} variant='outlined' color='default' active>
                <AppIcon of='expandLess' sx={{ fontSize: "1.3rem" }} />
              </AppIconButton>
            )}
          </>
        )}
      </>
    );
  };

  const renderTriggerLabel = () => {
    return (
      <Box>
        {trigger?.label && renderFoldableReactNode(trigger.label)}
        {!trigger?.label && (
          <>
            {isFolded && "Show more details"}
            {!isFolded && "Show less details"}
          </>
        )}
      </Box>
    );
  };

  const renderTrigger = () => {
    return (
      <Box sx={trigger?.sx}>
        {/* default trigger */}
        {!trigger?.trigger && (
          <Stack
            sx={{
              "&:hover": {
                backgroundColor: (th) => th.palette.primary.light,
              },
              cursor: "pointer",
              color: (th) =>
                trigger?.color === "inherit"
                  ? "inherit"
                  : th.palette[trigger?.color ?? "secondary"].main,
              borderRadius: (th) => th.shapeCustom.borderRadiusCard,
              ...(trigger?.variant === "contained" && {
                backgroundColor: (th) => th.palette.background.gray,
                borderWidth: 0,
                borderColor: "transparent",
              }),
              ...(trigger?.variant === "outlined" && {
                backgroundColor: "transparent",
                borderWidth: 1,
                borderColor: (th) => th.palette.divider,
                borderStyle: "solid",
              }),
              ...(trigger?.variant === "text" && {
                px: 0,
                py: 0,
                backgroundColor: "transparent",
                border: "none",
                // borderBottomLeftRadius: 0,
                // borderBottomRightRadius: 0,
                borderRadius: 0,
              }),
            }}
            onClick={() => {
              if (trigger?.disabled) return;
              toggle();
            }}
          >
            <AppTypography
              sx={{
                width: "100%",
                ...trigger?.typographyProps?.sx,
              }}
              isInheritFontStyle
              {...trigger?.typographyProps}
            >
              <Stack
                sx={{
                  width: "100%",
                }}
                spacing={0.25}
              >
                <Stack
                  sx={{
                    width: "100%",
                    justifyContent: trigger?.horizontalAlignment || "start",
                    alignItems: "center",
                  }}
                  direction='row'
                  spacing={2}
                >
                  {renderFoldableReactNode(trigger?.icon)}
                  {renderTriggerLabel()}
                  <Stack
                    direction='row'
                    spacing={1}
                    sx={{
                      flex: 1,
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <Box>{renderTriggerIcon()}</Box>
                  </Stack>
                </Stack>
              </Stack>
            </AppTypography>
          </Stack>
        )}

        {/* custom trigger */}
        {trigger?.trigger && <Box>{renderFoldableReactNode(trigger?.trigger)}</Box>}
      </Box>
    );
  };

  return (
    <Stack
      spacing={spacing ?? 1}
      sx={{
        // background: (t) => t.palette.background.paper,
        //p: 1, // why?
        ...sx,
      }}
    >
      {(trigger?.verticalPlacement === "start" || trigger?.verticalPlacement === "startAndEnd") &&
        renderTrigger()}

      <Fade in={!isFolded} unmountOnExit={content?.isUnmountOnExit}>
        <Box sx={content?.sx}>{children}</Box>
      </Fade>

      {(trigger?.verticalPlacement === "end" ||
        (trigger?.verticalPlacement === "startAndEnd" && !isFolded)) &&
        renderTrigger()}
    </Stack>
  );
}
