import { Button, Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";

import AppTooltip from "@/common/components/AppTooltip";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopoverActions from "@/common/components/Popover/AppPopoverActions";
import AppTypography from "@/common/components/Text/AppTypography";
import { RepairEntityDtoType, RepairHelper } from "@/common/helpers/entity/repair";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";

import TenantAutocomplete from "../../Tenant/TenantAutocomplete";
import { BaseEntitySearchAutocompleteInheritableProps } from "../BaseEntitySearchAutocomplete";

export interface RepairEntityCrossTenantSelection<TEntity extends RepairEntityDtoType> {
  tenantId: string;
  repairEntityId: string;
  repairEntity: TEntity;
  isExternal: boolean;
}

interface OwnProps<TEntity extends RepairEntityDtoType> {
  repairEntityName: {
    singular: string;
    plural: string;
  };
  renderRepairEntityAutocomplete: (params: {
    searchFilters: { myTenantId: string | undefined };
    displayProps: {
      crossTenantSelector: boolean;
      customFilter: boolean;
    };
    autocompleteProps: Partial<BaseEntitySearchAutocompleteInheritableProps<TEntity>>;
  }) => ReactNode;
  onSave: (newValue: Nil<RepairEntityCrossTenantSelection<TEntity>>) => void;
  onCancel: () => void;
}

export type BaseRepairEntityCrossTenantSelectorProps<TEntity extends RepairEntityDtoType> =
  OwnProps<TEntity>;

export type BaseRepairEntityCrossTenantSelectorInheritableProps<
  TEntity extends RepairEntityDtoType,
> = Pick<OwnProps<TEntity>, "onSave" | "onCancel">;

export default function BaseRepairEntityCrossTenantSelector<TEntity extends RepairEntityDtoType>({
  repairEntityName,
  renderRepairEntityAutocomplete,
  onSave,
  onCancel,
}: BaseRepairEntityCrossTenantSelectorProps<TEntity>) {
  const currentTenant = useCurrentTenant();

  const repairSpecLocalSettings = useMemo(() => RepairHelper.getLocalSettings(), []);

  const [defaultTenantId, setDefaultTenantId] = useState<string | undefined>(
    repairSpecLocalSettings?.defaultTenantIdForCrossTenant || undefined,
  );
  const isUseDefaultTenant = !!defaultTenantId && currentTenant?.id !== defaultTenantId;

  const [tenantId, setTenantId] = useState<string | undefined>(
    isUseDefaultTenant ? defaultTenantId || undefined : undefined,
  );
  const [isUseTheTenantByDefault, setIsUseTheTenantByDefault] =
    useState<boolean>(isUseDefaultTenant);
  const [repairEntityId, setRepairEntityId] = useState<string | undefined>(undefined);
  const [repairEntity, setRepairEntity] = useState<TEntity | undefined>(undefined);

  const handleSave = () => {
    const newRepairEntity: TEntity = {
      ...repairEntity,
      tenantId: repairEntity?.tenantId || tenantId,
    } as TEntity;
    const newValue: Nil<RepairEntityCrossTenantSelection<TEntity>> =
      tenantId && repairEntityId && repairEntity
        ? {
            tenantId,
            repairEntityId,
            repairEntity: newRepairEntity,
            isExternal: RepairHelper.isExternal({
              tenantId: currentTenant?.id,
              repairEntity: newRepairEntity,
            }),
          }
        : undefined;
    onSave(newValue);
  };

  if (!currentTenant?.id) {
    return null;
  }

  return (
    <Stack>
      <AppTooltip title={`Find and use your ${repairEntityName.plural} across your companies.`}>
        <AppTypography variant='subtitle1' decoration={{ variant: "helpText" }}>
          My cross-company {repairEntityName.plural}
        </AppTypography>
      </AppTooltip>

      <FormControl margin='normal' fullWidth>
        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <TenantAutocomplete
            sx={{ flex: 1 }}
            entityId={tenantId}
            searchFilters={{
              isMy: true,
              excludeIds: [currentTenant.id],
            }}
            required
            label='Company'
            size='small'
            onChange={(newValue) => {
              setTenantId(newValue?.id);
              setIsUseTheTenantByDefault(!!newValue && newValue?.id === defaultTenantId);
              setRepairEntityId(undefined);
              setRepairEntity(undefined);
            }}
          />

          <AppTooltip title='Use this company by default'>
            <FormControlLabel
              label=''
              disabled={!tenantId}
              control={
                <Checkbox
                  icon={<AppIcon of='bookmarkBorder' />}
                  checkedIcon={<AppIcon of='bookmark' />}
                  checked={isUseTheTenantByDefault}
                  onChange={(e) => {
                    const isUse = e.target.checked;
                    setIsUseTheTenantByDefault(isUse);

                    const newDefaultTenantId = isUse ? tenantId : undefined;
                    setDefaultTenantId(newDefaultTenantId);
                    RepairHelper.saveLocalSettings({
                      ...repairSpecLocalSettings,
                      defaultTenantIdForCrossTenant: newDefaultTenantId,
                    });
                  }}
                />
              }
            />
          </AppTooltip>
        </Stack>
      </FormControl>

      <FormControl margin='normal' fullWidth>
        {renderRepairEntityAutocomplete({
          searchFilters: { myTenantId: tenantId },
          displayProps: {
            crossTenantSelector: false,
            customFilter: false,
          },
          autocompleteProps: {
            entityId: repairEntityId,
            requestConfig: {
              skip: !tenantId,
            },
            required: true,
            disabled: !tenantId,
            withCreate: false,
            size: "small",
            onChange: (newValue) => {
              setRepairEntityId(newValue?.id);
              setRepairEntity(newValue || undefined);
            },
          },
        })}
      </FormControl>

      <AppPopoverActions sx={{ mt: 2 }}>
        <Button color='inherit' variant='contained' onClick={onCancel}>
          Cancel
        </Button>

        <Button color='primary' variant='contained' onClick={handleSave}>
          Save
        </Button>
      </AppPopoverActions>
    </Stack>
  );
}
