import TextField, { TextFieldProps } from "@mui/material/TextField";
import { isNil } from "lodash-es";
import React, { useCallback } from "react";

export interface NumberInputProps extends Omit<TextFieldProps, "type" | "inputMode" | "onChange"> {
  value: number | string | undefined;
  inputMode?: "numeric" | "decimal";
  onChange?: (newValue: React.ChangeEvent<HTMLInputElement>) => void;
}

const numericRegex = /^[0-9]*$/;
const decimalRegex = /^[0-9]*\.?[0-9]*$/;

export default function NumberInput({
  value,
  defaultValue,
  inputMode = "numeric",
  onChange,
  ...props
}: NumberInputProps) {
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value.replace(",", ".");
      event.target.value = newValue;

      if (inputMode === "numeric" && numericRegex.test(newValue)) {
        onChange && onChange(event);
      } else if (inputMode === "decimal" && decimalRegex.test(newValue)) {
        onChange && onChange(event);
      }
    },
    [onChange],
  );

  return (
    <TextField
      {...props}
      defaultValue={defaultValue}
      value={!isNil(value) ? value : defaultValue ? undefined : ""}
      onChange={handleInputChange}
      inputProps={{
        inputMode: inputMode,
        pattern: "[0-9]*\\.?[0-9]*", // Hint for browsers with support pattern
      }}
    />
  );
}
