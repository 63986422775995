import { Box, Chip, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { PoolDto, PoolSearchPaginatedDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import InlineApiEnumValueList from "../../Enum/InlineApiEnumValueList";

export type PoolAutocompleteOption = BaseAutocompleteOption<PoolDto>;

export const getPoolTitle = (data: PoolDto): string =>
  StringHelper.joinIntoString(
    [
      data.name || data.localNumber,
      enumService.getEnumValueName("PoolItemEntityType", data.itemEntityType),
      enumService.getEnumValueName("EntityType", data.itemEntitySubType?.entitySubType),
    ],
    ", ",
    {
      skipEmpty: true,
    },
  );

export const poolToOption = (data: PoolDto): PoolAutocompleteOption => ({
  id: data.id!,
  title: getPoolTitle(data),
  optionType: AutocompleteOptionType.Normal,
  groupBy: enumService.getEnumValueName("PoolItemEntityType", data.itemEntityType) || undefined,
  data: data,
});

export interface PoolAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<PoolDto> {
  searchFilters?: Partial<Omit<PoolSearchPaginatedDto, "search" | "includeIds">>;
}

export default function PoolAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: PoolAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={poolToOption}
      sortBy={(option) => option.groupBy || ""}
      sortOrder='asc'
      groupBy={(option) => option.groupBy || ""}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.poolsApi.apiV1PoolsSearchPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          poolSearchPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          poolSearchPaginatedDto: {
            ...params.poolSearchPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Pool'
      placeholder='Search for pool...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='pool' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                </>
              }
              secondary={
                <Typography component='div' variant='body2'>
                  {option.data && (
                    <Box>
                      <Stack direction='row' spacing={1}>
                        <Chip
                          variant='outlined'
                          size='small'
                          color='default'
                          label={
                            <InlineApiEnumValue
                              type='PoolStructureType'
                              value={option.data?.structureType}
                            />
                          }
                        />

                        <Chip
                          variant='outlined'
                          size='small'
                          color='default'
                          label={
                            <InlineApiEnumValue type='PoolItemType' value={option.data?.itemType} />
                          }
                        />

                        {option.data?.itemEntityType && (
                          <Chip
                            variant='outlined'
                            size='small'
                            color='default'
                            label={
                              <InlineApiEnumValue
                                type='PoolItemEntityType'
                                value={option.data?.itemEntityType}
                              />
                            }
                          />
                        )}
                        {!isEmpty(option.data?.itemEntityTypes) && (
                          <Chip
                            variant='outlined'
                            size='small'
                            color='default'
                            label={
                              <InlineApiEnumValueList
                                type='PoolItemEntityType'
                                values={option.data?.itemEntityTypes}
                              />
                            }
                          />
                        )}

                        {option.data?.itemEntitySubType?.entitySubType && (
                          <Chip
                            variant='outlined'
                            size='small'
                            color='default'
                            label={
                              <InlineApiEnumValue
                                type='EntityType'
                                value={option.data?.itemEntitySubType?.entitySubType}
                              />
                            }
                          />
                        )}
                      </Stack>
                    </Box>
                  )}
                </Typography>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
