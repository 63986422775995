import { Box, Chip, IconButton, Modal, Stack, SxProps, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { useCallback, useMemo, useRef } from "react";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import { FileItem } from "@/common/fileItem";
import { FileHelper } from "@/common/helpers/file";
import {
  VehicleArea,
  VehicleCleanlinessExtent,
  VehicleDamageExtent,
  VehiclePartType,
  VehicleProjection,
} from "@/core/api/generated";

import AppTooltip from "../AppTooltip";
import AppIconButton from "../Button/AppIconButton";
import DropdownIconButton from "../Button/DropdownIconButton";
import VehicleCleanlinessExtentInline from "../Entity/Vehicle/VehicleCleanlinessExtentInline";
import VehicleDamageExtentInline from "../Entity/Vehicle/VehicleDamageExtentInline";
import InlineApiEnumValue from "../Enum/InlineApiEnumValue";
import AttachmentCaptionInput from "../Files/AttachmentCaptionInput";
import FileItemView from "../Files/FileItemView";
import BooleanValue from "../Form/Display/BooleanValue";
import FieldValue from "../Form/Display/FieldValue";
import AppIcon from "../Icons/AppIcon";
import AppTypography from "../Text/AppTypography";
import ZoomableBlock from "./ZoomableBlock";

export interface FullScreenFileViewerV2Props {
  files?: FileItem[];
  selectedFileId?: string;
  actions?: (file: FileItem) => FullScreenFileActions;
  onSelectFile?: (file: FileItem) => void;
  onClose: () => void;
  sx?: SxProps<Theme>;
}

export interface FullScreenFileActions {
  setCaption?: boolean;
  onSetCaption?: (caption: string | undefined) => void;
}
export default function FullScreenFileViewerV2({
  files,
  selectedFileId,
  actions,
  onSelectFile,
  onClose,
  sx,
}: FullScreenFileViewerV2Props) {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const fileItems = useMemo(() => files || [], [files]);
  const isOpen = useMemo(() => fileItems.length > 0, [files]);
  const selectedFile = useMemo(
    () => fileItems.find((x) => x.id === selectedFileId),
    [files, selectedFileId],
  );

  const selectFile = useCallback(
    (file: FileItem) => {
      onSelectFile && onSelectFile(file);
    },
    [onSelectFile],
  );

  const downloadFile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (selectedFile?.blob) {
        FileHelper.downloadBlob(selectedFile.blob, selectedFile.blob.name);
      } else if (selectedFile?.file) {
        FileHelper.downloadFileByUrl(
          selectedFile.file.url!,
          selectedFile.file.originalFileName || selectedFile.file.fileName!,
        );
      }
    },
    [selectedFile],
  );

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "background.blur",
          backdropFilter: "blur(4px)",
        },
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          ...(sx as unknown as any),
        }}
      >
        {/* Close button */}
        <AppIconButton
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: 1,
          }}
          onClick={onClose}
        >
          <AppIcon of='close' sx={{ fill: "white" }} />
        </AppIconButton>

        {/* Selected file viewer */}
        <Box
          sx={{
            width: "100%",
            height: "auto",
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            overflow: "hidden",
            mt: 1,
          }}
        >
          {selectedFile && (
            <>
              <Box
                sx={{
                  position: "relative",
                  width: { xxs: "95%", md: "unset" },
                  maxWidth: { xxs: "95%", md: "90%", lg: "90%" },
                  height: "auto",
                  maxHeight: { xxs: "90%", md: "90%", lg: "90%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& .react-transform-wrapper": {
                    borderRadius: (th) => th.shape.borderRadius,
                    maxHeight: "fill-available",
                  },
                }}
              >
                <ZoomableBlock wrapperRef={transformComponentRef}>
                  <FileItemView
                    fileItem={selectedFile}
                    withCaption={false}
                    actions={{
                      enabled: false,
                      download: true,
                    }}
                    fullWidth
                    sx={{
                      width: "auto",
                      height: "100%",
                      overflow: "hidden",
                    }}
                    imageSx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </ZoomableBlock>
                {/* Fullscreen actions must be outside from ZoomableBlock */}
                <Stack
                  className='controls'
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    borderRadius: "4px",
                    padding: "2px",
                    backgroundColor: (theme) => theme.palette.background.default,
                  }}
                  direction='column'
                  spacing='4px'
                >
                  <Tooltip
                    title='You can use the mouse to zoom in/out and navigate through the image by dragging the image'
                    placement='top'
                  >
                    <IconButton size='extraSmall'>
                      <AppIcon of='info' />
                    </IconButton>
                  </Tooltip>
                  {selectedFile.attachment?.vehicleImageInsights && (
                    <AppTooltip title='Vehicle image insights generated by AI' placement='top'>
                      <DropdownIconButton
                        key={selectedFile.id}
                        size='extraSmall'
                        dropdownContent={
                          <Box sx={{ minWidth: 300 }}>
                            <FieldValue
                              label='Is Vehicle'
                              valueAlight={{ vertical: "center", horizontal: "end" }}
                              labelAlight={{ vertical: "center" }}
                            >
                              <Chip
                                label={
                                  <BooleanValue
                                    value={selectedFile.attachment.vehicleImageInsights?.isVehicle}
                                  />
                                }
                                color={
                                  selectedFile.attachment.vehicleImageInsights?.isVehicle
                                    ? "success"
                                    : "warning"
                                }
                              />
                            </FieldValue>
                            {selectedFile.attachment.vehicleImageInsights?.isVehicle && (
                              <>
                                <FieldValue
                                  label='Vehicle Area'
                                  isEmpty={
                                    selectedFile.attachment.vehicleImageInsights?.vehicleArea ===
                                    VehicleArea.None
                                  }
                                  valueAlight={{ vertical: "center", horizontal: "end" }}
                                  labelAlight={{ vertical: "center" }}
                                >
                                  <Chip
                                    label={
                                      <InlineApiEnumValue
                                        type='VehicleArea'
                                        value={
                                          selectedFile.attachment.vehicleImageInsights?.vehicleArea
                                        }
                                      />
                                    }
                                  />
                                </FieldValue>
                                <FieldValue
                                  label='Projection'
                                  isEmpty={
                                    selectedFile.attachment.vehicleImageInsights
                                      ?.vehicleProjection === VehicleProjection.None
                                  }
                                  valueAlight={{ vertical: "center", horizontal: "end" }}
                                  labelAlight={{ vertical: "center" }}
                                >
                                  <Chip
                                    label={
                                      <InlineApiEnumValue
                                        type='VehicleProjection'
                                        value={
                                          selectedFile.attachment.vehicleImageInsights
                                            ?.vehicleProjection
                                        }
                                      />
                                    }
                                  />
                                </FieldValue>
                                <FieldValue
                                  label='Cleanliness'
                                  isEmpty={
                                    selectedFile.attachment.vehicleImageInsights
                                      .vehicleCleanlinessExtent === VehicleCleanlinessExtent.None
                                  }
                                  valueAlight={{ vertical: "center", horizontal: "end" }}
                                  labelAlight={{ vertical: "center" }}
                                >
                                  <VehicleCleanlinessExtentInline
                                    value={
                                      selectedFile.attachment.vehicleImageInsights
                                        ?.vehicleCleanlinessExtent
                                    }
                                  />
                                </FieldValue>
                                <FieldValue
                                  label='Damage Extent'
                                  isEmpty={
                                    selectedFile.attachment.vehicleImageInsights
                                      .vehicleDamageExtent === VehicleDamageExtent.None
                                  }
                                  valueAlight={{ vertical: "center", horizontal: "end" }}
                                  labelAlight={{ vertical: "center" }}
                                >
                                  <VehicleDamageExtentInline
                                    value={
                                      selectedFile.attachment.vehicleImageInsights
                                        ?.vehicleDamageExtent
                                    }
                                  />
                                </FieldValue>
                                {selectedFile.attachment.vehicleImageInsights
                                  .vehiclePotentialDamages?.length !== 0 && (
                                  <Stack sx={{ p: 1 }}>
                                    <AppTypography sx={{ px: 1 }} variant='subtitle2'>
                                      Potential Damages
                                    </AppTypography>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                        px: 1,
                                        mt: 1,
                                        maxWidth: 300,
                                      }}
                                    >
                                      {selectedFile.attachment.vehicleImageInsights?.vehiclePotentialDamages?.map(
                                        (d, i) => (
                                          <Chip
                                            key={String(d.partType! + d.damageType! + i)}
                                            label={
                                              <Stack direction='row'>
                                                {d.partType &&
                                                  d.partType !== VehiclePartType.None && (
                                                    <InlineApiEnumValue
                                                      type='VehiclePartType'
                                                      value={d.partType}
                                                      sx={{ mr: 1 }}
                                                    />
                                                  )}
                                                {(!d.partType ||
                                                  d.partType === VehiclePartType.None) && (
                                                  <InlineApiEnumValue
                                                    type='VehiclePartCategory'
                                                    value={d.partCategory}
                                                    sx={{ mr: 1 }}
                                                  />
                                                )}
                                                {"-"}
                                                <InlineApiEnumValue
                                                  type='DamageTypeCategory'
                                                  value={d.damageType}
                                                  sx={{ ml: 1 }}
                                                />
                                              </Stack>
                                            }
                                            color='warning'
                                            sx={{ width: "fit-content" }}
                                          />
                                        ),
                                      )}
                                    </Box>
                                  </Stack>
                                )}
                              </>
                            )}
                          </Box>
                        }
                      >
                        <AppIcon of='sparkles' />
                      </DropdownIconButton>
                    </AppTooltip>
                  )}
                  {actions?.(selectedFile)?.setCaption && (
                    <AttachmentCaptionInput
                      key={selectedFile.id}
                      defaultCaption={selectedFile.caption}
                      tooltipProps={{ enabled: true, title: "Set caption", placement: "top" }}
                      onSetCaption={(value) => actions?.(selectedFile).onSetCaption?.(value)}
                    />
                  )}

                  <Tooltip title='Download' placement='top'>
                    <IconButton onClick={downloadFile} size='extraSmall'>
                      <AppIcon of='download' />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>

              {/* Caption */}
              <AppTypography sx={{ mt: 1 }}>{selectedFile.attachment?.caption}</AppTypography>
            </>
          )}
        </Box>

        {/* Files preview */}
        {fileItems.length > 1 && (
          <Box
            sx={{
              mt: "auto",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                overflowX: "auto",
                display: "grid",
                gridAutoFlow: "column",
                padding: 2,
                gridGap: "10px",
                webkitOverflowScrolling: "touch",
              }}
            >
              {fileItems.map((file, index) => (
                <Box sx={{ position: "relative" }} key={index}>
                  <FileItemView
                    fileItem={file}
                    actions={{
                      enabled: true,
                      click: true,
                      onClick: () => {
                        transformComponentRef?.current?.resetTransform();
                        selectFile(file);
                      },
                      ...(actions?.(file) || {}),
                    }}
                    sx={{
                      height: "150px",
                      width: "unset",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
