import { RepairSparePartDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelectorPopover, {
  BaseRepairEntityCrossTenantSelectorPopoverInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelectorPopover";
import RepairSparePartCrossTenantSelector, {
  RepairSparePartCrossTenantSelectorProps,
} from "./RepairSparePartCrossTenantSelector";

interface Props
  extends BaseRepairEntityCrossTenantSelectorPopoverInheritableProps<RepairSparePartDto> {
  selectorProps: RepairSparePartCrossTenantSelectorProps;
}

export default function RepairSparePartCrossTenantSelectorPopover({
  selectorProps,
  ...otherProps
}: Props) {
  return (
    <BaseRepairEntityCrossTenantSelectorPopover<RepairSparePartDto>
      selectorProps={selectorProps}
      renderSelector={(params) => <RepairSparePartCrossTenantSelector {...params.selectorProps} />}
      {...otherProps}
    />
  );
}
