import { AvatarProps } from "@mui/material";

import { useAppSelector } from "@/common/hooks/redux";
import { ChatParticipantDto, ParticipantOnlineStatus } from "@/core/api/generated";
import { selectChatParticipantOnlineStatus } from "@/store/communication/selectors";

import AppAvatar, { AppAvatarProps } from "../../Avatar/AppAvatar";

export interface ChatParticipantAvatarProps {
  chatId?: string | null;
  participant?: ChatParticipantDto | null;
  size?: AppAvatarProps["size"];
  withOnlineStatus?: boolean;
  withPopover?: boolean;
  sx?: AvatarProps["sx"];
}

export default function ChatParticipantAvatar({
  chatId,
  participant,
  size,
  withOnlineStatus = true,
  withPopover = true,
  sx,
}: ChatParticipantAvatarProps) {
  const participantId = participant?.id;
  const onlineStatus = useAppSelector((x) =>
    selectChatParticipantOnlineStatus(x, chatId || participant?.chatId, participantId),
  );
  const isOnline = onlineStatus === ParticipantOnlineStatus.Online;

  return (
    <AppAvatar
      participant={participant}
      size={size || 48}
      withOnlineStatus={withOnlineStatus}
      withPopover={withPopover}
      isOnline={isOnline}
      sx={sx}
    />
  );
}
