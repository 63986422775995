import { Box, Popover, PopoverProps, SxProps, Theme } from "@mui/material";
import React, {
  ReactElement,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import { ReactHelper } from "@/common/helpers/react";

import AppMountedSensor from "../AppMountedSensor";

/** Time to wait after Popover open is requested and then it can be considered as opened. */
// const openWaitMs = 300;
/** Time to wait before closing Popover after delayed close was requested. */
const closeDelayedWaitMs = 100;

export interface AppPopoverHoverBehaviorProps {
  /**
   * onTriggerMouseLeave - popover is closed when mouse leaves the trigger.
   * onContentMouseLeave - popover is closed when mouse enters and then leaves the content.
   * onTriggerOrContentMouseLeave - combination of onTriggerMouseLeave and onContentMouseLeave.
   * If mouse leaves the trigger and then enters the content during short time, then popover is not closed.
   * @default 'onTriggerMouseLeave'
   */
  closeBehavior?: "onTriggerMouseLeave" | "onContentMouseLeave" | "onTriggerOrContentMouseLeave";
}

interface OwnProps {
  /** Whether popover is enabled.
   * @default true
   */
  enabled?: boolean;
  /** Required for the below behaviors. */
  trigger?: ((isOpen: boolean) => ReactElement) | ReactElement;
  /** Popover is opened when trigger is clicked.
   * Set to non null/undefined value to enable this behavior. */
  clickBehavior?: {
    onTriggerClick?: () => void;
  };
  /** Popover is opened when mouse hovered on trigger.
   * Set to non null/undefined value to enable this behavior. */
  hoverBehavior?: AppPopoverHoverBehaviorProps;
  /** sx for content wrapper. */
  contentSx?: SxProps<Theme>;
  /** Popover content. */
  children: ReactNode;
  /** For debug. */
  tempDebugKey?: string;
  /** Fired when popover opened (its content is mounted). */
  onOpened?: () => void;
}

export type AppPopoverProps = OwnProps & Partial<Omit<PopoverProps, "ref">>;

export type AppPopoverHandle = {
  open: (newAnchorEl?: HTMLElement) => void;
  close: () => void;
};

/** Extends MUI Popover with custom logic.
 *  Additionally accepts trigger element and prebuilt behaviors to simplify open/close of popover. */
export default forwardRef<AppPopoverHandle, AppPopoverProps>(function AppPopover(
  {
    enabled = true,
    trigger,
    clickBehavior,
    hoverBehavior,
    contentSx,
    children,
    tempDebugKey,
    onOpened,
    ...otherProps
  }: AppPopoverProps,
  ref,
) {
  hoverBehavior = hoverBehavior
    ? { closeBehavior: "onTriggerMouseLeave", ...hoverBehavior }
    : undefined;

  const isClickBehavior = Boolean(clickBehavior);
  const isHoverBehavior = Boolean(hoverBehavior);
  const isCloseOnTriggerMouseLeave =
    isHoverBehavior && hoverBehavior?.closeBehavior === "onTriggerMouseLeave";
  const isCloseOnContentMouseLeave =
    isHoverBehavior && hoverBehavior?.closeBehavior === "onContentMouseLeave";
  const isCloseOnTriggerOrContentMouseLeave =
    isHoverBehavior && hoverBehavior?.closeBehavior === "onTriggerOrContentMouseLeave";

  const isOpenCompletedRef = useRef(false);
  const triggerRef = useRef<HTMLElement | undefined>(undefined);
  const closeDelayedTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const open = (newAnchorEl: HTMLElement) => {
    // console.log("AppPopover.open");
    setAnchorEl(newAnchorEl);
  };

  const close = () => {
    // console.log("AppPopover.close");
    setAnchorEl(null);
    isOpenCompletedRef.current = false;
  };

  const closeDelayed = () => {
    clearTimeout(closeDelayedTimeoutRef.current);
    closeDelayedTimeoutRef.current = setTimeout(() => {
      close();
    }, closeDelayedWaitMs);
  };

  const cancelCloseDelayed = () => {
    clearTimeout(closeDelayedTimeoutRef.current);
  };

  const triggerElement = useMemo(() => {
    const element = typeof trigger === "function" ? trigger(isOpen) : trigger;
    if (!element) {
      return null;
    }
    if (!enabled) {
      return element;
    }

    const elementProps = element.props as Nil<any>;
    return ReactHelper.cloneWithRef(element, {
      ref: triggerRef,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        if (enabled && isClickBehavior) {
          // console.log("AppPopover.onClick.");
          open(e.currentTarget);
          clickBehavior?.onTriggerClick && clickBehavior.onTriggerClick();
        }
        elementProps?.onClick && elementProps?.onClick(e);
      },
      onMouseEnter: (e: React.MouseEvent<HTMLElement>) => {
        if (enabled) {
          if (isHoverBehavior) {
            // console.log("AppPopover.onMouseEnter.");
            open(e.currentTarget);
          }
        }
        elementProps?.onMouseEnter && elementProps?.onMouseEnter(e);
      },
      // NB: while Popover is being opening this event can be triggered
      onMouseLeave: (e: React.MouseEvent<HTMLElement>) => {
        if (enabled) {
          if (isHoverBehavior) {
            // console.log("AppPopover.onMouseLeave.");
            if (isCloseOnTriggerMouseLeave) {
              close();
            } else if (isCloseOnTriggerOrContentMouseLeave) {
              // use delayed close to give user time to hover on the content and cancel the delayed close
              closeDelayed();
            }
          }
        }
        elementProps?.onMouseLeave && elementProps?.onMouseLeave(e);
      },
    });
  }, [enabled, trigger, isClickBehavior, isHoverBehavior]);

  useImperativeHandle(
    ref,
    () => ({
      open(newAnchorEl?: HTMLElement) {
        const newAnchorEl2 = newAnchorEl || triggerRef.current;
        if (newAnchorEl2) {
          open(newAnchorEl2);
        }
      },
      close() {
        close();
      },
    }),
    [triggerRef.current, open, close],
  );

  return (
    // use React.Fragment to render trigger directly without wrapping
    <>
      {triggerElement}

      <Popover
        ref={popoverRef}
        sx={{
          // NB:
          // 1. disable pointerEvents when using hover behavior to avoid unneeded events on the trigger while the Popover is being opened.
          // 2. pointerEvents are required for scrolling and mouse events in Popover when it's open.
          // 3. disable pointerEvents while the Popover is being opened and then enable them.
          ...(isHoverBehavior
            ? // ? { pointerEvents: isOpenCompletedRef.current ? "all" : "none" }
              { pointerEvents: "none" }
            : undefined),
        }}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={(e, reason) => {
          // console.log("AppPopover.onClose", { e, reason });
          close();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableRestoreFocus
        {...(otherProps as Partial<PopoverProps>)}
      >
        <Box
          sx={{
            // always enable pointerEvents for the content as pointerEvents are required for scrolling and mouse events
            pointerEvents: "all",
          }}
          onMouseEnter={(e) => {
            if (enabled && isOpen) {
              if (isHoverBehavior) {
                if (isCloseOnContentMouseLeave || isCloseOnTriggerOrContentMouseLeave) {
                  cancelCloseDelayed();
                }
              }
            }
          }}
          onMouseLeave={(e) => {
            if (enabled && isOpen) {
              if (isHoverBehavior) {
                if (isCloseOnContentMouseLeave || isCloseOnTriggerOrContentMouseLeave) {
                  close();
                }
              }
            }
          }}
        >
          <Box sx={contentSx}>{children}</Box>

          {/* Track when popover opened. */}
          <AppMountedSensor
            onMounted={() => {
              isOpenCompletedRef.current = true;
              onOpened && onOpened();
            }}
          />
        </Box>
      </Popover>
    </>
  );
});
