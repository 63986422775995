import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  Divider,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { chain } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import DamageDetectionAggregateLink from "@/common/components/Entity/DamageDetectionAggregate/DamageDetectionAggregateLink";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { ROUTE_PATH } from "@/common/constants/routing";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { DamageDetectionAggregateDto } from "@/core/api/generated";

import VehicleDamageInline from "../VehicleDamage/VehicleDamageInline";

interface OwnProps {
  aggregate: DamageDetectionAggregateDto;
  /** Redirect to cost eval on success. */
  withRedirectToCostEval?: boolean;
}

type Props = OwnProps & DialogProps;

export default function StartDamageCostEvalModal({
  aggregate,
  withRedirectToCostEval,
  ...modalProps
}: Props) {
  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();

  const [isStarting, setIsStarting] = useState(false);
  const [isEvaluateOnlyNewDamages, setIsEvaluateOnlyNewDamages] = useState(true);
  const [itemsToEvaluateMap, setItemsToEvaluateMap] = useState<Record<string, boolean>>({});

  const allDamagesAggregateItems = useMemo(() => aggregate.items || [], [aggregate]);
  const oldDamagesAggregateItems = useMemo(
    () => aggregate.items?.filter((item) => item.isCostEvaluationStarted === true) || [],
    [aggregate],
  );
  const newDamagesAggregateItems = useMemo(
    () => aggregate.items?.filter((item) => item.isCostEvaluationStarted === false) || [],
    [aggregate],
  );
  const damagesAggregateItemsToEvaluate = useMemo(
    () => (isEvaluateOnlyNewDamages ? newDamagesAggregateItems : allDamagesAggregateItems),
    [
      isEvaluateOnlyNewDamages,
      allDamagesAggregateItems,
      oldDamagesAggregateItems,
      newDamagesAggregateItems,
    ],
  );

  useEffect(() => {
    setItemsToEvaluateMap(
      chain(damagesAggregateItemsToEvaluate)
        .keyBy((x) => x.id || "")
        .mapValues((v, k) => true)
        .value(),
    );
  }, [damagesAggregateItemsToEvaluate]);

  const handleClose = () => {
    modalProps.onClose && modalProps.onClose({}, "escapeKeyDown");
  };

  const handleSave = async () => {
    setIsStarting(true);
    try {
      const response =
        await apiClient.damageDetectionAggregatesApi.apiV1DamageDetectionsAggregatesAggregateIdStartCostEvaluationPost(
          {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            aggregateId: aggregate!.id!,
            startCostEvaluationDto: {
              aggregateItemIds: chain(itemsToEvaluateMap)
                .pickBy((v, k) => v === true)
                .keys()
                .value(),
            },
          },
        );
      enqueueSnackbar("Cost evaluation started.", {
        variant: "success",
      });
      handleClose();

      if (withRedirectToCostEval) {
        history.push(
          ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(response.data.damageCostEvaluation!.id),
        );
      }
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    } finally {
      setIsStarting(false);
    }
  };

  return (
    <Dialog {...modalProps}>
      <AppModalTitle
        onCloseClicked={() => modalProps?.onClose && modalProps?.onClose({}, "escapeKeyDown")}
      >
        Start damage cost evaluation?
      </AppModalTitle>

      <DialogContent sx={{ maxHeight: "400px", overflowY: "auto" }}>
        <DialogContentText>
          You&apos;re going to start damage cost evaluation for{" "}
          <DamageDetectionAggregateLink entity={aggregate} />
        </DialogContentText>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={isEvaluateOnlyNewDamages}
                onChange={(e) => setIsEvaluateOnlyNewDamages(e.target.checked)}
              />
            }
            label={"Evaluate only new damages"}
          />
        </FormGroup>

        <Divider />

        <FormGroup>
          {damagesAggregateItemsToEvaluate.length === 0 && (
            <NoDataAlert title='No damages to evaluate' />
          )}

          {damagesAggregateItemsToEvaluate.map((item) => {
            return (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={!!itemsToEvaluateMap[item.id || ""]}
                    onChange={() => {
                      setItemsToEvaluateMap({
                        ...itemsToEvaluateMap,
                        [item.id || ""]: !itemsToEvaluateMap[item.id || ""],
                      });
                    }}
                  />
                }
                label={<VehicleDamageInline entity={item.item?.damage} />}
              />
            );
          })}
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' color='text' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          loading={isStarting}
          type='submit'
          onClick={handleSave}
        >
          Start
        </Button>
      </DialogActions>
    </Dialog>
  );
}
