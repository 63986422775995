import {
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Pagination,
  Stack,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { throttle } from "lodash-es";
import { Moment } from "moment";
import { useCallback, useEffect, useRef, useState } from "react";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useChatParticipants } from "@/common/hooks/communication/useChatParticipants";
import { useAppDispatch, useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { SortDefinition } from "@/common/sorting/sortDefinition";
import { SortDefinitionItem } from "@/common/sorting/sortDefinitionItem";
import { ChatEventDto, ChatEventGetPaginatedDto, SortOrder } from "@/core/api/generated";
import * as chatEventsSlice from "@/store/communication/chatEventsSlice";

import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import AppModalTitle from "../../Modals/AppModalTitle";
import UpdateChatUserSettingsModal from "../ChatUserSettings/UpdateChatUserSettingsModal";
import ChatEventListItem from "./ChatEventListItem";

export interface OwnProps {
  chatId: string;
  filters?: Partial<ChatEventGetPaginatedDto>;
}

type Props = OwnProps & DialogProps;

export default function ChatEventsModal({ chatId, filters, ...dialogProps }: Props) {
  const { participantsMap } = useChatParticipants(chatId);
  const topAnchorRef = useRef<HTMLElement | null>(null);
  const prevOpenRef = useRef(false);
  const thunkDispatch = useAppThunkDispatch();
  const dispatch = useAppDispatch();
  const {
    paginatedEvents,
    loading: { getPaginated: isPaginatedLoading },
  } = useAppSelector((x) => x.communication.chatEvents);
  const commonRequestParams = useCommonRequestParams<ChatEventGetPaginatedDto>({
    defaultValues: {
      limit: 50,
      sortDefinition: new SortDefinition({
        items: [
          new SortDefinitionItem({
            field: getTypedPath<ChatEventDto>().createdAt.$path,
            sortOrder: SortOrder.Desc,
          }),
        ],
      }),
    },
  });

  const [from, setFrom] = useState<Moment | undefined | null>(null);
  const [to, setTo] = useState<Moment | undefined | null>(null);
  const [dto, setDto] = useState<ChatEventGetPaginatedDto | undefined | null>(null);

  const [isChatUserSettingsModalOpen, setIsChatUserSettingsModalOpen] = useState(false);

  const getChatEventsWithParams = useCallback(() => {
    thunkDispatch(
      chatEventsSlice.getChatEvents({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        chatId,
        chatEventGetPaginatedDto: {
          ...commonRequestParams.params,
          offset: commonRequestParams.offset,
          limit: commonRequestParams.limit,
          search: commonRequestParams.search,
          sortDefinition: commonRequestParams.sortDefinitionDto,
          filterDefinition: commonRequestParams.filterDefinitionDto,
          range: {
            from: from?.clone()?.utc()?.format() || undefined,
            to: to?.clone()?.utc()?.format() || undefined,
          },
          ...dto,
        },
      }),
    );
  }, [...commonRequestParams.deps, from, to, dto]);
  const getChatEventsThrottle = useCallback(throttle(getChatEventsWithParams, 1000), [
    getChatEventsWithParams,
  ]);
  const getChatEventsDebounce = useCallback(debounce(getChatEventsWithParams, 1000), [
    getChatEventsWithParams,
  ]);

  useEffect(() => {
    setDto({
      ...(dto || {}),
      ...(filters || {}),
    });
  }, [filters]);

  // load on open
  useEffect(() => {
    const isOpening = !prevOpenRef.current && dialogProps.open;
    if (isOpening) {
      getChatEventsThrottle();
    }
  }, [prevOpenRef.current, dialogProps.open]);
  useEffect(() => {
    if (dialogProps.open) {
      getChatEventsThrottle();
    }
  }, [...commonRequestParams.deps, from, to, dto]);
  useEffect(() => {
    if (dialogProps.open) {
      getChatEventsDebounce();
    }
  }, [commonRequestParams.search]);

  // reset on close
  useEffect(() => {
    return () => {
      dispatch(chatEventsSlice.resetChatEvents());
    };
  }, []);
  useEffect(() => {
    const isClosing = prevOpenRef.current && !dialogProps.open;
    if (isClosing) {
      dispatch(chatEventsSlice.resetChatEvents());
    }
  }, [prevOpenRef.current, dialogProps.open]);

  useEffect(() => {
    prevOpenRef.current = dialogProps.open;
  }, [dialogProps.open]);

  return (
    <Box>
      <Dialog fullWidth maxWidth='sm' {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start" }}>
            <Box>
              <Typography component='div' variant='h6'>
                Chat event history
              </Typography>
            </Box>

            <Stack direction='row' spacing={1} sx={{ flex: 1 }}>
              <MenuWithTrigger
                trigger={
                  <IconButton edge='end' sx={{ ml: "auto" }}>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
                withAuthCloseOnClick
              >
                <MenuItem dense onClick={() => setIsChatUserSettingsModalOpen(true)}>
                  <ListItemIcon>
                    <AppIcon of='settings' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>My chat settings</ListItemText>
                </MenuItem>
              </MenuWithTrigger>
            </Stack>
          </Stack>
        </AppModalTitle>

        <DialogContent>
          <Box>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              sx={{ alignItems: { xs: "stretch", md: "center" }, m: 1 }}
            >
              {/* <TextField
                label='Search'
          size="small"
                margin='normal'
                type='text'
                variant='outlined'
                value={search || ""}
                onChange={(e) => setSearch(e.target.value)}
                sx={{ margin: 0, minWidth: 200 }}
              /> */}

              <FormControl>
                <MobileDatePicker
                  label='From date'
                  value={from}
                  format={DATETIME_FORMATS.DISPLAY_DATE}
                  onChange={(newValue) => {
                    setFrom(newValue);
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton onClick={() => setFrom(null)}>
                              <AppIcon of='clear' color='inherit' />
                            </IconButton>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </FormControl>

              <FormControl>
                <MobileDatePicker
                  label='To date'
                  value={to}
                  format={DATETIME_FORMATS.DISPLAY_DATE}
                  onChange={(newValue) => {
                    setTo(newValue);
                  }}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <IconButton onClick={() => setTo(null)}>
                              <AppIcon of='clear' color='inherit' />
                            </IconButton>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </FormControl>
            </Stack>

            <Divider sx={{ my: 2 }} />

            <Box>{isPaginatedLoading && <LinearProgress sx={{ my: 1 }} />}</Box>

            <Stack direction='column' spacing={1}>
              <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>

              {paginatedEvents?.items?.map((event, index) => {
                return (
                  <ChatEventListItem
                    key={index}
                    event={event}
                    participant={participantsMap[event.participantId || ""]}
                  />
                );
              })}

              {paginatedEvents?.items && paginatedEvents?.items?.length !== 0 && (
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    p: 2,
                    mt: "auto !important",
                  }}
                >
                  <Pagination
                    color='primary'
                    size='large'
                    count={paginatedEvents?.pagination?.totalPages || 1}
                    onChange={(e, page) => {
                      commonRequestParams.setOffset((page - 1) * commonRequestParams.limit);
                      topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
                    }}
                  />
                </Box>
              )}
            </Stack>
          </Box>
        </DialogContent>

        {/* Update chat user settings */}
        {isChatUserSettingsModalOpen && (
          <UpdateChatUserSettingsModal
            open={isChatUserSettingsModalOpen}
            onClose={() => setIsChatUserSettingsModalOpen(false)}
            updateChatUserSettingsProps={{
              chatId: chatId,
              onSave: () => setIsChatUserSettingsModalOpen(false),
            }}
          />
        )}
      </Dialog>
    </Box>
  );
}
