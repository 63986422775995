import { Alert, AlertTitle, Box, SxProps, Theme } from "@mui/material";

import { RepairSpecHelper } from "@/common/helpers/entity/repairSpec";
import {
  CreateRepairSpecDto,
  RepairMaterialDto,
  RepairSpecDto,
  RepairSpecSparePartDto,
  RepairWorkDto,
  UpdateRepairSpecDto,
} from "@/core/api/generated";

import AppPopover from "../../Popover/AppPopover";
import AppTypography from "../../Text/AppTypography";
import GeneralCurrencyDisplay from "../General/Display/GeneralCurrencyDisplay";

export interface OwnProps {
  repairSpec: RepairSpecDto | CreateRepairSpecDto | UpdateRepairSpecDto | null | undefined;
  repairMaterial?: RepairMaterialDto | null;
  repairWork?: RepairWorkDto | null;
  repairSparePart?: RepairSpecSparePartDto | null;
  sx?: SxProps<Theme>;
}

type Props = OwnProps;

export default function CurrencyMismatchAlert({
  repairSpec,
  repairMaterial,
  repairWork,
  repairSparePart,
  sx,
}: Props) {
  return RepairSpecHelper.isCurrencyMismatch({
    repairSpec,
    repairMaterial,
    repairWork,
    repairSparePart,
  }) ? (
    <Alert
      severity='warning'
      sx={{
        width: "fit-content",
        ...sx,
      }}
    >
      <AlertTitle>
        <AppPopover
          hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
          trigger={
            <AppTypography isInheritFontStyle decoration={{ variant: "helpText" }}>
              Currency mismatch detected!
            </AppTypography>
          }
        >
          <Alert icon={false} severity='warning' sx={{ maxWidth: { xxs: "90vw", md: 400 } }}>
            <Box>
              Repair spec uses{" "}
              <GeneralCurrencyDisplay
                currency={repairSpec?.currency}
                sx={{ color: "inherit", fontWeight: "bold" }}
              />
              , but{" "}
              {(repairMaterial && "repair material") ||
                (repairWork && "repair work") ||
                (repairSparePart && "repair spare part") ||
                "-"}{" "}
              uses{" "}
              <GeneralCurrencyDisplay
                currency={
                  repairMaterial?.currency || repairWork?.currency || repairSparePart?.currency
                }
                sx={{ color: "inherit", fontWeight: "bold" }}
              />
              . {`Prices won't be converted automatically between different currencies.`}{" "}
              {`You need to resolve the mismatch manually.`}{" "}
              <>
                If not,{" "}
                <GeneralCurrencyDisplay
                  currency={repairSpec?.currency}
                  sx={{ color: "inherit", fontWeight: "bold" }}
                />{" "}
                will be used.
              </>
            </Box>
          </Alert>
        </AppPopover>
      </AlertTitle>
    </Alert>
  ) : null;
}
