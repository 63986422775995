import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { AxiosResponse } from "axios";
import { Formik, getIn } from "formik";
import { isNil, omit, uniqBy } from "lodash-es";
import moment, { Moment } from "moment";
import { useState } from "react";
import * as Yup from "yup";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractStage,
  CreateRepairOperationDto,
  EntityType,
  GeneralAttachmentDto,
  GeneralStageUpdateDtoOfRepairOperationStage,
  RepairOperationDto,
  RepairOperationItemInputDto,
  RepairOperationStage,
  UpdateRepairOperationDto,
  VehicleDamageDto,
  VehicleDamageState,
  VehicleDto,
} from "@/core/api/generated";

import NoDataAlert from "../../AppAlerts/NoDataAlert";
import SplitDropdownButton from "../../Button/SplitDropdownButton";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import FuelLevelInput from "../../Form/Input/FuelLevelInput";
import MileageInput from "../../Form/Input/MileageInput";
import AppIcon from "../../Icons/AppIcon";
import CompoundIcon from "../../Icons/CompoundIcon";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import InferEntityAffiliationFromVehicleFormikComputedField from "../EntityAffiliation/InferEntityAffiliationFromVehicleFormikComputedField";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import SupplierAutocompleteOrCreate from "../Supplier/SupplierAutocompleteOrCreate";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import VehicleDamageAutocomplete from "../VehicleDamage/VehicleDamageAutocomplete";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  vehicleId?: CreateRepairOperationDto["vehicleId"];
  contractId?: CreateRepairOperationDto["contractId"];
  name?: CreateRepairOperationDto["name"];
  vehicleDamageIds?: string[] | null | undefined;
  currency?: CreateRepairOperationDto["currency"];
  tenantRequestsMeta?: CreateRepairOperationDto["tenantRequestsMeta"];
};

export interface RepairOperationCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<RepairOperationDto, DefaultValues> {
  repairOperationId?: string;

  createFunc?: (params: {
    dto: CreateRepairOperationDto;
  }) => Promise<AxiosResponse<RepairOperationDto, unknown>>;
  updateFunc?: (params: {
    repairOperationId: string;
    dto: UpdateRepairOperationDto;
  }) => Promise<AxiosResponse<RepairOperationDto, unknown>>;
}

export type RepairOperationCreateUpdateProps = RepairOperationCreateUpdateOwnProps;

export default function RepairOperationCreateUpdate({
  repairOperationId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  createFunc,
  updateFunc,
}: RepairOperationCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();
  const currentCurrency = useCurrentCurrency({ useDefault: true });
  const userAffiliation = useUserAffiliation();

  const isCreate = !repairOperationId;
  const isEdit = !!repairOperationId;

  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);

  const repairOperationRequest = useApiRequest(
    apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairOperationId: repairOperationId!,
    },
    {
      skip: !repairOperationId,
    },
  );
  const repairOperation = repairOperationRequest?.data;

  const defaultVehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehiclesVehicleIdDamagesByIdsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: defaultValues?.vehicleId || "",
      vehicleDamageIds: defaultValues?.vehicleDamageIds || undefined,
    },
    {
      deps: [defaultValues?.vehicleId, defaultValues?.vehicleDamageIds],
      skip: !defaultValues?.vehicleId && !defaultValues?.vehicleDamageIds,
    },
  );
  const defaultVehicleDamages = defaultVehicleDamagesRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: repairOperation && {
      idValue: repairOperation.id!,
      newTitle: repairOperation.localNumber || "",
    },
  });

  const _disabled = isEdit && !repairOperation?.canBeUpdated;

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.RepairOperation}
      entityId={repairOperationId}
      entity={repairOperation}
      entityRequest={repairOperationRequest}
      isIniting={
        repairOperationRequest.isLoading ||
        defaultVehicleDamagesRequest.isLoading ||
        !currentCurrency ||
        userAffiliation.isIniting
      }
    >
      <Formik<
        BaseFormikValues &
          (Omit<CreateRepairOperationDto, "items"> & Omit<UpdateRepairOperationDto, "items">) & {
            items?: Array<
              RepairOperationItemInputDto & {
                damage?: VehicleDamageDto | null | undefined;
                initialAttachments?: GeneralAttachmentDto[];
                isAttachmentFilesHaveErrors?: boolean;
                total?: number;
              }
            >;

            vehicle?: VehicleDto | undefined;
            initialAttachments: RepairOperationDto["attachments"];
            isAttachmentFilesHaveErrors: boolean;
          }
      >
        initialValues={{
          vehicleId: defaultValues?.vehicleId || repairOperation?.vehicle?.id || undefined,
          vehicle: undefined,
          supplierId: repairOperation?.supplier?.id || undefined,
          contractId: defaultValues?.contractId || repairOperation?.contract?.id || undefined,
          departmentId:
            repairOperation?.departmentIds?.at(0) ||
            userAffiliation.departments?.at(0)?.id ||
            undefined,
          locationId:
            repairOperation?.locationIds?.at(0) ||
            userAffiliation.locations?.at(0)?.id ||
            undefined,
          name: defaultValues?.name || repairOperation?.name || undefined,
          description: repairOperation?.description || undefined,
          date: repairOperation?.date || moment().format(),
          responsibleUser: {
            isCurrentUser: true,
            ...(repairOperation?.responsibleUser || {}),
          },
          mileage: repairOperation?.mileage || undefined,
          fuelLevel: repairOperation?.fuelLevel ?? undefined,
          currency:
            defaultValues?.currency || repairOperation?.currency || currentCurrency || undefined,
          items: repairOperation?.items?.map((x) => ({
            ...x,
            damageId: x.damage?.id,
            cost: x.cost,
            notes: x.notes,
            attachments:
              x.attachments?.length || x.damage?.attachments?.length
                ? uniqBy([...(x.attachments || []), ...(x.damage?.attachments || [])], "file.id")
                : undefined,
            isAttachmentFilesHaveErrors: false,
            discount: x.discount,
            tax: x.tax,

            damage: x.damage,
            initialAttachments: x?.attachments?.length ? x?.attachments : undefined,
          })) ||
            defaultValues?.vehicleDamageIds?.map((id) => {
              const currentDamage = defaultVehicleDamages?.find((y) => y.id === id);
              return {
                damageId: id,
                damage: currentDamage,
                attachments: currentDamage?.attachments?.length
                  ? currentDamage.attachments
                  : undefined,
              };
            }) || [{}],
          notes: repairOperation?.notes || undefined,
          attachments:
            repairOperation?.attachments && repairOperation.attachments.length > 0
              ? repairOperation.attachments
              : undefined,

          isAttachmentFilesHaveErrors: false,
          tenantRequestsMeta:
            defaultValues?.tenantRequestsMeta || repairOperation?.tenantRequestsMeta || undefined,
          initialAttachments: repairOperation?.attachments || undefined,
          tags: repairOperation?.tags || undefined,
          newStage: undefined,

          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // contractId: Yup.string().required("Contract is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            const dto = {
              ...omit(values, ["submit", "initialAttachments"]),
              date: values.date ? moment(values.date).utc().format() : undefined,
            };

            if (isCreate) {
              const response = createFunc
                ? await createFunc({
                    dto: dto,
                  })
                : await apiClient.repairOperationsApi.apiV1RepairOperationsPost({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    createRepairOperationDto: dto,
                  });
              enqueueSnackbar("Repair operation created.", { variant: "success" });
              onCreate && (await onCreate(response.data));
              onSave && (await onSave(response.data));
            } else {
              const response = updateFunc
                ? await updateFunc({
                    repairOperationId,
                    dto: dto,
                  })
                : await apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    repairOperationId,
                    updateRepairOperationDto: dto,
                  });
              enqueueSnackbar("Repair operation updated.", { variant: "success" });
              onUpdate && (await onUpdate(response.data));
              onSave && (await onSave(response.data));
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
          submitForm,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <InferEntityAffiliationFromVehicleFormikComputedField
                values={{
                  vehicleId: values.vehicleId,
                  vehicle: values.vehicle,
                  departmentId: values.departmentId,
                  locationId: values.locationId,
                }}
                onComputed={(computed) => {
                  setFieldValue("vehicle", computed.vehicle);
                  setFieldValue("departmentId", computed.departmentId);
                  setFieldValue("locationId", computed.locationId);
                }}
              />

              <FormContentBlock spacing={2}>
                {isEdit && !repairOperation?.canBeUpdated && (
                  <Alert severity='warning'>{`Can't be edited at this stage.`}</Alert>
                )}

                {/* Tags */}
                <FormControl margin='dense' fullWidth>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                      isIncludeNested: false,
                    })}
                  </FormHelperText>
                </FormControl>

                {!isCreate && (
                  <TextField
                    fullWidth
                    disabled
                    label='Number'
                    margin='dense'
                    type='text'
                    value={repairOperation?.localNumber}
                    variant='outlined'
                  />
                )}

                <FormControl margin='dense' fullWidth error={Boolean(errors.vehicleId)}>
                  <VehicleAutocompleteOrCreate
                    autocompleteProps={{
                      required: true,
                      disabled: !isCreate || _disabled || !!defaultValues?.vehicleId,
                      entityId: values.vehicleId,
                      isPreload: isCreate,
                      onChange: (newValue) => {
                        setFieldValue(`vehicleId`, newValue?.id);
                        setFieldValue(`vehicle`, newValue);
                        // Reset items since a different vehicle implies different damage items:
                        setFieldValue(`items`, [{}]);
                      },
                    }}
                    createFormPlacement='modal'
                    onCreate={(newValue) => {
                      setFieldValue(`vehicleId`, newValue?.id);
                      setFieldValue(`vehicle`, newValue);
                      // Reset items since a different vehicle implies different damage items:
                      setFieldValue(`items`, [{}]);
                    }}
                  />
                  <FormHelperText>{errors.vehicleId}</FormHelperText>
                </FormControl>

                <FormControl margin='dense' fullWidth error={Boolean(errors.supplierId)}>
                  <SupplierAutocompleteOrCreate
                    autocompleteProps={{
                      entityId: values.supplierId,
                      isPreload: isCreate,
                      onChange: (newValue) => {
                        setFieldValue(`supplierId`, newValue?.id);
                      },
                    }}
                    createFormPlacement='modal'
                    onCreate={(newValue) => {
                      setFieldValue(`supplierId`, newValue?.id);
                    }}
                  />
                  <FormHelperText>{errors.supplierId}</FormHelperText>
                </FormControl>

                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    error: errors.locationId,
                    disabled: !values.departmentId,
                  }}
                />

                {values.contractId && (
                  <FormControl margin='dense' fullWidth error={Boolean(errors.contractId)}>
                    <ContractAutocompleteOrCreate
                      autocompleteProps={{
                        disabled: true,
                        entityId: values.contractId,
                        isPreload: isCreate,
                        searchFilters: {
                          vehicleId: values.vehicleId || undefined,
                          excludeStage: ContractStage.Draft,
                        },
                        onChange: (newValue) => {
                          setFieldValue(`contractId`, newValue?.id);
                          setFieldValue(`contract`, newValue || undefined);
                        },
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => {
                        setFieldValue(`contractId`, newValue?.id);
                        setFieldValue(`contract`, newValue || undefined);
                      }}
                    />
                    <FormHelperText>{errors.contractId}</FormHelperText>
                  </FormControl>
                )}

                {values.vehicleId && (
                  <>
                    <FormControl margin='dense' error={Boolean(touched.date && errors.date)}>
                      <MobileDateTimePicker
                        disabled={_disabled}
                        ampm={false}
                        label='Date'
                        value={(values.date && moment(values.date)) || null}
                        format={DATETIME_FORMATS.DISPLAY_DATETIME}
                        onChange={(newValue: Moment | null) => {
                          setFieldValue("date", newValue?.format() || null);
                        }}
                        slots={{ textField: (params) => <TextField {...params} required /> }}
                      />
                      <FormHelperText>{touched.date && errors.date}</FormHelperText>
                    </FormControl>

                    <FormControl component='fieldset' variant='standard'>
                      <FormLabel component='legend' required>
                        Responsible user
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled
                              required
                              checked={values.responsibleUser?.isCurrentUser}
                              onChange={handleChange}
                              name='responsibleUser.isCurrentUser'
                            />
                          }
                          label={profile?.personName?.name}
                        />
                      </FormGroup>
                    </FormControl>

                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      disabled={_disabled}
                      fullWidth
                      rows={2}
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name || ""}
                      variant='outlined'
                    />

                    <AppTextArea
                      error={Boolean(touched.description && errors.description)}
                      disabled={_disabled}
                      fullWidth
                      helperText={touched.description && errors.description}
                      mode='description'
                      margin='dense'
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || ""}
                      variant='outlined'
                    />

                    {/* Mileage */}
                    <MileageInput
                      error={Boolean(touched.mileage && errors.mileage)}
                      helperText={touched.mileage && errors.mileage}
                      vehicleId={values.vehicleId}
                      vehicle={values.vehicle}
                      disabled={!values.vehicle}
                      fullWidth
                      name='mileage'
                      onBlur={handleBlur}
                      onChange={(newValue) => {
                        setFieldValue("mileage", newValue);
                      }}
                      value={values.mileage}
                    />

                    <FormControl
                      margin='dense'
                      fullWidth
                      error={Boolean(touched.fuelLevel && errors.fuelLevel)}
                    >
                      <Typography component='div' gutterBottom>
                        Fuel level
                      </Typography>

                      <FuelLevelInput
                        onBlur={handleBlur}
                        onChange={(e, val) => setFieldValue("fuelLevel", val ?? undefined)}
                        value={values.fuelLevel ?? undefined}
                      />
                      <FormHelperText>{touched.fuelLevel && errors.fuelLevel}</FormHelperText>
                    </FormControl>

                    {/* Items */}
                    <Stack spacing={1}>
                      <Typography component='div' variant='subtitle1' sx={{ mt: 2 }}>
                        Damages ({values.items?.length || 0})
                      </Typography>

                      {(!values.items || values.items.length === 0) && (
                        <NoDataAlert title='No items yet' />
                      )}

                      <Stack direction='column' spacing={1}>
                        {values.items?.map((item, index) => {
                          return (
                            <Card key={index} variant='outlined'>
                              <CardContent>
                                {/* Inputs  */}
                                <Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: "1fr 0fr",
                                      gridTemplateRows: "auto",
                                      columnGap: 1,
                                    }}
                                  >
                                    <Box>
                                      <FormControl
                                        fullWidth
                                        margin='none'
                                        error={Boolean(
                                          getIn(errors, `items[${index}].damageId`) ||
                                            getIn(errors, `items[${index}].damage`),
                                        )}
                                      >
                                        <VehicleDamageAutocomplete
                                          entityId={item.damageId}
                                          searchFilters={{
                                            vehicleId: values.vehicleId || "",
                                            state: VehicleDamageState.Actual,
                                            excludeIds: values.items
                                              ?.map((x) => x.damageId!)
                                              .filter((x) => !!x && x !== item.damageId),
                                          }}
                                          disabled={!values.vehicleId || _disabled}
                                          required
                                          fullWidth
                                          size='small'
                                          onChange={(newValue) => {
                                            if (item) {
                                              const updatedItem = { ...item };
                                              updatedItem.damage = newValue;
                                              updatedItem.damageId = newValue?.id;

                                              const computedAttachments = uniqBy(
                                                [
                                                  ...(updatedItem.attachments?.filter(
                                                    (prevAttachment) => {
                                                      // if attachment have not id it means it is new attachment
                                                      if (isNil(prevAttachment.id)) {
                                                        return true;
                                                      }
                                                      return updatedItem?.damage?.attachments?.some(
                                                        (att) => att.id === prevAttachment.id,
                                                      );
                                                    },
                                                  ) || []),
                                                  ...(newValue?.attachments || []),
                                                ],
                                                "file.id",
                                              );

                                              updatedItem.attachments =
                                                computedAttachments.length > 0
                                                  ? computedAttachments
                                                  : undefined;

                                              setFieldValue(`items[${index}]`, updatedItem);
                                            }
                                          }}
                                        />
                                        <FormHelperText>
                                          {getIn(errors, `items[${index}].damageId`) ||
                                            getIn(errors, `items[${index}].damage`)}
                                        </FormHelperText>
                                      </FormControl>

                                      <FormControl fullWidth margin='normal'>
                                        <PriceSummaryInput
                                          values={{
                                            currency: values.currency,
                                            subTotal: values.items?.at(index)?.cost ?? undefined,
                                            discount: values.items?.at(index)?.discount,
                                            tax: values.items?.at(index)?.tax,
                                            total: values.items?.at(index)?.total,
                                          }}
                                          formikProps={{
                                            errors: {
                                              subTotal: getIn(errors, `items[${index}].cost`),
                                              discount: getIn(errors, `items[${index}].discount`),
                                              tax: getIn(errors, `items[${index}].tax`),
                                              total: getIn(errors, `items[${index}].total`),
                                            },
                                          }}
                                          displayProps={{
                                            insurance: false,
                                            calcExplanationInline: false,
                                            calcExplanationModal: true,
                                          }}
                                          inputsProps={{
                                            all: {
                                              margin: "none",
                                              size: "small",
                                            },
                                            subTotal: {
                                              label: "Cost",
                                              required: true,
                                            },
                                            total: {
                                              required: true,
                                            },
                                          }}
                                          onChange={(newValue) => {
                                            setFieldValue(`currency`, newValue?.currency);
                                            setFieldValue(
                                              `items[${index}].cost`,
                                              newValue?.subTotal,
                                            );
                                            setFieldValue(
                                              `items[${index}].discount`,
                                              newValue?.discount,
                                            );
                                            setFieldValue(`items[${index}].tax`, newValue?.tax);
                                            setFieldValue(`items[${index}].total`, newValue?.total);
                                          }}
                                        />
                                      </FormControl>
                                    </Box>

                                    {/* Controls */}
                                    <Box>
                                      <Stack
                                        direction='row'
                                        sx={{
                                          alignItems: "flex-start",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        {values.items![index].notes === undefined && (
                                          <Tooltip title='Add notes'>
                                            <IconButton
                                              disabled={_disabled}
                                              onClick={() =>
                                                setFieldValue(`items[${index}].notes`, "")
                                              }
                                            >
                                              <CompoundIcon
                                                mainIcon={<AppIcon of='notes' />}
                                                badgeIcon={<AppIcon of='add' />}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                        {values.items![index].notes !== undefined && (
                                          <Tooltip title='Remove notes'>
                                            <IconButton
                                              disabled={_disabled}
                                              onClick={() =>
                                                setFieldValue(`items[${index}].notes`, undefined)
                                              }
                                            >
                                              <CompoundIcon
                                                mainIcon={<AppIcon of='notes' />}
                                                badgeIcon={<AppIcon of='remove' />}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        {values.items![index].attachments === undefined && (
                                          <Tooltip title='Add attachments'>
                                            <IconButton
                                              disabled={_disabled}
                                              onClick={() =>
                                                setFieldValue(`items[${index}].attachments`, [])
                                              }
                                            >
                                              <CompoundIcon
                                                mainIcon={<AppIcon of='attachments' />}
                                                badgeIcon={<AppIcon of='add' />}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}
                                        {values.items![index].attachments !== undefined && (
                                          <Tooltip title='Remove attachments'>
                                            <IconButton
                                              disabled={_disabled}
                                              onClick={() =>
                                                setFieldValue(
                                                  `items[${index}].attachments`,
                                                  undefined,
                                                )
                                              }
                                            >
                                              <CompoundIcon
                                                mainIcon={<AppIcon of='attachments' />}
                                                badgeIcon={<AppIcon of='remove' />}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        )}

                                        <Tooltip title='Delete'>
                                          <IconButton
                                            disabled={_disabled}
                                            onClick={() =>
                                              setFieldValue(
                                                "items",
                                                ArrayHelper.removeByIndex(values.items, index),
                                              )
                                            }
                                          >
                                            <AppIcon of='delete' />
                                          </IconButton>
                                        </Tooltip>
                                      </Stack>
                                    </Box>
                                  </Box>
                                </Box>

                                {values.items &&
                                  (values.items?.at(index)?.attachments ||
                                    values.items?.at(index)?.initialAttachments) && (
                                    <FormControl fullWidth margin='normal'>
                                      <FileUploader
                                        disabled={_disabled}
                                        multiple
                                        defaultFiles={
                                          values.items?.at(index)?.attachments ||
                                          values.items?.at(index)?.initialAttachments
                                            ? FileItem.createManyFrom(
                                                uniqBy(
                                                  [
                                                    ...(values.items?.at(index)?.attachments || []),
                                                    ...(values.items?.at(index)
                                                      ?.initialAttachments || []),
                                                  ],
                                                  "file.id",
                                                ),
                                              )
                                            : undefined
                                        }
                                        onChange={(newFiles) =>
                                          setFieldValue(
                                            `items[${index}].attachments`,
                                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                                          )
                                        }
                                        onUploadStarted={() => {
                                          setIsAttachmentFilesUploading(true);
                                        }}
                                        onUploadFinished={() => {
                                          setIsAttachmentFilesUploading(false);
                                        }}
                                        onValidationStatusChange={(filesValidationStatus) => {
                                          if (filesValidationStatus)
                                            setFieldValue(
                                              `items[${index}].isAttachmentFilesHaveErrors`,
                                              Object.values(filesValidationStatus).some(
                                                (x) => x === false,
                                              ),
                                            );
                                        }}
                                      />
                                    </FormControl>
                                  )}

                                {item.notes !== undefined && (
                                  <AppTextArea
                                    error={Boolean(getIn(errors, `items[${index}].notes`))}
                                    disabled={_disabled}
                                    fullWidth
                                    helperText={getIn(errors, `items[${index}].notes`)}
                                    mode='notes'
                                    margin='dense'
                                    name={`items[${index}].notes`}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type='text'
                                    value={values.items![index].notes}
                                    variant='outlined'
                                  />
                                )}
                              </CardContent>
                              <Box></Box>
                            </Card>
                          );
                        })}
                      </Stack>

                      {/* Add item */}
                      <Button
                        sx={{ flex: 0, alignSelf: "flex-start" }}
                        disabled={_disabled}
                        variant='outlined'
                        color='text'
                        size='small'
                        startIcon={<AppIcon of='add' />}
                        onClick={() => {
                          setFieldValue("items", [...(values.items || []), {}]);
                        }}
                      >
                        Add item
                      </Button>
                    </Stack>

                    <FormControl margin='dense' fullWidth error={Boolean(errors.items)}>
                      <FormHelperText>{errors.items?.toString()}</FormHelperText>
                    </FormControl>

                    <AppTextArea
                      error={Boolean(touched.notes && errors.notes)}
                      disabled={_disabled}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      mode='notes'
                      margin='dense'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.notes}
                      variant='outlined'
                    />

                    <FormControl fullWidth component='fieldset' margin='dense'>
                      <FileUploader
                        disabled={_disabled}
                        multiple
                        defaultFiles={FileItem.createManyFrom(values.initialAttachments)}
                        onChange={(newFiles) => {
                          setFieldValue(
                            `attachments`,
                            FileItem.toManyGeneralAttachmentInputDto(newFiles),
                          );
                        }}
                        onUploadStarted={() => {
                          setIsAttachmentFilesUploading(true);
                        }}
                        onUploadFinished={() => {
                          setIsAttachmentFilesUploading(false);
                        }}
                        onValidationStatusChange={(filesValidationStatus) => {
                          if (filesValidationStatus)
                            setFieldValue(
                              "isAttachmentFilesHaveErrors",
                              Object.values(filesValidationStatus).some((x) => x === false),
                            );
                        }}
                      />
                    </FormControl>
                  </>
                )}
              </FormContentBlock>

              {errors.currency && (
                <Box>
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.currency)}
                  </FormHelperText>
                </Box>
              )}

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              {values.vehicleId && (
                <FormActions>
                  <SplitDropdownButton
                    isSelectAndTriggerAction={false}
                    buttonProps={{
                      variant: "contained",
                      color: "primary",
                      type: "submit",
                      fullWidth: true,
                      loading: isSubmitting,
                      disabled:
                        isAttachmentFilesUploading ||
                        _disabled ||
                        values.isAttachmentFilesHaveErrors ||
                        values.items?.some((x) => x.isAttachmentFilesHaveErrors === true),
                    }}
                    options={[
                      {
                        content: "Save",
                        onClick: () => {
                          submitForm();
                        },
                      },
                      {
                        content: "Save & Start",
                        if: isCreate,
                        onClick: () => {
                          const newValue: GeneralStageUpdateDtoOfRepairOperationStage = {
                            newStage: RepairOperationStage.InProgress,
                          };
                          setFieldValue("newStage", newValue);
                          submitForm();
                        },
                      },
                      {
                        content: "Save & Complete",
                        if: isCreate,
                        onClick: () => {
                          const newValue: GeneralStageUpdateDtoOfRepairOperationStage = {
                            newStage: RepairOperationStage.Completed,
                          };
                          setFieldValue("newStage", newValue);
                          submitForm();
                        },
                      },
                    ]}
                  />
                </FormActions>
              )}
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
