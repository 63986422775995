import { intersection } from "lodash-es";

import { authService } from "@/common/services/auth";
import {
  AppPermission,
  DamageCostEvaluationCreateDto,
  DamageCostEvaluationDto,
  DamageCostEvaluationItemDto,
  DamageCostEvaluationUpdateDto,
  ProfileDto,
  RepairSpecDataDto,
  RepairSpecDto,
} from "@/core/api/generated";

import { RepairSpecHelper } from "./repairSpec";

export class DamageCostEvaluationHelper {
  /** Checks if there is any currency mismatch between DCE and RepairSpec. */
  public static isCurrencyMismatchWithRepairSpec(
    entity:
      | DamageCostEvaluationDto
      | DamageCostEvaluationCreateDto
      | DamageCostEvaluationUpdateDto
      | null
      | undefined,
    repairSpec: RepairSpecDto | RepairSpecDataDto | null | undefined,
  ): boolean {
    return (
      (entity?.currency &&
        repairSpec?.currency &&
        entity.currency.code !== repairSpec.currency.code) ??
      false
    );
  }

  public static getRepairSpecPermissions(params: {
    tenantId: Nil<string>;
    profile: Nil<ProfileDto>;
    damageCostEvaluation: Nil<DamageCostEvaluationDto>;
    item: Nil<DamageCostEvaluationItemDto>;
  }): {
    canViewAny: boolean;
    canViewLink: boolean;
    canViewName: boolean;
    canViewIsEditedLocally: boolean;
    canViewIsCreatedLocally: boolean;
    canViewIsExternal: boolean;
    canView: boolean;
    canViewData: boolean;
    canSaveDataAsNewSpec: boolean;
  } {
    const canViewAny = authService.userHasPermissions([
      AppPermission.FleetAppAccess,
      AppPermission.RepairCatalogRead,
    ]);
    const canManage = authService.userHasPermissions([
      AppPermission.FleetAppAccess,
      AppPermission.RepairCatalogManage,
    ]);
    const ownerTenantIds = [
      //params.damageCostEvaluation?.tenantId,
      params.item?.repairSpec?.tenantId,
    ].filter(Boolean);
    const isOwnerTenant = !!params.tenantId && ownerTenantIds.includes(params.tenantId);
    const isOwnerUser =
      !!params.profile?.tenantIds &&
      intersection(params.profile.tenantIds, ownerTenantIds).length > 0;
    const canViewAll = canViewAny && (isOwnerTenant || isOwnerUser);
    const canView =
      canViewAll &&
      !!params.item?.repairSpec?.id &&
      !params.item?.isRepairSpecChanged &&
      !params.item?.repairSpec?.isCreatedOnTheSpot;
    const canViewData = (canViewAny || canViewAll) && !!params.item?.repairSpec;

    return {
      canViewAny: canViewAny,
      canViewLink: canView,
      canViewName: canViewAny,
      canViewIsEditedLocally:
        canViewAny &&
        !!params.item?.isRepairSpecChanged &&
        !params.item?.repairSpec?.isCreatedOnTheSpot,
      canViewIsCreatedLocally: canViewAny && !!params.item?.repairSpec?.isCreatedOnTheSpot,
      canViewIsExternal:
        !!params.item?.repairSpec?.tenantId &&
        RepairSpecHelper.isSourceExternal({
          tenantId: params.tenantId,
          repairSpec: params.item?.repairSpec,
        }),
      canView: canView,
      canViewData: canViewData,
      canSaveDataAsNewSpec:
        canManage &&
        (!!params.item?.isRepairSpecChanged || !!params.item?.repairSpec?.isCreatedOnTheSpot),
      // canSelect: ,
      // canSelectCrossTenant: ,
      // canEditLocally: ,
    };
  }
}
