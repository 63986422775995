import { RepairSparePartDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelector, {
  BaseRepairEntityCrossTenantSelectorInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelector";
import RepairSparePartAutocomplete, {
  RepairSparePartAutocompleteProps,
} from "./RepairSparePartAutocomplete";

export type RepairSparePartCrossTenantSelectorProps =
  BaseRepairEntityCrossTenantSelectorInheritableProps<RepairSparePartDto> & {
    repairSparePartAutocompleteProps?: Partial<RepairSparePartAutocompleteProps>;
  };

export default function RepairSparePartCrossTenantSelector({
  repairSparePartAutocompleteProps,
  ...otherProps
}: RepairSparePartCrossTenantSelectorProps) {
  return (
    <BaseRepairEntityCrossTenantSelector<RepairSparePartDto>
      repairEntityName={{
        singular: "repair spare part",
        plural: "repair spare parts",
      }}
      renderRepairEntityAutocomplete={(params) => (
        <RepairSparePartAutocomplete
          {...params.autocompleteProps}
          {...repairSparePartAutocompleteProps}
          searchFilters={{
            ...params.searchFilters,
            ...repairSparePartAutocompleteProps?.searchFilters,
          }}
          displayProps={{
            ...params.displayProps,
            ...repairSparePartAutocompleteProps?.displayProps,
          }}
        />
      )}
      {...otherProps}
    />
  );
}
