import { Alert, Badge, Box, Button, Divider, Popover, Stack, Typography } from "@mui/material";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { useAppSelector } from "@/common/hooks/redux";

import AppIconButton from "../Button/AppIconButton";

/** Warnings bin with popup containing ignored warnings list */
export default function LastNotifications() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);

  const close = () => {
    setAnchorEl(null);
  };

  const warnings = useAppSelector((x) => x.app.warnings);

  // const clearAll = async () => {
  // };

  if (!warnings.length) {
    return null;
  }

  return (
    <Box>
      <AppIconButton
        tooltipProps={{
          title: "Warnings",
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        color='warning'
      >
        <Badge
          badgeContent={warnings.length}
          color='error'
          overlap='rectangular'
          invisible={warnings.length === 0}
        >
          <AppIcon of='warning' />
        </Badge>
      </AppIconButton>

      {/* Popup */}
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ minWidth: "400px", maxWidth: { sm: "100vw", md: "400px" } }}>
          <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2 }}>
            <Typography sx={{ fontWeight: "bold" }}>Warnings</Typography>
          </Box>

          <Divider sx={{ m: 0 }} />

          <Stack sx={{ p: 1, gap: 0.5, overflow: "auto" }}>
            {warnings.map((warning, i) => (
              <Alert severity='warning' key={i}>
                <Typography
                  component={"span"}
                  dangerouslySetInnerHTML={{ __html: warning }}
                  sx={{ "a:visited": { color: "inherit" } }}
                />
              </Alert>
            ))}
          </Stack>

          <Box>
            <Box sx={{ p: 1 }}>
              <Stack direction='row' spacing={1}>
                <Button variant='text' fullWidth onClick={close}>
                  Close
                </Button>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
