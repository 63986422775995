import { Slider, SliderProps, Stack } from "@mui/material";
import { isArray, isNumber } from "lodash-es";

import AppIcon from "../../Icons/AppIcon";

type Props = SliderProps;

export default function FuelLevelInput({ value, onChange, onBlur }: Props) {
  return (
    <Stack direction='row' spacing={2} sx={{ pb: 3, alignItems: "center" }}>
      <AppIcon of='fuel' />
      <Slider
        value={value || 0}
        onBlur={onBlur}
        onChange={(e, newValue, activeThumb) => {
          // ensure integer value is produced
          if (isNumber(newValue)) {
            newValue = Math.floor(newValue);
          } else if (isArray(newValue)) {
            newValue = newValue.map((x) => Math.floor(x));
          }

          onChange && onChange(e, newValue, activeThumb);
        }}
        name='fuelLevel'
        aria-label='Fuel level'
        step={100 / 8}
        marks={[
          { label: `Empty`, value: 0 },
          { label: `1/8`, value: 12 },
          { label: `1/4`, value: 25 },
          { label: `3/8`, value: 37 },
          { label: `1/2`, value: 50 },
          { label: `5/8`, value: 62 },
          { label: `3/4`, value: 75 },
          { label: `7/8`, value: 88 },
          { label: `Full`, value: 100 },
        ]}
        min={0}
        max={100}
        sx={{ maxWidth: "300px" }}
      />
    </Stack>
  );
}
