import pluralize from "pluralize";

export class TextHelper {
  public static getCodeFromText(
    text: string,
    codeLength: number,
    codeCase: "upper" | "lower" | "original",
  ): string {
    const code = text.substring(0, codeLength);
    return codeCase === "original"
      ? code
      : codeCase === "upper"
        ? code.toLocaleUpperCase()
        : code.toLocaleLowerCase();
  }

  /** Transforms word to plural form if count is greater than 1 by using provided plural value. */
  public static pluralizeManual(word: string, count: number, plural = word + "s") {
    return [1, -1].includes(Number(count)) ? word : plural;
  }

  /** Transforms word to plural form if count is greater than 1. */
  public static pluralize(word: string, count: number) {
    return pluralize(word, count);
  }

  /** Transforms word to singular form if word is in plural form. */
  public static unpluralize(word: string) {
    return pluralize.singular(word);
  }

  // TODO: find better name as seems like it actually converts text to title or sentence case.
  /** Converts string to human readable format.
   *  Replaces underscores, dashes, and spaces with a specified separator.
   *  If no separator is provided, defaults to a space (" ").
   *  Examples:
   *    humanize("this is a test")      -> "This is a test"
   *    humanize("foo_bar")             -> "Foo bar"
   *    humanize("foo-bar-baz")         -> "Foo bar baz"
   *    humanize("check-in_check out")  -> "Check-in check out"
   *    humanize("foo_bar-baz", "/")    -> "Foo/bar/baz"
   *    humanize("foo_bar-baz", "")     -> "Foobarbaz"
   */
  public static humanize(str: string, separator: string = " "): string {
    return str
      .replace(/^[\s_]+|[\s_]+$/g, "")
      .replace(/[_-\s]+/g, separator)
      .replace(/^[a-z]/, function (m) {
        return m.toUpperCase();
      });
  }

  public static toCamelCase(str: string): string {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  public static toPascalCase(str: string): string {
    return str
      .toLowerCase()
      .replace(new RegExp(/[-_]+/, "g"), " ")
      .replace(new RegExp(/[^\w\s]/, "g"), "")
      .replace(new RegExp(/\s+(.)(\w*)/, "g"), ($1, $2, $3) => `${$2.toUpperCase() + $3}`)
      .replace(new RegExp(/\w/), (s) => s.toUpperCase());
  }

  /** Estimates the mix, max, and average width of text using quick approximation (fast, no rendering). */
  public static estimateTextWidthInPx(
    text: string,
    fontSizePx: number,
    options?: { isMonospaced?: boolean },
  ): { min: number; max: number; avg: number } {
    if (!text || fontSizePx <= 0) {
      return { min: 0, max: 0, avg: 0 };
    }

    const fontWidthToHeightRatios = {
      monospaced: 0.5, // monospaced fonts
      avg: 0.6, // average fonts
    };

    const charWidthToHeightRatios = {
      thin: 0.3, // e.g., i, l, .
      wide: 0.9, // e.g., W, M, @
      avg: options?.isMonospaced ? fontWidthToHeightRatios.monospaced : fontWidthToHeightRatios.avg,
    };

    const charCount = text.length;

    const result = {
      min: Math.ceil(charCount * charWidthToHeightRatios.thin * fontSizePx),
      max: Math.ceil(charCount * charWidthToHeightRatios.wide * fontSizePx),
      avg: Math.ceil(charCount * charWidthToHeightRatios.avg * fontSizePx),
    };
    return result;
  }

  /** Measures text width using canvas text metrics (slow, rendering). */
  public static measureTextWidthInPx(
    text: string,
    font: { fontSizePx: number; fontFamily: string },
  ): number {
    if (!text) {
      return 0;
    }
    const tempCanvas = document.createElement("canvas");
    const context = tempCanvas.getContext("2d");
    if (!context) {
      throw new Error("Failed to create canvas 2d context.");
    }
    context.font = `${font.fontSizePx}px ${font.fontFamily}`;
    const metrics = context.measureText(text);
    return metrics.width;
  }
}
