import { Box } from "@mui/material";

import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { apiClient } from "@/core/api/ApiClient";
import { TenantDto, TenantReferenceDto } from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getTenantOptionTitle } from "./TenantAutocomplete";
import TenantModeChip from "./TenantModeChip";

export interface TenantInlineProps extends BaseEntityInlineInheritableProps {
  entity: TenantDto | TenantReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the entity. */
export default function TenantInline({ entity, entityId, ...otherProps }: TenantInlineProps) {
  const currentTenant = useCurrentTenant();

  const request = useApiRequest(
    apiClient.tenantsApi.apiV1TenantsTenantIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as TenantDto;
  // const ref = entity as TenantReferenceDto;
  return (
    <BaseEntityInline
      ellipsing
      entity={entity}
      request={request}
      icon={<AppIcon of='company' inText />}
      content={
        full && (
          <Box display='flex' flexDirection={"row"} alignItems='center' gap={1}>
            {getTenantOptionTitle(full, currentTenant)}
            {/* Display a chip indicating the tenant's mode only when an entityId is provided or when the entity is a full TenantDto. */}
            <TenantModeChip mode={full?.mode} />
          </Box>
        )
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Company"}
    />
  );
}
