import { ThrottleSettings, throttle } from "lodash-es";
import { DependencyList, EffectCallback, useCallback, useEffect } from "react";

import { useMemoWithDeepCompare } from "../memo/useMemoWithDeepCompare";

/** useEffect with throttled effect function. */
export function useEffectWithThrottle(
  effect: EffectCallback | (() => Promise<void>),
  wait?: number,
  options?: ThrottleSettings,
  deps?: DependencyList,
): void {
  const effectMemorized = useMemoWithDeepCompare(() => effect, [effect]);
  const effectThrottled = useCallback(throttle(effectMemorized, wait, options), [effectMemorized]);

  useEffect(() => {
    effectThrottled();
  }, deps);
}
