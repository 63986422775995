import { cloneDeep, isNull, isUndefined, values } from "lodash-es";

import { TypeHelper } from "./type";

export class ObjectHelper {
  /** Checks if all object values are empty (either null, undefined, '', false). */
  public static isValuesEmptyOrFalsy<T extends object>(obj: Nil<T>): boolean {
    return values(obj || {}).every(
      (value) => isNull(value) || isUndefined(value) || value === "" || value === false,
    );
  }

  /** Checks if object is empty.
   *  Ignores non-object types: boolean, number, string, function, etc.
   *  NB: isEmpty is not used as it treats number, boolean, Date as empty values.
   */
  public static isEmpty<T extends object>(obj: Nil<T>): boolean {
    return TypeHelper.isEmptyObject(obj);
  }

  /** Checks if all object keys are empty. */
  public static isKeysEmpty<T extends object>(obj: Nil<T>): boolean {
    return this.isEmpty(obj) || Object.keys(obj || {}).every((key) => TypeHelper.isEmpty(key));
  }

  /** Checks if all object values are empty. */
  public static isValuesEmpty<T extends object>(obj: Nil<T>): boolean {
    return (
      this.isEmpty(obj) || Object.values(obj || {}).every((value) => TypeHelper.isEmpty(value))
    );
  }

  /** Checks if either object is empty or all of its keys/values are empty. */
  public static isDeepEmpty<T extends object>(obj: Nil<T>): boolean {
    return this.isEmpty(obj) || (this.isKeysEmpty(obj) && this.isValuesEmpty(obj));
  }

  /** Removes key from the object. */
  public static removeKey<
    TKey extends CustomObjectKey,
    TValue,
    T extends CustomObject<TKey, TValue>,
  >(obj: T, key: TKey): T {
    delete obj[key];
    return obj;
  }

  /** Sets key if it doesn't exist and removes key if it exists in the object */
  public static setOrRemoveKey<
    TKey extends CustomObjectKey,
    TValue extends T[TKey],
    T extends CustomObject<TKey, TValue>,
  >(obj: T, key: TKey, value: TValue): T {
    if (key in obj) {
      this.removeKey(obj, key);
    } else {
      obj[key] = value;
    }
    return obj;
  }

  /** Compresses the specified object by removing empty keys/values recursively.
   * By default empty values are: undefined, null.
   * @returns New instance of compressed object.
   */
  public static compactObjectDeep<T>(
    obj: T,
    options?: {
      /** Custom key/value emptiness checker. */
      isEmpty?: (params: { key: CustomObjectKey; value: any }) => boolean;
    },
  ): T {
    if (
      !obj ||
      !TypeHelper.isObject(obj) ||
      TypeHelper.isArray(obj) ||
      TypeHelper.isFunction(obj)
    ) {
      return obj;
    }

    const isEmptyComputed: (params: { key: CustomObjectKey; value: any }) => boolean =
      options?.isEmpty ||
      ((params: { key: CustomObjectKey; value: any }) =>
        TypeHelper.isNil(params.key) || TypeHelper.isNil(params.value));

    const newObj = cloneDeep(obj);
    for (const key in newObj) {
      if (Object.hasOwn(newObj, key)) {
        if (TypeHelper.isPrimitive(newObj[key])) {
          if (isEmptyComputed({ key, value: newObj[key] })) {
            delete newObj[key];
          }
        }
        // NB: array is object
        else if (TypeHelper.isArray(newObj[key])) {
          const valueAsArray = newObj[key] as any[];
          newObj[key] = valueAsArray.map((x) => this.compactObjectDeep(x, options)) as any;
          if (isEmptyComputed({ key, value: newObj[key] })) {
            delete newObj[key];
          }
        } else if (
          TypeHelper.isObject(newObj[key]) &&
          !TypeHelper.isArray(newObj[key]) &&
          !TypeHelper.isFunction(newObj[key])
        ) {
          newObj[key] = this.compactObjectDeep(newObj[key], options);
          if (isEmptyComputed({ key, value: newObj[key] })) {
            delete newObj[key];
          }
        } else {
          console.error({ obj, newObj, key, value: newObj[key] });
          throw new Error("ObjectHelper.compactObject. Unhandled case.");
        }
      }
    }

    return newObj;
  }
}
