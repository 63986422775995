// {SubDomain}.{RootDomain}.{TopLevelDomain}
// {SubDomain}.{RootDomain}.localhost
import { isObject } from "lodash-es";

export type URLSearchParamsObject = Record<string, string | null | undefined>;

export class UrlHelper {
  /** Checks string is a valid absolute URL. */
  public static checkValidHttpUrl(value?: string): boolean {
    if (!value) {
      return false;
    }
    let url;
    try {
      url = new URL(value);
    } catch {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  public static getCurrentUrl(): string {
    return window.location.href;
  }

  /** http://mysubdomain.example.com -> mysubdomain,
   *  http://something.mysubdomain.example.com -> something.mysubdomain
   */
  public static getSubdomainFromUrl(url: string): string | null {
    const urlObj = new URL(url);
    const parts = urlObj.hostname.split(".");
    const minParts = 2;
    if (parts.length <= minParts) {
      return null;
    }

    return parts.slice(0, parts.length - minParts).join(".");
  }

  public static getCurrentSubdomain(): string | null {
    return this.getSubdomainFromUrl(window.location.origin);
  }

  /** http://example.com/a/b -> /a/b, http://example.com -> / */
  public static getUrlPathname(url: string): string {
    return new URL(url).pathname;
  }

  /** Returns base path (first segment of pathname)
   *  http://example.com/a/b -> /a, http://example.com -> /
   */
  public static getUrlBasePath(url: string): string {
    const pathname = this.getUrlPathname(url);
    const parts = pathname.split("/");
    parts.shift(); // drop leading empty string
    const basePath = "/" + parts[0];
    return basePath;
  }

  /** Returns URL port or default port if not specified explicitly in URL
   * https://example.com-> 443, http://example.com -> 80, https://example.com:3000 -> 3000
   */
  public static getUrlPort(url: string): string {
    const urlObj = new URL(url);
    const defaultPort = { "http:": "80", "https:": "443" }[urlObj.protocol];
    return new URL(url).port || defaultPort || "";
  }

  /** http://example.com -> http://mysubdomain.example.com */
  public static addUrlSubdomain(url: string, subdomain: string): string {
    const urlObj = new URL(url);
    const newHostname = `${subdomain}.${urlObj.hostname}`;
    return urlObj.href?.replace(urlObj.hostname, newHostname);
  }

  /** Add specified base path to URL:
   * http://example.com + /ff -> http://example.com/ff
   * http://example.com/a + /ff -> http://example.com/ff/a
   * http://example.com/a/b/c + /ff -> http://example.com/ff/a/b/c
   *  @param basePath Base path with leading slash. E.g.: /a, /newbasepath
   */
  public static addUrlBasePath(url: string, basePath: string): string {
    if (!basePath.startsWith("/")) {
      throw new Error("Base path must start with '/'.");
    }
    const urlObj = new URL(url);
    const newPathname = urlObj.pathname === "/" ? basePath : basePath + urlObj.pathname;
    return new URL(urlObj.origin + newPathname + urlObj.search).href;
  }

  /** Ads specified pathname to URL pathname.
   * http://example.com + /c -> http://example.com/c
   * http://example.com/a + /c -> http://example.com/a/c
   */
  public static addUrlPathname(url: string, pathname: string): string {
    const urlObj = new URL(url);
    return new URL(urlObj.origin + urlObj.pathname + pathname + urlObj.search).href;
  }

  /** http://example.com -> http://mysubdomain.example.com,
   *  http://mysubdomain.example.com -> http://mysubdomain2.example.com
   */
  public static updateUrlSubdomain(url: string, subdomain: string): string {
    const currentSubdomain = this.getSubdomainFromUrl(url);
    if (currentSubdomain === subdomain) {
      return url;
    }

    if (!currentSubdomain) {
      return this.addUrlSubdomain(url, subdomain);
    } else {
      return url.replace(currentSubdomain, subdomain);
    }
  }

  /** Replaces URL pathname with specified pathname.
   * http://example.com/a/b + /c/d/e -> http://example.com/c/d/e
   */
  public static updateUrlPathname(url: string, pathname: string): string {
    const urlObj = new URL(url);
    return new URL(urlObj.origin + pathname + urlObj.search).href;
  }

  /** Replaces current URL base bath with specified base.
   * http://example.com + /ff -> http://example.com/ff
   * http://example.com/a/b/c + /ff -> http://example.com/ff/b/c
   * http://example.com/a/b/c + /ff -> http://example.com/aa/b/c
   *  @param basePath Base path with leading slash . E.g.: /a, /newbasepath/ab
   */
  public static updateUrlBasePath(url: string, basePath: string): string {
    if (!basePath.startsWith("/")) {
      throw new Error("Base path must start with '/'.");
    }
    const urlObj = new URL(url);
    const newPathname =
      urlObj.pathname === "/" ? basePath : urlObj.pathname.replace(/^\/[^/]{0,}/, basePath);
    return new URL(urlObj.origin + newPathname + urlObj.search).href;
  }

  /** Returns URL search params.
   * http://example.com?a=1&b=a -> a=1&b=a
   */
  public static getUrlSearchParams(url: string): URLSearchParams {
    return new URLSearchParams(new URL(url).searchParams);
  }

  /** Returns URL search params.
   * http://example.com?a=1&b=a -> a=1&b=a
   */
  public static getUrlSearchParamsTyped<TParams extends URLSearchParamsObject>(
    url: string,
  ): TParams {
    return this.fromURLSearchParams<TParams>(this.getUrlSearchParams(url));
  }

  /** Returns URL search params of current URL.
   * http://example.com?a=1&b=a -> a=1&b=a
   */
  public static getCurrentUrlSearchParams(): URLSearchParams {
    return this.getUrlSearchParams(UrlHelper.getCurrentUrl());
  }

  /** Returns URL search params of current URL.
   * http://example.com?a=1&b=a -> a=1&b=a
   */
  public static getCurrentUrlSearchParamsTyped<TParams extends URLSearchParamsObject>(): TParams {
    return this.fromURLSearchParams<TParams>(this.getCurrentUrlSearchParams());
  }

  /** Returns URL search params string.
   * http://example.com?a=1&b=a -> a=1&b=a
   */
  public static getUrlSearch(url: string): string {
    return new URL(url).search;
  }

  /** Adds search params to URL (replaces existing values).
   * http://example.com?a=1 + {b: 2} -> http://example.com?a=1&b=2
   */
  public static updateUrlSearchParams(
    url: string,
    params: URLSearchParams | URLSearchParamsObject,
  ): string {
    const urlObj = new URL(url);

    if (params instanceof URLSearchParams) {
      params.forEach((value, key) => {
        urlObj.searchParams.append(key, value);
      });
    } else if (isObject(params)) {
      Object.keys(params).map((key) => {
        const value = params[key];
        if (urlObj.searchParams.get(key)) {
          urlObj.searchParams.delete(key);
        }
        urlObj.searchParams.append(key, value || "");
      });
    } else {
      throw new Error("Invalid params.");
    }

    return urlObj.toString();
  }

  /** Deletes search params from absolute URL.
   * http://example.com?a=1&b=2 -> http://example.com
   */
  public static deleteUrlSearchParams(url: string): string {
    const urlObj = new URL(url);
    const entries = Array.from(urlObj.searchParams.entries());
    entries.forEach(([key, value]) => {
      urlObj.searchParams.delete(key);
    });
    return urlObj.toString();
  }

  /** Deletes search params from absolute or relative URL.
   * /a/b/c?a=1&b=2 -> /a/b/c
   */
  public static deleteUrlStringSearchParams(urlString: string): string {
    return urlString.replaceAll(/\?.+$/g, "");
  }

  /** Deletes search params from absolute or relative URL.
   * http://example.com?a=1&b=2 -> http://example.com
   * /test/something?a=1&b=2 -> /test/something
   */
  public static deleteArbitraryUrlSearchParams(url: string): string {
    return url.replace(/\?.*$/g, "");
  }

  public static redirectToUrl(url: string): void {
    console.log("Redirecting to url:", url);
    (window as Window).location = url;
  }

  public static toURLSearchParams(
    params: URLSearchParamsObject,
    ignoreEmpty = false,
  ): URLSearchParams {
    const init: Record<string, string> = {};
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const value = params[key]?.toString() || "";
        const ignore = ignoreEmpty && !value;
        if (!ignore) {
          init[key] = value;
        }
      }
    }
    return new URLSearchParams(init);
  }

  public static fromURLSearchParams<TParams extends URLSearchParamsObject>(
    params: URLSearchParams,
  ): TParams {
    const result = {} as URLSearchParamsObject;
    const keys = Array.from(params.keys());
    keys.forEach((key) => {
      result[key] = params.get(key);
    });

    return result as TParams;
  }

  /** Reloads the current page. */
  public static reloadCurrentPage(): void {
    return window.location.reload();
  }
}
