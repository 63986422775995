import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function CoinStackIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.5 23C9.70238 23.008 10.8943 22.7761 12.006 22.318C12.9549 22.7706 13.9936 23.0037 15.045 22.9999C16.0963 22.9961 17.1333 22.7556 18.0789 22.2961C19.0245 21.8366 19.8545 21.1701 20.5073 20.3459C21.16 19.5217 21.6187 18.5611 21.8493 17.5353C22.0799 16.5096 22.0764 15.445 21.8393 14.4208C21.6021 13.3966 21.1372 12.4389 20.4792 11.6189C19.8212 10.7989 18.987 10.1377 18.0384 9.68432C17.0899 9.23093 16.0513 8.99704 15 9V5.333C15 2.9 12.145 1 8.5 1C4.855 1 2 2.9 2 5.333V18.667C2 21.1 4.855 23 8.5 23ZM15 11C15.9889 11 16.9556 11.2932 17.7778 11.8427C18.6001 12.3921 19.241 13.173 19.6194 14.0866C19.9978 15.0002 20.0969 16.0055 19.9039 16.9755C19.711 17.9454 19.2348 18.8363 18.5355 19.5355C17.8363 20.2348 16.9454 20.711 15.9754 20.9039C15.0055 21.0969 14.0002 20.9978 13.0866 20.6194C12.173 20.241 11.3921 19.6001 10.8427 18.7778C10.2932 17.9556 10 16.9889 10 16C10.0016 14.6744 10.5289 13.4036 11.4662 12.4662C12.4036 11.5289 13.6744 11.0016 15 11ZM8.5 3C11.152 3 13 4.23 13 5.333C13 6.436 11.152 7.667 8.5 7.667C5.848 7.667 4 6.437 4 5.333C4 4.229 5.848 3 8.5 3ZM4 8.482C5.35986 9.28958 6.91876 9.70009 8.5 9.667C10.0812 9.70009 11.6401 9.28958 13 8.482V9.3C11.4612 9.7606 10.1267 10.7347 9.219 12.06C8.98 12.081 8.743 12.111 8.5 12.111C5.848 12.111 4 10.881 4 9.778V8.482ZM4 12.927C5.29188 13.6933 6.76594 14.0984 8.268 14.1C8.09164 14.718 8.00145 15.3573 8 16C8 16.178 8.014 16.353 8.027 16.528C5.636 16.39 4 15.257 4 14.222V12.927ZM4 17.371C5.35698 18.1766 6.91221 18.5867 8.49 18.555C8.82724 19.4113 9.3298 20.1929 9.969 20.855C9.48499 20.9495 8.99314 20.998 8.5 21C5.848 21 4 19.77 4 18.667V17.371Z'
        fill='currentColor'
      />
    </SvgIcon>
  );
});
