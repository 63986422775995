import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { pageTabHeaderTypographyProps } from "@/App/Layouts/PageBody/PageTabHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import VehicleView from "@/common/components/Entity/Vehicle/View/VehicleView";
import { VehicleDamageDto } from "@/core/api/generated";

interface Props {
  vehicleDamage: VehicleDamageDto;
}

export default function VehicleTabContent({ vehicleDamage }: Props) {
  return (
    <PageTabContent>
      <VehicleView
        vehicleId={vehicleDamage.vehicle?.id}
        vehicle={undefined}
        displayProps={{
          breadcrumbs: false,
          header: false,
          actions: true,
          viewVariant: ViewLayoutVariant.Tab,
        }}
        headerProps={{
          withLink: true,
          typographyProps: pageTabHeaderTypographyProps.title,
        }}
      />
    </PageTabContent>
  );
}
