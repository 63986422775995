import { throttle } from "lodash-es";
import { useCallback, useEffect, useRef } from "react";

import { ChatUserSettingsDto } from "@/core/api/generated";
import * as chatUserSettingsSlice from "@/store/communication/chatUserSettingsSlice";

import { useAppSelector, useAppThunkDispatch } from "../redux";

export const useChatUserSetting = ({
  chatId,
  refetchIfFetchedBefore,
}: {
  chatId: string | null | undefined;
  refetchIfFetchedBefore?: boolean;
}): {
  globalSettings?: ChatUserSettingsDto;
  chatSettings?: ChatUserSettingsDto;
  settings?: ChatUserSettingsDto;
} => {
  const dispatch = useAppThunkDispatch();
  const settingsResult = useAppSelector(
    (x) => x.communication.chatUserSettings.chatUserSettingsMap[chatId || ""] || undefined,
  );
  const isRefetchedRef = useRef(false);

  const settings = settingsResult?.chat || settingsResult?.global;

  const getSettingsThrottle = useCallback(
    throttle(
      async () => {
        if (chatId) {
          await dispatch(
            chatUserSettingsSlice.getChatUserSettings({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              chatId,
            }),
          );
          isRefetchedRef.current = true;
        }
      },
      1000,
      {
        leading: true,
        trailing: false,
      },
    ),
    [dispatch, chatId],
  );

  useEffect(() => {
    if (!settings || (!isRefetchedRef.current && refetchIfFetchedBefore)) {
      getSettingsThrottle();
    }
  }, [settings, refetchIfFetchedBefore]);

  return {
    globalSettings: settingsResult?.global,
    chatSettings: settingsResult?.chat,
    settings,
  };
};
