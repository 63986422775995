import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function SunIcon(props: SvgIconProps, ref) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12647_48587)'>
        <path
          d='M9 4.5C9.19891 4.5 9.38968 4.42098 9.53033 4.28033C9.67098 4.13968 9.75 3.94891 9.75 3.75V2.25C9.75 2.05109 9.67098 1.86032 9.53033 1.71967C9.38968 1.57902 9.19891 1.5 9 1.5C8.80109 1.5 8.61032 1.57902 8.46967 1.71967C8.32902 1.86032 8.25 2.05109 8.25 2.25V3.75C8.25 3.94891 8.32902 4.13968 8.46967 4.28033C8.61032 4.42098 8.80109 4.5 9 4.5Z'
          fill='currentColor'
        />
        <path
          d='M15.75 8.25H14.25C14.0511 8.25 13.8603 8.32902 13.7197 8.46967C13.579 8.61032 13.5 8.80109 13.5 9C13.5 9.19891 13.579 9.38968 13.7197 9.53033C13.8603 9.67098 14.0511 9.75 14.25 9.75H15.75C15.9489 9.75 16.1397 9.67098 16.2803 9.53033C16.421 9.38968 16.5 9.19891 16.5 9C16.5 8.80109 16.421 8.61032 16.2803 8.46967C16.1397 8.32902 15.9489 8.25 15.75 8.25Z'
          fill='currentColor'
        />
        <path
          d='M4.5 9C4.5 8.80109 4.42098 8.61032 4.28033 8.46967C4.13968 8.32902 3.94891 8.25 3.75 8.25H2.25C2.05109 8.25 1.86032 8.32902 1.71967 8.46967C1.57902 8.61032 1.5 8.80109 1.5 9C1.5 9.19891 1.57902 9.38968 1.71967 9.53033C1.86032 9.67098 2.05109 9.75 2.25 9.75H3.75C3.94891 9.75 4.13968 9.67098 4.28033 9.53033C4.42098 9.38968 4.5 9.19891 4.5 9Z'
          fill='currentColor'
        />
        <path
          d='M4.66468 3.75002C4.51848 3.61178 4.32335 3.53727 4.12222 3.5429C3.92109 3.54853 3.73043 3.63382 3.59218 3.78002C3.45394 3.92622 3.37944 4.12135 3.38506 4.32248C3.39069 4.52362 3.47598 4.71428 3.62218 4.85252L4.70218 5.89502C4.77466 5.965 4.86049 6.01967 4.95456 6.05575C5.04863 6.09183 5.149 6.10859 5.24968 6.10502C5.35071 6.10464 5.45062 6.08384 5.54342 6.04389C5.63621 6.00394 5.71997 5.94565 5.78968 5.87252C5.92937 5.732 6.00778 5.54191 6.00778 5.34377C6.00778 5.14563 5.92937 4.95554 5.78968 4.81502L4.66468 3.75002Z'
          fill='currentColor'
        />
        <path
          d='M12.7502 6.10506C12.9433 6.10429 13.1287 6.02907 13.2677 5.89505L14.3477 4.85255C14.4795 4.71489 14.5539 4.53225 14.5558 4.34171C14.5578 4.15117 14.4872 3.96703 14.3583 3.82668C14.2294 3.68634 14.0519 3.60031 13.8619 3.58608C13.6719 3.57185 13.4836 3.63048 13.3352 3.75005L12.2552 4.81505C12.1155 4.95558 12.0371 5.14567 12.0371 5.3438C12.0371 5.54194 12.1155 5.73203 12.2552 5.87255C12.3851 6.00959 12.5618 6.09262 12.7502 6.10506Z'
          fill='currentColor'
        />
        <path
          d='M9 13.5C8.80109 13.5 8.61032 13.579 8.46967 13.7197C8.32902 13.8603 8.25 14.0511 8.25 14.25V15.75C8.25 15.9489 8.32902 16.1397 8.46967 16.2803C8.61032 16.421 8.80109 16.5 9 16.5C9.19891 16.5 9.38968 16.421 9.53033 16.2803C9.67098 16.1397 9.75 15.9489 9.75 15.75V14.25C9.75 14.0511 9.67098 13.8603 9.53033 13.7197C9.38968 13.579 9.19891 13.5 9 13.5Z'
          fill='currentColor'
        />
        <path
          d='M13.2972 12.1049C13.154 11.9667 12.9617 11.891 12.7627 11.8945C12.5636 11.898 12.3742 11.9805 12.2359 12.1237C12.0977 12.2669 12.022 12.4592 12.0255 12.6582C12.029 12.8572 12.1115 13.0467 12.2547 13.1849L13.3347 14.2499C13.4737 14.384 13.6591 14.4592 13.8522 14.4599C13.9527 14.4605 14.0523 14.4409 14.145 14.4023C14.2378 14.3636 14.3218 14.3067 14.3922 14.2349C14.4625 14.1652 14.5183 14.0823 14.5563 13.9909C14.5944 13.8995 14.614 13.8015 14.614 13.7024C14.614 13.6034 14.5944 13.5054 14.5563 13.414C14.5183 13.3226 14.4625 13.2397 14.3922 13.1699L13.2972 12.1049Z'
          fill='currentColor'
        />
        <path
          d='M4.70224 12.105L3.62224 13.1475C3.55194 13.2172 3.49615 13.3002 3.45807 13.3916C3.41999 13.483 3.40039 13.581 3.40039 13.68C3.40039 13.779 3.41999 13.8771 3.45807 13.9685C3.49615 14.0599 3.55194 14.1428 3.62224 14.2125C3.69258 14.2843 3.77663 14.3412 3.8694 14.3798C3.96216 14.4185 4.06175 14.4381 4.16224 14.4375C4.34713 14.4391 4.52608 14.3723 4.66474 14.25L5.74474 13.2075C5.88796 13.0693 5.97039 12.8798 5.97391 12.6808C5.97742 12.4818 5.90173 12.2895 5.76349 12.1463C5.62525 12.0031 5.43577 11.9206 5.23675 11.9171C5.03772 11.9136 4.84546 11.9893 4.70224 12.1275V12.105Z'
          fill='currentColor'
        />
        <path
          d='M9 6C8.40666 6 7.82664 6.17595 7.33329 6.50559C6.83994 6.83524 6.45543 7.30377 6.22836 7.85195C6.0013 8.40013 5.94189 9.00333 6.05765 9.58527C6.1734 10.1672 6.45912 10.7018 6.87868 11.1213C7.29824 11.5409 7.83279 11.8266 8.41473 11.9424C8.99667 12.0581 9.59987 11.9987 10.1481 11.7716C10.6962 11.5446 11.1648 11.1601 11.4944 10.6667C11.8241 10.1734 12 9.59334 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6ZM9 10.5C8.70333 10.5 8.41332 10.412 8.16665 10.2472C7.91997 10.0824 7.72771 9.84811 7.61418 9.57402C7.50065 9.29994 7.47095 8.99834 7.52882 8.70736C7.5867 8.41639 7.72956 8.14912 7.93934 7.93934C8.14912 7.72956 8.41639 7.5867 8.70737 7.52882C8.99834 7.47094 9.29994 7.50065 9.57403 7.61418C9.84812 7.72771 10.0824 7.91997 10.2472 8.16664C10.412 8.41332 10.5 8.70333 10.5 9C10.5 9.39782 10.342 9.77936 10.0607 10.0607C9.77936 10.342 9.39783 10.5 9 10.5Z'
          fill='currentColor'
        />
      </g>
    </SvgIcon>
  );
});
