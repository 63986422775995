import { isEmpty } from "lodash-es";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import AccessoryGrid from "@/common/components/Entity/Accessory/AccessoryGrid";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import { VehicleDto } from "@/core/api/generated";

interface Props {
  vehicle: VehicleDto;
}

export default function AccessoriesTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <ViewContentBlock>
        <PageTabHeader
          title={"Accessories"}
          subtitle2={<EntityCreatedByInfoDisplay entity={vehicle} />}
        />

        {isEmpty(vehicle.accessories) && <NoDataAlert title='No accessories added' />}

        <AccessoryGrid accessories={vehicle.accessories} sx={{ mt: 1 }} />
      </ViewContentBlock>
    </PageTabContent>
  );
}
