import { FeatureName, featureNames } from "@/common/constants/featureName";
import { FeatureManager } from "@/common/featureManagement/featureManager";
import { FeatureFlags } from "@/common/featureManagement/types";
import { EnvHelper } from "@/common/helpers/env";

/** App features config.
 *  Can mirror server-side feature flags.
 */
const featureFlags: FeatureFlags = {
  [FeatureName.RealtimeDataUpdates]: true,
  [FeatureName.ChatOnlineStatus]: true,
  [FeatureName.ChatActivity]: true,
  // [FeatureName.ChatTypingIndicatorEnabled]: true,
  [FeatureName.ChatMessageReadStatus]: true,
  [FeatureName.RepairSpecCustomItemAdd]: true,
  [FeatureName.CrossTenantRepairEntityAccess]: true,
  [FeatureName.AiAssistant]: !EnvHelper.isProductionAny,
};

export const featureManager = new FeatureManager(featureFlags);
featureManager.disableUnspecifiedFeatures(featureNames);
