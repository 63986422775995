import { BoxProps, IconButton, Stack, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import { isEmpty as _isEmpty, isNil, isNumber } from "lodash-es";
import { ReactNode, forwardRef, useMemo, useRef } from "react";

import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { clipboardService } from "@/common/services/clipboard";
import { useResponsiveValueResolver } from "@/theme/hooks";
import { ResponsiveStyleValueCustom } from "@/theme/types";

import AppTooltip from "../../AppTooltip";
import AppIcon from "../../Icons/AppIcon";
import AppTypography, { AppTypographyProps } from "../../Text/AppTypography";

export interface FieldValueProps {
  variant?: "standart" | "filled";
  size?: "normal" | "compact";
  direction?: ResponsiveStyleValueCustom<"row" | "column">;
  labelAlight?: {
    horizontal?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
    vertical?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
  };
  valueAlight?: {
    horizontal?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
    vertical?: ResponsiveStyleValueCustom<"start" | "center" | "end">;
  };
  /** Of container. */
  sx?: SxProps<Theme>;
  labelIcon?: ReactNode;
  label?: string | ReactNode;
  placeholder?: string | ReactNode;
  isEmpty?: boolean;
  withCopyButton?: boolean;
  helperTooltip?: string;
  /** Handles ellipsing for label only.
   *  Ellipsing for value must be handled by calling component.
   */
  ellipsing?: {
    enabled: boolean;
    label?: AppTypographyProps["ellipsing"];
  };
  onClick?: BoxProps["onClick"];
  children: ReactNode;
  withBorder?: boolean;
  forceValueRenderEvenIfEmpty?: boolean;
}

/** Displays field value. */
export default forwardRef<HTMLDivElement, FieldValueProps>(function FieldValue(
  {
    variant = "standart",
    size = "normal",
    direction = "row",
    labelAlight,
    valueAlight,
    sx,
    labelIcon,
    label,
    placeholder,
    isEmpty,
    withCopyButton,
    helperTooltip,
    ellipsing,
    onClick,
    withBorder = true,
    children,
    forceValueRenderEvenIfEmpty,
  }: FieldValueProps,
  ref,
) {
  const directionComputed = useResponsiveValueResolver(direction, "row");
  const { enqueueSnackbar } = useAppSnackbar();

  const valueRef = useRef<HTMLElement>(null);

  // const isLabelIconOnly = !!labelIcon && !label;

  const isChildrenEmpty = useMemo(
    () => isEmpty || isNil(children) || (!isNumber(children) && _isEmpty(children)),
    [children, isEmpty],
  );

  return (
    <Stack
      ref={ref}
      sx={{
        py: size === "compact" ? 0 : 1,
        px: size === "compact" ? 0 : 2,
        backgroundColor: (t) =>
          variant === "filled" ? t.palette.background.default : "transparent",
        borderBottom: (t) =>
          withBorder && variant === "standart" ? `1px solid ${t.palette.divider}` : "none",
        "&:last-of-type": {
          borderBottom: "none",
        },
        borderLeft: (t) => (variant === "filled" ? `2px solid ${t.palette.divider}` : "none"),
        borderTopRightRadius: (t) => (variant === "filled" ? t.shapeCustom.borderRadiusCard : 0),
        borderBottomRightRadius: (t) => (variant === "filled" ? t.shapeCustom.borderRadiusCard : 0),
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        ...sx,
      }}
      onClick={onClick}
    >
      {size === "normal" && labelIcon && (
        <>
          <Typography sx={{ fontSize: (t) => t.typography.pxToRem(26) }}>{labelIcon}</Typography>
        </>
      )}

      <Stack sx={{ minWidth: 0, flex: 1, gap: 0.5 }} direction={directionComputed}>
        {/* Title */}
        {label && (
          <AppTypography
            component='span'
            variant={size == "compact" ? "body1" : "subtitle2"}
            sx={{
              flex: 2,
              display: "grid",
              gap: 1,
              minWidth: 0,
            }}
          >
            <AppTypography
              component='span'
              sx={{
                "& > svg": { mr: 1 },
                ...(labelAlight && {
                  justifySelf: labelAlight.horizontal,
                  alignSelf: labelAlight.vertical,
                }),
              }}
              variant='inherit'
              ellipsing={
                ellipsing && {
                  enabled: ellipsing.enabled,
                  ...ellipsing.label,
                }
              }
            >
              {size === "compact" && labelIcon && <>{labelIcon}</>}

              {label}

              {helperTooltip && (
                <>
                  {" "}
                  <Tooltip title={helperTooltip}>
                    <AppIcon sx={{ ml: 0.5, verticalAlign: "middle" }} of='info' />
                  </Tooltip>
                </>
              )}
            </AppTypography>
          </AppTypography>
        )}

        {/* Value */}
        {/* NB: let parent element to handle content overflow. */}
        <AppTypography
          component='span'
          ref={valueRef}
          sx={{
            flex: 3,
            overflow: "hidden",
            display: "grid",
            ...(valueAlight && {
              justifyItems: valueAlight.horizontal,
              alignItems: valueAlight.vertical,
            }),
          }}
        >
          {!isChildrenEmpty || forceValueRenderEvenIfEmpty ? (
            <AppTypography
              component='span'
              sx={{
                ...(labelAlight && {
                  justifySelf: labelAlight.horizontal,
                  alignSelf: labelAlight.vertical,
                }),
              }}
              variant='inherit'
              ellipsing={
                ellipsing && {
                  enabled: ellipsing.enabled,
                  ...ellipsing.label,
                }
              }
            >
              {withCopyButton && (
                <AppTooltip title='Copy'>
                  <IconButton
                    size='small'
                    sx={{
                      p: 0.5,
                      mr: 0.5,
                      fontSize: "1rem",
                      width: "fit-content",
                      // display: "",
                    }}
                    onClick={async () => {
                      try {
                        const valueText = valueRef.current?.innerText;
                        await clipboardService.writeText(valueText);
                        enqueueSnackbar("Value copied to clipboard.", {
                          variant: "success",
                        });
                      } catch (error) {
                        console.error("Clipboard copy error", error);
                      }
                    }}
                  >
                    <AppIcon of='copy' />
                  </IconButton>
                </AppTooltip>
              )}

              {children}
            </AppTypography>
          ) : (
            <>{placeholder || "-"}</>
          )}
        </AppTypography>
      </Stack>
    </Stack>
  );
});
