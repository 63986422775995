import { Box, FormControl, Stack, SxProps, TextField, Theme } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect } from "react";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { CustomFormikSubProps } from "@/common/helpers/formik";
import { renderIf } from "@/common/helpers/render/renderIf";
import { AssignmentTimePeriodInputDto, AssignmentTimePeriodType } from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";

interface Props {
  formikProps: CustomFormikSubProps<AssignmentTimePeriodInputDto | null | undefined>;
  sx?: SxProps<Theme>;
  required?: boolean;
  disabled?: boolean;
  assignmentTimePeriodType?: AssignmentTimePeriodType | null | undefined;
}

export default function AssignmentTimePeriodInput({
  formikProps,
  sx,
  assignmentTimePeriodType,
  required,
  disabled,
}: Props) {
  const { values, touched, errors, setFieldValue, setValues } = formikProps;

  //set type
  useEffect(() => {
    if (assignmentTimePeriodType && values?.type !== assignmentTimePeriodType) {
      setFieldValue("type", assignmentTimePeriodType);
    }
  }, []);

  return (
    <>
      <Stack sx={sx}>
        <FormControl margin='dense' fullWidth error={Boolean(touched?.type && errors?.type)}>
          <ApiEnumAutocomplete
            type='AssignmentTimePeriodType'
            required={required}
            disabled={disabled}
            value={values?.type}
            textFieldProps={{
              label: "Period type",
              error: Boolean(errors?.type),
              helperText: errors?.type,
            }}
            onChange={(newValue) => {
              if (!newValue) {
                setValues(undefined);
              } else {
                setFieldValue("type", newValue);
                setFieldValue("startsAt", undefined);
                setFieldValue("endsAt", undefined);
                setFieldValue("dueAt", undefined);
              }
            }}
          />
        </FormControl>

        {/* Specific time period type inputs */}
        {values?.type && (
          <Box>
            {renderIf()
              .if(values.type === AssignmentTimePeriodType.Range)
              .then(
                <FormControl margin='dense' fullWidth>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: `repeat(2, 1fr)`,
                      columnGap: 2,
                    }}
                  >
                    <MobileDateTimePicker
                      ampm={false}
                      label='Starts at'
                      disabled={disabled}
                      value={(values.startsAt && moment(values.startsAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue) => {
                        setFieldValue("startsAt", newValue?.format() || null);
                      }}
                      slots={{
                        textField: (params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors?.startsAt)}
                            helperText={errors?.startsAt}
                          />
                        ),
                      }}
                    />

                    <MobileDateTimePicker
                      ampm={false}
                      label='Ends at'
                      disabled={disabled}
                      value={(values.endsAt && moment(values.endsAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue) => {
                        setFieldValue("endsAt", newValue?.format() || null);
                      }}
                      slots={{
                        textField: (params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors?.endsAt)}
                            helperText={errors?.endsAt}
                          />
                        ),
                      }}
                    />
                  </Box>
                </FormControl>,
              )
              .else(
                <FormControl margin='dense' fullWidth>
                  <MobileDateTimePicker
                    ampm={false}
                    label='Due at'
                    disabled={disabled}
                    value={(values.dueAt && moment(values.dueAt)) || null}
                    format={DATETIME_FORMATS.DISPLAY_DATETIME}
                    onChange={(newValue) => {
                      setFieldValue("dueAt", newValue?.format() || null);
                    }}
                    slots={{
                      textField: (params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors?.dueAt)}
                          helperText={errors?.dueAt}
                        />
                      ),
                    }}
                  />
                </FormControl>,
              )
              .render()}
          </Box>
        )}
      </Stack>
    </>
  );
}
