import { chain, isEmpty } from "lodash-es";
import uuid4 from "uuid4";

import { BaseSignalRServerDto, EntityChangedDtoOfTEntityDto } from "@/core/api/generated";

import { DataUpdatesHubClientMethodName } from "./dataUpdatesHubService";

export type DataUpdatesSubscriptionHandler = (
  methodName?: DataUpdatesHubClientMethodName,
  data?: any,
) => void | Promise<void>;

/** Typed handler for @see {DataUpdatesHubClientMethodName.EntityChanged} */
export type DataUpdatesSubscriptionHandlerForEntityChangedClientMethod = (
  methodName?: DataUpdatesHubClientMethodName,
  data?: EntityChangedDtoOfTEntityDto,
) => void | Promise<void>;

export type DataUpdatesSubscriptionTypedHandler<TData> = (
  methodName?: DataUpdatesHubClientMethodName,
  data?: TData,
) => void | Promise<void>;

/** Simplifies interaction with data updates events in calling code (usually React component). */
export class DataUpdatesSubscription {
  /** Unique identifier of the subscription instance */
  public readonly identifier: string;
  /** DataUpdatesChannelName:true if present. Channels are SignalR groups. */
  private channelNamesMap: Record<string, boolean>;
  /** DataUpdatesHubClientMethodName:listen or not */
  private hubMethodNamesMap: Record<string, boolean>;
  /** DataUpdatesHubClientMethodName:handlers */
  private hubMethodNameHandlersMap: Record<string, DataUpdatesSubscriptionHandler[]>;

  constructor(params: { identifier?: string; channelNames?: string[] }) {
    this.identifier = params.identifier || uuid4();
    this.channelNamesMap = chain(params.channelNames)
      .keyBy((x) => x)
      .mapValues((x) => true)
      .value();
    this.hubMethodNamesMap = {};
    this.hubMethodNameHandlersMap = {};
  }

  public get channelNames(): string[] {
    return Object.keys(this.channelNamesMap);
  }

  public reset() {
    this.channelNamesMap = {};
    this.hubMethodNamesMap = {};
    this.hubMethodNameHandlersMap = {};
  }

  /** Adds data updates event handler. */
  public on(
    dataUpdatesHubMethodNames: DataUpdatesHubClientMethodName | DataUpdatesHubClientMethodName[],
    handler: DataUpdatesSubscriptionHandler,
  ): void {
    const methodNames = Array.isArray(dataUpdatesHubMethodNames)
      ? dataUpdatesHubMethodNames
      : [dataUpdatesHubMethodNames];
    methodNames.forEach((methodName) => {
      this.hubMethodNamesMap[methodName] = true;
      this.hubMethodNameHandlersMap[methodName] = this.hubMethodNameHandlersMap[methodName] || [];
      this.hubMethodNameHandlersMap[methodName].push(handler);
    });
  }

  /** Removes data updates event handler. */
  public off(
    dataUpdatesHubMethodNames: DataUpdatesHubClientMethodName | DataUpdatesHubClientMethodName[],
  ): void {
    const methodNames = Array.isArray(dataUpdatesHubMethodNames)
      ? dataUpdatesHubMethodNames
      : [dataUpdatesHubMethodNames];
    methodNames.forEach((methodName) => {
      this.hubMethodNamesMap[methodName] = false;
      this.hubMethodNameHandlersMap[methodName] = [];
    });
  }

  public handleMethodCalled(methodName: DataUpdatesHubClientMethodName, ...args: any[]): void {
    const groupName = args
      ?.map((x) => (x as BaseSignalRServerDto)?.groupName)
      ?.filter((x) => !isEmpty(x))
      ?.at(0);

    if (this.hasMethodName(methodName) && this.hasGroupName(groupName)) {
      const data = (args && args[0]) || undefined;
      const handlers = this.hubMethodNameHandlersMap[methodName] || [];
      handlers.forEach((handler) => handler(methodName, data));
    }
  }

  public hasMethodName(methodName: DataUpdatesHubClientMethodName): boolean {
    return this.hubMethodNamesMap[methodName] || false;
  }

  public hasGroupName(groupName: string | null | undefined): boolean {
    return (groupName && this.channelNamesMap[groupName]) || false;
  }

  public addChannelName(channelName: string): void {
    if (!this.channelNamesMap[channelName]) {
      this.channelNamesMap[channelName] = true;
    }
  }

  public addChannelNames(channelNames: string[]): void {
    channelNames.forEach((x) => this.addChannelName(x));
  }
}
