import { ReactNode } from "react";

import { UseApiRequestBaseResult } from "@/common/hooks/api/useApiRequest";
import { IBaseEntityDto } from "@/core/api/generated";

import AppTooltip from "../../AppTooltip";
import AppIcon from "../../Icons/AppIcon";
import TextLineSkeleton from "../../Skeleton/TextLineSkeleton";
import AppTypography from "../../Text/AppTypography";

export interface BaseEntityInlineProps {
  entity: IBaseEntityDto | null | undefined;
  request?: UseApiRequestBaseResult;
  content: ReactNode;
  icon?: ReactNode;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
  ellipsing?: boolean;
}

export interface BaseEntityInlineInheritableProps {
  fetch?: boolean;
  withIcon?: boolean;
  withTooltip?: boolean;
  tooltipTitle?: ReactNode;
}

/** Inline brief information about the entity. */
export default function BaseEntityInline({
  entity,
  request,
  content,
  icon,
  withIcon = true,
  withTooltip = false,
  tooltipTitle = "",
  ellipsing,
}: BaseEntityInlineProps) {
  const isRequestFailed =
    (request && request.isEnded && request.responseDetails && !request.responseDetails.isOk) ??
    false;

  if (request && !request.isEnded && request.isLoading) {
    return <TextLineSkeleton sx={{ display: "inline-block", minWidth: 40 }} />;
  }
  if (!entity && !isRequestFailed) {
    return null;
  }

  return (
    <AppTooltip enabled={withTooltip} title={tooltipTitle} wrapperProps={{ component: "span" }}>
      <AppTypography isInheritFontStyle component='span' ellipsing={{ enabled: ellipsing }}>
        {isRequestFailed && (
          <span>
            {withIcon && (
              <AppTypography isInheritFontStyle component='span' sx={{ mr: 1 }}>
                <AppIcon of='error' inText />
              </AppTypography>
            )}

            <span>
              {(request?.responseDetails?.isBadRequest && "Bad request") ||
                (request?.responseDetails?.isUnauthorized && "Login required") ||
                (request?.responseDetails?.isUnauthorized && "Login required") ||
                (request?.responseDetails?.isForbidden && "Insufficient permissions") ||
                (request?.responseDetails?.isNotFound && "Entity not found") ||
                (request?.responseDetails?.isInternalServerError && "Internal error") ||
                "Error"}
            </span>
          </span>
        )}

        {!isRequestFailed && (
          <span style={{ display: "inline-flex", alignItems: "center" }}>
            {withIcon && icon && <span style={{ marginRight: "8px" }}>{icon}</span>}
            <span>{content}</span>
          </span>
        )}
      </AppTypography>
    </AppTooltip>
  );
}
