import { useRef } from "react";

import { ITenantInfo, tenantService } from "@/common/services/tenant";

/** Returns currently resolved tenant identifier. */
export const useCurrentTenantInfo = (): Nil<ITenantInfo> => {
  const tenantInfoRef = useRef<ITenantInfo>(null);
  if (!tenantInfoRef.current) {
    tenantInfoRef.current = tenantService.resolveTenant();
  }

  return tenantInfoRef.current;
};
