import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { isFunction, isNil, pick } from "lodash-es";

import { controlFields } from "../DataTabular/DataTabular";
import TableCellContent from "../DataTabular/TableCellContent";
import HighlightElement from "../HighlightElement";
import CardActionAreaFacade from "../Mui/Facades/CardActionAreaFacade";
import AppTypography from "../Text/AppTypography";
import {
  DataListDefinition,
  DataListItemColor,
  DataListItemSeverityColor,
  DataListItemVariant,
  DataListPaddings,
  DataListValidRowModel,
} from "./DataList";
import DataListRowAction from "./DataListRowAction";

export interface DataListContentOwnProps<TItem extends DataListValidRowModel> {
  itemVariant?: DataListItemVariant;
  /** Item color (border, background, etc). */
  itemColor?: DataListItemColor | ((item: TItem, index: number) => DataListItemColor);
  /** Severity color similar to Alert severity. */
  itemSeverity?:
    | DataListItemSeverityColor
    | ((item: TItem, index: number) => DataListItemSeverityColor);
}

type Props<TItem extends DataListValidRowModel> = DataListContentOwnProps<TItem> &
  DataListDefinition<TItem> & {
    paddings?: DataListPaddings;
    sx?: SxProps<Theme>;
  };

export default function DataListContent<TItem extends DataListValidRowModel>({
  itemVariant = "shadowed",
  itemColor = "none",
  itemSeverity = "none",
  columns,
  rows = [],
  columnSpacing = 2,
  rowSpacing = 1,
  size = "medium",
  getRowId,
  renderRowAction,
  rowActions,
  renderRowStart,
  renderRowEnd,
  rowTo,
  onRowClick,
  rowHighlightPropsGetter,
  paddings,
  sx,
}: Props<TItem>) {
  const theme = useTheme();
  // const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack direction='column' spacing={rowSpacing} sx={sx}>
      {rows?.map((item, i) => {
        const itemColorComputed =
          (itemColor && isFunction(itemColor) && itemColor(item, i).toString()) ||
          (itemColor && itemColor.toString()) ||
          undefined;
        const itemSeverityComputed =
          (itemSeverity && isFunction(itemSeverity) && itemSeverity(item, i).toString()) ||
          (itemSeverity && itemSeverity.toString()) ||
          undefined;

        return (
          <Card
            key={i}
            sx={{
              p: 0,
              position: "relative",
              overflow: "visible",
              backgroundColor: (th) => `${th.palette.background.default}`,
              borderLeft: (t) =>
                itemVariant === "filled"
                  ? `2px solid ${t.palette.divider}`
                  : `1px solid ${t.palette.divider}`,
              borderTop: (t) => (itemVariant === "filled" ? 0 : `1px solid ${t.palette.divider}`),
              borderRight: (t) => (itemVariant === "filled" ? 0 : `1px solid ${t.palette.divider}`),
              borderBottom: (t) =>
                itemVariant === "filled" ? 0 : `1px solid ${t.palette.divider}`,
              borderRadius: 0,
              borderTopRightRadius: (t) =>
                itemVariant === "filled" ? t.shapeCustom.borderRadiusCard : 0,
              borderBottomRightRadius: (t) =>
                itemVariant === "filled" ? t.shapeCustom.borderRadiusCard : 0,

              ...(itemColorComputed === "gray" && {
                borderColor: (th) => th.palette.divider,
              }),
              ...((itemColorComputed === "success" || itemSeverityComputed === "success") && {
                borderColor: (th) => th.palette.success.main,
              }),
              ...((itemColorComputed === "info" || itemSeverityComputed === "info") && {
                borderColor: (th) => th.palette.info.main,
              }),
              ...((itemColorComputed === "warning" || itemSeverityComputed === "warning") && {
                borderColor: (th) => th.palette.warning.main,
              }),
              ...((itemColorComputed === "error" || itemSeverityComputed === "error") && {
                borderColor: (th) => th.palette.error.main,
              }),
              ...(itemColorComputed === "selected" && {
                backgroundColor: (th) => th.palette.primary.light,
              }),
            }}
            variant={itemVariant === "shadowed" ? "elevation" : "outlined"}
          >
            <Stack
              direction={{ xxs: "column", md: "row" }}
              spacing={0}
              sx={{
                width: "100%",
                overflowX: "hidden",
                borderRadius: "inherit",
              }}
            >
              {/* Bulk action checkbox */}
              {columns
                .filter((column) => isNil(column.if) || column.if === true)
                .filter((x) => x.field === controlFields.bulkActionControl)
                .map((column) => (
                  <DataListRowAction key={column.title} paddings={paddings}>
                    {column.renderCell(item)}
                  </DataListRowAction>
                ))}

              {/* Content */}
              <CardActionAreaFacade
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  overflowX: "hidden",
                  minWidth: 0,
                }}
                enabled={!!rowTo}
                to={isFunction(rowTo) ? rowTo(item) : rowTo}
                onClick={() => onRowClick && onRowClick(item)}
              >
                <HighlightElement
                  {...((rowHighlightPropsGetter && rowHighlightPropsGetter(item)) || {})}
                  sx={{
                    minWidth: 0,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    "& > div": { width: "100%" },
                  }}
                >
                  <Stack sx={{ width: "100%" }} direction='column' spacing={0}>
                    {/* Custom content */}
                    {(() => {
                      const rowStartContent = renderRowStart && renderRowStart(item);
                      return rowStartContent ? (
                        <CardContent sx={{ px: paddings?.card?.x, py: paddings?.card?.y, pb: 0 }}>
                          {rowStartContent}
                        </CardContent>
                      ) : null;
                    })()}

                    {/* Main content */}
                    <CardContent sx={{ px: paddings?.card?.x, py: paddings?.card?.y }}>
                      <Grid key={i} container columnSpacing={columnSpacing} rowSpacing={rowSpacing}>
                        {columns
                          .filter((column) => isNil(column.if) || column.if === true)
                          .filter((x) => x.field !== controlFields.bulkActionControl)
                          .map((column, j) => {
                            let cellContentRaw = column.renderCell(item) || "-";
                            if (
                              typeof cellContentRaw === "number" ||
                              typeof cellContentRaw === "string" ||
                              typeof cellContentRaw === "boolean"
                            ) {
                              cellContentRaw = (
                                <AppTypography ellipsing={{ enabled: true }}>
                                  {cellContentRaw}
                                </AppTypography>
                              );
                            }
                            // wrap each cell content
                            const cellContent = (
                              <TableCellContent
                                isHandleOverflow={!column.isIgnoreCellOverflow}
                                renderContent={() => cellContentRaw}
                                renderDetails={
                                  column.renderCellDetails
                                    ? () =>
                                        column.renderCellDetails && column.renderCellDetails(item)
                                    : undefined
                                }
                              />
                            );

                            return (
                              <Grid
                                sx={(th) => ({
                                  [th.breakpoints.down("md")]: {
                                    // hide empty values on mobile
                                    // display: !cellContent ? "none" : undefined,
                                  },
                                })}
                                key={j}
                                width={column.width}
                                minWidth={column.minWidth}
                                maxWidth={column.maxWidth}
                                {...pick(column, theme.breakpoints.keys)} // apply breakpoint (xxs, xs, ...) props
                              >
                                <Stack
                                  direction='column'
                                  sx={{
                                    height: "100%",
                                    alignItems:
                                      (column.horizontalAlign === "start" && "flex-start") ||
                                      (column.horizontalAlign === "center" && "center") ||
                                      (column.horizontalAlign === "end" && "flex-end") ||
                                      undefined,
                                    textAlign:
                                      (column.horizontalAlign === "start" && "left") ||
                                      (column.horizontalAlign === "center" && "center") ||
                                      (column.horizontalAlign === "end" && "right") ||
                                      undefined,
                                  }}
                                >
                                  {isMobile && (
                                    <Typography component='div' variant='subtitle1'>
                                      {column.title}
                                    </Typography>
                                  )}

                                  <Box sx={{ height: "100%" }}>{cellContent}</Box>
                                </Stack>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </CardContent>

                    {/* Custom content */}
                    {(() => {
                      const rowEndContent = renderRowEnd && renderRowEnd(item);
                      return rowEndContent ? (
                        <CardContent sx={{ px: paddings?.card?.x, py: paddings?.card?.y, pt: 0 }}>
                          {rowEndContent}
                        </CardContent>
                      ) : null;
                    })()}

                    {/* Fix last item padding */}
                    <CardContent sx={{ display: "none" }}></CardContent>
                  </Stack>
                </HighlightElement>
              </CardActionAreaFacade>

              {/* Action */}
              {renderRowAction && (
                <DataListRowAction paddings={paddings}>
                  {renderRowAction({
                    item,
                  })}
                </DataListRowAction>
              )}
              {rowActions && (
                <DataListRowAction paddings={paddings}>
                  {rowActions.renderActions({
                    item,
                  })}
                </DataListRowAction>
              )}
            </Stack>
          </Card>
        );
      })}
    </Stack>
  );
}
