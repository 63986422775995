import { Box, SxProps, Theme, Typography, TypographyProps } from "@mui/material";
import { isNil } from "lodash-es";

import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { CurrencyCode, GeneralCurrencyDto, GeneralCurrencyInputDto } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props {
  value?: number | null;
  currency?: GeneralCurrencyDto | GeneralCurrencyInputDto | null;
  currencyCode?: CurrencyCode | null;
  currencyTypographyProps?: TypographyProps;
  sx?: SxProps<Theme>;
}

/** Displays number value in some currency. E.g. 10 USD.
 *  By default (if currency not provided) checks for currency info in profile settings.
 */
export default function CurrencyValue({
  sx,
  value,
  currency,
  currencyCode,
  currencyTypographyProps,
}: Props) {
  const currentCurrency = useCurrentCurrency();
  const _currencyCode = currency?.code || currencyCode || currentCurrency?.code;

  return (
    <Box
      component='span'
      // direction='row'
      // spacing={0}
      // sx={{ display: "inline-flex", alignItems: "center", ...sx }}
      sx={{ whiteSpace: "nowrap", ...sx }}
    >
      {isNil(value) ? "-" : <Box component='span'>{value}</Box>}
      <Box component='span'>&nbsp;</Box>
      {!isNil(value) && (
        <Typography
          component='span'
          variant='caption'
          color='text.secondary'
          sx={{ textDecoration: "none" }}
          {...currencyTypographyProps}
        >
          {currency?.code ||
            (currencyCode && <InlineApiEnumValue type='CurrencyCode' value={currencyCode} />) || (
              <InlineApiEnumValue type='CurrencyCode' value={_currencyCode} />
            )}
        </Typography>
      )}{" "}
    </Box>
  );
}
