import { SvgIconProps, SxProps, Theme } from "@mui/material";
import { isEmpty } from "lodash-es";

import Image from "@/common/components/Images/Image";

function getCountryFlagIconByCountryCode(countryCode: string | null | undefined): string | null {
  if (isEmpty(countryCode)) {
    return null;
  }
  return `https://raw.githubusercontent.com/nexus-ops/country-flag-icons/gh-pages/3x2/${countryCode!.toUpperCase()}.svg`;
}

interface Props extends SvgIconProps {
  countryCode: string | null | undefined;
  sx?: SxProps<Theme>;
}

export default function CountryFlagIcon({ countryCode, sx }: Props) {
  const iconUrl = getCountryFlagIconByCountryCode(countryCode);
  if (!iconUrl) {
    return null;
  }

  return (
    <Image
      src={iconUrl}
      alt={countryCode}
      sx={
        sx || {
          width: 24,
          borderRadius: 4,
          ...(sx || {}),
        }
      }
    />
  );
}
