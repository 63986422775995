import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { ReactNode, useState } from "react";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useEffectWithThrottle } from "@/common/hooks/effect/useEffectWithThrottle";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, RepairMaterialDto } from "@/core/api/generated";

import TenantLink from "../../Tenant/TenantLink";
import RepairMaterialMenu from "../RepairMaterialMenu";

const defaultHeaderProps = {
  withGoBack: true,
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps {
  repairMaterialId?: string | null;
  repairMaterial?: RepairMaterialDto;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  getFunc?: (params: {
    repairMaterialId: string;
  }) => Promise<AxiosResponse<RepairMaterialDto, unknown>>;
  deleteFunc?: (params: { repairMaterialId: string }) => Promise<AxiosResponse<unknown, unknown>>;
  onDeleted?: () => void;
}

export type RepairMaterialViewProps = OwnProps;

export default function RepairMaterialView({
  repairMaterialId,
  repairMaterial,
  headerProps,
  displayProps = defaultDisplayProps,
  getFunc,
  deleteFunc,
  onDeleted,
}: RepairMaterialViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const { hasPermissions } = useAuthorizationInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [repairMaterialFetched, setRepairMaterialFetched] = useState<RepairMaterialDto | undefined>(
    undefined,
  );

  const _repairMaterial = repairMaterial || repairMaterialFetched || undefined;

  useEffectWithThrottle(
    async () => {
      if (!repairMaterial && repairMaterialId) {
        setIsLoading(true);
        try {
          const response = getFunc
            ? await getFunc({ repairMaterialId })
            : await apiClient.repairMaterialsApi.apiV1RepairMaterialsRepairMaterialIdGet({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                repairMaterialId: repairMaterialId!,
              });
          setRepairMaterialFetched(response.data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    500,
    { leading: true, trailing: false },
    [repairMaterial, repairMaterialId, getFunc],
  );

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        _repairMaterial && {
          idValue: _repairMaterial.id!,
          newTitle: _repairMaterial.localNumber || "",
        }) ||
      undefined,
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!_repairMaterial) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <Box>
            {headerProps?.title || (
              <Typography component='div' variant='h1'>
                <span>Repair material</span>{" "}
                <AppLink
                  enabled={
                    headerProps?.withLink &&
                    hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])
                  }
                  to={ROUTE_PATH.REPAIR_MATERIAL_VIEW(_repairMaterial.id)}
                >
                  <span>{_repairMaterial.localNumber}</span>
                </AppLink>
              </Typography>
            )}
          </Box>
          {displayProps?.actions && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              sx={{ flex: 1, justifyContent: "flex-end" }}
            >
              <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                <Button
                  component={AppLink}
                  to={ROUTE_PATH.REPAIR_MATERIAL_EDIT(_repairMaterial?.id)}
                  variant='outlined'
                  color='text'
                  size='medium'
                  startIcon={<AppIcon of='edit' />}
                >
                  Edit
                </Button>
              </AuthorizedElement>

              {/* Menu */}
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <RepairMaterialMenu
                  onUpdate={(newValue) => newValue && setRepairMaterialFetched(newValue)}
                  onDelete={() => (onDeleted ? onDeleted() : history.goBack())}
                  entity={_repairMaterial}
                  deleteFunc={deleteFunc}
                  displayProps={{
                    actions: {
                      edit: false,
                      delete: true,
                    },
                  }}
                />
              </AuthorizedElement>
            </Stack>
          )}
        </Stack>
      }
    >
      <ViewContentBlock>
        {currentTenant?.id && _repairMaterial.tenantId !== currentTenant?.id && (
          <FieldValue label='Company' isEmpty={!_repairMaterial.tenantId}>
            <TenantLink entityId={_repairMaterial.tenantId} entity={undefined} />
          </FieldValue>
        )}

        <FieldValue label='Name'>{_repairMaterial.name}</FieldValue>

        <FieldValue label='Description'>{_repairMaterial.description}</FieldValue>

        <FieldValue label='Brand name'>{_repairMaterial.brandName}</FieldValue>

        <FieldValue label='Dividable?'>
          <BooleanValue value={_repairMaterial.isDividable} />
        </FieldValue>

        <FieldValue label='Unit'>
          <InlineApiEnumValue type='MeasurementUnit' value={_repairMaterial.unit} withDescription />
        </FieldValue>

        <FieldValue label='Currency'>
          <GeneralCurrencyDisplay currency={_repairMaterial.currency} />
        </FieldValue>

        <FieldValue label='Price'>
          <CurrencyValue value={_repairMaterial.price} currency={_repairMaterial.currency} />
        </FieldValue>
      </ViewContentBlock>

      {/* Summary */}
      <ViewContentBlock alignSelf='flex-end'>
        <GeneralPriceSummaryDisplay
          sx={{ minWidth: { xxs: "100%", md: "300px" } }}
          summary={{
            currency: _repairMaterial.currency,
            subTotal: _repairMaterial.subTotal,
            subTotalIncDiscount: _repairMaterial.subTotalIncDiscount,
            discount: _repairMaterial.discount,
            tax: _repairMaterial.tax,
            total: _repairMaterial.total,
          }}
        />
      </ViewContentBlock>
    </ViewLayoutV2>
  );
}
