import { Chip, ChipProps } from "@mui/material";

import { VehicleDamageExtent } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props {
  value?: VehicleDamageExtent;
}

const colorMap: Record<VehicleDamageExtent, ChipProps["color"]> = {
  [VehicleDamageExtent.None]: "success",
  [VehicleDamageExtent.NoDamage]: "success",
  [VehicleDamageExtent.VeryMinor]: "warning",
  [VehicleDamageExtent.Minor]: "warning",
  [VehicleDamageExtent.Moderate]: "warning",
  [VehicleDamageExtent.Severe]: "error",
  [VehicleDamageExtent.VerySevere]: "error",
  [VehicleDamageExtent.Total]: "error",
};

export default function VehicleDamageExtentInline({ value }: Props) {
  return (
    <Chip
      label={<InlineApiEnumValue type='VehicleDamageExtent' value={value} />}
      color={colorMap[value!]}
    />
  );
}
