import { Grid2, Stack, StackProps } from "@mui/material";
import { Box } from "@mui/system";
import { isNil } from "lodash-es";
import { useEffect, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  VehicleVisualModelImageDto,
  VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest,
} from "@/core/api/generated";

import AppIcon from "../Icons/AppIcon";
import { DamagePointsViewer } from "./DamagePointsViewer";
import DamagePointsVisualizer from "./DamagePointsVisualizer";

interface Props {
  visualModelId: string | null | undefined;
  visualModelLatestOrDefaultFor?: VehicleVisualModelsApiApiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGetRequest;
  damages: Array<any>;
  sx?: StackProps["sx"];
}

export default function DamagePointsByVisualModelViewer({
  sx,
  visualModelId,
  visualModelLatestOrDefaultFor,
  damages,
}: Props) {
  const [selectedPerspective, setSelectedPerspective] = useState<
    VehicleVisualModelImageDto | undefined | null
  >(null);

  const visualModelByIdRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsModelIdGet,
    {
      modelId: visualModelId!,
    },
    {
      skip: !visualModelId,
    },
  );
  const visualModelById = visualModelByIdRequest.data;

  const visualModelLatestOrDefaultRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsLatestOrDefaultGet,
    {
      ...visualModelLatestOrDefaultFor,
    },
    {
      skip: !!visualModelId || isNil(visualModelLatestOrDefaultFor),
    },
  );
  const visualModelLatestOrDefault = visualModelLatestOrDefaultRequest.data;

  const visualModel = visualModelById || visualModelLatestOrDefault;

  useEffect(() => {
    if (visualModel && visualModel.images && visualModel.images.length > 0) {
      setSelectedPerspective(visualModel.images[0]);
    }
  }, [visualModel]);

  if (!visualModel) {
    return <></>;
  }

  return (
    <Stack direction={{ xs: "column", md: "column" }} sx={sx}>
      <DamagePointsViewer
        imageUrl={selectedPerspective?.file?.url}
        containerSx={{
          height: `500px`,
          width: `500px`,
        }}
        pointsInfos={
          damages?.filter((item) => {
            return item.projection === selectedPerspective?.projection;
          }) || []
        }
        selectedPerspective={true}
        onPointClicked={(editingPointInfo) => {}}
      />

      {/* <Stack spacing={1} sx={{ mb: 1 }}> */}
      <Grid2 container gap={1} sx={{ ml: 1 }}>
        {visualModel?.images?.map((img, index) => {
          const file = FileItem.createFrom(img);
          const points =
            damages?.filter((item) => {
              return item.projection === img?.projection;
            }) || [];

          return (
            <Grid2 key={index} size={{ xs: 12, md: 3 }}>
              <Box
                sx={{
                  backgroundColor:
                    img.id === selectedPerspective?.id
                      ? "transparent"
                      : (theme) => theme.palette.background.gray,
                  borderRadius: "8px",
                  border:
                    img.id === selectedPerspective?.id
                      ? "2px solid #78BEFF"
                      : "2px solid transparent",
                  width: `auto`,
                  cursor: "pointer",
                  "& svg": {
                    pointerEvents: "none",
                  },
                  position: "relative",
                }}
                onClick={() => {
                  setSelectedPerspective(img);
                }}
              >
                {points.length > 0 && (
                  <AppIcon
                    sx={{
                      position: "absolute",
                      zIndex: 1,
                      top: 5,
                      right: 5,
                      color: "success.main",
                    }}
                    of='successFilled'
                    fontSize='medium'
                  />
                )}

                <DamagePointsVisualizer
                  pointCircleRadius={3}
                  pointsInfo={[]}
                  containerSx={{
                    width: "100px",
                    height: "auto",
                    overflow: "hidden",
                  }}
                  imageUrl={file?.file?.url}
                />
              </Box>
            </Grid2>
          );
        })}
      </Grid2>

      {/* </Stack> */}
    </Stack>
  );
}
