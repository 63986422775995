import { Box, Stack, Tab, Tabs } from "@mui/material";
import { ReactNode } from "react";

import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import PageTabsManagement from "@/common/components/Tab/PageTabsManagement";
import { UseCommonViewParamsHookResult } from "@/common/hooks/layout/useCommonViewParams";
import {
  PageTabDefinitionList,
  TabIdsDefinition,
  UsePageTabsHookResult,
  usePageTabs,
} from "@/common/hooks/layout/usePageTabs";

import { ViewLayoutVariant } from "../ViewLayout";

export interface PageTabsRenderFuncParams<TTabId extends string>
  extends UsePageTabsHookResult<TTabId> {}

interface Props<TTabId extends string> {
  /** Tab ids. */
  tabIdsDefinition: TabIdsDefinition<TTabId>;
  /** Tab that is active by default. */
  defaultTabId: TTabId;
  /** All tab definitions. */
  tabs: PageTabDefinitionList<TTabId>;
  /** Display these tabs. */
  onlyTabIds?: TTabId[];
  /** Don't display these tabs. */
  excludeTabIds?: TTabId[];
  /** If `true`, enables tab visibility management. By default enabled if tab persistence is enabled. */
  isTabManagementEnabled?: boolean;
  viewVariant?: ViewLayoutVariant;
  /** Integration with `CommonViewParams`. */
  commonViewParams?: UseCommonViewParamsHookResult;
  /** Custom tabs render function. */
  renderTabs?: (tabParams: PageTabsRenderFuncParams<TTabId>) => ReactNode;
  /** Tabs content. */
  children: (tabParams: PageTabsRenderFuncParams<TTabId>) => ReactNode;
}

/** @deprecated - Use PageTabsV2. */
export default function PageTabs<TTabId extends string>({
  tabIdsDefinition: tabsDefinition,
  defaultTabId,
  tabs,
  onlyTabIds,
  excludeTabIds,
  isTabManagementEnabled,
  viewVariant = ViewLayoutVariant.Page,
  commonViewParams,
  renderTabs,
  children,
}: Props<TTabId>) {
  const pageTabs = usePageTabs<TTabId>({
    tabIdsDefinition: tabsDefinition,
    defaultTabId,
    tabs,
    onlyTabIds,
    excludeTabIds,
    isTabManagementEnabled,
    commonViewParams,
  });

  return (
    <Stack
      sx={{
        background: (t) => t.palette.background.paper,
        borderRadius: "inherit",
      }}
      direction='column'
    >
      {/* Display based on view variants: */}
      {/* Page || Modal - display tabs and content */}
      {/* Tab - display content only */}
      {(viewVariant === ViewLayoutVariant.Page || viewVariant === ViewLayoutVariant.Modal) && (
        <Box
          sx={{
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
            borderBottom: (t) => `0.5px solid ${t.palette.divider}`,
            mb: 2,
            backgroundColor: (t) => t.palette.background.paper,
          }}
        >
          <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
            {renderTabs && renderTabs(pageTabs)}

            {!renderTabs && (
              <Tabs
                value={pageTabs.activeTabId}
                onChange={(e, v) => pageTabs.setActiveTab(v)}
                sx={{ width: "100%", minHeight: 0 }}
                variant='scrollable'
              >
                {pageTabs.visibleTabs.map((tab, i) => (
                  <Tab key={i} label={tab.label} value={tab.value} {...tab.tabProps} />
                ))}
              </Tabs>
            )}

            {/* Tabs management */}
            {pageTabs.isTabManagementEnabled && (
              <Stack
                direction='row'
                sx={{ pr: 0.5, justifySelf: "flex-end", justifyContent: "flex-end" }}
              >
                <AppPopover
                  clickBehavior={{}}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  trigger={
                    <AppIconButton variant='default' tooltipProps={{ title: "Configure tabs" }}>
                      <AppIcon of='settings' />
                    </AppIconButton>
                  }
                >
                  <AppPopoverContent
                    sx={{
                      width: 300,
                      maxWidth: 300,
                      maxHeight: {
                        xxs: "90vh",
                        md: 400,
                      },
                      // let the child to handle scroll
                      p: 0,
                    }}
                    isUseDefaultStyles={false}
                  >
                    <PageTabsManagement
                      manageableTabs={pageTabs.manageableTabs}
                      initialTabVisibilityMap={pageTabs.initialTabVisibilityMap}
                      visibilityMap={pageTabs.tabVisibilityMap}
                      onVisibilityMapChange={(newValue) => {
                        pageTabs.setTabVisibilityMap(newValue);
                      }}
                    />
                  </AppPopoverContent>
                </AppPopover>
              </Stack>
            )}
          </Stack>
        </Box>
      )}

      <Box>{children(pageTabs)}</Box>
    </Stack>
  );
}
