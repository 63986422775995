import { Button, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";

import AppPopover, { AppPopoverHandle } from "@/common/components/Popover/AppPopover";
import AppPopoverActions from "@/common/components/Popover/AppPopoverActions";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";

export interface AvailableFilters {
  damageType: boolean;
  partType: boolean;
  make: boolean;
  model: boolean;
}
export interface RepairFilterFlags {
  damageType?: boolean;
  partType?: boolean;
  make?: boolean;
  model?: boolean;
}

export interface BaseRepairCustomFilterPopoverProps {
  availableFilters: AvailableFilters;
  initialFlags?: RepairFilterFlags;

  onSave: (enabledFlags: {
    damageType?: boolean;
    partType?: boolean;
    make?: boolean;
    model?: boolean;
  }) => void;

  onCancel?: () => void;
  trigger?: ((isOpen: boolean) => ReactElement) | ReactElement;
}

export default function BaseRepairCustomFilterPopover({
  availableFilters,
  initialFlags,
  onSave,
  onCancel,
  trigger,
}: BaseRepairCustomFilterPopoverProps) {
  const [useDamageType, setUseDamageType] = useState(false);
  const [usePartType, setUsePartType] = useState(false);
  const [useMake, setUseMake] = useState(false);
  const [useModel, setUseModel] = useState(false);
  const appPopoverRef = useRef<AppPopoverHandle>(null);

  useEffect(() => {
    if (initialFlags) {
      setUseDamageType(Boolean(initialFlags.damageType));
      setUsePartType(Boolean(initialFlags.partType));
      setUseMake(Boolean(initialFlags.make));
      setUseModel(Boolean(initialFlags.model));
    }
  }, [initialFlags]);

  const handleApply = () => {
    const flags: RepairFilterFlags = {};

    flags.damageType = availableFilters.damageType && useDamageType;
    flags.partType = availableFilters.partType && usePartType;
    flags.make = availableFilters.make && useMake;
    flags.model = availableFilters.model && useModel;

    onSave(flags);
    appPopoverRef.current?.close();
  };

  const handleCancel = () => {
    onCancel?.();
    appPopoverRef.current?.close();
  };

  return (
    <AppPopover ref={appPopoverRef} clickBehavior={{}} trigger={trigger}>
      <AppPopoverContent sx={{ minWidth: 250 }}>
        <Typography variant='subtitle1' sx={{ mb: 1 }}>
          Custom filter
        </Typography>

        {availableFilters.damageType && (
          <FormControlLabel
            control={
              <Checkbox
                checked={useDamageType}
                onChange={(e) => setUseDamageType(e.target.checked)}
              />
            }
            label='Filter by damage type'
          />
        )}

        {availableFilters.partType && (
          <FormControlLabel
            control={
              <Checkbox checked={usePartType} onChange={(e) => setUsePartType(e.target.checked)} />
            }
            label='Filter by part type'
          />
        )}

        {availableFilters.make && (
          <FormControlLabel
            control={<Checkbox checked={useMake} onChange={(e) => setUseMake(e.target.checked)} />}
            label='Filter by make'
          />
        )}

        {availableFilters.model && (
          <FormControlLabel
            control={
              <Checkbox checked={useModel} onChange={(e) => setUseModel(e.target.checked)} />
            }
            label='Filter by model'
          />
        )}

        <AppPopoverActions sx={{ mt: 2 }}>
          <Button variant='outlined' onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant='contained' onClick={handleApply}>
            Apply
          </Button>
        </AppPopoverActions>
      </AppPopoverContent>
    </AppPopover>
  );
}
