import { Stack, StackProps } from "@mui/material";

type FormContentBlockProps<T extends React.ElementType> = StackProps<T, { component?: T }>;

function FormContentBlock<T extends React.ElementType = "div">({
  children,
  ...otherProps
}: FormContentBlockProps<T>) {
  return (
    <Stack
      {...otherProps}
      sx={{
        p: 1.5,
        backgroundColor: (t) => t.palette.background.paper,
        borderRadius: (t) => t.shapeCustom.borderRadiusCard,
        border: (t) => `1px solid ${t.palette.divider}`,
        ...otherProps?.sx,
      }}
    >
      {children}
    </Stack>
  );
}

export default FormContentBlock;
