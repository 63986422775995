import { EntityType, GeneralScopeType } from "@/core/api/generated";

export const ENTITY_TYPE_GENERAL_SCOPE_TYPE_MAP: Partial<Record<EntityType, GeneralScopeType>> = {
  [EntityType.Vehicle]: GeneralScopeType.Vehicle,
  [EntityType.Contract]: GeneralScopeType.Contract,
  [EntityType.AssessmentFlow]: GeneralScopeType.AssessmentFlow,
  [EntityType.VisualInspection]: GeneralScopeType.VisualInspection,
  [EntityType.DamageDetection]: GeneralScopeType.DamageDetection,
  [EntityType.DamageDetectionAggregate]: GeneralScopeType.DamageDetectionAggregate,
  [EntityType.DamageCostEvaluation]: GeneralScopeType.DamageCostEvaluation,
  [EntityType.DamageCostEvaluationAggregate]: GeneralScopeType.DamageCostEvaluationAggregate,
  [EntityType.AccessoryCheck]: GeneralScopeType.AccessoryCheck,
  [EntityType.RepairOperation]: GeneralScopeType.RepairOperation,
  [EntityType.WheelOperation]: GeneralScopeType.WheelOperation,
  [EntityType.Document]: GeneralScopeType.Document,
  [EntityType.DocumentItem]: GeneralScopeType.DocumentItem,
  [EntityType.TenantConnectionRequest]: GeneralScopeType.TenantConnectionRequest,
  [EntityType.TenantConnection]: GeneralScopeType.TenantConnection,
  [EntityType.TenantRequest]: GeneralScopeType.TenantRequest,
  [EntityType.Wash]: GeneralScopeType.Wash,
  [EntityType.Maintenance]: GeneralScopeType.Maintenance,
  [EntityType.Assignment]: GeneralScopeType.Assignment,
};
