import { Box, LinearProgress } from "@mui/material";
import { Ref, forwardRef, useImperativeHandle, useRef } from "react";

import { ChatContext, chatContextValue } from "@/common/contexts/chat";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { ChatDto, ChatType, GeneralScopeDto } from "@/core/api/generated";
import { leaveChat } from "@/store/communication/chatParticipantsSlice";
import { acknowledgeChat, reopenChat, resolveChat } from "@/store/communication/chatsSlice";
import {
  selectChat,
  selectChatCurrentParticipant,
  selectIsChatClosing,
  selectIsChatOpening,
} from "@/store/communication/selectors";

import ChatSkeleton from "../../Skeleton/ChatSkeleton";
import { ChatHistoryProps } from "../ChatHistory/ChatHistory";
import { ChatMessageInputFeaturesProps } from "../ChatMessage/Input/ChatMessageInput";
import ChatDisplay, { ChatDisplayHandle, ChatDisplayProps, ChatSize } from "./ChatDisplay";

export interface OwnProps {
  chatType: ChatType;
  chatId?: string | null;
  scope?: GeneralScopeDto | null;

  allowPin?: boolean;
  allowClose?: boolean;
  allowLeave?: boolean;
  allowAttachments?: boolean;
  allowOpenInNewTab?: boolean;
  size?: ChatSize;
  disabled?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  maxHeight?: string | number;
  chatHistoryProps?: ChatHistoryProps;
  chatInputFeatures?: ChatMessageInputFeaturesProps;
  displayProps?: ChatDisplayProps;
  onPin?: (chat: ChatDto) => void;
  onClose?: (chat: ChatDto) => void;
  onSendMessage?: () => void;
}

export type ChatProps = OwnProps;

interface Props extends OwnProps {
  chat?: ChatDto;
}

export default forwardRef(function Chat(
  {
    chat,
    chatId,
    chatType,

    scope,
    allowPin,
    allowClose,
    allowLeave,
    allowAttachments,
    allowOpenInNewTab,
    size,
    disabled,
    fullWidth,
    fullHeight,
    maxHeight,
    chatHistoryProps,
    chatInputFeatures,
    displayProps,
    onPin,
    onClose,
    onSendMessage,
  }: Props,
  ref: Ref<ChatDisplayHandle>,
) {
  const currentChat = useAppSelector(
    (state) => chat || selectChat(state, { chatId: chatId, scope: scope }),
  );
  const currentChatId = chatId || currentChat?.id || "";
  const isScopedChat = !!scope;
  const isValidScope = isScopedChat ? !!scope?.identifier : true;
  const thunkDispatch = useAppThunkDispatch();
  const isChatOpening = useAppSelector((state) => selectIsChatOpening(state, currentChat?.id));
  const paginatedParticipants = useAppSelector(
    (state) =>
      state.communication.chatParticipants.paginatedChatParticipantMap[currentChatId] || undefined,
  );
  const hasPinnedItems = useAppSelector(
    (state) =>
      state.communication.chatHistory.pinnedChatHistoryItems[currentChatId]?.items?.length !== 0 ||
      false,
  );
  const isChatClosing = useAppSelector((state) => selectIsChatClosing(state, currentChat?.id));
  const currentParticipant = useAppSelector((state) =>
    selectChatCurrentParticipant(state, currentChat?.id),
  );

  const chatRef = useRef<ChatDisplayHandle>(null);
  useImperativeHandle(ref, () => chatRef.current as ChatDisplayHandle, [chatRef.current]);

  const _onClose = (_chat: ChatDto) => {
    onClose && onClose(_chat);
  };

  if (!isValidScope) {
    return <Box>Invalid chat scope specified!</Box>;
  }

  if (isChatOpening) {
    return <ChatSkeleton />;
  }

  return (
    (currentChat && (
      <ChatContext.Provider value={chatContextValue}>
        {isChatClosing && <LinearProgress sx={{ my: 1 }} />}

        <ChatDisplay
          ref={chatRef}
          disabled={disabled}
          chat={currentChat}
          currentParticipant={currentParticipant}
          participants={paginatedParticipants?.items || []}
          hasPinnedItems={hasPinnedItems}
          resolveChat={(x) => thunkDispatch(resolveChat(x))}
          reopenChat={(x) => thunkDispatch(reopenChat(x))}
          acknowledgeChat={(x) => thunkDispatch(acknowledgeChat(x))}
          leaveChat={(x) => thunkDispatch(leaveChat(x))}
          allowPin={allowPin}
          allowClose={allowClose}
          allowLeave={allowLeave}
          allowAttachments={allowAttachments}
          allowOpenInNewTab={allowOpenInNewTab}
          size={size}
          fullWidth={fullWidth}
          fullHeight={fullHeight}
          maxHeight={maxHeight}
          chatHistoryProps={chatHistoryProps}
          chatInputFeatures={chatInputFeatures}
          displayProps={displayProps}
          onPin={onPin}
          onClose={_onClose}
          onSendMessage={onSendMessage}
        />
      </ChatContext.Provider>
    )) ||
    null
  );
});
