import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AxiosResponse } from "axios";
import { Formik } from "formik";
import moment from "moment";
import { useMemo, useRef, useState } from "react";
import * as Yup from "yup";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import AccessoriesSelectOrCreate from "@/common/components/Entity/Accessory/AccessoriesSelectOrCreate";
import { DATETIME_FORMATS } from "@/common/constants/common";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FormikHelper } from "@/common/helpers/formik";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  ContractType,
  CreateContractDto,
  EntityType,
  PaginationDtoOfContractDto,
  SpotType,
  UpdateContractDto,
  VehicleType,
} from "@/core/api/generated";

import AppTooltip from "../../AppTooltip";
import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import FormikComputedField from "../../Form/Formik/FormikComputedField";
import AppTextArea from "../../Form/Input/AppTextArea";
import AppTypography from "../../Text/AppTypography";
import CustomerAutocompleteOrCreate from "../Customer/CustomerAutocompleteOrCreate";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import ContractLink from "./ContractLink";
import ContractSettingsInput from "./ContractSettingsInput";
import ContractSpotInfoInput from "./ContractSpotInfoInput";

type DefaultValues = {
  vehicleId?: CreateContractDto["vehicleId"];
  customerId?: CreateContractDto["customerId"];
  externalNumber?: CreateContractDto["externalNumber"];
};

export interface ContractCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<ContractDto, DefaultValues> {
  contractId?: string;
}

export type ContractCreateUpdateProps = ContractCreateUpdateOwnProps;

export default function ContractCreateUpdate({
  contractId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: ContractCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const userAffiliation = useUserAffiliation();

  const isCreate = !contractId;
  const isEdit = !!contractId;
  const canUpdateVehicle = isCreate;

  const isCheckOutDateEditedRef = useRef(false);
  const isCheckInDateEditedRef = useRef(false);

  const contractRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsContractIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      contractId: contractId!,
    },
    {
      skip: !contractId,
    },
  );
  const contract = contractRequest?.data;

  const createMetaRequest = useApiRequest(
    apiClient.contractsApi.apiV1ContractsCreateMetaGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {
      deps: [],
    },
  );
  const createMeta = createMetaRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: contract && {
      idValue: contract.id!,
      newTitle: contract.localNumber || "",
    },
  });

  // const formik = useFormik<CreateContractDto & UpdateContractDto & BaseFormikValues>({
  //   enableReinitialize: true,
  //   initialValues: {
  //     externalNumber: defaultValues?.externalNumber || contract?.externalNumber || undefined,
  //     vehicleId: defaultValues?.vehicleId || contract?.vehicle?.id || undefined,
  //     accessoryIds: contract?.accessories?.map((x) => x.id!) || [],
  //     customerId: defaultValues?.customerId || contract?.customer?.id || undefined,
  //     type: contract?.type || ContractType.Rental,
  //     startsAt: contract?.startsAt || null,
  //     endsAt: contract?.endsAt || null,
  //     departmentId:
  //       contract?.departmentIds?.at(0) ||
  //       userAffiliation.departments?.at(0) ||
  //       undefined,
  //     locationId:
  //       contract?.locationIds?.at(0) || userAffiliation.locations?.at(0) || undefined,
  //     checkOutSpotInfo:
  //       (contract?.checkOutSpotInfo
  //         ? {
  //             ...contract?.checkOutSpotInfo,
  //             spotId: contract?.checkOutSpotInfo.spot?.id,
  //           }
  //         : undefined) || undefined,
  //     checkInSpotInfo:
  //       (contract?.checkInSpotInfo
  //         ? {
  //             ...contract?.checkInSpotInfo,
  //             spotId: contract?.checkInSpotInfo.spot?.id,
  //           }
  //         : undefined) || undefined,
  //     notes: contract?.notes || undefined,
  //     isConfirmVehicleAllocation: true,
  //     settings: contract?.settings || undefined,
  //     tags: contract?.tags || undefined,
  //     submit: "",
  //   },
  //   validationSchema: Yup.object().shape({
  //     // vehicleId: Yup.string().required("Vehicle is required"),
  //     // customerId: Yup.string().required("Customer is required"),
  //     // type: Yup.string().required("Type is required"),
  //   }),
  //   onSubmit:
  // });

  // const {
  //   errors,
  //   handleBlur,
  //   handleChange,
  //   handleSubmit,
  //   isSubmitting,
  //   touched,
  //   values,
  //   setErrors,
  //   setFieldValue,
  //   setValues,
  //   setStatus,
  //   setSubmitting,
  //   setFieldError,
  // } = formik;

  const [alreadyExistsRelatedContracts, setAlreadyExistsRelatedContracts] = useState<ContractDto[]>(
    [],
  );

  // const alreadyExistsRelatedContractsRequest = useApiRequest(
  //   apiClient.contractsApi.apiV1ContractsSearchPost,
  //   {
  //     nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
  //     contractSearchPaginatedDto: {
  //       customerId: values.customerId || undefined,
  //       vehicleId: values.vehicleId || undefined,
  //       range: {
  //         from: values.startsAt || undefined,
  //         to: values.endsAt || undefined,
  //       },
  //     },
  //   },
  //   {
  //     debouncedDeps: {
  //       deps: [values],
  //       wait: 100,
  //     },
  //     skip: !values.customerId || !values.vehicleId || !(values.startsAt && values.endsAt),
  //   },
  // );

  const showOtherContractsFoundAlert = useMemo(() => {
    return alreadyExistsRelatedContracts && alreadyExistsRelatedContracts.length !== 0
      ? alreadyExistsRelatedContracts.filter((x) => x.id !== contractId).length !== 0
      : false;
  }, [alreadyExistsRelatedContracts]);

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Contract}
      entityId={contractId}
      entity={contract}
      entityRequest={contractRequest}
      isIniting={userAffiliation.isIniting}
    >
      <Formik<CreateContractDto & UpdateContractDto & BaseFormikValues>
        enableReinitialize={true}
        initialValues={{
          externalNumber: defaultValues?.externalNumber || contract?.externalNumber || undefined,
          vehicleId: defaultValues?.vehicleId || contract?.vehicle?.id || undefined,
          accessoryIds: contract?.accessories?.map((x) => x.id!) || [],
          customerId: defaultValues?.customerId || contract?.customer?.id || undefined,
          type: contract?.type || ContractType.Rental,
          startsAt: contract?.startsAt || null,
          endsAt: contract?.endsAt || null,
          departmentId:
            contract?.departmentIds?.at(0) || userAffiliation.departments?.at(0)?.id || undefined,
          locationId:
            contract?.locationIds?.at(0) || userAffiliation.locations?.at(0)?.id || undefined,
          checkOutSpotInfo:
            (contract?.checkOutSpotInfo
              ? {
                  ...contract?.checkOutSpotInfo,
                  spotId: contract?.checkOutSpotInfo.spot?.id,
                }
              : undefined) || undefined,
          checkInSpotInfo:
            (contract?.checkInSpotInfo
              ? {
                  ...contract?.checkInSpotInfo,
                  spotId: contract?.checkInSpotInfo.spot?.id,
                }
              : undefined) || undefined,
          notes: contract?.notes || undefined,
          isConfirmVehicleAllocation: true,
          settings: contract?.settings || createMeta?.settings || undefined,
          tags: contract?.tags || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
          // customerId: Yup.string().required("Customer is required"),
          // type: Yup.string().required("Type is required"),
        })}
        onSubmit={async (values, { setFieldValue, setFieldError, setStatus, setSubmitting }) => {
          try {
            const values2 = {
              ...values,
              startsAt: values.startsAt && moment(values.startsAt).utc().format(),
              endsAt: values.endsAt && moment(values.endsAt).utc().format(),
              checkOutSpotInfo: {
                ...values.checkOutSpotInfo,
                date:
                  values.checkOutSpotInfo?.date &&
                  moment(values.checkOutSpotInfo?.date).utc().format(),
              },
              checkInSpotInfo: {
                ...values.checkInSpotInfo,
                date:
                  values.checkInSpotInfo?.date &&
                  moment(values.checkInSpotInfo?.date).utc().format(),
              },
            };

            if (isCreate) {
              const response = await apiClient.contractsApi.apiV1ContractsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createContractDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Contract created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.contractsApi.apiV1ContractsContractIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                contractId,
                updateContractDto: {
                  ...values2,
                },
              });
              enqueueSnackbar("Contract updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {(formik) => {
          const {
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          } = formik;
          return (
            <>
              <FormikComputedField<
                any,
                {
                  alreadyExistsRelatedContracts: ContractDto[];
                }
              >
                deps={[values.vehicleId, values.customerId, values.startsAt, values.endsAt]}
                compute={async () => {
                  let response: AxiosResponse<PaginationDtoOfContractDto, any> | undefined =
                    undefined;
                  if (values.customerId && values.vehicleId && (values.startsAt || values.endsAt)) {
                    response = await apiClient.contractsApi.apiV1ContractsSearchPost({
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      contractSearchPaginatedDto: {
                        customerId: values.customerId || undefined,
                        vehicleId: values.vehicleId || undefined,
                        range: {
                          from: values.startsAt || undefined,
                          to: values.endsAt || undefined,
                        },
                      },
                    });
                  }

                  const items = response?.data?.items || [];
                  return {
                    alreadyExistsRelatedContracts: items?.filter((x) => x.id !== contractId) || [],
                  };
                }}
                onComputed={(computed) => {
                  setAlreadyExistsRelatedContracts(computed.alreadyExistsRelatedContracts);
                }}
              />
              <FormContentBlock gap={1}>
                {showOtherContractsFoundAlert && (
                  <Alert severity='info'>
                    Already created{" "}
                    {alreadyExistsRelatedContracts.map((x) => (
                      <ContractLink key={x.id} entity={x} withTooltip withIcon={false} />
                    ))}{" "}
                    was found for selected customer or vehicle in selected date range.
                  </Alert>
                )}

                {/* Tags */}
                <FormControl margin='dense' fullWidth>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                  <FormHelperText error>
                    {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                      isIncludeNested: false,
                    })}
                  </FormHelperText>
                </FormControl>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    columnGap: 1,
                  }}
                >
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.type && errors.type)}
                  >
                    <ApiEnumAutocomplete
                      type='ContractType'
                      value={values.type}
                      onlyEnumValues={[
                        ContractType.Rental,
                        ContractType.Leasing,
                        ContractType.Subscription,
                      ]}
                      textFieldProps={{
                        label: "Contract type",
                        error: Boolean(errors.type),
                        helperText: errors.type,
                      }}
                      onChange={(newValue) => setFieldValue("type", newValue)}
                    />
                  </FormControl>

                  <FormControl margin='dense' fullWidth>
                    <TextField
                      error={Boolean(errors.externalNumber)}
                      fullWidth
                      helperText={errors.externalNumber}
                      label='External number'
                      name='externalNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      inputProps={{ maxLength: 100 }}
                      value={values.externalNumber || ""}
                      variant='outlined'
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    columnGap: 1,
                  }}
                >
                  <FormControl margin='dense' fullWidth>
                    <VehicleAutocompleteOrCreate
                      autocompleteProps={{
                        required: true,
                        disabled: !canUpdateVehicle,
                        entityId: values.vehicleId,
                        isPreload: isCreate,
                        searchFilters: {
                          type: VehicleType.Car,
                        },
                        textFieldProps: {
                          error: Boolean(errors.vehicleId),
                          helperText: errors.vehicleId,
                        },
                        onChange: (newValue) => {
                          setFieldValue("vehicleId", newValue?.id);
                          setFieldValue(
                            "accessoryIds",
                            newValue?.accessories?.map((x) => x.id) || [],
                          );
                        },
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => {
                        setFieldValue("vehicleId", newValue?.id);
                        setFieldValue(
                          "accessoryIds",
                          newValue?.accessories?.map((x) => x.id) || [],
                        );
                      }}
                    />

                    {isCreate && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isConfirmVehicleAllocation}
                              onChange={(e) => {
                                setFieldValue("isConfirmVehicleAllocation", e.target.checked);
                              }}
                            />
                          }
                          label={
                            <AppTooltip
                              title={`When confirmed you won't be able to reallocate vehicle no more.`}
                            >
                              <AppTypography
                                decoration={{
                                  variant: "helpText",
                                }}
                              >
                                Confirm vehicle allocation
                              </AppTypography>
                            </AppTooltip>
                          }
                        />
                        {errors.isConfirmVehicleAllocation && (
                          <FormHelperText error>
                            {ValidationHelper.getFormikErrorsAsString(
                              errors.isConfirmVehicleAllocation,
                            )}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </FormControl>

                  <FormControl margin='dense' fullWidth>
                    <CustomerAutocompleteOrCreate
                      autocompleteProps={{
                        required: true,
                        entityId: values.customerId,
                        disabled: isEdit,
                        textFieldProps: {
                          error: Boolean(errors.customerId),
                          helperText: errors.customerId,
                        },
                        onChange: (newValue) => setFieldValue("customerId", newValue?.id),
                      }}
                      onCreate={(newValue) => setFieldValue("customerId", newValue?.id)}
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    columnGap: 1,
                  }}
                >
                  <FormControl margin='none' fullWidth>
                    <MobileDateTimePicker
                      ampm={false}
                      label='Starts at'
                      value={(values.startsAt && moment(values.startsAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue) => {
                        setFieldValue("startsAt", newValue?.format() || null);
                        if (!isCheckOutDateEditedRef.current) {
                          setFieldValue("checkOutSpotInfo.date", newValue?.format() || null);
                        }
                      }}
                      slots={{
                        textField: (params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.startsAt)}
                            helperText={errors.startsAt}
                          />
                        ),
                      }}
                    />
                    <FormHelperText>
                      If you leave the start date empty the contract will be considered as ongoing.
                    </FormHelperText>
                  </FormControl>

                  <FormControl margin='none' fullWidth>
                    <MobileDateTimePicker
                      ampm={false}
                      label='Ends at'
                      value={(values.endsAt && moment(values.endsAt)) || null}
                      format={DATETIME_FORMATS.DISPLAY_DATETIME}
                      onChange={(newValue) => {
                        setFieldValue("endsAt", newValue?.format() || null);
                        if (!isCheckInDateEditedRef.current) {
                          setFieldValue("checkInSpotInfo.date", newValue?.format() || null);
                        }
                      }}
                      slots={{
                        textField: (params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.endsAt)}
                            helperText={errors.endsAt}
                          />
                        ),
                      }}
                    />
                    <FormHelperText>
                      If you leave the end date empty the contract will be considered as ongoing.
                    </FormHelperText>
                  </FormControl>
                </Box>

                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    error: errors.locationId,
                    disabled: !values.departmentId,
                  }}
                />

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    columnGap: 1,
                  }}
                >
                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.accessoryIds && errors.accessoryIds)}
                  >
                    <AccessoriesSelectOrCreate
                      autocompleteProps={{
                        withCreate: true,
                        disabled: !values.vehicleId,
                        entityIds: values.accessoryIds,
                        entities: undefined,
                        onChange: (newValues) => {
                          setFieldValue("accessoryIds", newValues?.map((x) => x.id!) || []);
                        },
                      }}
                      onCreate={(newValue) => {
                        setFieldValue("accessoryIds", [...values.accessoryIds!, newValue.id]);
                      }}
                    />
                    <FormHelperText>
                      Accessories are loaded from vehicle by default, but you can select any
                      accessories in scope of the contract.
                    </FormHelperText>
                    <FormHelperText>{touched.accessoryIds && errors.accessoryIds}</FormHelperText>
                  </FormControl>

                  <FormControl
                    margin='dense'
                    fullWidth
                    error={Boolean(touched.notes && errors.notes)}
                  >
                    <AppTextArea
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      mode='notes'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant='outlined'
                    />
                  </FormControl>
                </Box>

                {values?.settings?.isCheckEnabled && (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: `repeat(2, 1fr)`,
                      columnGap: 1,
                    }}
                  >
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: (
                          <Typography component='span' variant='subtitle1'>
                            Check-out spot info
                          </Typography>
                        ),
                      }}
                    >
                      <ContractSpotInfoInput
                        contractId={contract?.id}
                        spotInfo={contract?.checkOutSpotInfo}
                        formikProps={FormikHelper.getSubProps(
                          formik,
                          "checkOutSpotInfo",
                          (v) => v.checkOutSpotInfo,
                        )}
                        spotType={SpotType.CheckOut}
                        onDateChange={(newValue) => {
                          isCheckOutDateEditedRef.current = true;
                        }}
                      />
                    </FoldableBlock>
                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: (
                          <Typography component='span' variant='subtitle1'>
                            Check-in spot info
                          </Typography>
                        ),
                      }}
                    >
                      <ContractSpotInfoInput
                        contractId={contract?.id}
                        spotInfo={contract?.checkInSpotInfo}
                        formikProps={FormikHelper.getSubProps(
                          formik,
                          "checkInSpotInfo",
                          (v) => v.checkInSpotInfo,
                        )}
                        spotType={SpotType.CheckIn}
                        onDateChange={(newValue) => {
                          isCheckInDateEditedRef.current = true;
                        }}
                      />
                    </FoldableBlock>
                  </Box>
                )}

                <Box>
                  <FoldableBlock
                    defaultIsFolded={false}
                    trigger={{
                      label: <Typography variant='h6'>Contract settings</Typography>,
                    }}
                  >
                    <ContractSettingsInput
                      formikProps={FormikHelper.getSubProps(formik, "settings", (x) => x.settings)}
                    />
                  </FoldableBlock>
                </Box>
              </FormContentBlock>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  onClick={() => handleSubmit()}
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  variant='contained'
                >
                  Save
                </Button>
              </FormActions>
            </>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
