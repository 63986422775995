import { Box, Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

import { TextHelper } from "@/common/helpers/text";
import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";

import AppTooltip from "../AppTooltip";
import AppTypography from "../Text/AppTypography";

export interface InlineApiEnumValueProps<TEnumName extends ApiEnumName> {
  type: TEnumName;
  value: ApiEnumValue<TEnumName> | null | undefined;
  direction?: "row" | "column";
  withValue?: boolean;
  withName?: boolean;
  nameSeparator?: string;
  withDescription?: boolean;
  withHelperTooltip?: boolean;
  isPluralize?: boolean;
  sx?: SxProps<Theme>;
}

/** Inline element for displaying enum value.
 *  Checks for user-friendly enum name in enum catalog.
 */
export default function InlineApiEnumValue<TEnumName extends ApiEnumName>({
  type,
  value,
  direction = "row",
  withValue = false,
  withName = true,
  withDescription = false,
  withHelperTooltip = false,
  isPluralize = false,
  nameSeparator,
  sx,
}: InlineApiEnumValueProps<TEnumName>): React.ReactNode {
  const enumDto = (value && enumService.getEnumDto(type, value)) || null;

  const computedName = useMemo(() => {
    let result = enumDto?.name || value || "";
    if (nameSeparator !== undefined) {
      result = TextHelper.humanize(result, nameSeparator);
    }
    if (isPluralize) {
      result = TextHelper.pluralize(result, 2);
    }
    return result;
  }, [enumDto, type, value]);

  return (
    <Box
      component='span'
      sx={{
        ...sx,
      }}
    >
      <AppTooltip
        enabled={withHelperTooltip && !!enumDto?.description}
        wrapperProps={{
          component: "span",
        }}
        variant={(withHelperTooltip && "helpText") || "default"}
        title={withHelperTooltip && enumDto?.description}
      >
        <Stack
          component='span'
          direction={direction || "row"}
          spacing={0.5}
          sx={{
            display: "inline-flex",

            ...(direction === "row" && { alignItems: "center", justifyContent: "flex-start" }),
            ...(direction === "column" && {
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }),
          }}
        >
          {withValue && (
            <AppTypography ellipsing={{ enabled: false }} component='span' isInheritFontStyle>
              {value}
            </AppTypography>
          )}
          {withName && (
            <AppTypography ellipsing={{ enabled: true }} component='span' isInheritFontStyle>
              {computedName}
            </AppTypography>
          )}
          {withDescription && enumDto?.description && (
            <AppTypography
              ellipsing={{ enabled: false }}
              component='span'
              variant='caption'
              color='text.secondary'
              isInheritFontStyle
            >
              ({enumDto?.description})
            </AppTypography>
          )}
        </Stack>
      </AppTooltip>
    </Box>
  );
}
