import { GridPaginationModel, GridPaginationState } from "@mui/x-data-grid";
import { isNil } from "lodash-es";

import { PaginationInfoDto } from "@/core/api/generated";

import { TypeHelper } from "./type";

export class PaginationHelper {
  public static readonly minOffset = 0;
  public static readonly maxOffset = 100_000_000;
  public static readonly defaultOffset = 0;

  /** 0 is not allowed as it can be interpreted as no limit or ignore limit. */
  public static readonly minLimit = 1;
  public static readonly maxLimit = 200;
  public static readonly defaultLimit = 25;

  // NB: page is 0-based index.
  public static readonly minPage = 0;
  public static readonly maxPage = 100_000;
  public static readonly defaultPage = 0;

  public static readonly defaultPageSize = 25;
  public static readonly pageSizeOptions = [10, 25, 50, 100, 200];

  /** Returns passed value if it's valid or undefined. */
  public static getOffset(offset: Nil<number>): Nil<number> {
    return TypeHelper.isNil(offset)
      ? undefined
      : offset < this.minOffset
        ? undefined
        : offset > this.maxOffset
          ? this.maxOffset
          : offset;
  }

  /** Returns passed value if it's valid or the default value. */
  public static getOffsetOrDefault(offset: Nil<number>): number {
    let newValue = TypeHelper.isNil(offset) ? this.defaultOffset : offset;
    newValue = newValue < this.minOffset ? this.defaultOffset : newValue;
    newValue = newValue > this.maxOffset ? this.maxOffset : newValue;
    return newValue;
  }

  /** Returns passed value if it's valid or undefined. */
  public static getLimit(limit: Nil<number>): Nil<number> {
    return TypeHelper.isNil(limit)
      ? undefined
      : limit < this.minLimit
        ? undefined
        : limit > this.maxLimit
          ? this.maxLimit
          : limit;
  }

  /** Returns passed value if it's valid or the default value. */
  public static getLimitOrDefault(limit: Nil<number>): number {
    let newValue = TypeHelper.isNil(limit) ? this.defaultLimit : limit;
    newValue = newValue < this.minLimit ? this.defaultLimit : newValue;
    newValue = newValue > this.maxLimit ? this.maxLimit : newValue;
    return newValue;
  }

  /** Returns passed value if it's valid or the default value. */
  public static getPageOrDefault(page: Nil<number>): number {
    let newValue = TypeHelper.isNil(page) ? this.defaultPage : page;
    newValue = newValue < this.minPage ? this.defaultPage : newValue;
    newValue = newValue > this.maxPage ? this.maxPage : newValue;
    return newValue;
  }

  /** Returns passed value if it's valid or the default value. */
  public static getPageSizeOrDefault(pageSize: Nil<number>): number {
    return this.getLimitOrDefault(pageSize);
  }

  public static makeEmptyPaginationModel<T>(limit: number | null = null) {
    return {
      items: [],
      pagination: { currentPage: 0, totalCount: 0, limit: limit || 0, offset: 0, totalPages: 0 },
    } as unknown as T;
  }

  public static mapPaginationInfoToGridPaginationModel(
    source: PaginationInfoDto | undefined,
  ): GridPaginationModel | undefined {
    return source
      ? {
          pageSize: this.getPageSizeOrDefault(source.limit ?? 0),
          page: this.getPageOrDefault(
            source.currentPage ??
              (!isNil(source.offset) && !isNil(source.limit)
                ? Math.ceil(source.offset / source.limit)
                : 0),
          ),
        }
      : undefined;
  }

  public static mapGridPaginationModelToPaginationInfo(
    source: GridPaginationModel,
  ): PaginationInfoDto {
    return {
      offset: this.getOffsetOrDefault((source.page ?? 0) * (source.pageSize ?? 0)),
      limit: this.getLimitOrDefault(source.pageSize ?? 0),
      totalCount: undefined,
      currentPage: source.page,
      totalPages: undefined,
    };
  }

  public static mapGridPaginationStateToPaginationInfo(
    source: GridPaginationState,
  ): PaginationInfoDto {
    const page = this.getPageOrDefault(source.paginationModel?.page ?? 0);
    const pageSize = this.getPageSizeOrDefault(source.paginationModel?.pageSize ?? 0);
    const totalCount = source.rowCount ?? 0;
    return {
      offset: this.getOffsetOrDefault(page * pageSize),
      limit: this.getLimitOrDefault(pageSize),
      totalCount: totalCount,
      currentPage: page,
      totalPages: totalCount !== 0 && pageSize !== 0 ? Math.ceil(totalCount / pageSize) : 0,
    };
  }
}
