import { isNil } from "lodash-es";

import { FeatureFlags, FeatureNames } from "./types";

export class FeatureManager {
  private readonly featureFlags: FeatureFlags;

  constructor(featureFlags: FeatureFlags) {
    this.featureFlags = featureFlags;
  }

  public getFeatureFlags(): FeatureFlags {
    return this.featureFlags;
  }

  public getFeatureNames(): string[] {
    return Object.keys(this.featureFlags);
  }

  public isEnabled(featureName: string): boolean {
    return !!this.featureFlags[featureName];
  }

  public disableUnspecifiedFeatures(featureNames: FeatureNames): void {
    Object.values(featureNames).forEach((featureName) => {
      if (isNil(this.featureFlags[featureName])) {
        this.featureFlags[featureName] = false;
      }
    });
  }
}
