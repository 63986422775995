import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import FormContentBlock from "@/App/Layouts/FormContentBlock";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CreateSpotDto, EntityType, SpotDto, UpdateSpotDto } from "@/core/api/generated";

import ApiEnumsAutocomplete from "../../Enum/ApiEnumsAutocomplete";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import GeneralAddressInput from "../General/Input/GeneralAddressInput";
import LocationAutocompleteOrCreate from "../Location/LocationAutocompleteOrCreate";
import ProductLocationAutocompleteOrCreate from "../ProductLocation/ProductLocationAutocompleteOrCreate";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  locationId?: CreateSpotDto["locationId"];
  productLocationId?: CreateSpotDto["productLocationId"];
  name?: CreateSpotDto["name"];
  address?: CreateSpotDto["address"];
};

export interface SpotCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<SpotDto, DefaultValues> {
  spotId?: string;
}

export type SpotCreateUpdateProps = SpotCreateUpdateOwnProps;

export default function SpotCreateUpdate({
  spotId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: SpotCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !spotId;

  const spotRequest = useApiRequest(
    apiClient.spotsApi.apiV1SpotsSpotIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      spotId: spotId!,
    },
    {
      skip: !spotId,
    },
  );
  const spot = spotRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: spot && {
      idValue: spot.id!,
      newTitle: spot.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Spot}
      entityId={spotId}
      entity={spot}
      entityRequest={spotRequest}
    >
      <Formik<CreateSpotDto & UpdateSpotDto & BaseFormikValues>
        enableReinitialize={!isCreate}
        initialValues={{
          locationId: defaultValues?.locationId || spot?.location?.id || undefined,
          productLocationId:
            defaultValues?.productLocationId || spot?.productLocation?.id || undefined,
          types: spot?.types || undefined,
          name: defaultValues?.name || spot?.name || undefined,
          description: spot?.description || undefined,
          address: defaultValues?.address || spot?.address || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.spotsApi.apiV1SpotsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                createSpotDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Spot created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.spotsApi.apiV1SpotsSpotIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                spotId,
                updateSpotDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Spot updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <FormContentBlock spacing={2}>
                {/* Location */}
                <Stack direction={{ xxs: "column", md: "row" }} spacing={2}>
                  <Box sx={{ flex: 1 }}>
                    <FormControl margin='dense' fullWidth error={Boolean(errors.locationId)}>
                      <LocationAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.locationId,
                          entity: undefined,
                          isPreload: isCreate,
                          textFieldProps: {
                            error: Boolean(errors.locationId),
                          },
                          onChange: (newValue) => {
                            setFieldValue("locationId", newValue?.id);
                            newValue?.address && setFieldValue("address", newValue.address);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue("locationId", newValue?.id);
                          newValue?.address && setFieldValue("address", newValue?.address);
                        }}
                      />
                      <FormHelperText>{errors.locationId}</FormHelperText>
                    </FormControl>
                  </Box>

                  <Box sx={{ flex: 1 }}>
                    <FormControl margin='dense' fullWidth error={Boolean(errors.productLocationId)}>
                      <ProductLocationAutocompleteOrCreate
                        autocompleteProps={{
                          entityId: values.productLocationId,
                          entity: undefined,
                          isPreload: isCreate,
                          textFieldProps: {
                            error: Boolean(errors.productLocationId),
                          },
                          onChange: (newValue) => {
                            setFieldValue("productLocationId", newValue?.id);
                          },
                        }}
                        createFormPlacement='modal'
                        onCreate={(newValue) => {
                          setFieldValue("productLocationId", newValue?.id);
                        }}
                      />
                      <FormHelperText>{errors.productLocationId}</FormHelperText>
                    </FormControl>
                  </Box>
                </Stack>

                <FormControl margin='dense' fullWidth required error={Boolean(errors.types)}>
                  <ApiEnumsAutocomplete
                    type='SpotType'
                    values={values.types}
                    onChange={(newValues) => setFieldValue("types", newValues)}
                    label='Types'
                    required
                    textFieldProps={{
                      error: Boolean(errors.types),
                    }}
                  />
                  <FormHelperText>
                    {errors.types && ValidationHelper.getErrorsAsString(errors.types)}
                  </FormHelperText>
                </FormControl>

                <TextField
                  error={Boolean(touched.name && errors.name)}
                  required
                  fullWidth
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <AppTextArea
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  mode='description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description || ""}
                  variant='outlined'
                />

                <FormControl fullWidth>
                  <Stack sx={{ p: 1, mt: 1 }}>
                    <FormLabel required>
                      <Typography component='span' variant='subtitle1'>
                        Address
                      </Typography>
                    </FormLabel>

                    <GeneralAddressInput
                      errors={getIn(errors, "address")}
                      touched={getIn(touched, "address")}
                      value={values.address}
                      inputsProps={{ all: {} }}
                      onChange={(newValue) => setFieldValue("address", newValue)}
                    />
                  </Stack>
                </FormControl>
              </FormContentBlock>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <Button
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
