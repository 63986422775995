import { useRealtimeChatConnectionStatus } from "@/common/hooks/realtime/useRealtimeChatConnectionStatus";

import ConnectionStatusIndicator from "./ConnectionStatusIndicator";

/** Displays status of SignalR connection. */
function RealtimeChatConnectionStatusIndicator() {
  const status = useRealtimeChatConnectionStatus();

  return <ConnectionStatusIndicator connected={status.connected} variant='alert' />;
}

export default RealtimeChatConnectionStatusIndicator;
