import { Button, Dialog, DialogContent, DialogProps, Stack } from "@mui/material";
import { Formik } from "formik";
import { omit } from "lodash-es";
import * as Yup from "yup";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  GeneralAttachedTagsDto,
  GeneralAttachedTagsOfEntityUpdateDto,
  TagEntityType,
} from "@/core/api/generated";

import AppModalTitle from "../../../Modals/AppModalTitle";
import GeneralAttachedTagsInput from "./GeneralAttachedTagsInput";

export interface OwnProps {
  entityType: TagEntityType;
  entityId: string;
  onSaved?: (newTags: GeneralAttachedTagsDto | null | undefined) => void | Promise<void>;
}

type Props = OwnProps & DialogProps;

/** Edit and save tags of specified entity. */
export default function GeneralAttachedTagsOfEntityEditModal({
  entityType,
  entityId,
  onSaved,
  ...dialogProps
}: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const openStatus = useModalOpenStatus(dialogProps);

  const getEntityTagsRequest = useApiRequest(
    apiClient.generalTagsApi.apiV1TagsGeneralForEntityGetGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      entityType,
      entityId,
    },
    {
      skip: !openStatus.isOpen,
      deps: [openStatus.isOpen],
      debouncedDeps: {
        skip: !openStatus.isOpen,
        deps: [openStatus.isOpen],
        wait: 500,
        options: { leading: false, trailing: true },
      },
    },
  );
  const entityTags = getEntityTagsRequest?.data;

  return (
    <Dialog fullWidth maxWidth='sm' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Edit entity tags
      </AppModalTitle>

      <DialogContent>
        <Formik<GeneralAttachedTagsOfEntityUpdateDto & BaseFormikValues>
          enableReinitialize
          initialValues={{
            entityType: entityType,
            entityId: entityId,
            tags: entityTags || undefined,

            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // name: Yup.string().required("Name is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              const response = await apiClient.generalTagsApi.apiV1TagsGeneralForEntityUpdatePut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                generalAttachedTagsOfEntityUpdateDto: {
                  ...omit(values, ["submit"]),
                },
              });
              enqueueSnackbar("Tags updated.", { variant: "success" });
              onSaved && onSaved(response.data);
              dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <GeneralAttachedTagsInput
                    value={values.tags}
                    onChange={(newValue) => {
                      setFieldValue("tags", newValue);
                    }}
                  />
                </Stack>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", mt: 1 }}>
                  <Button
                    variant='outlined'
                    color='text'
                    onClick={(e) => dialogProps.onClose && dialogProps.onClose(e, "escapeKeyDown")}
                  >
                    Cancel
                  </Button>

                  <Button variant='contained' color='primary' loading={isSubmitting} type='submit'>
                    Save
                  </Button>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
