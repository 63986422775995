import { DebounceSettings, debounce } from "lodash-es";
import { DependencyList, EffectCallback, useCallback, useEffect } from "react";

import { useMemoWithDeepCompare } from "../memo/useMemoWithDeepCompare";

/** useEffect with debounced effect function. */
export function useEffectWithDebounce(
  effect: EffectCallback | (() => Promise<void>),
  wait?: number,
  options?: DebounceSettings,
  deps?: DependencyList,
): void {
  const effectMemorized = useMemoWithDeepCompare(() => effect, [effect]);
  const effectDebounced = useCallback(debounce(effectMemorized, wait, options), [effectMemorized]);

  useEffect(() => {
    effectDebounced();
  }, deps);
}
