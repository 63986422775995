import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";

import { BrowserHelper } from "@/common/helpers/browser";
import { useAppDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { _addWarning } from "@/store/appCommon/slice";

function BrowserNotSupportedChecker() {
  const { enqueueSnackbar } = useAppSnackbar();
  const dispatch = useAppDispatch();
  const hasShownWarning = useRef(false);

  useEffect(() => {
    if (!hasShownWarning.current && !BrowserHelper.isSupportedBrowser()) {
      hasShownWarning.current = true;

      const warning = `<span>To ensure the best experience, please use a modern browser. Some features may not work properly or may be unavailable until you update your browser. For the latest browser versions, visit - </span><a href="https://browsehappy.com/" target="_blank" rel="noopener noreferrer">https://browsehappy.com/</a><span>.</span>`;
      enqueueSnackbar(
        <Box>
          <Typography textAlign={"center"} fontWeight={"bold"}>
            Your browser is not fully supported.
          </Typography>
          <Typography
            dangerouslySetInnerHTML={{ __html: warning }}
            sx={{ "a:visited": { color: "inherit" } }}
          ></Typography>
        </Box>,
        {
          variant: "info",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          preventDuplicate: true,
          persist: true,
          onClose: () => {
            dispatch(_addWarning(warning));
          },
        },
      );
    }
  }, []);

  return null;
}

export default BrowserNotSupportedChecker;
