import { Box, SxProps, Theme } from "@mui/material";
import { isNil } from "lodash-es";

import { DataListPaddings, DataListRenderFuncResult } from "./DataList";

interface Props {
  isPlaceholder?: boolean;
  paddings?: DataListPaddings;
  sx?: SxProps<Theme>;
  children?: DataListRenderFuncResult;
}

export default function DataListRowAction({ isPlaceholder, paddings, sx, children }: Props) {
  return (
    <Box
      sx={(th) => ({
        [th.breakpoints.up("xxs")]: {
          display: isPlaceholder ? "none" : "block",
          p: 0,
          minWidth: "100%",
          maxWidth: "100%",
          width: "100%",
        },
        [th.breakpoints.up("md")]: {
          display: "block",
          flex: 0,
          py: isNil(paddings?.card?.y) ? 0.5 : paddings!.card!.y! / 2,
          pr: isNil(paddings?.card?.x) ? 2 : paddings!.card!.x!,
          pl: 0,
          minWidth: "40px",
          maxWidth: "40px",
          width: "40px",
        },
        ...sx,
      })}
    >
      {(!isPlaceholder && children) || null}
      {isPlaceholder && <> &nbsp;</>}
    </Box>
  );
}
