import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import PoolPaginatedList from "@/common/components/Entity/Pool/ListView/PoolPaginatedList";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  PoolGetPaginatedDto,
  PoolItemEntityType,
} from "@/core/api/generated";

interface Props {
  asset: AssetDto;
}

export default function ItemsTabContent({ asset }: Props) {
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<PoolGetPaginatedDto>({});

  const paginatedPoolsRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsForEntityGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      offset: commonRequestParams.offset,
      limit: commonRequestParams.limit,
      entityType: PoolItemEntityType.Asset,
      entityId: asset.id!,
    },
    {
      deps: [asset.id],
    },
  );
  const paginatedPools = paginatedPoolsRequest.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.Pool)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedPoolsRequest.handleEntityChanged(data);
    },
  });
  return (
    <PageTabContent>
      <PoolPaginatedList
        shouldFetch={false}
        paginated={paginatedPools}
        displayProps={{
          breadcrumbs: false,
          filters: false,
          create: false,
          edit: true,
          delete: false,
          viewVariant: ViewLayoutVariant.Tab,
        }}
        dataTabularProps={{
          isLoading: paginatedPoolsRequest.isLoading,
          pagination: commonRequestParams.dataTabularProps.pagination,
        }}
      />
    </PageTabContent>
  );
}
