import { Typography } from "@mui/material";

import { RepairSpecItemType } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";

interface Props extends BaseEntityLinkInheritableProps {
  type: Nil<RepairSpecItemType>;
  sourceType: Nil<RepairSpecItemType>;
}

export default function RepairSpecItemTypeInline({ type, sourceType }: Props) {
  return (
    <>
      {type === RepairSpecItemType.Custom && sourceType ? (
        <>
          <InlineApiEnumValue type='RepairSpecItemType' value={sourceType} />{" "}
          <Typography component='span' variant='body2' color='text.secondary'>
            (<InlineApiEnumValue type='RepairSpecItemType' value={type} />)
          </Typography>
        </>
      ) : (
        <InlineApiEnumValue type='RepairSpecItemType' value={type} />
      )}
    </>
  );
}
