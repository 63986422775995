import {
  Box,
  BoxProps,
  Breakpoint,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  Tab,
  TabProps,
  Tabs,
  TabsProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GridSize } from "@mui/material/Grid";
import { CSSProperties } from "@mui/styles";
import { GridSortItem } from "@mui/x-data-grid";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { PaginationHelper } from "@/common/helpers/pagination";
import { TabularColumnModel, TabularRowActionsProps } from "@/common/ts/dataTabular";
import { PaginationInfoDto } from "@/core/api/generated";

import NoDataAlert from "../AppAlerts/NoDataAlert";
import { HighlightElementProps } from "../HighlightElement";
import AppPagination from "../Pagination/AppPagination";
import PaginationInfoDisplay from "../Pagination/PaginationInfoDisplay";
import DataListContent, { DataListContentOwnProps } from "./DataListContent";
import DataListHeader, { DataListHeaderOwnProps } from "./DataListHeader";

export interface DataListPaddings {
  card?: {
    x?: number;
    y?: number;
  };
}

export type DataListValidRowModel = {
  [key: string]: any;
};

export type DataListSize = "small" | "medium";

export type DataListItemVariant = "shadowed" | "bordered" | "filled";

export type DataListHeaderVariant = "shadowed" | "bordered" | "compact";

export type DataListItemSeverityColor = "none" | "success" | "info" | "warning" | "error";

export type DataListItemColor =
  | "none"
  | "gray"
  | "success"
  | "info"
  | "warning"
  | "error"
  | "selected";

export type DataListRenderFuncResult = (React.ReactNode & ReactNode) | string | null | undefined;

export type DataListSetIsLoadingFunc = (isLoading: boolean) => void;

export interface DataListTabsProps extends TabsProps {
  tabs: TabProps[];
}

export type DataListRenderRowActionFunc<TItem extends DataListValidRowModel> = (params: {
  item: TItem;
  // isMenuOpen: boolean;
  // menuAnchorEl: HTMLElement | null;
  // setMenuAnchorEl: (el: HTMLElement | null) => void;
  // handleMenuClose: () => void;
  // setIsLoading: DataListSetIsLoadingFunc;
}) => DataListRenderFuncResult;

export interface DataListColumnModel<TItem extends DataListValidRowModel>
  extends Partial<Record<Breakpoint, boolean | GridSize | undefined>> {
  field: string;
  title: string;
  description?: string;
  /** Hide (don't render) the column */
  if?: boolean;
  /** Cell link. Overrides row link. */
  to?: string | ((item: TItem) => string);
  width?: CSSProperties["width"];
  minWidth?: CSSProperties["minWidth"];
  maxWidth?: CSSProperties["maxWidth"];
  isSortable?: boolean;
  isColumnMenuDisabled?: boolean;
  isIgnoreCellOverflow?: boolean;
  horizontalAlign?: "start" | "center" | "end";
  renderTitle?: () => DataListRenderFuncResult;
  /** Renders cell content for the item. Must fit the table cell. */
  renderCell: (item: TItem) => DataListRenderFuncResult;
  /** Renders extended/full/detailed content for the item.
   *  May not fit the table cell as displayed in popover by request. */
  renderCellDetails?: (item: TItem) => DataListRenderFuncResult;
  renderFilterElement?: ({
    column,
    data,
  }: {
    column: TabularColumnModel<TItem>;
    data: any;
  }) => React.ReactNode;
}

export interface DataListDefinition<TItem extends DataListValidRowModel> {
  columns: DataListColumnModel<TItem>[];
  rows: TItem[] | null | undefined;
  columnSpacing?: number;
  rowSpacing?: number;
  size?: DataListSize;
  rowTo?: string | ((item: TItem) => string);
  /** v2. Configurable. Suits for any case. */
  rowActions?: TabularRowActionsProps<TItem>;
  getRowId: (item: TItem) => string;
  /** v1. Not configurable. Suits for single icon button action. */
  renderRowAction?: DataListRenderRowActionFunc<TItem>;
  /** Render custom content before row main content. */
  renderRowStart?: (item: TItem) => DataListRenderFuncResult;
  /** Render custom content after row main content. */
  renderRowEnd?: (item: TItem) => DataListRenderFuncResult;
  /** Row link. */
  onRowClick?: (item: TItem) => void | Promise<void>;
  /** Row highlight props (for all or for specified only). */
  rowHighlightPropsGetter?: (item: TItem) => HighlightElementProps;
}

export interface DataListProps<TItem extends DataListValidRowModel>
  extends DataListDefinition<TItem> {
  headerProps?: DataListHeaderOwnProps<TItem>;
  tabs?: DataListTabsProps;
  contentProps?: DataListContentOwnProps<TItem>;
  paginationInfo?: PaginationInfoDto;
  defaultOffset?: number | undefined;
  defaultLimit?: number | undefined;
  defaultSortModel?: GridSortItem;
  isLoading?: boolean;
  sx?: BoxProps["sx"];
  onPaginationChange?: (newValue: PaginationInfoDto) => void | Promise<void>;
  onSortModelChange?: (newSortModel: GridSortItem | undefined) => void;
}

/** Alternative to DataGrid.
 *  Displays data as columnized responsive list.
 * @deprecated DataTabular usage is preferred. For mobile use DataListV2.
 */
export default function DataList<TItem extends DataListValidRowModel>({
  headerProps,
  tabs,
  contentProps,
  sx,
  paginationInfo,
  defaultOffset = 0,
  defaultLimit,
  onPaginationChange = () => {},
  onSortModelChange = () => {},
  isLoading,
  defaultSortModel,
  ...definition
}: DataListProps<TItem>) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const topAnchorRef = useRef<HTMLElement | null>(null);
  const bottomAnchorRef = useRef<HTMLElement | null>(null);

  const pageSizes = [10, 25, 50, 100, 200];
  const [limit, setLimit] = useState<number>(defaultLimit || pageSizes[0]);
  const [offset, setOffset] = useState<number>(defaultOffset);
  const [page, setPage] = useState<number>(paginationInfo?.currentPage || 1);
  const [sortModel, setSortModel] = useState<GridSortItem | undefined>(defaultSortModel);

  useEffect(() => {
    onSortModelChange(sortModel);
  }, [sortModel]);

  useEffect(() => {
    onPaginationChange({ offset, limit, currentPage: 0 });
  }, [limit, offset]);

  // todo: investigate how to deal with limit > 200 or limit==30 exactly cases correctly
  useEffect(() => {
    if (paginationInfo?.limit && paginationInfo.limit !== limit) {
      setLimit(pageSizes.find((x) => x >= paginationInfo.limit!) || pageSizes[0]);
    }
    if (paginationInfo && (paginationInfo?.currentPage || 0) + 1 !== page) {
      setPage((paginationInfo?.currentPage || 0) + 1);
    }
  }, [paginationInfo]);

  const paddings = useMemo<DataListPaddings>(() => {
    return {
      card: {
        x: (definition.size === "small" && 1.5) || (definition.size === "medium" && 2) || 2,
        y: (definition.size === "small" && 1) || (definition.size === "medium" && 2) || 2,
      },
    };
  }, [definition.size]);

  return (
    <Box sx={sx}>
      <Box ref={topAnchorRef} sx={{ m: 0, p: 0 }}></Box>
      <Box>{isLoading && <LinearProgress sx={{ my: 1 }} />}</Box>

      {/* Pagination info */}
      {paginationInfo && (
        <Stack
          direction='row'
          sx={{ justifyContent: tabs && paginationInfo ? "space-between" : "flex-end", mb: 0.5 }}
        >
          {tabs && (
            <Tabs value={tabs.value} onChange={tabs.onChange} variant='scrollable'>
              {tabs.tabs.map((tab, index) => (
                <Tab key={index} {...tab} />
              ))}
            </Tabs>
          )}

          {paginationInfo && (
            <Stack sx={{ minWidth: 70, mr: 1 }} direction='column'>
              <Select
                sx={{ height: 30 }}
                fullWidth
                displayEmpty={false}
                value={limit}
                onChange={(e) => {
                  const newSize = Number(e.target.value);
                  setLimit(newSize);
                  setPage(1);
                  setOffset(0);
                }}
              >
                {pageSizes.map((pageSize) => (
                  <MenuItem key={pageSize} value={pageSize}>
                    {pageSize}
                  </MenuItem>
                ))}
              </Select>

              <PaginationInfoDisplay info={paginationInfo} />
            </Stack>
          )}
        </Stack>
      )}

      {/* Header */}
      {isDesktop && (
        <DataListHeader<TItem>
          sx={{
            ...(headerProps?.variant !== "compact" && {
              mb: definition.rowSpacing || 1,
            }),
            ...(headerProps?.variant === "compact" && {
              mb: 0.25,
            }),
          }}
          paddings={paddings}
          sortModel={sortModel}
          onSortModelChange={(_sortModel) => {
            setSortModel(_sortModel);
          }}
          {...definition}
          {...headerProps}
        />
      )}

      {/* Filters */}
      {/* <Box ref={filtersAnchorRef}></Box>
      <Popover
        open={isFiltersPanelOpen}
        anchorEl={anchorEl}
        onClose={closeFiltersPanel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack sx={{ p: 1 }}>
          {targetFilterColumn?.renderFilterElement
            ? targetFilterColumn.renderFilterElement({
                column: targetFilterColumn,
                data: definition.filterValues && definition.filterValues[targetFilterColumn.field],
              })
            : "No filter specified."}
        </Stack>
      </Popover> */}

      {/* Content */}
      <DataListContent paddings={paddings} {...definition} {...contentProps} />

      {!isLoading && (!definition.rows || definition.rows.length === 0) && <NoDataAlert />}

      {/* Pagination */}
      {paginationInfo &&
        (definition.rows?.length || 0) !== 0 &&
        (paginationInfo?.totalPages || 0) > 1 && (
          <AppPagination
            // currentPage={page}
            paginationInfo={paginationInfo}
            onChange={(newValue) => {
              setOffset(newValue.offset ?? 0);
              setLimit(newValue.limit ?? PaginationHelper.defaultLimit);
              setPage(newValue.currentPage ?? 0);
              topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
            }}
          />
        )}

      <Box ref={bottomAnchorRef} sx={{ m: 0, p: 0 }}></Box>
    </Box>
  );
}
