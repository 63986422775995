import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { apiClient } from "@/core/api/ApiClient";
import { TenantProfileDto, TenantSettingsDto } from "@/core/api/generated";
import { AppThunk } from "@/store";

interface TenantProfileState {
  loading: {
    getProfile?: boolean;
    getSettings?: boolean;
    updateSettings?: boolean;
    updateProfile?: boolean;
  };
  profile?: TenantProfileDto;
  settings?: TenantSettingsDto;
}

const initialState: TenantProfileState = {
  loading: {},
  profile: undefined,
  settings: undefined,
};

const slice = createSlice({
  name: "tenantProfile",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<TenantProfileState["loading"]>) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      };
    },
    _tenantProfileFetched: (state, action: PayloadAction<TenantProfileDto>) => {
      state.profile = action.payload;
    },
    _tenantProfileCleared: (state) => {
      state.profile = undefined;
    },
    _tenantSettingsFetched: (state, action: PayloadAction<TenantSettingsDto>) => {
      state.settings = action.payload;
    },
    _tenantSettingsUpdated: (state, action: PayloadAction<TenantSettingsDto>) => {
      state.settings = action.payload;
    },
    resetTenantSettings: (state, action: PayloadAction<undefined>) => {
      state.settings = undefined;
    },
  },
});

export const {
  setLoading,
  _tenantProfileFetched,
  _tenantProfileCleared,
  _tenantSettingsFetched,
  _tenantSettingsUpdated,
  resetTenantSettings,
} = slice.actions;

export const tenantProfileReducer = slice.reducer;

export const fetchTenantProfile =
  (params?: { isRefetch?: boolean }): AppThunk<Promise<Nil<TenantProfileDto>>> =>
  async (dispatch, getState) => {
    // enure fetched once
    const isLoading = getState().tenantProfile.loading.getProfile;
    const profile = getState().tenantProfile.profile;
    if ((isLoading || profile) && !params?.isRefetch) {
      return profile;
    }

    dispatch(
      setLoading({
        getProfile: true,
      }),
    );
    try {
      const response = await apiClient.tenantProfileApi.apiV1TenantProfileGet({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      });
      await dispatch(_tenantProfileFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getProfile: false,
        }),
      );
    }
  };

export const clearTenantProfile = (): AppThunk => async (dispatch) => {
  dispatch(_tenantProfileCleared());
};

export const getTenantSettings =
  (
    ...arg: Parameters<typeof apiClient.tenantProfileApi.apiV1TenantProfileSettingsGet>
  ): AppThunk<Promise<TenantSettingsDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        getSettings: true,
      }),
    );
    try {
      const response = await apiClient.tenantProfileApi.apiV1TenantProfileSettingsGet(...arg);
      await dispatch(_tenantSettingsFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          getSettings: false,
        }),
      );
    }
  };

export const updateTenantSettings =
  (
    ...arg: Parameters<typeof apiClient.tenantProfileApi.apiV1TenantProfileSettingsPut>
  ): AppThunk<Promise<TenantSettingsDto>> =>
  async (dispatch) => {
    dispatch(
      setLoading({
        updateSettings: true,
      }),
    );
    try {
      const response = await apiClient.tenantProfileApi.apiV1TenantProfileSettingsPut(...arg);
      await dispatch(_tenantSettingsFetched(response.data));
      return response.data;
    } finally {
      dispatch(
        setLoading({
          updateSettings: false,
        }),
      );
    }
  };

export const updateTenantProfile =
  (
    ...args: Parameters<typeof apiClient.tenantProfileApi.apiV1TenantProfileProfilePut>
  ): AppThunk<Promise<TenantProfileDto>> =>
  async (dispatch) => {
    dispatch(setLoading({ updateProfile: true }));
    try {
      const response = await apiClient.tenantProfileApi.apiV1TenantProfileProfilePut(...args);
      await dispatch(_tenantProfileFetched(response.data));
      return response.data;
    } finally {
      dispatch(setLoading({ updateProfile: false }));
    }
  };
