import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Stack,
  TextField,
} from "@mui/material";
import { Formik } from "formik";
import { omit } from "lodash-es";
import { enqueueSnackbar } from "notistack";
import { useMemo, useState } from "react";
import * as Yup from "yup";

import AppTooltip from "@/common/components/AppTooltip";
import DropdownButton from "@/common/components/Button/DropdownButton";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppMenuItem from "@/common/components/Menu/AppMenuItem";
import HeaderMenuItem from "@/common/components/Menu/HeaderMenuItem";
import AppModalActions from "@/common/components/Modals/AppModalActions";
import AppModalContent from "@/common/components/Modals/AppModalContent";
import AppModalV2 from "@/common/components/Modals/AppModalV2";
import TextLineListSkeleton from "@/common/components/Skeleton/TextLineListSkeleton";
import { TypeHelper } from "@/common/helpers/type";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { ApiEnumName, ApiEnumValue, enumService } from "@/common/services/enum";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralStatusInputDto, GeneralStatusMetaDto, IBaseEntityDto } from "@/core/api/generated";

import GeneralAttachedTagsInput from "../../General/GeneralTag/GeneralAttachedTagsInput";
import GeneralStatusMetaDetailsPopover, {
  GeneralStatusMetaDetailsPopoverProps,
} from "./GeneralStatusMetaDetailsPopover";
import GeneralStatusMetaDisplay from "./GeneralStatusMetaDisplay";

interface Props {
  value: GeneralStatusMetaDto | undefined | null;
  subjectEntity: IBaseEntityDto | undefined;
  withDetailsPopover?: boolean;
  detailsPopoverProps?: Partial<GeneralStatusMetaDetailsPopoverProps>;
  onMetaUpdated: (newValue: GeneralStatusMetaDto) => void;
  onStatusChange: (newValue: GeneralStatusInputDto) => void | Promise<void>;
}

export default function GeneralStatusMetaDisplayAndInput({
  value,
  subjectEntity,
  withDetailsPopover = true,
  detailsPopoverProps,
  onMetaUpdated,
  onStatusChange,
}: Props) {
  const statusEnumTypeName = value?.statusEnumTypeName as ApiEnumName | undefined;
  const currentStatus = value?.status as ApiEnumValue<ApiEnumName> | undefined;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isConfirmUpdateModalOpen, setIsConfirmUpdateModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<string | undefined>(undefined);

  const enumTransitionSpecRequest = useApiRequest(
    apiClient.enumsApi.apiV1EnumsTransitionSpecGet,
    {
      enumTypeName: statusEnumTypeName,
    },
    {
      deps: [statusEnumTypeName, isDropdownOpen],
      skip: !statusEnumTypeName || !isDropdownOpen,
    },
  );
  const enumTransitionSpec = enumTransitionSpecRequest.data;

  const newStatusCandidates = useMemo(
    () =>
      statusEnumTypeName && currentStatus && enumTransitionSpec
        ? enumService.getEnumValues(statusEnumTypeName).filter((status) =>
            enumService.canTransit({
              enumName: statusEnumTypeName,
              fromEnumValue: currentStatus,
              toEnumValue: status,
              transitionSpec: enumTransitionSpec,
            }),
          )
        : undefined,
    [statusEnumTypeName, currentStatus, enumTransitionSpec],
  );

  if (!value || !statusEnumTypeName) {
    return null;
  }

  return (
    <Box>
      <DropdownButton
        component='button'
        size='small'
        autoCloseOnClick={false}
        buttonProps={{
          color: "inherit",
          size: "small",
          variant: "text",
          sx: { p: 0, minWidth: "auto", height: "auto" },
          // startIcon: <ApiEnumIcon type='SubscriptionStatus' value={assetSubscription?.status} />,
        }}
        dropdownContentWrapperProps={{
          minWidth: 200,
          maxHeight: {
            xxs: "90vh",
            md: 300,
          },
        }}
        onOpen={() => setIsDropdownOpen(true)}
        onClose={() => setIsDropdownOpen(false)}
        dropdownContent={(params) => (
          <Stack spacing={1}>
            {enumTransitionSpecRequest.isLoading && (
              <Stack sx={{ p: 1 }}>
                <TextLineListSkeleton itemCount={5} />
              </Stack>
            )}

            {!enumTransitionSpecRequest.isLoading && enumTransitionSpec && (
              <MenuList>
                {!TypeHelper.isEmpty(newStatusCandidates) && (
                  <HeaderMenuItem primaryTitle='Update status to:' />
                )}
                {TypeHelper.isEmpty(newStatusCandidates) && (
                  <HeaderMenuItem
                    primaryTitle={`Can't update status when it's ${enumService.getEnumValueName(
                      statusEnumTypeName,
                      currentStatus,
                    )}.`}
                  />
                )}

                {newStatusCandidates?.map((newStatusCandidate, i) => (
                  <AppMenuItem
                    key={i}
                    onClick={async () => {
                      setNewStatus(newStatusCandidate);
                      setIsConfirmUpdateModalOpen(true);
                      params.handleClose();
                    }}
                  >
                    <ListItemIcon>
                      {/* <ApiEnumIcon type='data' value={newStatusCandidate} fontSize='small' /> */}
                      <AppIcon of='data' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>
                      <InlineApiEnumValue
                        type={statusEnumTypeName}
                        value={newStatusCandidate}
                        withHelperTooltip
                      />
                    </ListItemText>
                  </AppMenuItem>
                ))}
              </MenuList>
            )}
          </Stack>
        )}
      >
        <GeneralStatusMetaDisplay
          value={value}
          subjectEntity={subjectEntity}
          withDetailsPopover={false}
          onMetaUpdated={onMetaUpdated}
        />
      </DropdownButton>

      {withDetailsPopover && (
        <GeneralStatusMetaDetailsPopover
          {...detailsPopoverProps}
          clickBehavior={{}}
          trigger={
            <AppTooltip title='View details'>
              <IconButton size='extraSmall' sx={{ ml: 0.25 }}>
                <AppIcon of='details' />
              </IconButton>
            </AppTooltip>
          }
          value={value}
          subjectEntity={subjectEntity}
          onMetaUpdated={onMetaUpdated}
        />
      )}

      <AppModalV2
        open={isConfirmUpdateModalOpen}
        fullWidth
        maxWidth='md'
        keepMounted={false}
        titleProps={{
          title: "Update status",
        }}
        onClose={() => setIsConfirmUpdateModalOpen(false)}
      >
        <Formik<GeneralStatusInputDto & BaseFormikValues>
          enableReinitialize
          initialValues={{
            status: newStatus || undefined,
            startReason: undefined,
            tags: undefined,
            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // name: Yup.string().required("Name is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              const newValue = omit(values, ["submit"]);
              await onStatusChange(newValue);
              enqueueSnackbar("Status updated.", { variant: "success" });
              setIsConfirmUpdateModalOpen(false);
            } catch (err: any) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <AppModalContent>
                  <Stack spacing={2}>
                    <Box>
                      <Stack spacing={1}>
                        <FieldValue label='Old status' direction='row' isEmpty={!currentStatus}>
                          <InlineApiEnumValue
                            type={statusEnumTypeName}
                            value={currentStatus}
                            withHelperTooltip
                          />
                        </FieldValue>

                        <FieldValue label='New status' direction='row' isEmpty={!values.status}>
                          <InlineApiEnumValue
                            type={statusEnumTypeName}
                            value={values.status as ApiEnumValue<ApiEnumName>}
                            withHelperTooltip
                          />
                        </FieldValue>
                      </Stack>

                      <TextField
                        error={Boolean(errors.startReason)}
                        fullWidth
                        multiline
                        rows={2}
                        helperText={errors.startReason}
                        label='Reason'
                        margin='normal'
                        name='startReason'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.startReason || ""}
                        variant='outlined'
                      />

                      {/* Tags */}
                      <FormControl margin='dense' fullWidth>
                        <GeneralAttachedTagsInput
                          value={values.tags}
                          onChange={(newValue) => {
                            setFieldValue("tags", newValue);
                          }}
                        />
                        <FormHelperText error>
                          {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                            isIncludeNested: false,
                          })}
                        </FormHelperText>
                      </FormControl>
                    </Box>

                    <GeneralValidationError errors={errors} />

                    <AppModalActions
                      modalProps={{
                        onClose: () => setIsConfirmUpdateModalOpen(false),
                      }}
                      isAutoCloseOnCancel
                      cancelButton={<Button variant='text'>Cancel</Button>}
                      submitButton={
                        <Button
                          variant='contained'
                          color='primary'
                          type='submit'
                          loading={isSubmitting}
                        >
                          Save
                        </Button>
                      }
                    ></AppModalActions>
                  </Stack>
                </AppModalContent>
              </form>
            );
          }}
        </Formik>
      </AppModalV2>
    </Box>
  );
}
