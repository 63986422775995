import { AnyAction } from "@reduxjs/toolkit";
import { debounce } from "lodash-es";

import store from "@/store";
import * as chatMessagesSlice from "@/store/communication/chatMessagesSlice";

export class ChatMessageService {
  /** chatId:messageId:boolean  */
  private _messagesToMarkAsReadMap: Record<string, Record<string, boolean>> = {};

  public queueMarkChatMessageAsRead(params: { chatId: string; messageId: string }): void {
    // add to queue
    this._messagesToMarkAsReadMap[params.chatId] ||= {};
    this._messagesToMarkAsReadMap[params.chatId][params.messageId] = true;

    // send in batches to server
    this._markChatMessagesAsReadThrottle();
  }

  //#region Private

  private _markChatMessagesAsReadThrottle = debounce(
    async () => {
      const chatIds = Object.keys(this._messagesToMarkAsReadMap);
      await Promise.all(
        chatIds.map(async (chatId) => {
          const messageIds = Object.entries(this._messagesToMarkAsReadMap[chatId])
            .filter(([key, value]) => value === true)
            .map(([key, value]) => key);

          await store.dispatch(
            chatMessagesSlice.markChatMessagesAsRead({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              chatId: chatId,
              markChatMessagesAsReadDto: {
                messageIds: messageIds,
              },
            }) as unknown as AnyAction,
          );

          // cleanup queue
          messageIds.forEach((messageId) => {
            delete this._messagesToMarkAsReadMap[chatId][messageId];
          });
        }),
      );
    },
    2500,
    {
      leading: false,
      trailing: true,
    },
  );

  //#endregion
}

export const chatMessageService = new ChatMessageService();
