import AppIcon from "@/common/components/Icons/AppIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssignmentDto,
  AssignmentReferenceDto,
  DamageCostEvaluationDto,
} from "@/core/api/generated";

import BaseEntityInline, { BaseEntityInlineInheritableProps } from "../components/BaseEntityInline";
import { getAssignmentOptionTitle } from "./AssignmentAutocomplete";

export interface AssignmentInlineProps extends BaseEntityInlineInheritableProps {
  entity: AssignmentDto | AssignmentReferenceDto | null | undefined;
  entityId?: string | null;
}

/** Inline brief information about the contract. */
export default function AssignmentInline({
  entity,
  entityId,
  ...otherProps
}: AssignmentInlineProps) {
  const request = useApiRequest(
    apiClient.assignmentsApi.apiV1AssignmentsAssignmentIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assignmentId: entityId!,
    },
    {
      deps: [entityId],
      skip: !entityId || !!entity,
    },
  );
  entity = request?.data || entity;

  const full = entity as DamageCostEvaluationDto;

  return (
    <BaseEntityInline
      entity={entity}
      request={request}
      icon={<AppIcon of='assignment' inText />}
      content={<>{full && getAssignmentOptionTitle(full)}</>}
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Assignment"}
    />
  );
}
