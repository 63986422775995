import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { ReactNode, useState } from "react";

import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import BooleanValue from "@/common/components/Form/Display/BooleanValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useEffectWithThrottle } from "@/common/hooks/effect/useEffectWithThrottle";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, RepairWorkDto } from "@/core/api/generated";

import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import TenantLink from "../../Tenant/TenantLink";
import { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import RepairWorkMenu from "../RepairWorkMenu";

const defaultHeaderProps = {
  withGoBack: true,
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<RepairWorkDto> {
  repairWorkId?: string | null;
  repairWork?: RepairWorkDto;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  getFunc?: (params: { repairWorkId: string }) => Promise<AxiosResponse<RepairWorkDto, unknown>>;
  deleteFunc?: (params: { repairWorkId: string }) => Promise<AxiosResponse<unknown, unknown>>;
  onDeleted?: () => void;
}

export type RepairWorkViewProps = OwnProps;

export default function RepairWorkView({
  repairWorkId,
  repairWork,
  headerProps,
  displayProps = defaultDisplayProps,
  getFunc,
  deleteFunc,
  onDeleted,
}: RepairWorkViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const { hasPermissions } = useAuthorizationInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [repairWorkFetched, setRepairWorkFetched] = useState<RepairWorkDto | undefined>(undefined);

  const _repairWork = repairWork || repairWorkFetched || undefined;

  useEffectWithThrottle(
    async () => {
      if (!repairWork && repairWorkId) {
        setIsLoading(true);
        try {
          const response = getFunc
            ? await getFunc({ repairWorkId })
            : await apiClient.repairWorkApi.apiV1RepairWorkRepairWorkIdGet({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                repairWorkId: repairWorkId!,
              });
          setRepairWorkFetched(response.data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    500,
    { leading: true, trailing: false },
    [repairWork, repairWorkId, getFunc],
  );

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        _repairWork && {
          idValue: _repairWork.id!,
          newTitle: _repairWork.localNumber || "",
        }) ||
      undefined,
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!_repairWork) {
    return <EntityNotFoundAlert />;
  }

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
          <Box>
            {headerProps?.title || (
              <Typography component='div' variant='h1'>
                <span>Repair work</span>{" "}
                <AppLink
                  enabled={
                    headerProps?.withLink &&
                    hasPermissions([AppPermission.FleetAppAccess, AppPermission.RepairCatalogRead])
                  }
                  to={ROUTE_PATH.REPAIR_WORK_VIEW(_repairWork.id)}
                >
                  <span>{_repairWork.localNumber}</span>
                </AppLink>
              </Typography>
            )}
          </Box>

          {displayProps?.actions && (
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={1}
              sx={{ flex: 1, justifyContent: "flex-end" }}
            >
              <AuthorizedElement permissions={[AppPermission.RepairCatalogManage]}>
                <Button
                  component={AppLink}
                  to={ROUTE_PATH.REPAIR_WORK_EDIT(_repairWork?.id)}
                  variant='outlined'
                  color='text'
                  size='medium'
                  startIcon={<AppIcon of='edit' />}
                >
                  Edit
                </Button>
              </AuthorizedElement>

              {/* Menu */}
              <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                <RepairWorkMenu
                  entity={_repairWork}
                  onUpdate={(newValue) => newValue && setRepairWorkFetched(newValue)}
                  onDelete={() => (onDeleted ? onDeleted() : history.goBack())}
                  deleteFunc={deleteFunc}
                  displayProps={{
                    actions: {
                      edit: false,
                      delete: true,
                    },
                  }}
                />
              </AuthorizedElement>
            </Stack>
          )}
        </Stack>
      }
    >
      <ViewContentBlock>
        {currentTenant?.id && _repairWork.tenantId !== currentTenant?.id && (
          <FieldValue label='Company' isEmpty={!_repairWork.tenantId}>
            <TenantLink entityId={_repairWork.tenantId} entity={undefined} />
          </FieldValue>
        )}

        <FieldValue label='Name'>{_repairWork.name}</FieldValue>
        <FieldValue label='Description'>{_repairWork.description}</FieldValue>
        <FieldValue label='Expendables included?'>
          <BooleanValue value={_repairWork.isExpendablesIncluded} />
        </FieldValue>
        <FieldValue label='Unit'>
          <InlineApiEnumValue type='MeasurementUnit' value={_repairWork.unit} withDescription />
        </FieldValue>
        <FieldValue label='Currency'>
          <GeneralCurrencyDisplay currency={_repairWork.currency} />
        </FieldValue>
        <FieldValue label='Price'>
          <CurrencyValue value={_repairWork.price} currency={_repairWork.currency} />
        </FieldValue>
        <FieldValue
          label='Included expendables'
          helperTooltip='Percentage of expendables included in the final price. I.e. SubTotal = Price + (Price * ExpendablesPercent).'
        >
          <PercentValue value={_repairWork.expendablesPercent} />
        </FieldValue>
      </ViewContentBlock>

      {/* Summary */}
      <ViewContentBlock alignSelf='flex-end'>
        <GeneralPriceSummaryDisplay
          sx={{ minWidth: { xxs: "100%", md: "300px" } }}
          summary={{
            currency: _repairWork.currency,
            subTotal: _repairWork.subTotal,
            subTotalIncDiscount: _repairWork.subTotalIncDiscount,
            discount: _repairWork.discount,
            tax: _repairWork.tax,
            total: _repairWork.total,
          }}
        />
      </ViewContentBlock>
    </ViewLayoutV2>
  );
}
