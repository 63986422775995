import { RefObject, forwardRef, useEffect, useRef, useState } from "react";

import { VehicleVisualModelHelper } from "@/common/helpers/entity/vehicleVisualModel";

import {
  ExtendedDamageType,
  VisualModelPointClickedInfo,
  VisualModelPointDraggedInfo,
} from "../Entity/DamageDetection/DamageDetectionCreateUpdate";
import { getSvgElementFromSvgContainerOrThrow } from "./DamagePointsVisualizer";

interface Props {
  pointInfo: ExtendedDamageType;
  pointCircleRadius: number;
  defaultPointColor: string;
  svgContent: string;
  svgContainerRef: RefObject<HTMLDivElement | null>;
  debugKey?: string;
  onPointClicked?: (pointInfo: ExtendedDamageType) => void;
  onPointMoved?: (pointInfo: VisualModelPointDraggedInfo) => void;
  addParentOnMoveListener?: (fn: (ev: Event, zoomLevel: number) => void) => void;
}

export default forwardRef(function DamagePoint(
  {
    svgContent,
    pointInfo,
    pointCircleRadius,
    defaultPointColor,
    svgContainerRef,
    debugKey,
    onPointClicked,
    onPointMoved,
    addParentOnMoveListener,
  }: Props,
  parentRef: any,
) {
  const dragDataRef = useRef({
    isDragging: false,
    prevX: 0,
    prevY: 0,
    startX: 0,
    startY: 0,
    coords: pointInfo.point ? { x: pointInfo.point.x || 0, y: pointInfo.point.y || 0 } : undefined,
  });
  // hacks to fix closuring issues
  const pointInfoRef = useRef(pointInfo);
  const onPointClickedRef = useRef(onPointClicked);

  // isPointMoved state for use inside eventListener
  const [isPointMoved, _setIsPointMoved] = useState<boolean>(false);
  const isPointMovedRef = useRef(isPointMoved);
  const setIsPointMoved = (toggle: boolean) => {
    isPointMovedRef.current = toggle;
    _setIsPointMoved(toggle);
  };

  const [draggedPointInfo, setDraggedPointInfo] = useState<VisualModelPointClickedInfo>({
    id: pointInfo.id || "",
    area: pointInfo.area || undefined,
    projection: pointInfo.projection || undefined,
    svgPathMetadata: pointInfo.svgPathMetadata!,
    point: {
      x: 0,
      y: 0,
      xp: 0,
      yp: 0,
      height: 0,
      width: 0,
    },
  });

  const getSvgEl = (): Nil<SVGElement> => {
    return svgContainerRef.current?.querySelector("svg");
  };

  useEffect(() => {
    pointInfoRef.current = { ...pointInfo };
  }, [pointInfo]);

  useEffect(() => {
    onPointClickedRef.current = onPointClicked;
  }, [onPointClicked]);

  useEffect(() => {
    if (!pointInfo.point || !draggedPointInfo.point.height || !draggedPointInfo.point.width) {
      return;
    }
    onPointMoved &&
      onPointMoved({
        ...draggedPointInfo,
        id: pointInfo.id!,
        point: { ...draggedPointInfo.point },
        initialPointInfo: pointInfo,
      });
  }, [isPointMoved]);

  useEffect(() => {
    const svgEl = getSvgEl();
    if (!svgContent || !svgEl || !pointInfo.point) {
      return;
    }

    // create <circle /> element for the point
    const NS = svgEl.getAttribute("xmlns");
    const circle2 = document.createElementNS(NS, "circle") as SVGCircleElement;
    circle2.setAttribute("cx", `${pointInfo.point.xp! * 100}%`);
    circle2.setAttribute("cy", `${pointInfo.point.yp! * 100}%`);
    circle2.setAttribute("r", `${pointCircleRadius}%`);
    circle2.setAttribute("fill", pointInfo.color || defaultPointColor);
    circle2.setAttribute("style", `cursor: pointer;`);
    circle2.addEventListener("mousedown", (e) => {
      const e2 = e as MouseEvent;
      e2.stopPropagation();
      if (dragDataRef.current.isDragging === true) return;
      dragDataRef.current.startX = e2.clientX;
      dragDataRef.current.startY = e2.clientY;
      dragDataRef.current.prevX = e2.clientX;
      dragDataRef.current.prevY = e2.clientY;
      if (pointInfo.isDraggable) {
        dragDataRef.current.isDragging = true;
      }
    });
    circle2.addEventListener("mouseup", (e) => {
      const e2 = e as MouseEvent;
      e2.stopPropagation();
      dragDataRef.current.isDragging = false;
      if (dragDataRef.current.startX === e2.clientX && dragDataRef.current.startY === e2.clientY) {
        onPointClickedRef.current && onPointClickedRef.current(pointInfoRef.current);
      }
      if (dragDataRef.current.startX !== e2.clientX && dragDataRef.current.startY !== e2.clientY) {
        setIsPointMoved(!isPointMovedRef.current);
      }
    });
    svgEl.appendChild(circle2);

    // listen to mouse moves of parent to handle dragging of the point
    if (pointInfo.isDraggable && addParentOnMoveListener) {
      addParentOnMoveListener((e: any) => {
        e.preventDefault();
        if (dragDataRef.current.isDragging && svgContainerRef.current) {
          e.stopPropagation();
          const svgElement = getSvgElementFromSvgContainerOrThrow(svgContainerRef.current);
          const refBBox = svgElement.getBBox();
          const pt = new DOMPoint();
          pt.x = e.pageX;
          pt.y = e.pageY;
          const svgMatrix = svgElement.getScreenCTM()?.inverse();
          if (svgMatrix) {
            const pointTransformed = pt.matrixTransform(svgMatrix);
            const newXP = pointTransformed.x / (refBBox.width + refBBox.x * 2);
            const newYP = pointTransformed.y / (refBBox.height + refBBox.y * 2);
            circle2.style.display = "none";
            const elementUnderCircle2 = document.elementFromPoint(e.clientX, e.clientY) as Element;
            circle2.style.display = "block";
            if (elementUnderCircle2.nodeName !== "path") {
              return;
            }
            circle2.setAttribute("cx", `${newXP * 100}%`);
            circle2.setAttribute("cy", `${newYP * 100}%`);

            const metadata = VehicleVisualModelHelper.getMetadataFromSvgPathEl(elementUnderCircle2);
            if (metadata) {
              setDraggedPointInfo({
                id: draggedPointInfo.id,
                area: metadata.vehicleArea,
                projection: metadata.vehicleProjection,
                svgPathMetadata: metadata,
                point: {
                  x: pointTransformed.x,
                  y: pointTransformed.y,
                  xp: newXP,
                  yp: newYP,
                  height: pointInfoRef.current.point?.height || 0,
                  width: pointInfoRef.current.point?.width || 0,
                },
              });
            }
          }
        }
      });
    }

    return () => {
      if (svgEl === circle2.parentNode) {
        svgEl.removeChild(circle2);
      }
    };
  }, [svgContent, pointInfo.point?.xp, pointInfo.point?.yp]);

  return <></>;
});
