import { RepairWorkDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelector, {
  BaseRepairEntityCrossTenantSelectorInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelector";
import RepairWorkAutocomplete, { RepairWorkAutocompleteProps } from "./RepairWorkAutocomplete";

export type RepairWorkCrossTenantSelectorProps =
  BaseRepairEntityCrossTenantSelectorInheritableProps<RepairWorkDto> & {
    repairWorkAutocompleteProps?: Partial<RepairWorkAutocompleteProps>;
  };

export default function RepairWorkCrossTenantSelector({
  repairWorkAutocompleteProps,
  ...otherProps
}: RepairWorkCrossTenantSelectorProps) {
  return (
    <BaseRepairEntityCrossTenantSelector<RepairWorkDto>
      repairEntityName={{
        singular: "repair work",
        plural: "repair work",
      }}
      renderRepairEntityAutocomplete={(params) => (
        <RepairWorkAutocomplete
          {...params.autocompleteProps}
          {...repairWorkAutocompleteProps}
          searchFilters={{
            ...params.searchFilters,
            ...repairWorkAutocompleteProps?.searchFilters,
          }}
          displayProps={{
            ...params.displayProps,
            ...repairWorkAutocompleteProps?.displayProps,
          }}
        />
      )}
      {...otherProps}
    />
  );
}
