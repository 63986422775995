import { Box, Chip, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { FeatureName } from "@/common/constants/featureName";
import { RepairHelper } from "@/common/helpers/entity/repair";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { featureManager } from "@/config/features";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, RepairWorkDto, RepairWorkGetPaginatedDto } from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import AppTypography from "../../Text/AppTypography";
import CreateUpdateRepairWorkModal from "./CreateUpdateRepairWorkModal";
import RepairWorkCrossTenantSelectorPopover from "./RepairWorkCrossTenantSelectorPopover";
import RepairWorkViewModal from "./View/RepairWorkViewModal";

const defaultDisplayProps = {
  viewInModal: true,
  editInModal: true,
  crossTenantSelector: true,
};

export type RepairWorkAutocompleteOption = BaseAutocompleteOption<RepairWorkDto>;

export const getRepairWorkOptionTitle = (data: RepairWorkDto): string =>
  StringHelper.joinIntoString([data.name || data.localNumber], ", ", {
    skipEmpty: true,
  });

export const repairWorkToOption = (data: RepairWorkDto): RepairWorkAutocompleteOption => ({
  id: data.id!,
  title: getRepairWorkOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface RepairWorkAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<RepairWorkDto> {
  searchFilters?: Partial<Omit<RepairWorkGetPaginatedDto, "search" | "includeIds">>;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function RepairWorkAutocomplete({
  searchFilters,
  displayProps,

  isPreload = true,
  ...otherProps
}: RepairWorkAutocompleteProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const authorizationInfo = useAuthorizationInfo();

  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={repairWorkToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.repairWorkApi.apiV1RepairWorkGetPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairWorkGetPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          repairWorkGetPaginatedDto: {
            ...params.repairWorkGetPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Repair work'
      placeholder='Search for repair work...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='repairWork' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <AppTypography
                    ellipsing={{ enabled: true }}
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </AppTypography>
                </>
              }
              secondary={
                option.data && (
                  <Box>
                    <AppTypography ellipsing={{ enabled: true }} variant='body2'>
                      {option.data?.description}
                    </AppTypography>

                    {RepairHelper.isExternal({
                      tenantId: currentTenant?.id,
                      repairEntity: option.data,
                    }) && (
                      <Box>
                        <Chip
                          variant='outlined'
                          size='extraSmall'
                          label={
                            <>
                              <AppIcon of='external' inText /> External
                            </>
                          }
                        />
                      </Box>
                    )}
                  </Box>
                )
              }
            />
          </ListItem>
        );
      }}
      entityViewModal={{
        if:
          displayProps?.viewInModal &&
          authorizationInfo.hasPermissions([AppPermission.RepairCatalogRead]),
        renderModal: (params) => (
          <RepairWorkViewModal
            {...params.dialogProps}
            viewProps={{
              repairWork: params.entity,
            }}
          />
        ),
      }}
      entityEditModal={{
        if:
          displayProps?.editInModal &&
          authorizationInfo.hasPermissions([AppPermission.RepairCatalogManage]),
        renderModal: (params) => (
          <CreateUpdateRepairWorkModal
            {...params.dialogProps}
            createUpdateProps={{
              repairWorkId: params.entity.id,
              repairWork: params.entity,
              onSave: (newValue) => {
                params.handleEntityUpdated(newValue);
                params.dialogProps?.onClose?.({}, "escapeKeyDown");
              },
            }}
          />
        ),
      }}
      endAdornmentActions={{
        actions: [
          {
            if:
              displayProps?.crossTenantSelector &&
              featureManager.isEnabled(FeatureName.CrossTenantRepairEntityAccess),
            renderAction: (params) => (
              <RepairWorkCrossTenantSelectorPopover
                clickBehavior={{}}
                trigger={
                  <AppIconButton {...params.iconButtonProps} title='My cross-company repair work'>
                    <AppIcon of='crossTenantBrowse' />
                  </AppIconButton>
                }
                selectorProps={{
                  repairWorkAutocompleteProps: {
                    searchFilters: {},
                  },
                  onSave: (newValue) => {
                    console.log("Cross-tenant repair work selected", newValue);
                    if (newValue?.repairEntity) {
                      params.handleEntitySelected(newValue?.repairEntity);
                    }
                  },
                  onCancel: () => {},
                }}
              />
            ),
          },
        ],
      }}
      {...otherProps}
    />
  );
}
