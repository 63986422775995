import { Buffer } from "buffer";
import { isNil } from "lodash-es";
import { AnyAction } from "redux";

import { CookieHelper } from "@/common/helpers/cookie";
import { CookieListener } from "@/common/helpers/cookieListener";
import { LocalStorageHelper } from "@/common/helpers/localStorage";
import { auth0Config } from "@/config/config";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission } from "@/core/api/generated";
import store, { AppThunkDispatchType } from "@/store";
import * as authSlice from "@/store/auth/slice";

import { auth0Provider } from "../auth0Provider";
import { API_HTTP_HEADER_NAME } from "../constants/common";
import { LocalStorageKey } from "../constants/localStorage";
import { TypedEventEmitter } from "../eventEmmiters/typedEventEmitter";
import { HttpHelper } from "../helpers/http";
import { queryStringParamsService } from "./queryStringParams";
import { tenantService } from "./tenant";

export interface ICookieAuthorizationData {
  separator?: string;
  tenantId?: string;
  permissions: string[];
}

export type AuthorizationDataStrategy = "header" | "cookie";

export interface CustomAuth0AppState {
  tenantIdentifier?: string | null;
  /** Absolute URL where to redirect after login (SPA -> Auth0 -> SPA -> URL).
   *  NB: this is custom URL that is handled manually and not by Auth0, so it can be any URL. */
  redirectUrl?: string | null;
  /** React router relative URL where to redirect after login (SPA -> Auth0 -> SPA -> SPA URL).
   *  Can include query string parameters. For instance, /auth/invite/63ad579db563ed170d6c6a00/accept?redirectUrl=.
   *  NB: this URL doesn't include base path and tenant identifier in base path.
   */
  spaRedirectUrl?: string | null;

  [key: string]: any;
}

export interface LastAuthenticatedUserInfo {
  auth0UserId?: string;
  email?: string;
}

export class AuthService extends TypedEventEmitter<{
  // list of supported events
  authenticationStateChange: { isAuthenticated?: boolean };
}> {
  private isMonitoringAuthorizationData = false;
  private isMonitoringAuthenticatedStatus = false;
  private prevIsAuthenticated: boolean | undefined = undefined;

  constructor() {
    super();

    this.monitorAuthenticatedStatus();
  }

  public get strategy(): AuthorizationDataStrategy {
    return "header";
  }

  public get isPermissionsReady(): boolean {
    if (isNil(store.getState().auth.isAuthenticated)) {
      return false;
    }
    return store.getState().auth.isAuthenticated
      ? !isNil(store.getState().auth.authorizationProfile?.permissions)
      : true;
  }

  public get userPermissions(): AppPermission[] {
    if (this.strategy === "header") {
      return store.getState().auth.authorizationProfile?.permissions || [];
    } else if (this.strategy === "cookie") {
      return this.cookieUserPermissions;
    } else {
      return [];
    }
  }

  public async isAuthenticated(): Promise<boolean | undefined> {
    return isNil(auth0Provider?.auth0Client)
      ? undefined
      : await auth0Provider.auth0Client!.isAuthenticated();
  }

  public userHasPermissions(permissions: AppPermission[]): boolean {
    return permissions.every((x) => this.userPermissions.includes(x));
  }

  /** Checks whether user has at least one of the specified permissions */
  public userHasPermissionsAny(permissions: AppPermission[]): boolean {
    return permissions.some((x) => this.userPermissions.includes(x));
  }

  /** Monitor isAuthenticated. */
  private async monitorAuthenticatedStatus() {
    // because @auth0/auth0-spa-js doesn't provides auth events,
    // we can do it stupid way - periodically check for auth status.
    if (this.isMonitoringAuthenticatedStatus) {
      return;
    }

    await this.checkAuthenticatedStatus();

    setInterval(
      (async () => {
        await this.checkAuthenticatedStatus();
      }).bind(this),
      2500,
    );

    this.isMonitoringAuthenticatedStatus = true;
  }

  public async loginWithRedirect(options?: {
    /** The user's email address or other identifier. */
    loginHint?: string | null;
    connection?: string | null;
    appState?: CustomAuth0AppState;
  }) {
    const auth0Client = auth0Provider.getAuth0Client();
    if (!auth0Client) {
      throw new Error(`Can't instantiate auth0Client.`);
    }

    const tenantInfo = tenantService.resolveTenant();
    const appState: CustomAuth0AppState = {
      ...(options?.appState || {}),
      // save tenant identifier that was resolved before redirect, so we can restore it after login.
      tenantIdentifier: options?.appState?.tenantIdentifier || tenantInfo?.identifier,
    };

    const authParams = queryStringParamsService.getCurrentAuthParams();

    await auth0Client.logout({ openUrl: false });

    await auth0Client.loginWithRedirect({
      authorizationParams: {
        display: "page",
        screen_hint: "login",
        audience: auth0Config.audience,
        redirectMethod: "assign",
        login_hint: options?.loginHint || authParams?.auth0LoginHint || undefined,
        connection: options?.connection || authParams?.preferredAuth0Connection || undefined,
      },
      appState: appState,
      //redirect_uri // already configured in Auth0
    });
  }

  public async checkAuthenticatedStatus() {
    await auth0Provider.getAuth0Client()?.checkSession();
    const isAuthenticated = await this.isAuthenticated();
    await this.handleAuthenticatedStatusChange(isAuthenticated);
  }

  public async handleAuthenticatedStatusChange(isAuthenticated?: boolean) {
    if (isAuthenticated === true) {
      const token = await auth0Provider.getAuth0Client()?.getTokenSilently();
      if (token && token !== apiClient.getAccessToken()) {
        console.log("Updating access token...");
        apiClient.updateAccessToken(token);
        store.dispatch(authSlice._authenticateSucceeded());
        store.dispatch(authSlice.getAuthorizationProfile() as unknown as AnyAction); // don't await intentionally (in case it fail)
      }

      this.monitorAuthorizationData();

      const user = await auth0Provider.auth0Client?.getUser();
      this.saveLastAuthenticatedUserInfo(
        user
          ? {
              auth0UserId: user.sub,
              email: user.email,
            }
          : null,
      );
    } else if (isAuthenticated === false) {
      store.dispatch(authSlice._authenticateFailed());
    }

    // emit event after internal handling
    if (this.prevIsAuthenticated !== isAuthenticated) {
      this.emit("authenticationStateChange", { isAuthenticated });
      this.prevIsAuthenticated = isAuthenticated;
    }
  }

  /** Start active authorization data (permissions) monitoring. */
  public monitorAuthorizationData() {
    if (this.isMonitoringAuthorizationData) {
      return;
    }
    if (this.strategy === "header") {
      this.monitorAuthorizationStateHeader();
    } else if (this.strategy === "cookie") {
      this.monitorAuthorizationCookie();
    }
    this.isMonitoringAuthorizationData = true;
  }

  public getLastAuthenticatedUserInfo(): LastAuthenticatedUserInfo | null | undefined {
    const raw = LocalStorageHelper.getItem(LocalStorageKey.lastAuthenticatedUserInfo);
    return raw ? (JSON.parse(raw) as LastAuthenticatedUserInfo | null) : undefined;
  }

  private saveLastAuthenticatedUserInfo(userInfo: LastAuthenticatedUserInfo | null | undefined) {
    if (userInfo) {
      LocalStorageHelper.setItem(
        LocalStorageKey.lastAuthenticatedUserInfo,
        JSON.stringify(userInfo),
      );
    } else {
      LocalStorageHelper.removeItem(LocalStorageKey.lastAuthenticatedUserInfo);
    }
  }

  // #region 'AuthorizationData' Cookie strategy

  private authorizationCookieName = "AuthorizationData";
  private authorizationData: ICookieAuthorizationData | null = null;

  private get cookieUserPermissions(): AppPermission[] {
    return (this.authorizationData?.permissions || []) as AppPermission[];
  }

  private monitorAuthorizationCookie(): void {
    // build initial data
    this.buildAuthorizationData(CookieHelper.get(this.authorizationCookieName));

    CookieListener.listenCookieChanges();
    CookieListener.onCookieChange(this.authorizationCookieName, (detail, e) => {
      console.log(`'${this.authorizationCookieName}' cookie changed:`, detail);
      this.buildAuthorizationData(detail.newValue);
    });
  }

  private buildAuthorizationData(cookieValue?: string | null) {
    if (!cookieValue) {
      this.authorizationData = null;
    } else {
      const encoded = cookieValue;
      const json = Buffer.from(encoded, "base64").toString();
      const parsed = JSON.parse(json);
      const { s: separator, tId: tenantId, p: permissionsRaw } = parsed;
      const permissions = permissionsRaw.split(separator);

      this.authorizationData = {
        separator,
        tenantId,
        permissions,
      };
    }
  }

  // #endregion

  // #region 'AuthorizationState` HTTP header strategy

  private authorizationStateHeaderName = API_HTTP_HEADER_NAME.AUTHORIZATION_STATE;
  private authorizationState?: string | null = undefined;

  public setAuthorizationState(state?: string | null) {
    this.authorizationState = state;
  }

  private monitorAuthorizationStateHeader() {
    apiClient.registerApiResponseHandler(async (error, response) => {
      const actualResponse = error?.response || response;
      if (!actualResponse || !actualResponse.headers) {
        return;
      }

      const authorizationState = HttpHelper.getHeaderValue(
        actualResponse.headers,
        this.authorizationStateHeaderName,
      );

      if (this.authorizationState !== authorizationState) {
        // console.log("Authorization state changed:", {
        //   oldValue: this.authorizationState,
        //   newValue: authorizationState,
        // });

        const thunkDispatch = store.dispatch as AppThunkDispatchType;
        const authorizationProfile = await thunkDispatch(
          authSlice.getAuthorizationProfileThrottle(),
        );

        console.log("Authorization profile fetched:", authorizationProfile);
      }
    });
  }

  // #endregion
}

export const authService = new AuthService();
