import { Chip, ChipProps } from "@mui/material";

import { VehicleCleanlinessExtent } from "@/core/api/generated";

import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";

interface Props {
  value?: VehicleCleanlinessExtent;
}

const colorMap: Record<VehicleCleanlinessExtent, ChipProps["color"]> = {
  [VehicleCleanlinessExtent.None]: "success",
  [VehicleCleanlinessExtent.Spotless]: "success",
  [VehicleCleanlinessExtent.VeryClean]: "success",
  [VehicleCleanlinessExtent.Clean]: "success",
  [VehicleCleanlinessExtent.SlightlyDirty]: "warning",
  [VehicleCleanlinessExtent.ModerateDirty]: "warning",
  [VehicleCleanlinessExtent.VeryDirty]: "error",
  [VehicleCleanlinessExtent.ExtremelyDirty]: "error",
};
export default function VehicleCleanlinessExtentInline({ value }: Props) {
  return (
    <Chip
      label={<InlineApiEnumValue type='VehicleCleanlinessExtent' value={value} />}
      color={colorMap[value!]}
    />
  );
}
