import { Button, DialogProps, Stack, Typography, TypographyProps } from "@mui/material";
import { ReactNode, useState } from "react";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  NegotiationDto,
} from "@/core/api/generated";

import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import NegotiationSettingsModal from "../EditNegotiationModal";
import NegotiationMenu from "../NegotiationMenu";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";

export enum NegotiationViewPageTabs {
  Overview = "Overview",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  source: true,
  proposals: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<NegotiationDto> {
  negotiationId?: string | null;
  negotiation?: NegotiationDto | null;
  isLoading?: boolean;
  withRealtimeDataUpdates?: boolean;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode;
    titleTypographyProps?: TypographyProps;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  dialogProps?: DialogProps;
  onDelete?: () => void;
}

export type NegotiationViewProps = OwnProps;

export default function NegotiationView({
  negotiationId,
  negotiation,
  isLoading,
  withRealtimeDataUpdates,
  headerProps,
  displayProps = defaultDisplayProps,
  dialogProps,
  onDelete,
}: NegotiationViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);

  const history = useAppHistory();

  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Negotiation),
      viewVariant: displayProps.viewVariant,
    },
  });

  const request = useApiRequest(
    apiClient.negotiationsApi.apiV1NegotiationsNegotiationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      negotiationId: negotiationId!,
    },
    {
      deps: [negotiationId],
      skip: !negotiationId,
    },
  );
  negotiation = request?.data || negotiation;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(currentTenant?.id, EntityType.Negotiation, negotiationId || ""),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      request.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        negotiation && {
          idValue: negotiation?.id || "",
          newTitle: negotiation?.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Negotiation}
      entityId={negotiationId}
      entity={negotiation}
      entityRequest={request}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        dialogProps={dialogProps}
        header={
          <SimpleViewPageHeader
            title={
              displayProps?.header && (
                <Stack direction='row' spacing={1} sx={{ alignItems: "flex-start" }}>
                  {headerProps?.title || (
                    <Typography
                      component={"span"}
                      variant='h1'
                      {...headerProps?.titleTypographyProps}
                    >
                      Negotiation{" "}
                      <AppLink
                        enabled={headerProps?.withLink}
                        to={ROUTE_PATH.NEGOTIATION_VIEW(negotiation?.id)}
                      >
                        <span>{negotiation?.localNumber}</span>
                      </AppLink>
                    </Typography>
                  )}
                </Stack>
              )
            }
            primaryActions={
              <AuthorizedElement
                permissions={[AppPermission.FleetAppAccess, AppPermission.NegotiationManage]}
              >
                <Button
                  variant='outlined'
                  color='text'
                  startIcon={<AppIcon of='edit' />}
                  onClick={() => setIsEditModalOpened(true)}
                >
                  Edit
                </Button>
              </AuthorizedElement>
            }
            secondaryActions={
              displayProps?.actions && (
                <NegotiationMenu
                  onDelete={() => history.goBack()}
                  onUpdate={(newValue) =>
                    newValue ? request.replaceData(newValue) : request.refetch()
                  }
                  entity={negotiation}
                />
              )
            }
          />
        }
      >
        <PageTabsV2
          tabIdsDefinition={NegotiationViewPageTabs}
          defaultTabId={NegotiationViewPageTabs.Overview}
          viewVariant={displayProps?.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: NegotiationViewPageTabs.Overview, isHideable: false },
            { label: "History", value: NegotiationViewPageTabs.GeneralHistory },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            negotiation && (
              <>
                {activeTab === NegotiationViewPageTabs.Overview && (
                  <OverviewTabContent
                    negotiation={negotiation}
                    displayProps={displayProps}
                    onTagsUpdated={(newValue) => {
                      request.updateData((data) => {
                        data.tags = newValue || undefined;
                      });
                    }}
                  />
                )}
                {activeTab === NegotiationViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent negotiation={negotiation} />
                )}
              </>
            )
          }
        </PageTabsV2>
      </ViewLayoutV2>

      <NegotiationSettingsModal
        negotiation={negotiation || undefined}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
      />
    </BaseEntityView>
  );
}
