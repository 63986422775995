import { Stack } from "@mui/material";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import { ProfileDto } from "@/core/api/generated";

interface Props {
  profile: ProfileDto;
}

export default function PersonalInfoTabContent({ profile }: Props) {
  return (
    <PageTabContent>
      <Stack direction='column' spacing={1}>
        <Stack spacing={1}>
          <FieldValue label='Id'>{profile?.id || ""}</FieldValue>
          <FieldValue label='Email'>{profile?.email || ""}</FieldValue>
          <FieldValue label='Status'>{profile?.status || ""}</FieldValue>
          <FieldValue label='Name'>
            {profile.personName?.firstName || ""} {profile.personName?.lastName || ""}
          </FieldValue>
          <FieldValue label='Phone number'>{profile?.phoneNumber || ""}</FieldValue>
        </Stack>
      </Stack>
    </PageTabContent>
  );
}
