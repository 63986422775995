import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  SxProps,
  Theme,
} from "@mui/material";

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { AssignmentStepSettingsInputDto } from "@/core/api/generated";

interface Props {
  formikProps: CustomFormikSubProps<AssignmentStepSettingsInputDto | null | undefined>;
  sx?: SxProps<Theme>;
  required?: boolean;
  disabled?: boolean;
}

export default function AssignmentStepSettingsInput({
  formikProps,
  sx,
  required,
  disabled,
}: Props) {
  const { values, errors, setFieldValue } = formikProps;

  return (
    <Box sx={sx}>
      <FormControl fullWidth error={Boolean(errors?.isRequireVisualInspection)}>
        <FormControlLabel
          control={
            <Checkbox
              required={required}
              disabled={disabled}
              checked={values?.isRequireVisualInspection ?? false}
              onChange={(e) => setFieldValue("isRequireVisualInspection", e.target.checked)}
            />
          }
          label='Require visual inspection'
        />
        <FormHelperText>{errors?.isRequireVisualInspection}</FormHelperText>
      </FormControl>
    </Box>
  );
}
