import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import {
  DebounceSettings,
  ThrottleSettings,
  isArray as _isArray,
  isObject as _isObject,
  cloneDeep,
  debounce,
  isBoolean,
  isEmpty,
  isNil,
  throttle,
} from "lodash-es";
import moment, { Moment } from "moment";
import { DependencyList, useCallback, useEffect, useMemo, useRef } from "react";

import { ArrayHelper } from "@/common/helpers/array";
import { PaginationDtoOfTItemTyped } from "@/common/ts/pagination";
import {
  EntityChangeType,
  EntityChangedDtoOfTEntityDto,
  IBaseEntityDto,
} from "@/core/api/generated";

import { useEffectWithDeepCompare } from "../effect/useEffectWithDeepCompare";
import { useValueMemoWithDeepCompare } from "../memo/useValueMemoWithDeepCompare";
import { useUnmountEffect } from "../mount/useUnmountEffect";
import { useTriggerRender } from "../render/useTriggerRender";
import { ShortPolling, ShortPollingOptions } from "./shortPolling";
import { UseCommonRequestParamsHookResult } from "./useCommonRequestParams";

type DebounceOptions = { wait: number | undefined; options?: DebounceSettings };
type ThrottleOptions = { wait: number | undefined; options?: ThrottleSettings };

/** HTTP response details. */
export type UseApiRequestResponseDetails = {
  statusCode: number;
  isOk: boolean;
  isBadRequest: boolean;
  isUnauthorized: boolean;
  isForbidden: boolean;
  isNotFound: boolean;
  isInternalServerError: boolean;
};

export type UseApiRequestBaseResult = {
  /** Request is in process. */
  isLoading: boolean;
  /** Request is started. Set only once, for the first request. */
  isStarted: boolean;
  /** Request is ended (success or error). Set only once, for the first request. */
  isEnded: boolean;
  /** HTTP response details. */
  responseDetails?: UseApiRequestResponseDetails;
  error?: any;
};

export type UseApiRequestResult<
  TApiRequestFunc extends (
    requestParameters: any,
    requestOptions?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>,
  TRequestParams extends Parameters<TApiRequestFunc>[0],
  TRequestOptions extends AxiosRequestConfig,
  TResponse extends Awaited<ReturnType<TApiRequestFunc>>,
  TData extends Awaited<ReturnType<TApiRequestFunc>>["data"],
  // TRequestParams = Parameters<TApiRequestFunc>[0],
> = {
  /** Request is in process. */
  isLoading: boolean;
  /** Request is started. Set only once, for the first request. */
  isStarted: boolean;
  /** Request is ended (success or error). Set for every request. */
  isEnded: boolean;
  /** First request is ended (success or error). Set only once, for the first request. */
  isFirstEnded: boolean;
  /** Current request params. */
  requestParams: TRequestParams | null;
  /** HTTP response. */
  response?: TResponse | null;
  /** HTTP response details. */
  responseDetails?: UseApiRequestResponseDetails;
  /** Data from HTTP response body. */
  data: TData | undefined | null;
  /** Axios error or HTTP error. */
  error: any | undefined;
  /** Refetches data with current params. Ignores skip option. */
  refetch: () => Promise<TData | undefined | null>;
  /** Refetches data with new params. Ignores skip option. */
  refetchWithNewParams: (
    newRequestParams: Partial<TRequestParams>,
  ) => Promise<TData | undefined | null>;
  /** Locally replaces fetched data. */
  replaceData: (newData: TData | undefined | null) => void;
  /** Locally update fetched data using provided func. */
  updateData: (updateFunc: (fetchedData: TData) => void) => void;
  /** Figures out and automatically applies updates locally. */
  handleEntityChanged: (dto: EntityChangedDtoOfTEntityDto | undefined | null) => void;
  /** Resets to initial state (like if no requests has been sent). */
  reset: () => void;
};

/** Hook for provided API request. */
export function useApiRequest<
  TApiRequestFunc extends (
    requestParameters: any,
    requestOptions?: AxiosRequestConfig,
  ) => Promise<AxiosResponse>,
  TRequestParams extends Parameters<TApiRequestFunc>[0],
  TRequestOptions extends AxiosRequestConfig,
  TResponse extends Awaited<ReturnType<TApiRequestFunc>>,
  TData extends Awaited<ReturnType<TApiRequestFunc>>["data"],
>(
  apiRequestFunc: TApiRequestFunc,
  /** API endpoint params.
   *  By default data is refetched when params change.
   *  You can change this behavior by providing custom deps. */
  requestParams: Parameters<TApiRequestFunc>[0],
  options?: {
    /** Additional request options. */
    requestOptions?: TRequestOptions;
    /** Custom list of deps. When changed data is refetched. */
    deps?: DependencyList;
    /** List of deps for which debounce is applied. */
    debouncedDeps?: {
      deps?: DependencyList;
      /** If true/false, then skip for main request is ignored.
       *  If undefined, main requests skip is used.
       */
      skip?: boolean;
    } & DebounceOptions;
    /** List of deps for which throttle is applied. */
    throttledDeps?: {
      deps?: DependencyList;
      /** If true/false, then skip for main request is ignored.
       *  If undefined, main requests skip is used.
       */
      skip?: boolean;
    } & ThrottleOptions;
    /** Debounce options for main request. */
    debounce?: DebounceOptions;
    /** Throttle options for main request. */
    throttle?: ThrottleOptions;
    /** For main request. When set, request is not sent (skipped) if condition is true. Doesn't apply to refetch.
     * @default false
     */
    skip?: boolean;
    /** Configures Short Polling which will poll server (send request) after every time interval. */
    shortPolling?: ShortPollingOptions<TData>;
    /** Integration with useRequestParams() hook. */
    commonRequestParams?: UseCommonRequestParamsHookResult<any>; // use any as we don't know and don't care about the custom request params type stored inside
    /** For debug. */
    debugKey?: string;
    /** Whether active request can be cancelled.
     * Main scenario: fetch -> deps changed -> cancel -> fetch.
     * @default true
     */
    isAllowCancel?: boolean;
  },
): UseApiRequestResult<TApiRequestFunc, TRequestParams, TRequestOptions, TResponse, TData> {
  const { triggerRender } = useTriggerRender();

  // memorize value to avoid issue with anonymous objects equality
  // (ensure the same object is not treated as a new value)
  const requestCountRef = useRef(0);
  const firstRequestStartedAtRef = useRef<Moment | null>(null);
  const lastRequestStartedAtRef = useRef<Moment | null>(null);
  const requestParamsMemorizedRef = useRef<TRequestParams>(requestParams);
  const responseRef = useRef<TResponse | undefined | null>(undefined);
  const dataRef = useRef<TData | undefined | null>(undefined);
  const errorRef = useRef<any | undefined | null>(undefined);
  const isLoadingRef = useRef<boolean>(false);
  const isStartedRef = useRef<boolean>(false);
  const isEndedRef = useRef<boolean>(false);
  const isFirstEndedRef = useRef<boolean>(false);
  const isAnyRequestStartedRef = useRef<boolean>(false);
  const shortPollingRef = useRef<ShortPolling<TData> | null>(null);
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null);
  const currentRequestEndPromiseRef = useRef<Promise<void> | null>(null);

  const optionsMemorized = useValueMemoWithDeepCompare(options);
  const depsMemorized = useValueMemoWithDeepCompare(options?.deps || []);
  const debouncedDepsMemorized = useValueMemoWithDeepCompare(options?.debouncedDeps?.deps);
  const throttledDepsMemorized = useValueMemoWithDeepCompare(options?.throttledDeps?.deps);
  const fetchDepsMemorized = useMemo(
    () => depsMemorized || requestParamsMemorizedRef.current,
    [depsMemorized, requestParamsMemorizedRef.current],
  );
  const responseDetailsComputed = useMemo<UseApiRequestResponseDetails | undefined>(
    () =>
      responseRef.current
        ? {
            statusCode: responseRef.current.status,
            isOk: responseRef.current.status >= 200 && responseRef.current.status <= 299,
            isBadRequest: responseRef.current.status === 400,
            isUnauthorized: responseRef.current.status === 401,
            isForbidden: responseRef.current.status === 403,
            isNotFound: responseRef.current.status === 404,
            isInternalServerError: responseRef.current.status === 500,
          }
        : undefined,
    [responseRef.current],
  );

  // track request params change
  useEffectWithDeepCompare(() => {
    requestParamsMemorizedRef.current = requestParams;
  }, [requestParams]);
  useEffect(() => {
    isAnyRequestStartedRef.current = !isNil(firstRequestStartedAtRef.current);
  }, [firstRequestStartedAtRef.current]);

  // #region Handle short polling

  const shortPollingIfDep =
    !isNil(options?.shortPolling?.if) && isBoolean(options?.shortPolling?.if)
      ? options?.shortPolling?.if
      : null;

  useEffectWithDeepCompare(() => {
    const newShortPolling = options?.shortPolling
      ? new ShortPolling<TData>(options?.shortPolling)
      : null;
    if (shortPollingRef.current) {
      shortPollingRef.current.end();
    }
    shortPollingRef.current = newShortPolling;
    shortPollingRef.current?.start();
  }, [options?.shortPolling?.intervalMs, shortPollingIfDep]);

  useEffect(() => {
    shortPollingRef.current?.setData(dataRef.current || null);
  }, [dataRef.current]);

  useUnmountEffect(() => {
    shortPollingRef.current?.end();
  }, []);

  // #endregion

  const handleRequestStarted = useCallback(() => {
    options?.commonRequestParams?.refetch?.eventEmitter?.emit("requestStarted", undefined);
  }, [options?.commonRequestParams]);

  const handleRequestEnded = useCallback(() => {
    options?.commonRequestParams?.refetch?.eventEmitter?.emit("requestEnded", undefined);
  }, [options?.commonRequestParams]);

  const handleRequestSucceeded = useCallback(() => {
    if (options?.commonRequestParams) {
      const paginated = dataRef.current as PaginationDtoOfTItemTyped<TData>;
      if (!isNil(paginated?.pagination?.totalCount)) {
        options.commonRequestParams.setPaginationInfo(paginated.pagination);
      }
    }
  }, [options?.commonRequestParams]);

  const apiRequestFuncComputed = useCallback(
    (requestParams2: TRequestParams, requestOptions2?: AxiosRequestConfig) => {
      const func: TApiRequestFunc =
        (optionsMemorized?.debounce &&
          (debounce(
            apiRequestFunc,
            optionsMemorized.debounce.wait,
            optionsMemorized.debounce.options,
          ) as unknown as TApiRequestFunc)) ||
        (optionsMemorized?.throttle &&
          (throttle(
            apiRequestFunc,
            optionsMemorized.throttle.wait,
            optionsMemorized.throttle.options,
          ) as unknown as TApiRequestFunc)) ||
        (throttle(apiRequestFunc, 500, {
          leading: true,
          trailing: false,
        }) as unknown as TApiRequestFunc);

      return func(requestParams2, requestOptions2);
    },
    [optionsMemorized?.debounce, optionsMemorized?.throttle],
  );

  const executeRequest = useCallback(
    async (
      requestParams2: TRequestParams,
      requestOptions?: { isPollRequest?: boolean },
    ): Promise<TData | null> => {
      handleRequestStarted();

      let resolveRequestEnd: () => void = () => {};
      currentRequestEndPromiseRef.current = new Promise((resolve) => (resolveRequestEnd = resolve));

      isLoadingRef.current = true;
      isStartedRef.current = true;
      isEndedRef.current = false;
      firstRequestStartedAtRef.current ||= moment();
      lastRequestStartedAtRef.current = moment();
      triggerRender(); // notify caller
      if (!requestOptions?.isPollRequest) {
        shortPollingRef.current?.end();
      }

      cancelTokenSourceRef.current = axios.CancelToken.source();
      const requestOptions2: AxiosRequestConfig = {
        ...optionsMemorized?.requestOptions,
        cancelToken: cancelTokenSourceRef.current.token,
      };

      try {
        const response2 = await apiRequestFuncComputed(requestParams2, requestOptions2);
        const newResponse = response2 as TResponse;
        const newData = (response2 as TResponse).data as TData;
        responseRef.current = newResponse;
        dataRef.current = newData;
        errorRef.current = undefined;
        handleRequestSucceeded();
        return newData;
      } catch (err) {
        // if (axios.isCancel(err)) {
        //   console.log(options?.debugKey, "Request cancelled.", err);
        // }

        responseRef.current =
          err instanceof AxiosError ? ((err as AxiosError)?.response as TResponse) || null : null;
        dataRef.current = undefined;
        errorRef.current = err;
      } finally {
        isLoadingRef.current = false;
        isStartedRef.current = false;
        isEndedRef.current = true;
        isFirstEndedRef.current = true;
        requestCountRef.current += 1;
        cancelTokenSourceRef.current = null;
        resolveRequestEnd();
        triggerRender();
        if (!requestOptions?.isPollRequest) {
          shortPollingRef.current?.start();
        }
        handleRequestEnded();
      }

      return null;
    },
    [apiRequestFuncComputed],
  );

  const executeRequestForDebouncedDeps = useCallback(
    debounce(
      executeRequest,
      optionsMemorized?.debouncedDeps?.wait,
      optionsMemorized?.debouncedDeps?.options,
    ),
    [executeRequest],
  );

  const executeRequestForThrottledDeps = useCallback(
    throttle(
      executeRequest,
      optionsMemorized?.throttledDeps?.wait,
      optionsMemorized?.throttledDeps?.options,
    ),
    [executeRequest],
  );

  const cancel = useCallback(async (message?: string) => {
    // console.log(options?.debugKey, `Cancel request. Message: ${message || "-"}`);
    cancelTokenSourceRef.current?.cancel(message);
    await cancelTokenSourceRef.current?.token.promise;
  }, []);

  const tryCancel = useCallback(async (message?: string): Promise<boolean> => {
    const isAllowCancel = optionsMemorized?.isAllowCancel ?? true;
    if (!isAllowCancel || !cancelTokenSourceRef.current) {
      return false;
    }
    // ensure current request ended
    await cancel(message);
    await currentRequestEndPromiseRef.current;
    return true;
  }, []);

  const refetch = useCallback(async () => {
    if (isStartedRef.current === true) {
      if (!(await tryCancel("Cancel due to refetch."))) {
        return;
      }
    }

    return await executeRequest(requestParamsMemorizedRef.current);
  }, [executeRequest]);

  const refetchWithNewParams = useCallback(
    async (newRequestParams: Partial<TRequestParams>) => {
      requestParamsMemorizedRef.current = {
        ...requestParamsMemorizedRef.current,
        ...newRequestParams,
      };
      return await executeRequest(requestParamsMemorizedRef.current);
    },
    [executeRequest],
  );

  const replaceData = useCallback((newData?: TData | null) => {
    if (!isNil(newData)) {
      errorRef.current = undefined;
    }
    responseRef.current = undefined;
    dataRef.current = cloneDeep(newData);
    triggerRender();
  }, []);

  const updateData = useCallback((updateFunc: (fetchedData: TData) => void) => {
    if (!isNil(dataRef.current)) {
      const newData = cloneDeep(dataRef.current);
      updateFunc(newData);
      dataRef.current = newData;
      triggerRender();
    }
  }, []);

  const handleEntityChanged = useCallback(
    (dto: EntityChangedDtoOfTEntityDto | null | undefined) => {
      if (!dto || isNil(dataRef.current)) {
        return;
      }

      const entity = dto.entity as IBaseEntityDto | null | undefined;
      const entityId = dto.entityId;

      // handle few scenarios of current data:
      // 1. Array
      // 2. PaginatedDto
      // 3. Single object
      const oldArray = dataRef.current as Array<IBaseEntityDto>;
      const oldPaginated = dataRef.current as PaginationDtoOfTItemTyped<IBaseEntityDto>;
      const oldObj = dataRef.current as IBaseEntityDto;

      const isArray = _isArray(dataRef.current);
      const isPaginated =
        _isObject(dataRef.current) && !isNil(oldPaginated.items) && !isNil(oldPaginated.pagination);
      const isObject = !isArray && !isPaginated && _isObject(dataRef.current);

      if (dto.changeType === EntityChangeType.Created) {
        // always refetch as we don't know if it's right to add new item locally and do not break the view
        refetch();
      } else if (dto.changeType === EntityChangeType.Updated) {
        // if entity is present then update locally, otherwise refetch
        if (entity && !isEmpty(entity.id)) {
          if (isArray && oldArray) {
            updateData((data2) => {
              ArrayHelper.replaceByPredicate(
                data2 as Array<IBaseEntityDto>,
                (x) => !isEmpty(x.id) && x.id === entity.id,
                entity,
              );
            });
          } else if (isPaginated && oldPaginated) {
            updateData((data2) => {
              const oldPaginated2 = data2 as PaginationDtoOfTItemTyped<IBaseEntityDto>;
              ArrayHelper.replaceByPredicate(
                oldPaginated2.items,
                (x) => !isEmpty(x.id) && x.id === entity.id,
                entity,
              );
            });
          } else if (isObject && oldObj) {
            replaceData(entity as TData);
          }
        } else {
          refetch();
        }
      } else if (dto.changeType === EntityChangeType.Deleted) {
        // if entityId is present then update locally, otherwise refetch
        if (!isEmpty(entityId)) {
          if (isArray && oldArray) {
            updateData((data2) => {
              ArrayHelper.removeByPredicate(
                data2 as Array<IBaseEntityDto>,
                (x) => !isEmpty(x.id) && x.id === entityId,
              );
            });
          } else if (isPaginated && oldPaginated) {
            updateData((data2) => {
              const oldPaginated2 = data2 as PaginationDtoOfTItemTyped<IBaseEntityDto>;
              ArrayHelper.removeByPredicate(
                oldPaginated2.items,
                (x) => !isEmpty(x.id) && x.id === entityId,
              );
            });
          } else if (isObject && oldObj) {
            replaceData(null);
          }
        } else {
          refetch();
        }
      }
    },
    [],
  );

  const reset = useCallback(() => {
    requestCountRef.current = 0;
    firstRequestStartedAtRef.current = null;
    lastRequestStartedAtRef.current = null;
    responseRef.current = null;
    dataRef.current = null;
    errorRef.current = null;
    isLoadingRef.current = false;
    isStartedRef.current = false;
    isEndedRef.current = false;
    isFirstEndedRef.current = false;
    isAnyRequestStartedRef.current = false;
    cancelTokenSourceRef.current = null;
    currentRequestEndPromiseRef.current = null;
  }, []);

  // refetch data for normal deps
  useEffect(() => {
    (async () => {
      if (optionsMemorized?.skip === true) {
        return;
      }

      if (isStartedRef.current === true) {
        if (!(await tryCancel("Cancel due to normal deps change."))) {
          return;
        }
      }

      await executeRequest(requestParamsMemorizedRef.current);
    })();
  }, [fetchDepsMemorized, optionsMemorized?.skip]);

  // refetch data for debounced deps
  useEffect(() => {
    (async () => {
      const isSkip = isNil(optionsMemorized?.debouncedDeps?.skip)
        ? optionsMemorized?.skip === true
        : optionsMemorized?.debouncedDeps?.skip === true;
      if (isSkip || isNil(debouncedDepsMemorized)) {
        return;
      }

      if (isStartedRef.current === true) {
        if (!(await tryCancel("Cancel due to debounced deps change."))) {
          return;
        }
      }

      await executeRequestForDebouncedDeps(requestParamsMemorizedRef.current);
    })();
  }, [debouncedDepsMemorized]);

  // refetch data for throttled deps
  useEffect(() => {
    (async () => {
      const isSkip = isNil(optionsMemorized?.throttledDeps?.skip)
        ? optionsMemorized?.skip === true
        : optionsMemorized?.throttledDeps?.skip === true;
      if (isSkip || isNil(throttledDepsMemorized)) {
        return;
      }

      if (isStartedRef.current === true) {
        if (!(await tryCancel("Cancel due to throttled deps change."))) {
          return;
        }
      }

      await executeRequestForThrottledDeps(requestParamsMemorizedRef.current);
    })();
  }, [throttledDepsMemorized]);

  // #region Handle short polling

  useEffect(() => {
    shortPollingRef.current?.setRequestFunc(async () => {
      await executeRequest(requestParamsMemorizedRef.current, {
        isPollRequest: true,
      });
    });
  }, [executeRequest]);

  // #endregion

  // #region CommonRequestParams integration

  useEffect(() => {
    const sub = options?.commonRequestParams?.refetch?.eventEmitter?.on2(
      "triggerRefetch",
      async () => {
        try {
          options?.commonRequestParams?.refetch?.eventEmitter?.emit("refetchStarted", undefined);
          await refetch();
        } finally {
          options?.commonRequestParams?.refetch?.eventEmitter?.emit("refetchEnded", undefined);
        }
      },
    );
    return () => {
      sub?.off();
    };
  }, [options?.commonRequestParams]);

  // #endregion

  //#region Result

  // always return the same instance of the result object (same reference),
  // to allow callers to capture the result once and access the same instance regardless of re-renders.

  const computeResult = (): UseApiRequestResult<
    TApiRequestFunc,
    TRequestParams,
    TRequestOptions,
    TResponse,
    TData
  > => ({
    isLoading: isLoadingRef.current,
    isStarted: isStartedRef.current,
    isEnded: isEndedRef.current,
    isFirstEnded: isFirstEndedRef.current,
    requestParams: requestParamsMemorizedRef.current,
    response: responseRef.current,
    responseDetails: responseDetailsComputed,
    data: dataRef.current,
    error: errorRef.current,
    refetch,
    refetchWithNewParams,
    replaceData,
    updateData,
    handleEntityChanged,
    reset,
  });

  const initialResult = useMemo(() => computeResult(), []);
  const result = useRef(initialResult);
  Object.assign(result.current, computeResult());

  //#endregion

  return result.current;
}
