import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { getIn } from "formik";
import { isNil } from "lodash-es";

import FoldableBlock from "@/common/components/Display/FoldableBlock";
import ApiEnumAutocomplete from "@/common/components/Enum/ApiEnumAutocomplete";
import NumberInput from "@/common/components/Form/Input/NumberInput";
import { CustomFormikSubProps, FormikHelper } from "@/common/helpers/formik";
import { ValidationHelper } from "@/common/validation";
import { VehicleSpecDto, VehicleSpecInputDto } from "@/core/api/generated";

import VehicleGenerationAutocomplete from "../../ReferenceData/VehicleGeneration/VehicleGenerationAutocomplete";
import VehicleMakeAutocomplete from "../../ReferenceData/VehicleMake/VehicleMakeAutocomplete";
import VehicleModelAutocomplete from "../../ReferenceData/VehicleModel/VehicleModelAutocomplete";
import VehicleModificationAutocomplete from "../../ReferenceData/VehicleModification/VehicleModificationAutocomplete";
import VehiclePurchasePriceInput from "./VehiclePurchasePriceInput";

export function vehicleSpecToInputDto(
  spec: VehicleSpecDto | VehicleSpecInputDto | null | undefined,
): VehicleSpecInputDto | undefined {
  if (!spec) {
    return undefined;
  }

  const dto = spec as VehicleSpecDto;
  const inputDto = spec as VehicleSpecInputDto;

  return {
    ...spec,
    makeId: inputDto?.makeId || dto?.make?.id,
    modelId: inputDto?.modelId || dto?.model?.id,
    generationId: inputDto?.generationId || dto?.generation?.id,
    modificationId: inputDto?.modificationId || dto?.modification?.id,
    purchasePrice: {
      price: {
        ...dto.purchasePrice,
        price: dto.purchasingCost ?? dto.purchasePrice?.price ?? 0,
      },
      settings: {
        isForAllSimilarVehicles: false,
        isForAllWithSameMake: false,
        isForAllWithSameModel: false,
        isForAllWithSameGeneration: false,
        isForAllWithSameModification: false,
      },
    },
  };
}

export function vehicleSpecInputDtoFormatBeforePost(
  spec: VehicleSpecInputDto | null | undefined,
): VehicleSpecInputDto | undefined {
  if (!spec) {
    return undefined;
  }

  return {
    ...spec,
    purchasePrice:
      spec.purchasePrice &&
      spec.purchasePrice.price &&
      spec.purchasePrice.price.currency &&
      !isNil(spec.purchasePrice.price.price)
        ? spec.purchasePrice
        : undefined,
  };
}

interface Props {
  formikProps: CustomFormikSubProps<VehicleSpecInputDto | null | undefined>;
  sx?: SxProps<Theme>;
}

export default function VehicleSpecInput({ formikProps, sx }: Props) {
  const { values, errors, setFieldValue } = formikProps;

  return (
    <Stack spacing={0} sx={sx}>
      {/* Required/Primary fields */}
      <Box>
        <FormControl margin='dense' fullWidth>
          <ApiEnumAutocomplete
            type='VehicleType'
            value={values?.type}
            required
            allowNone={false}
            label='Type'
            textFieldProps={{
              error: Boolean(getIn(errors, "type")),
              helperText: getIn(errors, "type"),
              margin: "none",
            }}
            onChange={(newValue) => {
              setFieldValue("type", newValue);
              setFieldValue("makeId", undefined);
              setFieldValue("modelId", undefined);
              setFieldValue("generationId", undefined);
              setFieldValue("modification", undefined);
            }}
          />
        </FormControl>

        {/* Make, Model */}
        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth error={Boolean(errors?.makeId)}>
              <VehicleMakeAutocomplete
                entityId={values?.makeId}
                entity={undefined}
                searchFilters={{
                  vehicleType: values?.type,
                }}
                onChange={(newValue) => {
                  setFieldValue("makeId", newValue?.id);
                  setFieldValue("modelId", undefined);
                  setFieldValue("generationId", undefined);
                  setFieldValue("modification", undefined);
                }}
                disabled={!values?.type}
                required={true}
                isPreload
                textFieldProps={{
                  error: Boolean(errors?.makeId),
                  helperText: ValidationHelper.getErrorsAsString(errors?.makeId),
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth error={Boolean(errors?.modelId)}>
              <VehicleModelAutocomplete
                entityId={values?.modelId}
                searchFilters={{
                  vehicleType: values?.type,
                  makeId: values?.makeId || undefined,
                }}
                onChange={(newValue) => {
                  setFieldValue("modelId", newValue?.id);
                  setFieldValue("generationId", undefined);
                  setFieldValue("modification", undefined);
                  setFieldValue("size", values?.size ?? newValue?.vehicleSize);
                }}
                disabled={!values?.type || !values?.makeId}
                required={true}
                textFieldProps={{
                  error: Boolean(errors?.modelId),
                  helperText: ValidationHelper.getErrorsAsString(errors?.modelId),
                }}
              />
            </FormControl>
          </Box>
        </Stack>

        {/* Generation, Modification */}
        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth error={Boolean(errors?.generationId)}>
              <VehicleGenerationAutocomplete
                searchFilters={{
                  vehicleType: values?.type,
                  makeId: values?.makeId || undefined,
                  modelId: values?.modelId || undefined,
                }}
                entityId={values?.generationId}
                isPreload={false}
                onChange={(newValue) => setFieldValue("generationId", newValue?.id)}
                disabled={!values?.type}
                textFieldProps={{
                  error: Boolean(errors?.generationId),
                  helperText: ValidationHelper.getErrorsAsString(errors?.generationId),
                }}
              />
            </FormControl>
          </Box>

          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth error={Boolean(errors?.modificationId)}>
              <VehicleModificationAutocomplete
                entityId={values?.modificationId}
                isPreload={false}
                searchFilters={{
                  vehicleType: values?.type,
                  makeId: values?.makeId,
                  modelId: values?.modelId,
                  generationId: values?.generationId,
                }}
                disabled={!values?.type}
                onChange={(newValue) => setFieldValue("modificationId", newValue?.id)}
                textFieldProps={{
                  error: Boolean(errors?.modificationId),
                  helperText: ValidationHelper.getErrorsAsString(errors?.modificationId),
                }}
              />
            </FormControl>
          </Box>
        </Stack>

        {/* Size, BodyType */}
        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth>
              <ApiEnumAutocomplete
                type='VehicleSize'
                value={values?.size}
                allowNone={false}
                label='Size'
                textFieldProps={{
                  error: Boolean(getIn(errors, "size")),
                  helperText: getIn(errors, "size"),
                  margin: "none",
                }}
                onChange={(newValue) => setFieldValue("size", newValue)}
              />
            </FormControl>
          </Box>

          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth>
              <ApiEnumAutocomplete
                type='VehicleBodyType'
                value={values?.bodyType}
                allowNone={false}
                label='Body type'
                textFieldProps={{
                  error: Boolean(getIn(errors, "bodyType")),
                  helperText: getIn(errors, "bodyType"),
                  margin: "none",
                }}
                onChange={(newValue) => setFieldValue("bodyType", newValue)}
              />
            </FormControl>
          </Box>
        </Stack>

        {/*  FuelType */}
        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth>
              <ApiEnumAutocomplete
                type='VehicleFuelType'
                value={values?.fuelType}
                allowNone={false}
                label='Fuel type'
                textFieldProps={{
                  error: Boolean(getIn(errors, "fuelType")),
                  helperText: getIn(errors, "fuelType"),
                  margin: "none",
                }}
                onChange={(newValue) => setFieldValue("fuelType", newValue)}
              />
            </FormControl>
          </Box>
        </Stack>

        {/* GearboxType, DrivetrainType */}
        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth>
              <ApiEnumAutocomplete
                type='VehicleGearboxType'
                value={values?.gearboxType}
                allowNone={false}
                label='Gearbox type'
                textFieldProps={{
                  error: Boolean(getIn(errors, "gearboxType")),
                  helperText: getIn(errors, "gearboxType"),
                  margin: "none",
                }}
                onChange={(newValue) => setFieldValue("gearboxType", newValue)}
              />
            </FormControl>
          </Box>

          <Box sx={{ flex: 1 }}>
            <FormControl margin='dense' fullWidth>
              <ApiEnumAutocomplete
                type='VehicleDrivetrainType'
                value={values?.drivetrainType}
                allowNone={false}
                label='Drivetrain type'
                textFieldProps={{
                  error: Boolean(getIn(errors, "drivetrainType")),
                  helperText: getIn(errors, "drivetrainType"),
                  margin: "none",
                }}
                onChange={(newValue) => setFieldValue("drivetrainType", newValue)}
              />
            </FormControl>
          </Box>
        </Stack>
      </Box>

      {/* Optional/Secondary fields */}
      <FoldableBlock
        defaultIsFolded
        trigger={{
          label: <Typography variant='subtitle2'>More vehicle details</Typography>,
          size: "medium",
        }}
        sx={{ my: 1 }}
      >
        <Stack>
          <FormControl margin='dense' fullWidth>
            <VehiclePurchasePriceInput
              formikProps={FormikHelper.getSubProps(
                formikProps,
                "purchasePrice",
                (v) => v.purchasePrice,
              )}
            />
          </FormControl>
        </Stack>

        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <FormControl margin='dense' fullWidth>
            <TextField
              fullWidth
              error={Boolean(getIn(errors, "manufactureYear"))}
              helperText={getIn(errors, "manufactureYear")}
              label='Manufacture year'
              margin='dense'
              onChange={(e) => setFieldValue("manufactureYear", e.target.value)}
              inputMode='numeric'
              value={values?.manufactureYear || ""}
              variant='outlined'
            />
          </FormControl>
        </Stack>

        <Stack
          direction={{
            md: "row",
            xxs: "column",
          }}
          spacing={{
            md: 1,
            xxs: 0,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              error={Boolean(getIn(errors, "numberOfDoors"))}
              helperText={getIn(errors, "numberOfDoors")}
              label='Number of doors'
              margin='dense'
              onChange={(e) => setFieldValue("numberOfDoors", e.target.value)}
              inputMode='numeric'
              value={values?.numberOfDoors || ""}
              variant='outlined'
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <TextField
              fullWidth
              error={Boolean(getIn(errors, "numberOfSeats"))}
              helperText={getIn(errors, "numberOfSeats")}
              label='Number of seats'
              margin='dense'
              onChange={(e) => setFieldValue("numberOfSeats", e.target.value)}
              inputMode='numeric'
              value={values?.numberOfSeats || ""}
              variant='outlined'
            />
          </Box>
        </Stack>

        <NumberInput
          fullWidth
          error={Boolean(getIn(errors, "bootCapacityL"))}
          helperText={getIn(errors, "bootCapacityL")}
          label='Boot capacity L'
          margin='dense'
          onChange={(e) => setFieldValue("bootCapacityL", e.target.value)}
          inputMode='numeric'
          value={values?.bootCapacityL || ""}
          variant='outlined'
        />

        <TextField
          fullWidth
          error={Boolean(getIn(errors, "cO2EmissionsGKm"))}
          helperText={getIn(errors, "cO2EmissionsGKm")}
          label='CO2 emissions g/Km'
          margin='dense'
          onChange={(e) => setFieldValue("cO2EmissionsGKm", e.target.value)}
          inputMode='decimal'
          value={values?.cO2EmissionsGKm || ""}
          variant='outlined'
        />

        <TextField
          fullWidth
          error={Boolean(getIn(errors, "horsepower"))}
          helperText={getIn(errors, "horsepower")}
          label='Horsepower'
          margin='dense'
          onChange={(e) => setFieldValue("horsepower", e.target.value)}
          inputMode='numeric'
          value={values?.horsepower || ""}
          variant='outlined'
        />

        <TextField
          fullWidth
          error={Boolean(getIn(errors, "batteryCapacityKWh"))}
          helperText={getIn(errors, "batteryCapacityKWh")}
          label='Battery capacity kW/h'
          margin='dense'
          onChange={(e) => setFieldValue("batteryCapacityKWh", e.target.value)}
          inputMode='decimal'
          value={values?.batteryCapacityKWh || ""}
          variant='outlined'
        />

        <TextField
          fullWidth
          error={Boolean(getIn(errors, "electricRangeKm"))}
          helperText={getIn(errors, "electricRangeKm")}
          label='Electric range Km'
          margin='dense'
          onChange={(e) => setFieldValue("electricRangeKm", e.target.value)}
          inputMode='decimal'
          value={values?.electricRangeKm || ""}
          variant='outlined'
        />

        <TextField
          fullWidth
          error={Boolean(getIn(errors, "fuelConsumption.lPer100Km"))}
          helperText={getIn(errors, "fuelConsumption.lPer100Km")}
          label='Fuel consumption L/100Km'
          margin='dense'
          onChange={(e) => setFieldValue("fuelConsumption.lPer100Km", e.target.value)}
          inputMode='decimal'
          value={values?.fuelConsumption?.lPer100Km || ""}
          variant='outlined'
        />

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(1, 1fr)`,
            gridTemplateRows: "auto",
            rowGap: 0,
            columnGap: 1,
          }}
        >
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.hasAirConditioner || false}
                  onChange={(e) => setFieldValue("hasAirConditioner", e.target.checked)}
                />
              }
              label='Has Air conditioner (AC)'
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.hasTowHook || false}
                  onChange={(e) => setFieldValue("hasTowHook", e.target.checked)}
                />
              }
              label='Has tow hook'
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.hasTowBar || false}
                  onChange={(e) => setFieldValue("hasTowBar", e.target.checked)}
                />
              }
              label='Has tow bar'
            />
          </FormControl>

          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values?.hasTowRope || false}
                  onChange={(e) => setFieldValue("hasTowRope", e.target.checked)}
                />
              }
              label='Has tow rope'
            />
          </FormControl>
        </Box>
      </FoldableBlock>
    </Stack>
  );
}
