import { ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { AssignmentDto, AssignmentGetPaginatedDto } from "@/core/api/generated";

import ContractInline from "../Contract/ContractInline";
import { getVehicleOptionTitle } from "../Vehicle/VehicleAutocomplete";
import VehicleInline from "../Vehicle/VehicleInline";

export type AssignmentAutocompleteOption = BaseAutocompleteOption<AssignmentDto>;

export const getAssignmentOptionTitle = (data: AssignmentDto): string =>
  StringHelper.joinIntoString(
    [
      data.name,
      data.vehicle ? getVehicleOptionTitle(data.vehicle) : data.localNumber,
      data?.stageHistory &&
        enumService.getEnumValueName("AssignmentStage", data?.stageHistory?.stage),
    ],
    " | ",
    {
      skipEmpty: true,
    },
  );

export const assignmentToOption = (data: AssignmentDto): AssignmentAutocompleteOption => ({
  id: data.id!,
  title: getAssignmentOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface AssignmentAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<AssignmentDto> {
  searchFilters?: Partial<Omit<AssignmentGetPaginatedDto, "search" | "includeIds">>;
}

export default function AssignmentAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: AssignmentAutocompleteProps) {
  return (
    <BaseEntitySearchAutocomplete
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={assignmentToOption}
      isPreload={isPreload}
      request={{
        requestFunc: apiClient.assignmentsApi.apiV1AssignmentsGetPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          assignmentGetPaginatedDto: {
            ...searchFilters,
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          assignmentGetPaginatedDto: {
            ...params.assignmentGetPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters],
      }}
      label='Assignment'
      placeholder='Search...'
      renderOption={(props, option: AssignmentAutocompleteOption) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='assignment' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    {option.title}
                  </Typography>
                  {option.data && (
                    <Typography component='div' variant='body2'>
                      {option?.data?.vehicle && (
                        <VehicleInline entity={option.data.vehicle} withIcon={false} />
                      )}
                      {option?.data?.contract?.contract && (
                        <ContractInline entity={option.data.contract.contract} withIcon={false} />
                      )}
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        );
      }}
      {...otherProps}
    />
  );
}
