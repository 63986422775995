import { Button, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { omit } from "lodash-es";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataExportModal from "@/common/components/DataExport/DataExportModal";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import EntityTagsInfoDisplay from "@/common/components/EntityInfo/EntityTagsInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppPopover from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import TableCellContentOfAttachments from "@/common/components/Table/TableCell/TableCellContentOfAttachments";
import TableCellContentOfCount from "@/common/components/Table/TableCell/TableCellContentOfCount";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { EntityHelper } from "@/common/helpers/entity";
import { TagStaticFilterInputDto, TagsHelper } from "@/common/helpers/entity/tags";
import { getTypedPath } from "@/common/helpers/typedPath";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { PropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageCostEvaluationDto,
  DamageCostEvaluationGetPaginatedDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  ExportEntityType,
  FilterDefinitionDto,
  GeneralAttachedTagsInputDto,
  GeneralAttachmentDto,
  TagEntityType,
} from "@/core/api/generated";

import AssignmentInline from "../../Assignment/AssignmentInline";
import ContractInline from "../../Contract/ContractInline";
import AffiliationInfoDisplay from "../../EntityAffiliation/AffiliationInfoDisplay";
import GeneralInspectorDisplay from "../../General/Display/GeneralInspectorDisplay";
import GeneralDiscountDisplay from "../../General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralAttachedTagsOfEntitiesEditModal from "../../General/GeneralTag/GeneralAttachedTagsOfEntitiesEditModal";
import GeneralTaxDisplay from "../../General/GeneralTax/GeneralTaxDisplay";
import TenantStructureMembershipOfEntityEditModal from "../../Membership/TenantStructureMembershipOfEntityEditModal";
import VehicleInline from "../../Vehicle/VehicleInline";
import DamageCostEvaluationMenu from "../DamageCostEvaluationMenu";
import DamageCostEvaluationsDeleteModal from "../DamageCostEvaluationsDeleteModal";

enum BulkActionFlags {
  UpdateTenantStructureMembership = "UpdateTenantStructureMembership",
  UpdateTags = "UpdateTags",
  Export = "Export",
  Delete = "Delete",
}
const defaultDisplayProps = {
  header: true,
  filters: true,
  create: true,
  edit: true,
  delete: true,
  viewVariant: ViewLayoutVariant.Page,
};

const defaultPropagatedDeps = {
  refetch: {},
};
export type DamageCostEvaluationPaginatedListPropagatedDepsMap = typeof defaultPropagatedDeps;
type DamageCostEvaluationGetPaginatedInputDto = Omit<
  DamageCostEvaluationGetPaginatedDto,
  "tags"
> & {
  tags?: TagStaticFilterInputDto;
};

export interface DamageCostEvaluationPaginatedListOwnProps {
  displayProps?: Partial<typeof defaultDisplayProps>;
  initialValues?: {
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
  };
  defaultValues?: {
    limit?: number;
    vehicleId?: string | null;
    contractId?: string | null;
    customerId?: string | null;
    anyVehicleDamageIds?: string[] | null;
  };
  propagatedDeps?: PropagatedDeps<DamageCostEvaluationPaginatedListPropagatedDepsMap>;
}

export type DamageCostEvaluationPaginatedListProps = DamageCostEvaluationPaginatedListOwnProps;

export default function DamageCostEvaluationPaginatedList({
  displayProps,
  initialValues,
  defaultValues,
  propagatedDeps,
}: DamageCostEvaluationPaginatedListProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<DamageCostEvaluationGetPaginatedInputDto>({
    statePersistence: {
      persistenceKey: EntityType.DamageCostEvaluation,
    },
    initialValues: {
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        ...defaultValues,
      },
    },
    initParams: (filterDefinition) => {
      const tagsFilter = filterDefinition.filterDefinition?.items.find(
        (x) => x.field === getTypedPath<DamageCostEvaluationDto>().tags.$path,
      );

      return {
        tags: {
          operator: tagsFilter?.operator,
          value: tagsFilter?.value as GeneralAttachedTagsInputDto,
        } as TagStaticFilterInputDto,
      };
    },
  });

  const [isDataExportModalOpened, setIsDataExportModalOpened] = useState(false);

  const damageCostEvaluationsRequest = useApiRequest(
    apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageCostEvaluationGetPaginatedDto: {
        ...omit(commonRequestParams.params, "tags"),
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        tags:
          commonRequestParams.filterDefinition?.items
            .filter((x) => x.field === getTypedPath<DamageCostEvaluationDto>().tags.$path)
            .map((x) => TagsHelper.mapFromFilter(x))[0] || undefined,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [
        ...commonRequestParams.deps,
        commonRequestParams.filterDefinition,
        propagatedDeps?.depsMap["refetch"],
      ],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedDamageCostEvaluations = useMemo(
    () => damageCostEvaluationsRequest?.data,
    [damageCostEvaluationsRequest.data],
  );

  const damageCostEvaluationIds = useMemo(
    () => paginatedDamageCostEvaluations?.items?.map((item) => item.id!) || [],
    [paginatedDamageCostEvaluations?.items],
  );

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.DamageCostEvaluation),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      damageCostEvaluationsRequest.handleEntityChanged(data);
    },
  });

  // menu, dialogs
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  return (
    <ViewLayoutV2
      displayProps={displayProps}
      header={
        displayProps?.header && (
          <SimpleViewPageHeader
            title='Damage cost evaluations'
            primaryActions={
              displayProps?.create && (
                <AuthorizedElement permissionsAny={[AppPermission.DamageCostEvaluationManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.DAMAGE_COST_EVALUATION_CREATE({
                      vehicleId: commonRequestParams.params?.vehicleId,
                      contractId: commonRequestParams.params?.contractId,
                    })}
                  >
                    Create new damage cost evaluation
                  </Button>
                </AuthorizedElement>
              )
            }
            secondaryActions={
              <>
                <IconButton
                  onClick={handleMoreClick}
                  sx={{
                    ml: "auto",
                    width: "fit-content",
                    alignSelf: { xxs: "flex-end", md: "flex-start" },
                  }}
                >
                  <AppIcon of='moreVert' />
                </IconButton>

                <Menu anchorEl={menuAnchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                  {/* <MenuItem
                      component={RouterLink}
                      to={ROUTE_PATH.DOCUMENTS_DAMAGE_COST_EVALUATIONS()}
                    >
                      <AppIcon of='documents' fontSize='small' sx={{ mr: 1 }} />
                      <Typography component='span'>View documents</Typography>
                    </MenuItem> */}
                  <MenuItem
                    component={RouterLink}
                    to={ROUTE_PATH.DAMAGE_COST_EVALUATION_AGGREGATES}
                  >
                    <AppIcon of='damageCostEvaluations' fontSize='small' sx={{ mr: 1 }} />
                    <Typography component='span'>View aggregated</Typography>
                  </MenuItem>
                  <AuthorizedElement permissions={[AppPermission.ExportManage]}>
                    <MenuItem onClick={() => setIsDataExportModalOpened(true)}>
                      <AppIcon of='export' fontSize='small' sx={{ mr: 1 }} />
                      <Typography>Export damage cost evaluations</Typography>
                    </MenuItem>
                  </AuthorizedElement>
                </Menu>
              </>
            }
          />
        )
      }
    >
      <DataTabular
        columns={[
          {
            field: getTypedPath<DamageCostEvaluationDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <Stack direction='row' spacing={0.5}>
                <span>{item.localNumber}</span>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().stageHistory.stage.$path,
            title: "Stage",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <InlineApiEnumValue
                type='DamageCostEvaluationStage'
                value={item.stageHistory!.stage}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.DamageCostEvaluationStage,
                },
              },
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => <VehicleInline entity={item.vehicle} />,
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().contract.id.$path,
            title: "Contract",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => (item.contract ? <ContractInline entity={item.contract} /> : "-"),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().assignment.id.$path,
            title: "Assignment",
            width: 300,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.assignment ? <AssignmentInline entity={item.assignment} /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfAssignment(),
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().inspectedAt.$path,
            title: "Inspected at",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.inspectedAt ? <Datetime datetime={item.inspectedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().inspector.userId.$path,
            title: "Inspector",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.inspector && (
                <GeneralInspectorDisplay
                  inspector={item.inspector}
                  inlineUserProps={{
                    withLink: false,
                    size: "full",
                  }}
                />
              ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().draftAt.$path,
            title: "Draft at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.draftAt ? <Datetime datetime={item.draftAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().inProgressAt.$path,
            title: "In progress at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.inProgressAt ? (
                <Datetime datetime={item.inProgressAt} withDurationFromNow />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().completedAt.$path,
            title: "Completed at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.completedAt ? <Datetime datetime={item.completedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().items.$path,
            title: "Items",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfCount count={item.items?.length} />,
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().attachments.$path,
            title: "Attachments",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <TableCellContentOfAttachments
                attachments={[
                  ...(item.attachments || []),
                  ...(item.items?.reduce(
                    (acc, itm) => acc.concat(itm.attachments || []),
                    [] as GeneralAttachmentDto[],
                  ) || []),
                ]}
              />
            ),
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().subTotal.$path,
            title: "Sub total",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => <CurrencyValue value={item.subTotal} currency={item.currency} />,
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().discount.$path,
            title: "Discount",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => (
              <GeneralDiscountDisplay discount={item.discount} currency={item.currency} />
            ),
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().subTotalIncDiscount.$path,
            title: "Sub total (inc. discount)",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <CurrencyValue value={item.subTotalIncDiscount} currency={item.currency} />
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().tax.$path,
            title: "Tax",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <GeneralTaxDisplay tax={item.tax} currency={item.currency} />,
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().total.$path,
            title: "Total",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => (
              <AppPopover
                hoverBehavior={{}}
                trigger={
                  <AppTypography decoration={{ variant: "helpText" }}>
                    <CurrencyValue value={item.total} currency={item.currency} />
                  </AppTypography>
                }
              >
                <AppPopoverContent>
                  <GeneralPriceSummaryDisplay
                    direction='column'
                    summary={{
                      currency: item.currency,
                      subTotal: item.subTotal,
                      discount: item.discount || undefined,
                      tax: item.tax || undefined,
                      total: item.total || 0,
                    }}
                  />
                </AppPopoverContent>
              </AppPopover>
            ),
            filters: {
              fieldType: FilterFieldType.Number,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "affiliation",
            title: "Affiliation",
            description: "Affiliation to departments & locations",
            width: 120,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => (
              <AffiliationInfoDisplay
                variant='compact'
                tenantId={item.tenantId}
                departmentIds={item.departmentIds}
                locationIds={item.locationIds}
              />
            ),
          },
          {
            field: getTypedPath<DamageCostEvaluationDto>().tags.$path,
            title: "Tags",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            isColumnMenuDisabled: false,
            isToDisabled: true,
            renderCell: (item) => (
              <EntityTagsInfoDisplay
                entityType={TagEntityType.DamageCostEvaluation}
                entity={item}
                noDataPlaceholder='-'
                edit={{
                  onSaved: (newValue) =>
                    damageCostEvaluationsRequest.updateData((data) => {
                      data.items?.forEach((item2) => {
                        if (item2.id === item.id) {
                          item2.tags = newValue || undefined;
                        }
                      });
                    }),
                }}
              />
            ),
            filters: {
              fieldType: FilterFieldType.Arbitrary,
              isUseSingleFilter: true,
              operators: FilterCatalog.getOperatorsForTagsField(),
            },
          },
        ]}
        rows={paginatedDamageCostEvaluations?.items}
        isLoading={damageCostEvaluationsRequest.isLoading}
        getRowId={(item) => item.id!}
        rowTo={(item) => ROUTE_PATH.DAMAGE_COST_EVALUATION_VIEW(item.id)}
        renderRowAction={({ item }) => (
          <DamageCostEvaluationMenu
            entity={item}
            onDelete={() => damageCostEvaluationsRequest.refetch()}
            onUpdate={() => damageCostEvaluationsRequest.refetch()}
            displayProps={{
              actions: {
                downloadPdf: false,
                sendToCustomer: false,
                edit: displayProps?.edit || false,
                addToContract: displayProps?.edit || false,
                addToAssignment: true,
                delete: displayProps?.delete || false,
                createRepairOperation: false,
              },
            }}
          />
        )}
        statePersistence={commonRequestParams.dataTabularProps.statePersistence}
        pagination={commonRequestParams.dataTabularProps.pagination}
        sort={commonRequestParams.dataTabularProps.sort}
        quickFilter={commonRequestParams.dataTabularProps.quickFilter}
        filters={commonRequestParams.dataTabularProps.filters}
        refetch={commonRequestParams.dataTabularProps.refetch}
        bulkActions={{
          enabled: true,
          definition: BulkActionFlags,
          actionTriggers: ({ startAction }) => (
            <>
              <AppIconButton
                tooltipProps={{ title: "Update affiliation" }}
                onClick={() => startAction(BulkActionFlags.UpdateTenantStructureMembership)}
              >
                <AppIcon of='department' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Update tags" }}
                onClick={() => startAction(BulkActionFlags.UpdateTags)}
              >
                <AppIcon of='tag' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Delete" }}
                onClick={() => startAction(BulkActionFlags.Delete)}
              >
                <AppIcon of='delete' />
              </AppIconButton>
              <AppIconButton
                tooltipProps={{ title: "Export" }}
                onClick={() => startAction(BulkActionFlags.Export)}
              >
                <AppIcon of='export' />
              </AppIconButton>
            </>
          ),
          actionHandlers: ({ selectedIds, currentAction, cancelAction, completeAction }) => {
            const selectedEntities = EntityHelper.filterEntitiesByIds(
              paginatedDamageCostEvaluations?.items || [],
              selectedIds as string[],
            );
            return (
              <>
                <DamageCostEvaluationsDeleteModal
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.Delete}
                  onClose={() => cancelAction()}
                  onDelete={() => {
                    completeAction();
                    damageCostEvaluationsRequest.refetch();
                  }}
                />
                <TenantStructureMembershipOfEntityEditModal
                  open={currentAction === BulkActionFlags.UpdateTenantStructureMembership}
                  onClose={() => cancelAction()}
                  tenantStructureMembershipOfEntityEditProps={{
                    entityType: EntityType.DamageCostEvaluation,
                    entities: selectedEntities,
                    onSave: () => {
                      completeAction();
                      damageCostEvaluationsRequest.refetch();
                    },
                  }}
                />
                <GeneralAttachedTagsOfEntitiesEditModal
                  tagEntityType={TagEntityType.DamageCostEvaluation}
                  entities={selectedEntities}
                  open={currentAction === BulkActionFlags.UpdateTags}
                  onClose={() => cancelAction()}
                  onSaved={() => {
                    completeAction();
                    damageCostEvaluationsRequest.refetch();
                  }}
                />
                <DataExportModal
                  open={currentAction === BulkActionFlags.Export}
                  onClose={() => cancelAction()}
                  exportDataProps={{
                    entityType: ExportEntityType.DamageCostEvaluation,
                    ids: selectedIds as string[],
                    onExportDone: completeAction,
                    displayProps: {
                      mode: false,
                    },
                  }}
                />
              </>
            );
          },
        }}
      />

      {/* Export data modal */}
      <DataExportModal
        open={isDataExportModalOpened}
        onClose={() => setIsDataExportModalOpened(false)}
        exportDataProps={{
          entityType: ExportEntityType.DamageCostEvaluation,
          ids: damageCostEvaluationIds,
          onExportDone: () => {
            setIsDataExportModalOpened(false);
          },
        }}
      />
    </ViewLayoutV2>
  );
}
