import { Box, List, Popover, PopoverProps } from "@mui/material";
import { useMemo } from "react";

import { useAppSelector } from "@/common/hooks/redux";
import { ChatParticipantDto } from "@/core/api/generated";

import ChatParticipantListItem, { ParticipantListItemData } from "./ChatParticipantListItem";

interface Props extends PopoverProps {
  chatId?: string | null;
  participantIds?: string[];
  participants?: ChatParticipantDto[] | null;
  getParticipantData?: (participantId: string) => ParticipantListItemData;
}

function ChatParticipantListPopover({
  chatId,
  participantIds,
  participants,
  getParticipantData,
  ...popoverProps
}: Props) {
  const paginatedParticipants = useAppSelector(
    (x) => x.communication.chatParticipants.paginatedChatParticipantMap[chatId || ""],
  );
  const participantList = useMemo(() => {
    if (participants) {
      return participants;
    } else if (participantIds && paginatedParticipants) {
      return paginatedParticipants.items!.filter((x) => participantIds.includes(x.id!));
    } else {
      return [];
    }
  }, [participants, participantIds, paginatedParticipants]);

  return (
    <Popover
      sx={{
        pointerEvents: "none",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      disableRestoreFocus
      {...popoverProps}
    >
      <Box sx={{ minWidth: "200px", maxWidth: { sm: "95vw", md: "400px" } }}>
        <List dense>
          {participantList.map((participant, i) => (
            <ChatParticipantListItem
              key={i}
              participant={participant}
              participantData={
                (getParticipantData && getParticipantData(participant.id!)) || undefined
              }
            />
          ))}
        </List>
      </Box>
    </Popover>
  );
}

export default ChatParticipantListPopover;
