import { ReactNode, useRef } from "react";

import AppPopover, {
  AppPopoverHandle,
  AppPopoverProps,
} from "@/common/components/Popover/AppPopover";
import AppPopoverContent from "@/common/components/Popover/AppPopoverContent";
import { RepairEntityDtoType } from "@/common/helpers/entity/repair";

import { BaseRepairEntityCrossTenantSelectorInheritableProps } from "./BaseRepairEntityCrossTenantSelector";

interface BaseRepairEntityCrossTenantSelectorPopoverProps<TEntity extends RepairEntityDtoType>
  extends Omit<AppPopoverProps, "children"> {
  selectorProps: BaseRepairEntityCrossTenantSelectorInheritableProps<TEntity>;
  renderSelector: (params: {
    selectorProps: BaseRepairEntityCrossTenantSelectorInheritableProps<TEntity>;
  }) => ReactNode;
}

export type BaseRepairEntityCrossTenantSelectorPopoverInheritableProps<
  TEntity extends RepairEntityDtoType,
> = Omit<BaseRepairEntityCrossTenantSelectorPopoverProps<TEntity>, "renderSelector">;

export default function BaseRepairEntityCrossTenantSelectorPopover<
  TEntity extends RepairEntityDtoType,
>({
  selectorProps,
  renderSelector,
  ...popoverProps
}: BaseRepairEntityCrossTenantSelectorPopoverProps<TEntity>) {
  const appPopoverRef = useRef<AppPopoverHandle>(null);

  return (
    <AppPopover ref={appPopoverRef} {...popoverProps}>
      <AppPopoverContent sx={{ minWidth: 500 }}>
        {renderSelector({
          selectorProps: {
            ...selectorProps,
            onSave: (newValue) => {
              selectorProps.onSave(newValue);
              appPopoverRef.current?.close();
            },
            onCancel: () => {
              selectorProps.onCancel();
              appPopoverRef.current?.close();
            },
          },
        })}
      </AppPopoverContent>
    </AppPopover>
  );
}
