import { Box, SxProps, Theme } from "@mui/material";
import { isNil } from "lodash-es";
import { useMemo } from "react";

import { DatetimeHelper, DurationHumanizeOptions } from "@/common/helpers/datetime";
import { DurationRepresentation } from "@/common/ts/duration";

export interface DurationDisplayProps {
  /** ISO 8601 duration or .NET TimeSpan string. */
  value: string | null | undefined;
  /** Format of in and out value. */
  representation?: DurationRepresentation;
  humanizeOptions?: DurationHumanizeOptions;
  sx?: SxProps<Theme>;
}

export default function DurationDisplay({
  value,
  representation,
  humanizeOptions = { isSuffix: false },
  sx,
}: DurationDisplayProps) {
  const representationComputed = useMemo(
    () => representation || (value && DatetimeHelper.detectDurationRepresentation(value)),
    [value, representation],
  );
  const valueComputed = useMemo(
    () => DatetimeHelper.humanizeDuration(value, humanizeOptions),
    [value, representationComputed],
  );

  if (isNil(value)) {
    return null;
  }

  return (
    <Box component='span' sx={sx}>
      {valueComputed}
    </Box>
  );
}
