import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";

import { FileItem } from "@/common/fileItem";
import { FileHelper } from "@/common/helpers/file";
import { GeneralAttachmentDto } from "@/core/api/generated";

import AppIcon from "../Icons/AppIcon";
import AppPopover from "../Popover/AppPopover";
import AppPopoverContent from "../Popover/AppPopoverContent";
import FileListView from "./FileListView";

export interface Props {
  attachments: GeneralAttachmentDto[];
  isPopoverEnabled?: boolean;
}

export default function AttachmentsBriefInfo({ attachments, isPopoverEnabled = true }: Props) {
  const files = useMemo(() => FileItem.createManyFrom(attachments || []), [attachments]);
  const images = useMemo(
    () => files.filter((file) => FileHelper.getFileType(file.mimeType).isImage),
    [files],
  );
  const otherFiles = useMemo(
    () => files.filter((file) => !FileHelper.getFileType(file.mimeType).isImage),
    [files],
  );

  return (
    <AppPopover
      hoverBehavior={{ closeBehavior: "onTriggerOrContentMouseLeave" }}
      trigger={
        <Stack
          direction='row'
          spacing={0.5}
          sx={{
            backgroundColor: "background.default",
            borderRadius: "30px",
            borderColor: (th) => th.palette.divider,
            borderWidth: "1px",
            borderStyle: "solid",
            p: 1,
          }}
        >
          {images.length !== 0 && (
            <Stack direction='row' spacing={0.5} sx={{ alignItems: "center" }}>
              <AppIcon of='image' fontSize='medium' />
              <Typography variant='body2' color='text.secondary'>
                {images.length}
              </Typography>
            </Stack>
          )}
          {otherFiles.length !== 0 && (
            <Stack direction='row' spacing={0.5} sx={{ alignItems: "center" }}>
              <AppIcon of='file' fontSize='medium' />
              <Typography variant='body2' color='text.secondary'>
                {otherFiles.length}
              </Typography>
            </Stack>
          )}
          {images.length === 0 && otherFiles.length === 0 && (
            <Typography variant='body2' color='text.secondary'>
              -
            </Typography>
          )}
        </Stack>
      }
    >
      <AppPopoverContent sx={{ maxWidth: 400 }}>
        <FileListView files={files} maxVisibleFiles={10} itemWidth={80} itemHeight={80} />
      </AppPopoverContent>
    </AppPopover>
  );
}
