import { Chip, ChipProps } from "@mui/material";

import AppTooltip from "@/common/components/AppTooltip";
import { EnvHelper } from "@/common/helpers/env";
import { TenantMode } from "@/core/api/generated";

interface Props extends Partial<ChipProps> {
  mode: TenantMode | null | undefined;
}

export default function TenantModeChip({ mode, ...otherProps }: Props) {
  if (!mode || (EnvHelper.isProductionAny && mode === TenantMode.Live)) {
    return null;
  }
  return (
    <AppTooltip title={`Company mode`}>
      <Chip variant='outlined' size='small' color='default' label={mode} {...otherProps} />
    </AppTooltip>
  );
}
