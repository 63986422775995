import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import InlineUser from "@/App/MainAppView/components/User/InlineUser";
import DataTabular, { TabularProps } from "@/common/components/DataTabular/DataTabular";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FilterCatalog } from "@/common/filters/filterCatalog";
import { getTypedPath } from "@/common/helpers/typedPath";
import { ApiEnumName } from "@/common/services/enum";
import { FilterFieldType } from "@/common/ts/filters";
import { AppPermission, VehicleDamageDto } from "@/core/api/generated";

import AuthorizedElement from "../../Auth/AuthorizedElement";
import Datetime from "../../Datetime/Datetime";
import AppIcon from "../../Icons/AppIcon";
import MenuWithTrigger from "../../Menu/MenuWithTrigger";
import TableCellContentOfAttachments from "../../Table/TableCell/TableCellContentOfAttachments";
import ContractInline from "../Contract/ContractInline";
import DamageDetectionInline from "../DamageDetection/DamageDetectionInline";
import GeneralByWhoDisplay from "../General/Display/GeneralByWhoDisplay";
import GeneralInspectorDisplay from "../General/Display/GeneralInspectorDisplay";
import { RepairOperationAddVehicleDamagesModal } from "../RepairOperation/RepairOperationAddVehicleDamagesModal";
import RepairOperationInline from "../RepairOperation/RepairOperationInline";
import VehicleInline from "../Vehicle/VehicleInline";
import VehicleVisualModelInline from "../VehicleVisualModel/VehicleVisualModelInline";
import { VehicleDamageStatus } from "./VehicleDamageStatus";

const defaultDisplayProps = {
  vehicleColumn: true,
  contractColumn: true,
};

interface OwnProps {
  vehicleDamages: VehicleDamageDto[];
  displayProps?: Partial<typeof defaultDisplayProps>;
  dataTabularProps?: Partial<TabularProps<VehicleDamageDto>>;
}

type Props = OwnProps;

export default function VehicleDamageList({
  vehicleDamages,
  displayProps = defaultDisplayProps,
  dataTabularProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const [isAddToRepairOperationModalOpen, setIsAddToRepairOperationModalOpen] = useState(false);
  const [damageToAddToRepairOperation, setDamageToAddToRepairOperation] = useState<
    VehicleDamageDto | undefined
  >(undefined);

  return (
    <>
      <DataTabular<VehicleDamageDto>
        columns={[
          {
            field: getTypedPath<VehicleDamageDto>().id.$path,
            title: "ID",
            width: 250,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) => item.id,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().localNumber.$path,
            title: "Number",
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: false,
            renderCell: (item) => item.localNumber,
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: "Info badges",
            title: "Info badges",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => <EntityChipList entity={item} variant='compact' />,
          },
          {
            field: getTypedPath<VehicleDamageDto>().vehicle.id.$path,
            title: "Vehicle",
            width: 300,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            if: !!displayProps?.vehicleColumn,
            renderCell: (item) => (
              <Stack spacing={0.5}>
                <VehicleInline entity={item.vehicle} />
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehicle(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().contract.id.$path,
            title: "Contract",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            if: !!displayProps?.contractColumn,
            renderCell: (item) => (
              <Stack spacing={0.5}>
                {item.contract ? (
                  <Typography component='div' variant='body2'>
                    <ContractInline entity={item.contract} />
                  </Typography>
                ) : (
                  <>-</>
                )}
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfContract(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().partType.id.$path,
            title: "Part type",
            width: 150,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) => (
              <Stack>
                <Box>{item.partType?.name}</Box>
                <Typography component='div' variant='body2' color='text.secondary'>
                  <InlineApiEnumValue type='VehicleArea' value={item.area} /> /{" "}
                  <InlineApiEnumValue type='VehicleProjection' value={item.projection} />
                </Typography>
              </Stack>
            ),
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfVehiclePartType(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().damageType.id.$path,
            title: "Damage type",
            width: 150,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) => <>{item.damageType?.name}</>,
            filters: {
              fieldType: FilterFieldType.String,
              operators: FilterCatalog.getOperatorsForIdFieldOfDamageType(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().state.$path,
            title: "State",
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) => <VehicleDamageStatus vehicleDamage={item} sx={{ ml: 0.5 }} />,
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.VehicleDamageState,
                },
              },
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().inspectedAt.$path,
            title: "Inspected at",
            width: 150,
            isVisible: true,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            flex: 1,
            renderCell: (item) => (
              <>
                <Datetime datetime={item.inspectedAt} direction='column' />
              </>
            ),
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().inspector.$path,
            title: "Inspector",
            width: 200,
            isVisible: true,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) =>
              item.inspector && (
                <GeneralInspectorDisplay
                  inspector={item.inspector}
                  inlineUserProps={{
                    withLink: false,
                    size: "full",
                  }}
                />
              ),
          },
          {
            field: getTypedPath<VehicleDamageDto>().visualModel.id.$path,
            title: "Visual model",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) => <VehicleVisualModelInline entity={item.visualModel} withIcon />,
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().oldVisualModel.id.$path,
            title: "Old visual model",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.oldVisualModel ? (
                <VehicleVisualModelInline entity={item.oldVisualModel} withIcon />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Id,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().damageDetection.id.$path,
            title: "Damage detection",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.damageDetection ? <DamageDetectionInline entity={item.damageDetection} /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfDamageDetection(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().area.$path,
            title: "Area",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.area ? (
                <InlineApiEnumValue
                  type={ApiEnumName.VehicleArea}
                  value={item.area}
                  withHelperTooltip
                />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.VehicleArea,
                },
              },
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().projection.$path,
            title: "Projection",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.area ? (
                <InlineApiEnumValue
                  type={ApiEnumName.VehicleProjection}
                  value={item.projection}
                  withHelperTooltip
                />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.VehicleProjection,
                },
              },
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().appType.$path,
            title: "App type",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.appType ? (
                <InlineApiEnumValue
                  type={ApiEnumName.FrontEndAppType}
                  value={item.appType}
                  withHelperTooltip
                />
              ) : (
                "-"
              ),
            filters: {
              fieldType: FilterFieldType.Enum,
              isUseDefaultOperators: true,
              fieldTypeMeta: {
                enum: {
                  enumName: ApiEnumName.FrontEndAppType,
                },
              },
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().notes.$path,
            title: "Notes",
            width: 100,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) => item.notes || "-",
            filters: {
              fieldType: FilterFieldType.String,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().repairedAt.$path,
            title: "Repaired at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.repairedAt ? <Datetime datetime={item.repairedAt} direction='column' /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().repairedBy.$path,
            title: "Repaired by",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            renderCell: (item) => item.inspector && <GeneralByWhoDisplay who={item.repairedBy} />,
          },
          {
            field: getTypedPath<VehicleDamageDto>().repairOperation.id.$path,
            title: "Repair operation",
            width: 200,
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            isToDisabled: false,
            renderCell: (item) =>
              item.repairOperation ? <RepairOperationInline entity={item.repairOperation} /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfRepairOperation(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().createdAt.$path,
            title: "Created at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.createdAt ? <Datetime datetime={item.createdAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().updatedAt.$path,
            title: "Updated at",
            isVisible: false,
            isHideable: true,
            isSortable: true,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedAt ? <Datetime datetime={item.updatedAt} withDurationFromNow /> : "-",
            filters: {
              fieldType: FilterFieldType.Date,
              isUseDefaultOperators: true,
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().createdBy.$path,
            title: "Created by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.createdBy ? <InlineUser userId={item.createdBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().updatedBy.$path,
            title: "Updated by",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: true,
            renderCell: (item) =>
              item.updatedBy ? <InlineUser userId={item.updatedBy} withAvatar /> : "-",
            filters: {
              fieldType: FilterFieldType.Id,
              operators: FilterCatalog.getOperatorsForIdFieldOfUser(),
            },
          },
          {
            field: getTypedPath<VehicleDamageDto>().attachments.$path,
            title: "Attachments",
            isVisible: false,
            isHideable: true,
            isSortable: false,
            isFilterable: false,
            isToDisabled: true,
            renderCell: (item) => <TableCellContentOfAttachments attachments={item.attachments} />,
          },
        ]}
        rows={vehicleDamages}
        getRowId={(item) => item.id!}
        rowTo={(item) =>
          ROUTE_PATH.VEHICLE_DAMAGE_VIEW({
            vehicleId: item.vehicle?.id,
            vehicleDamageId: item.id,
          })
        }
        renderRowAction={(actionParams) => (
          <>
            <MenuWithTrigger
              withAuthCloseOnClick
              trigger={
                <IconButton>
                  <AppIcon of='moreVert' />
                </IconButton>
              }
            >
              <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
                <MenuItem
                  onClick={() => {
                    setIsAddToRepairOperationModalOpen(true);
                    setDamageToAddToRepairOperation(actionParams.item);
                  }}
                >
                  <ListItemIcon>
                    <AppIcon of='add' fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Add to repair operation</ListItemText>
                </MenuItem>
              </AuthorizedElement>
            </MenuWithTrigger>
          </>
        )}
        {...dataTabularProps}
      />

      {/* Add to repair operation */}
      {damageToAddToRepairOperation && (
        <RepairOperationAddVehicleDamagesModal
          open={isAddToRepairOperationModalOpen}
          onClose={() => setIsAddToRepairOperationModalOpen(false)}
          damages={[damageToAddToRepairOperation]}
        />
      )}
    </>
  );
}
