import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function SparklesIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...props}
    >
      <path
        d='M9.43968 2.94485C9.44749 2.95285 9.45533 2.96075 9.46322 2.96854C9.45339 2.97791 9.44362 2.98743 9.43391 2.99712C9.42607 2.98943 9.41821 2.98185 9.41029 2.97436C9.42015 2.96469 9.42995 2.95485 9.43968 2.94485ZM4.9297 8.90415C4.80059 9.02935 4.69269 9.164 4.60153 9.3054C4.50707 9.15043 4.39362 9.003 4.25564 8.8667C4.08528 8.69841 3.89723 8.5667 3.69921 8.46084C3.88614 8.36121 4.06459 8.23889 4.22764 8.0846C4.36124 7.95818 4.47276 7.82162 4.56683 7.67768C4.66231 7.83677 4.77744 7.9881 4.91819 8.12757C5.08245 8.29035 5.26308 8.41856 5.45333 8.52178C5.26761 8.6235 5.09084 8.74789 4.9297 8.90415ZM7.51747 15.7084C7.36112 15.6613 7.19896 15.6168 7.0309 15.5744C8.22646 15.2843 9.1819 14.8536 9.90909 14.1208C10.5455 13.4794 10.9454 12.6735 11.2324 11.7173C11.2887 11.5297 11.3414 11.334 11.391 11.1298C11.4404 11.3337 11.4928 11.5292 11.5489 11.7165C11.835 12.6724 12.2337 13.4786 12.8696 14.1203C13.5768 14.8339 14.5007 15.2611 15.6527 15.5509C14.5007 15.8407 13.5768 16.2679 12.8696 16.9815C12.2337 17.6232 11.8349 18.4294 11.5489 19.3853C11.4891 19.5852 11.4334 19.7943 11.3811 20.013C11.3283 19.7989 11.2723 19.5943 11.2124 19.3988C10.922 18.4514 10.5215 17.6566 9.88703 17.023C9.25209 16.3889 8.45925 15.9925 7.51747 15.7084Z'
        stroke='currentColor'
        fill='none'
        strokeWidth='2'
      />
    </SvgIcon>
  );
});
