import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import { VehicleDto } from "@/core/api/generated";

import ContractPaginatedList from "../../../Contract/ListView/ContractPaginatedList";

interface Props {
  vehicle: VehicleDto;
}

export default function ContractsTabContent({ vehicle }: Props) {
  return (
    <PageTabContent>
      <ContractPaginatedList
        defaultValues={{
          vehicleId: vehicle.id!,
        }}
        displayProps={{
          viewVariant: ViewLayoutVariant.Tab,
          header: false,
          counters: false,
        }}
      />
    </PageTabContent>
  );
}
