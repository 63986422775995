import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import AppIcon from "@/common/components/Icons/AppIcon";
import { RepairSparePartDto } from "@/core/api/generated";

import CreateUpdateRepairSparePart, {
  CreateUpdateRepairSparePartProps,
} from "./CreateUpdateRepairSparePart";
import CreateUpdateRepairSparePartModal from "./CreateUpdateRepairSparePartModal";
import RepairSparePartAutocomplete, {
  RepairSparePartAutocompleteProps,
} from "./RepairSparePartAutocomplete";

interface OwnProps {
  autocompleteProps: RepairSparePartAutocompleteProps;
  createUpdateRepairSparePartProps?: Pick<CreateUpdateRepairSparePartProps, "defaultValues">;
  createFormPlacement?: "modal" | "page";
  onCreateStart?: () => void;
  onCreate: (newValue: RepairSparePartDto) => void;
}

type Props = OwnProps;

export default function SelectOrCreateRepairSparePart({
  autocompleteProps,
  createUpdateRepairSparePartProps,
  createFormPlacement = "modal",
  onCreateStart,
  onCreate,
}: Props) {
  const [isCreate, setIsCreate] = useState(false);
  const [defaultValues, setDefaultValues] = useState<
    CreateUpdateRepairSparePartProps["defaultValues"] | undefined
  >(undefined);

  return (
    <Stack spacing={2}>
      {/* 1. Search repairSparePart */}
      {!isCreate && (
        <Box>
          <RepairSparePartAutocomplete
            {...autocompleteProps}
            withCreate
            onCreate={(newOption) => {
              setDefaultValues({
                ...createUpdateRepairSparePartProps?.defaultValues,
                name: newOption?.inputValue || undefined,
              });
              setIsCreate(true);
              onCreateStart && onCreateStart();
            }}
          />
        </Box>
      )}

      {isCreate && (
        <Box>
          {isCreate && (
            <>
              {createFormPlacement === "modal" && (
                <CreateUpdateRepairSparePartModal
                  open={isCreate}
                  onClose={() => setIsCreate(false)}
                  createUpdateProps={{
                    defaultValues: defaultValues,
                    onCreate: onCreate,
                    onSave: (newValue) => {
                      setIsCreate(false);
                    },
                  }}
                />
              )}

              {createFormPlacement === "page" && (
                <Stack spacing={1}>
                  <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                    <Button
                      variant='text'
                      color='text'
                      size='small'
                      startIcon={<AppIcon of='goBack' />}
                      onClick={() => setIsCreate(false)}
                    >
                      Back to search
                    </Button>

                    <Typography component='div' variant='subtitle1'>
                      Create new repair spare part
                    </Typography>
                  </Stack>

                  <CreateUpdateRepairSparePart
                    defaultValues={defaultValues}
                    onCreate={onCreate}
                    onSave={(newValue) => {
                      setIsCreate(false);
                    }}
                  />
                </Stack>
              )}
            </>
          )}
        </Box>
      )}
    </Stack>
  );
}
