import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ROUTE_PATH } from "@/common/constants/routing";
import { FileHelper } from "@/common/helpers/file";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, VisualInspectionDto } from "@/core/api/generated";

import AuthorizedElement from "../../Auth/AuthorizedElement";
import EntityMenu from "../../EntityMenu/EntityMenu";
import AppIcon from "../../Icons/AppIcon";
import LoadingMenuItem from "../../Menu/LoadingMenuItem";
import VisualInspectionAddToAssignmentModal from "./VisualInspectionAddToAssignmentModal";
import VisualInspectionAddToContractModal from "./VisualInspectionAddToContractModal";
import VisualInspectionDeleteModal from "./VisualInspectionDeleteModal";

enum ActionFlags {
  DownloadPdf = "DownloadPdf",
  Edit = "Edit",
  AddToContract = "AddToContract",
  AddToAssignment = "AddToAssignment",
  Delete = "Delete",
}
const defaultDisplayProps = {
  actions: {
    downloadPdf: true,
    edit: true,
    addToContract: true,
    addToAssignment: true,
    smartAutoInspection: true,
    delete: true,
  },
};

interface TriggersProps {
  entity?: VisualInspectionDto | null;
  definition: typeof ActionFlags;
  handleClose: () => void;
  startAction: (props: ActionFlags) => void;
}

interface Props {
  entity?: VisualInspectionDto | null;
  disabled?: boolean;
  onUpdate: ((newValue: VisualInspectionDto | null | undefined) => void) | undefined;
  onDelete?: (() => void) | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customActions?: (props: TriggersProps) => ReactNode;
}

export default function VisualInspectionMenu({
  entity,
  disabled,
  customActions,
  onUpdate,
  onDelete,
  displayProps = defaultDisplayProps,
}: Props) {
  displayProps = { ...defaultDisplayProps, ...displayProps };
  return (
    <EntityMenu
      definition={ActionFlags}
      entity={entity}
      disabled={disabled}
      actionTriggers={({ handleClose, startAction }) => (
        <>
          {customActions &&
            customActions({
              entity,
              definition: ActionFlags,
              handleClose,
              startAction,
            })}
          {displayProps?.actions?.downloadPdf && (
            <LoadingMenuItem
              key='downloadPdf'
              onClick={async () => {
                const downloadResult = await FileHelper.getDownloadFileApiResult(() =>
                  apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdDownloadPdfGet(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      visualInspectionId: entity?.id || "",
                    },
                    {
                      responseType: "blob",
                    },
                  ),
                );
                FileHelper.downloadBlobFromApiResult(downloadResult);
                handleClose();
              }}
            >
              <ListItemIcon>
                <AppIcon of='download' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Download PDF</ListItemText>
            </LoadingMenuItem>
          )}
          {displayProps?.actions?.edit && (
            <MenuItem component={RouterLink} to={ROUTE_PATH.VISUAL_INSPECTION_EDIT(entity?.id)}>
              <ListItemIcon>
                <AppIcon of='edit' fontSize='small' />
              </ListItemIcon>
              <ListItemText>Edit</ListItemText>
            </MenuItem>
          )}
          {displayProps?.actions?.addToContract && (
            <AuthorizedElement permissions={[AppPermission.VisualInspectionManage]}>
              <MenuItem
                disabled={!!entity?.contract}
                onClick={() => startAction(ActionFlags.AddToContract)}
              >
                <ListItemIcon>
                  <AppIcon of='add' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add to contract</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {/* Add ot assignment */}
          {displayProps?.actions?.addToAssignment && (
            <AuthorizedElement permissions={[AppPermission.VisualInspectionManage]}>
              <MenuItem
                disabled={!!entity?.assignment}
                onClick={() => startAction(ActionFlags.AddToAssignment)}
              >
                <ListItemIcon>
                  <AppIcon of='add' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Add to assignment</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}

          {/* Smart auto inspection */}
          {displayProps?.actions?.smartAutoInspection && (
            <AuthorizedElement permissions={[AppPermission.VisualInspectionManage]}>
              <LoadingMenuItem
                disabled={!entity?.items?.some((item) => item.attachments?.length !== 0)}
                onClick={async () => {
                  await apiClient.visualInspectionsApi.apiV1VisualInspectionsVisualInspectionIdAutoInspectPut(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      visualInspectionId: entity?.id || "",
                    },
                  );
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <AppIcon of='sparkles' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Smart auto inspection</ListItemText>
              </LoadingMenuItem>
            </AuthorizedElement>
          )}

          {displayProps?.actions?.delete && (
            <AuthorizedElement permissions={[AppPermission.VisualInspectionManage]}>
              <MenuItem onClick={() => startAction(ActionFlags.Delete)}>
                <ListItemIcon>
                  <AppIcon of='delete' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </AuthorizedElement>
          )}
        </>
      )}
      actionHandlers={({ currentAction, cancelAction, completeAction }) => (
        <>
          {/* Add to contract */}
          {entity && (
            <VisualInspectionAddToContractModal
              open={currentAction === ActionFlags.AddToContract}
              onClose={() => cancelAction()}
              visualInspection={entity}
              onSaved={() => {
                onUpdate && onUpdate(null);
                completeAction();
              }}
            />
          )}

          {/* Add to assignment */}
          {entity && (
            <VisualInspectionAddToAssignmentModal
              open={currentAction === ActionFlags.AddToAssignment}
              onClose={() => cancelAction()}
              visualInspection={entity}
              onSaved={() => {
                onUpdate && onUpdate(null);
                completeAction();
              }}
            />
          )}

          {/* Delete */}
          {entity && (
            <VisualInspectionDeleteModal
              entity={entity}
              open={currentAction === ActionFlags.Delete}
              onClose={() => cancelAction()}
              onDelete={() => {
                onDelete && onDelete();
                completeAction();
              }}
            />
          )}
        </>
      )}
    />
  );
}
