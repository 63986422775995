import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { chain } from "lodash-es";
import { useEffect, useMemo } from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import DropdownIconButton, {
  DropdownIconButtonProps,
} from "@/common/components/Button/DropdownIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import CountryFlagIcon from "@/common/components/Icons/CountryFlagIcon";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { GeneralCountryDto, GeneralCountryInputDto } from "@/core/api/generated";

interface Props {
  value: GeneralCountryDto | GeneralCountryInputDto | null | undefined;
  defaultCountryCode: string | null | undefined;
  required?: boolean;
  dropdownIconButtonProps?: Partial<DropdownIconButtonProps>;
  onChange?: (newValue: GeneralCountryDto | GeneralCountryInputDto | null | undefined) => void;
}

export default function CountryCodeSelectInputAdornment({
  value,
  defaultCountryCode,
  required,
  dropdownIconButtonProps,
  onChange,
}: Props) {
  defaultCountryCode = defaultCountryCode?.toUpperCase();

  const countriesRequest = useApiRequest(
    apiClient.generalReferenceDataApi.apiV1ReferenceDataGeneralCountriesGet,
    {},
    {},
  );

  const countries = useMemo(
    () =>
      countriesRequest?.data
        ? [...(!required ? [{}] : []), ...(countriesRequest?.data || [])]
        : undefined,
    [countriesRequest?.data, required],
  );
  const countriesByCodeMap = useMemo(
    () =>
      chain(countries || [])
        .keyBy((x) => x.alpha2Code!)
        .mapValues((x) => x)
        .value(),
    [countries],
  );
  const selectedCountry = useMemo(
    () => (value ? countriesByCodeMap[value.alpha2Code?.toUpperCase() || ""] : undefined),
    [value, countriesByCodeMap],
  );

  useEffect(() => {
    const defaultCountry = countriesByCodeMap[defaultCountryCode || ""];
    if (
      defaultCountryCode &&
      !value?.alpha2Code &&
      value?.alpha2Code?.toUpperCase() !== defaultCountryCode.toUpperCase() &&
      defaultCountry
    ) {
      onChange && onChange(defaultCountry);
    }
  }, [value?.alpha2Code, defaultCountryCode, countriesByCodeMap]);

  return (
    <Tooltip
      title={
        !selectedCountry
          ? "Select country"
          : `${selectedCountry.name} (${selectedCountry.alpha2Code})`
      }
    >
      <DropdownIconButton
        sx={{
          fontWeight: "inherit",
        }}
        loading={countriesRequest.isLoading}
        {...dropdownIconButtonProps}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        dropdownContent={
          <MenuList>
            {countries && (
              <FixedSizeList
                height={200}
                width={500}
                itemSize={28}
                itemCount={countries.length}
                overscanCount={10}
              >
                {(props2: ListChildComponentProps) => {
                  const item = countries[props2.index];

                  return !item.alpha2Code ? (
                    <MenuItem onClick={() => onChange && onChange(undefined)}>
                      <ListItemText>None</ListItemText>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      style={props2.style}
                      key={props2.index}
                      onClick={() => onChange && onChange(item)}
                    >
                      <ListItemIcon>
                        <CountryFlagIcon countryCode={item.alpha2Code} sx={{ width: 24 }} />
                      </ListItemIcon>
                      <ListItemText>
                        {item.name} ({item.alpha2Code})
                      </ListItemText>
                    </MenuItem>
                  );
                }}
              </FixedSizeList>
            )}
          </MenuList>
        }
      >
        {!selectedCountry && <AppIcon of='country' fontWeight='inherit' />}
        {selectedCountry && (
          <Stack direction='row' spacing={0.5} sx={{ alignItems: "center" }}>
            <CountryFlagIcon
              countryCode={selectedCountry.alpha2Code}
              sx={{ width: 24, borderRadius: "4px" }}
            />
            <Typography
              component='span'
              variant='body2'
              sx={{ fontWeight: "bold", color: (th) => th.palette.text.primary }}
            >
              {selectedCountry.alpha2Code}
            </Typography>
          </Stack>
        )}
      </DropdownIconButton>
    </Tooltip>
  );
}
