import { IconButton, SxProps, TextField, Theme } from "@mui/material";
import { getIn } from "formik";

import { CustomFormikSubProps } from "@/common/helpers/formik";
import { ValidationErrors } from "@/common/ts/error";
import { AssignmentPartyCustomInputDto } from "@/core/api/generated";

import ContactInput, { ContactData } from "../../Contact/ContactInput";
import AppIcon from "../../Icons/AppIcon";

interface Props {
  formikProps: CustomFormikSubProps<AssignmentPartyCustomInputDto | null | undefined>;
  required?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

export default function AssignmentPartyCustomInput({ formikProps, required, disabled }: Props) {
  const { values, touched, errors, setFieldValue } = formikProps;

  return (
    <>
      <TextField
        error={Boolean(touched?.name && errors?.name)}
        fullWidth
        required={required}
        disabled={disabled}
        helperText={touched?.name && errors?.name}
        label='Name'
        margin='dense'
        name='name'
        onChange={(newValue) => setFieldValue("name", newValue.target.value)}
        type='text'
        value={values?.name || ""}
        variant='outlined'
      />

      <ContactInput
        sx={{ mb: 1, minWidth: 400 }}
        value={{
          firstName: values?.contact?.personName?.firstName,
          lastName: values?.contact?.personName?.lastName,
          email: values?.contact?.email ?? "",
          phoneNumber: values?.contact?.phoneNumber ?? "",
        }}
        displayProps={{
          firstName: true,
          lastName: true,
          email: true,
          phoneNumber: true,
          nationalIdentityNumber: false,
          isPrimary: false,
        }}
        inputsProps={{
          firstName: {
            name: `contacts.personName.firstName`,
          },
          lastName: {
            name: `contacts.personName.lastName`,
          },
          email: {
            name: `contacts.email`,
          },
          phoneNumber: {
            name: `contacts.phoneNumber`,
          },
          nationalIdentityNumber: {
            name: `contacts.nationalIdentityNumber`,
          },
          isPrimary: {
            name: `contacts.isPrimary`,
          },
        }}
        errors={getIn(errors, `contact`) as ValidationErrors<ContactData>}
        action={
          <IconButton
            onClick={() => {
              setFieldValue("contact", values?.contact);
            }}
          >
            <AppIcon of='close' />
          </IconButton>
        }
        onChange={(newValue) => {
          setFieldValue(`contact.personName.firstName`, newValue.firstName);
          setFieldValue(`contact.personName.lastName`, newValue.lastName);
          setFieldValue(`contact.email`, newValue.email);
          setFieldValue(`contact.phoneNumber`, newValue.phoneNumber);
        }}
      />
    </>
  );
}
