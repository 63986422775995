import { ROUTE_PATH } from "@/common/constants/routing";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { AppPermission, AssignmentDto, AssignmentReferenceDto } from "@/core/api/generated";

import BaseEntityLink, { BaseEntityLinkInheritableProps } from "../components/BaseEntityLink";
import AssignmentInline, { AssignmentInlineProps } from "./AssignmentInline";

interface Props extends BaseEntityLinkInheritableProps {
  entity: AssignmentDto | AssignmentReferenceDto | null | undefined;
  entityId?: string | null;
  inlineProps?: Partial<AssignmentInlineProps>;
}

export default function AssignmentLink({ entity, entityId, inlineProps, ...otherProps }: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  return (
    <BaseEntityLink
      enabled={hasPermissions([AppPermission.FleetAppAccess, AppPermission.AssignmentRead])}
      to={ROUTE_PATH.ASSIGNMENT_VIEW(entity?.id || entityId || "")}
      icon={undefined}
      content={
        <AssignmentInline entity={entity} entityId={entityId} {...inlineProps} {...otherProps} />
      }
      {...otherProps}
      tooltipTitle={otherProps?.tooltipTitle || "Assignment"}
    />
  );
}
