import {
  Badge,
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { isNil } from "lodash-es";
import { useState } from "react";

import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "@/common/components/Entity/components/BaseEntitySearchAutocomplete";
import AppIcon from "@/common/components/Icons/AppIcon";
import { FeatureName } from "@/common/constants/featureName";
import { RepairHelper } from "@/common/helpers/entity/repair";
import { renderIf } from "@/common/helpers/render/renderIf";
import { StringHelper } from "@/common/helpers/string";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { featureManager } from "@/config/features";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  RepairSparePartDto,
  RepairSparePartGetPaginatedDto,
} from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import AppTypography from "../../Text/AppTypography";
import BaseRepairCustomFilterPopover from "../components/repair/BaseRepairCustomFilterPopover";
import CreateUpdateRepairSparePartModal from "./CreateUpdateRepairSparePartModal";
import RepairSparePartCrossTenantSelectorPopover from "./RepairSparePartCrossTenantSelectorPopover";
import RepairSparePartViewModal from "./View/RepairSparePartViewModal";

const defaultDisplayProps = {
  viewInModal: true,
  editInModal: true,
  crossTenantSelector: true,
  customFilter: true,
};

export type RepairSparePartAutocompleteOption = BaseAutocompleteOption<RepairSparePartDto>;

export const getRepairSparePartOptionTitle = (data: RepairSparePartDto): string =>
  StringHelper.joinIntoString([data.name || data.localNumber], ", ", {
    skipEmpty: true,
  });

export const repairSparePartToOption = (
  data: RepairSparePartDto,
): RepairSparePartAutocompleteOption => ({
  id: data.id!,
  title: getRepairSparePartOptionTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface RepairSparePartAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<RepairSparePartDto> {
  searchFilters?: Partial<Omit<RepairSparePartGetPaginatedDto, "search" | "includeIds">>;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export default function RepairSparePartAutocomplete({
  searchFilters,
  displayProps,

  isPreload = true,
  ...otherProps
}: RepairSparePartAutocompleteProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const currentTenant = useCurrentTenant();
  const authorizationInfo = useAuthorizationInfo();

  const [savedFlags, setSavedFlags] = useState(() => {
    return RepairHelper.getRepairSparePartAutocompleteSettings();
  });

  const [filtersApplied, setFiltersApplied] = useState({
    partType: !!savedFlags.filters.partType,
    make: !!savedFlags.filters.make,
    model: !!savedFlags.filters.model,
  });

  const [ignoreCanLoadOnce, setIgnoreCanLoadOnce] = useState(false);

  return (
    <BaseEntitySearchAutocomplete
      debugKey='RepairSparePartAutocomplete'
      sx={{ minWidth: 200, flex: 1 }}
      entityToOption={repairSparePartToOption}
      isPreload={isPreload}
      ignoreCanLoadCondition={ignoreCanLoadOnce}
      onLoaded={() => {
        setIgnoreCanLoadOnce(false);
      }}
      request={{
        requestFunc: apiClient.repairSparePartApi.apiV1RepairSparePartsGetPost,
        limit: 25,
        parameters: {
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          repairSparePartGetPaginatedDto: {
            ...searchFilters,
            customFilter: {
              makeId: filtersApplied.make ? searchFilters?.customFilter?.makeId : undefined,
              modelId: filtersApplied.model ? searchFilters?.customFilter?.modelId : undefined,
              partTypeId: filtersApplied.partType
                ? searchFilters?.customFilter?.partTypeId
                : undefined,
            },
          },
        },
        combineParameters: (params, newParams) => ({
          ...params,
          repairSparePartGetPaginatedDto: {
            ...params.repairSparePartGetPaginatedDto,
            ...newParams,
          },
        }),
        deps: [searchFilters, filtersApplied],
      }}
      label='Repair spare part'
      placeholder='Search for repair spare part...'
      renderOption={(props, option) => {
        return (
          <ListItem {...props}>
            <ListItemIcon>
              {renderIf()
                .if(option.optionType === AutocompleteOptionType.Normal)
                .then(<AppIcon of='repairSparePart' />)
                .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                .then(<AppIcon of='add' />)
                .render()}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant={
                    option.optionType === AutocompleteOptionType.DynamicCreateNew
                      ? "subtitle1"
                      : "body1"
                  }
                  component='div'
                >
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Box>{option.data?.name || option.title}</Box>

                    {!isNil(option.data?.matchMeta?.score) && (
                      <Box>
                        <Tooltip title='The bigger the match score the better the result suits your request.'>
                          <Typography variant='caption'>
                            <Chip
                              size='extraSmall'
                              variant='filled'
                              label={<>Match score: {option.data?.matchMeta?.score} / 100</>}
                            />
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </Stack>
                </Typography>
              }
              secondary={
                option.data && (
                  <Box>
                    <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                      {option.data?.partType?.name}
                    </AppTypography>

                    {RepairHelper.isExternal({
                      tenantId: currentTenant?.id,
                      repairEntity: option.data,
                    }) && (
                      <Box>
                        <Chip
                          variant='outlined'
                          size='extraSmall'
                          label={
                            <>
                              <AppIcon of='external' inText /> External
                            </>
                          }
                        />
                      </Box>
                    )}
                  </Box>
                )
              }
            />
          </ListItem>
        );
      }}
      entityViewModal={{
        if:
          displayProps?.viewInModal &&
          authorizationInfo.hasPermissions([AppPermission.RepairCatalogRead]),
        renderModal: (params) => (
          <RepairSparePartViewModal
            {...params.dialogProps}
            viewProps={{
              repairSparePart: params.entity,
            }}
          />
        ),
      }}
      entityEditModal={{
        if:
          displayProps?.editInModal &&
          authorizationInfo.hasPermissions([AppPermission.RepairCatalogManage]),
        renderModal: (params) => (
          <CreateUpdateRepairSparePartModal
            {...params.dialogProps}
            createUpdateProps={{
              repairSparePartId: params.entity.id,
              repairSparePart: params.entity,
              onSave: (newValue) => {
                params.handleEntityUpdated(newValue);
                params.dialogProps?.onClose?.({}, "escapeKeyDown");
              },
            }}
          />
        ),
      }}
      endAdornmentActions={{
        actions: [
          {
            if:
              displayProps?.crossTenantSelector &&
              featureManager.isEnabled(FeatureName.CrossTenantRepairEntityAccess),
            renderAction: (params) => (
              <RepairSparePartCrossTenantSelectorPopover
                clickBehavior={{}}
                trigger={
                  <AppIconButton
                    {...params.iconButtonProps}
                    title='My cross-company repair spare parts'
                  >
                    <AppIcon of='crossTenantBrowse' />
                  </AppIconButton>
                }
                selectorProps={{
                  repairSparePartAutocompleteProps: {
                    searchFilters: { match: searchFilters?.match },
                  },
                  onSave: (newValue) => {
                    console.log("Cross-tenant repair spare part selected", newValue);
                    if (newValue?.repairEntity) {
                      params.handleEntitySelected(newValue?.repairEntity);
                    }
                  },
                  onCancel: () => {},
                }}
              />
            ),
          },
          {
            if: displayProps?.customFilter,
            renderAction: (params) => {
              const activeFiltersCount = Object.values(filtersApplied).filter(Boolean).length;
              const isAnyFilterActive = activeFiltersCount > 0;

              return (
                <BaseRepairCustomFilterPopover
                  trigger={
                    <AppIconButton
                      {...params.iconButtonProps}
                      color={isAnyFilterActive ? "primary" : "default"}
                      title='Custom Filter'
                    >
                      <AppIcon of='filter' />
                      {isAnyFilterActive && (
                        <Badge
                          badgeContent={activeFiltersCount}
                          color='primary'
                          sx={{ position: "absolute", top: 0, right: 0 }}
                        />
                      )}
                    </AppIconButton>
                  }
                  availableFilters={{
                    damageType: false,
                    partType: true,
                    make: true,
                    model: true,
                  }}
                  initialFlags={filtersApplied}
                  onSave={(newFlags) => {
                    setIgnoreCanLoadOnce(true);

                    setFiltersApplied((prev) => ({
                      ...prev,
                      ...newFlags,
                    }));

                    const mergedSettings = {
                      ...savedFlags,
                      filters: {
                        ...filtersApplied,
                        ...newFlags,
                      },
                    };

                    setSavedFlags(mergedSettings);

                    RepairHelper.saveRepairSparePartAutocompleteSettings(mergedSettings);
                  }}
                  onCancel={() => {}}
                />
              );
            },
          },
        ],
      }}
      {...otherProps}
    />
  );
}
