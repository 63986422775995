import { RepairMaterialDto } from "@/core/api/generated";

import BaseRepairEntityCrossTenantSelector, {
  BaseRepairEntityCrossTenantSelectorInheritableProps,
} from "../components/repair/BaseRepairEntityCrossTenantSelector";
import RepairMaterialAutocomplete, {
  RepairMaterialAutocompleteProps,
} from "./RepairMaterialAutocomplete";

export type RepairMaterialCrossTenantSelectorProps =
  BaseRepairEntityCrossTenantSelectorInheritableProps<RepairMaterialDto> & {
    repairMaterialAutocompleteProps?: Partial<RepairMaterialAutocompleteProps>;
  };

export default function RepairMaterialCrossTenantSelector({
  repairMaterialAutocompleteProps,
  ...otherProps
}: RepairMaterialCrossTenantSelectorProps) {
  return (
    <BaseRepairEntityCrossTenantSelector<RepairMaterialDto>
      repairEntityName={{
        singular: "repair material",
        plural: "repair materials",
      }}
      renderRepairEntityAutocomplete={(params) => (
        <RepairMaterialAutocomplete
          {...params.autocompleteProps}
          {...repairMaterialAutocompleteProps}
          searchFilters={{
            ...params.searchFilters,
            ...repairMaterialAutocompleteProps?.searchFilters,
          }}
          displayProps={{
            ...params.displayProps,
            ...repairMaterialAutocompleteProps?.displayProps,
          }}
        />
      )}
      {...otherProps}
    />
  );
}
