import { StandardTextFieldProps, TextField, debounce } from "@mui/material";
import { useCallback } from "react";

import { tenantService } from "@/common/services/tenant";
import { apiClient } from "@/core/api/ApiClient";

interface Props extends StandardTextFieldProps {
  onError?: (err: unknown) => void;
}

export default function TenantIdentifierInput({
  value,
  helperText,
  onChange,
  onError,
  ...other
}: Props) {
  const validateCompanyIdentifierDebounce = useCallback(
    debounce(
      async (
        ...args: Parameters<
          typeof apiClient.accountApi.apiV1AccountRegisterCompanyIdentifierValidatePost
        >
      ) =>
        apiClient.accountApi
          .apiV1AccountRegisterCompanyIdentifierValidatePost(...args)
          .catch((err) => onError && onError(err)),
      300,
    ),
    [apiClient.accountApi.apiV1AccountRegisterCompanyIdentifierValidatePost],
  );

  return (
    <TextField
      autoComplete='off'
      label='Identifier'
      name='identifier'
      helperText={
        helperText
          ? helperText
          : `Your company will be available at ${tenantService.getTenantUrl(
              `${value}`,
              false,
              false,
            )}`
      }
      {...other}
      value={value || ""}
      onChange={async (e) => {
        if (onChange) {
          onChange(e);
        }
        await validateCompanyIdentifierDebounce({
          validateCompanyIdentifierDto: {
            identifier: e.target.value,
          },
        });
      }}
    />
  );
}
